import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {IoMdAddCircle} from "react-icons/all";
import {Dialog, DialogContent} from '@material-ui/core';
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import CardForm from "../forms/CardForm";
import DialogActions from "@material-ui/core/DialogActions";
import {defaultCardImages} from "@PlaystreamENV/front-lib/src/card-image-uploader/CardImageUploader";


function CreateCardDialog({onCreated}) {
    const {handleSubmit} = useForm();
    const [open, setOpen] = useState(false);
    const {cardsStore} = useStores();
    const onSave = () => {
        handleSubmit(async (formData) => {

            const image = formData.image ? formData.image : defaultCardImages[formData.type];
            await cardsStore.createCard({...formData, image})
            closeModal();
            onCreated && onCreated();
        })();
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <>
            <Button size='small' variant='contained' color='default' onClick={() => {
                setOpen(!open)
            }} startIcon={<IoMdAddCircle/>}>
                Create Card
            </Button>
            <Dialog open={open} onClose={closeModal} maxWidth={"lg"}>
                <DialogTitle title={'Create Card'} onClose={closeModal}/>
                <DialogContent>
                    <CardForm card={{metadata: {timer_enabled: false}}}/>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeModal} variant='contained'
                            color='secondary'>
                        Cancel
                    </Button>
                    <Button onClick={onSave} variant='contained'
                            color='primary'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withForm(CreateCardDialog)
