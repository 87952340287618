const {
    REACT_APP_ENV,
    REACT_APP_UI_BASE_URL
} = window && window.env && typeof window.env === "object" ? window.env : process.env;

const redirectFromLocalhost = () => {
    const {href} = window.location;

    const isLocalhost = href.indexOf("localhost") > -1;
    if (isLocalhost && REACT_APP_ENV === 'dev' && REACT_APP_UI_BASE_URL) {
        window.location.href = REACT_APP_UI_BASE_URL + (window.location.pathname + window.location.search).substr(1);
    }
}

redirectFromLocalhost();