import React from 'react';
import {FormInputErrorMessage} from "./FormInputErrorMessage";
import {useForm} from "./context";
import {InputPassword, InputText} from "./inputs";

function FormInputText({name, startAdornment, endAdornment, validation, ...rest}) {

    const {errors, register, formState} = useForm();
    return (
        <>
            <InputText
                name={name}
                InputProps={{
                    endAdornment, startAdornment
                }}

                error={!!errors[name]}
                inputRef={register(validation)}
                {...rest}
            />


            <FormInputErrorMessage name={name}/>
        </>
    )
}


export {FormInputText}
