import React, {useState} from "react";
import {Container, Tab, Tabs as TabsMaterial, withStyles} from "@material-ui/core";
import TabPanel from "./tab-panel/TabPanel";

const CustomTabs = withStyles({
    root: {
        marginBottom: '35px',
    },
    flexContainer: {
        padding: '10px 10px 0 !important',
        borderBottom: '1px solid #939495 !important',
        justifyContent: 'start !important',
    }
})(TabsMaterial)

const Tabs = ({labels, panels}) => {
    const [displayedPanel, setDisplayedPanel] = useState(0)
    const onTabChange = (e, tabIndex) => {
        setDisplayedPanel(tabIndex)
    }
    return (
        <div>
            <CustomTabs
                value={displayedPanel}
                indicatorColor='secondary'
                onChange={onTabChange}
                textColor='secondary'>
                {labels.map(label => <Tab label={label} key={label} style={{fontSize: '16px'}}/>)}
            </CustomTabs>
            <Container maxWidth={false}>
                {panels.map((panel, index) => (
                    <TabPanel key={index} panelIndex={index} displayedPanel={displayedPanel}>
                        {panel}
                    </TabPanel>
                ))}
            </Container>
        </div>

    )
}

export default Tabs;