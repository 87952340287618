import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ReferralsTable from "../../../components/entities/referrals/table/ReferralsTable";
import useQueryFilters from "../../hooks/useQueryFilters";

const ReferralsRoute = ({}) => {

    const {id = "", source = ""} = useQueryFilters();
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Referrals
                    </Typography>


                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>

                <ReferralsTable defaultFilters={{id, source}}/>
            </Box>
        </>
    )
}

export default ReferralsRoute
