import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MdTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHeader from "./table-components/table-header/TableHeader";
import {BaseLoader} from "../loaders/BaseLoader";
import "./table.scss"
import TableBody from "./table-components/table-body/TableBody";
import BasicTablePagination from "./table-components/table-pagination/BasicTablePagination";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',
        minHeight: 300
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none',
        borderRadius: '0',
        backgroundColor: '#1b1d21'
    },
    table: {
        // minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const TableView = ({
                       rows,
                       columns,
                       count,
                       rowProps,
                       renderEmpty,
                       size,
                       showHeader,
                       rowsPerPageOptions,
                       isLoading,
                       pagination,
                       setPagination,
                       orderDirection,
                       orderBy,
                       onOrder
                   }) => {
    const classes = useStyles()
    const isTableEmpty = (!rows || !rows.length) && !isLoading

    return (
        <div className={classes.root}>
            <>
                {isLoading && <BaseLoader/>}
                {!!rows && <Paper className={classes.paper}>
                    <TableContainer>
                        <MdTable className={classes.table} size={size}>
                            {!!showHeader &&
                            <TableHeader
                                columns={columns}
                                orderDirection={orderDirection}
                                orderBy={orderBy}
                                onOrder={onOrder}/>}
                            {!isTableEmpty && <TableBody
                                rows={rows}
                                columns={columns}
                                rowProps={rowProps}
                            />}
                        </MdTable>
                    </TableContainer>
                    {isTableEmpty && renderEmpty}
                    {!!count && <BasicTablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={count}
                        pagination={pagination}
                        setPagination={setPagination}
                    />}
                </Paper>}
            </>
        </div>
    )

}

export default TableView