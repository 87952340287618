import {useStores} from "../mbox/hooks/useStores";


export const useSocket = () => {

    const {socketStore} = useStores()

    const {isConnected} = socketStore;
    const connect = async (secret, onForbidden) => {
        // console.log("useSocket logger: connecting...");

        const socket = socketStore.connect(secret);

        subscribeToSailsEvents(socket, secret, onForbidden);

        onReconnect((ev) => {
            console.log(`useSocket logger: socket reconnect event - reSubscribeToSailsEvents attempt #${ev} with secret ${secret} isConnected ${isConnected}`);
            subscribeToSailsEvents(socket, secret, onForbidden);
        });

        // socket.on("user", (data) => {
        //     console.log("useSocket logger: message arrived", data)
        // });

        socket.on("error", (error) => {
            console.log("useSocket logger: sails.error", error)
        });

    };


    const subscribeToSailsEvents = async (socket, secret, onForbidden) => {

        return new Promise(async (resolve, reject) => {
            socket.get(`/api/v1/socket/subscribe/${secret}`, function (data, jwr) {
                if (jwr.error) {
                    console.error("Could not subscribe to socket events", JSON.stringify(jwr));
                    if (jwr.statusCode === 403) {
                        // socketStore.disconnect();
                        onForbidden && onForbidden();
                    }
                    reject(jwr.error)
                    return;
                }
                // console.log("socket - subscribed", jwr)
                // console.log("useSocket logger: socket connection status", JSON.stringify(data))
                resolve(data);
            });
        })
    }

    const subscribe = (channel, handler) => {

        const {socket} = socketStore;
        // console.log("socket - subscribing to channel")

        const onUser = (data) => {
            const event_channel = data.channel;
            if (channel === "all" || event_channel === channel && handler)
                handler(data);
        };

        socket.on("user", onUser);
        return () => {
            socket.off("user", onUser);
        };
    };

    const subscribeToGlobalChannel = (handler) => {
        const {socket} = socketStore;
        socket.on('gg-global', function (data) {
            console.log('gg-global', data);
            handler(data)
        })
        return () => {
            socket.off("gg-global", handler);
        };
    }

    const onReconnect = (handler) => {
        const {socket} = socketStore;
        socket.on('reconnect', handler);

        return () => {
            socket.off("reconnect", handler);
        };
    }


    return {connect, subscribe, subscribeToGlobalChannel, onReconnect, isConnected};
};

