import React from "react";
import FeatureList from "./body/FeatureList";
import CardActions from "./body/CardActions";
import CardBackground from "./body/CardBackground";
import "./rarity-colors.scss";
import "./card.scss";
import IconButtonPlaySound from "./buttons/play-sound/IconButtonPlaySound";
import CardPrice from "./body/CardPrice";
import CardLevel from "./body/CardLevel";
import CaByCa from "./body/CaByCa";

const CardItem = ({card, amount, rarity, level, className, volume = 1}) => {
    const {name, image, metadata = {}, disabled} = card;
    const {sound, video} = metadata;
    const hasSound = !!sound;
    const hasVideo = !!video;
    console.log(card.type);
    return (
        <div className={`card-item ${className} ${disabled ? 'card-disabled' : ''} ${hasVideo ? 'card-video' : ''}`}>
            <CardBackground rarity={rarity}/>
            <div className={`card-image-container`}>
                <CaByCa card={card}/>
                {card.type !== 'merchandise' && <CardPrice amount={amount} rarity={rarity}/>}
                {!hasVideo && <img className='card-item__image' src={image}/>}
                {hasVideo && <Video src={video}/>}
                <div className={'card-overlay'}>

                        <span className={'card-name'}>
                            {name}
                        </span>

                    <CardLevel level={level} rarity={rarity}/>
                </div>
            </div>
            <div className='card-item__bottom' onClick={(e) => {
                e.stopPropagation();
            }}>
                <div className={'card-actions-container'}>
                    <CardActions card={card}/>
                    <FeatureList card={card}/>
                </div>

                {/*<span className='price'>{min_price}$</span>*/}
                {hasSound && <IconButtonPlaySound volume={volume} sound={metadata.sound}/>}
            </div>

        </div>
    )
}

const Video = ({src}) => {
    return <>
        <video width="600" height="540" autoPlay={true}>
            <source src={src} type="video/mp4"/>
        </video>
    </>
}
export default CardItem
