import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {Grid, Slide} from "@material-ui/core";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {BiHistory} from "react-icons/all";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogActions from "@material-ui/core/DialogActions";
import {parseLogs} from "./read-logs";

const ParseLogsDialog = ({}) => {

    const [isOpen, setIsOpen] = useState(false);
    const {handleSubmit, setValue} = useForm();


    const onClose = () => {
        setIsOpen(false)
    }
    const submitLogs = () => {

        handleSubmit(async (formData) => {
            // onClose();

            const parsedLogs = parseLogs(formData.logs)
            setValue("parsed_logs", parsedLogs, {
                shouldValidate: false,
                shouldDirty: false
            })

        })();
    }
    return (
        <>
            <Button   variant='contained' color='secondary' onClick={() => {
                setIsOpen(!isOpen)
            }} startIcon={<BiHistory/>}>
                Parse Logs
            </Button>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'down'
                }}
                maxWidth={"xl"}
                open={isOpen}
                fullWidth
                onClose={onClose}

            >
                <DialogTitle title={<>
                    Logs
                </>} onClose={onClose}/>


                <DialogContent>

                    <form>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputText label={'Place logs here'} name='logs' className={'plain-logs'}
                                               variant={"outlined"}
                                               InputLabelProps={{shrink: true}}
                                               multiline={true}
                                               validation={{}}

                                />

                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Parsed Logs'} name='parsed_logs' className={'parsed-logs'}
                                               variant={"outlined"}
                                               readOnly={true}
                                               InputLabelProps={{shrink: true}}
                                               multiline={true}
                                               validation={{}}

                                />
                            </Grid>

                        </Grid>


                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant='contained'
                            color='secondary'>
                        Cancel
                    </Button>
                    <Button onClick={submitLogs} variant='contained'
                            color='primary'>
                        Parse Logs
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withForm(ParseLogsDialog)
