import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "./CardForm";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";

const ChallengeExpirationPicker = ({metadata, type}) => {
    const {watch} = useForm();
    const watchMetaData = watch('metadata', metadata);
    const timer_enabled = watch("metadata.timer_enabled", metadata.timer_enabled || false);
    const timeUnitLabel = (() => {
        if (!watchMetaData) return ''
        if (type === 'ai') {
            const {event} = watchMetaData
            return `Time Per ${(event && event.replace('_', ' ')) || 'event'} (Minutes)`

        }
        return 'Contract Duration (Minutes)'
    })()
    return (
        <>
            <Grid item xs={12}>
                <FormInputText label={'metadata.timer_enabled'} className={"hidden"}
                               defaultValue={metadata.timer_enabled}
                               name='metadata.timer_enabled'
                               validation={formValidation.required}/>
                <FormInputCheckbox name={'metadata.timer_enabled'} defaultChecked={metadata.timer_enabled || false}
                                   label={"Timer Enabled"}
                                   validation={{}}/>
            </Grid>
            {timer_enabled && <Grid item xs={12}>
                <FormInputText
                    InputProps={{ inputProps: { min: 1 } }}
                    label={timeUnitLabel} type={"number"} defaultValue={metadata.time_unit}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    name='metadata.time_unit'
                    validation={formValidation.required}/>
            </Grid>}
        </>
    )

}

export default ChallengeExpirationPicker
