import React from "react";
import {FaRegPauseCircle, FaRegPlayCircle} from "react-icons/all";
import IconButton from "@material-ui/core/IconButton";
import {CircularProgress} from "@material-ui/core";
import useSound from "./useSound";

const IconButtonPlaySound = ({sound, volume = 1}) => {
    const {play, pause, isLoading, isPlaying} = useSound({sound, volume});
    return (
        <IconButton size={"small"} onClick={isPlaying ? pause : play}>
            {!isLoading && <>
                {!isPlaying && <FaRegPlayCircle color={"#fff"}/>}
                {isPlaying && <FaRegPauseCircle color={"#fff"}/>}
            </>}

            {isLoading && <CircularProgress size={22} color={"inherit"}/>}
        </IconButton>
    )

}

export default IconButtonPlaySound
