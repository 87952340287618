import api from "@PlaystreamENV/front-lib/src/services/api";
import SoundService from "@PlaystreamENV/front-lib/src/sound/SoundService";


export class TextToSpeech {

    static async getVoices() {
        const response = await api.get(`/tts/get-voices`)
        return response.data.voices;
    }


    static async getPreviewAudio(voice_name) {

        const response = await api.get(`/tts/preview-tts/`, {
            params: {
                voice_name
            }
        })
        return await response.data.playableAudio64
    }

}

