import React from "react";
import FormSelect from "../../form/FormSelect";
import {roles, toRoleText} from "./roles";
import {useUser} from "./VisibleByRole";

function RoleSelect({defaultValue, name = "role", ...rest}) {

    const user = useUser();
    return (
        <FormSelect label={"Role"} defaultValue={defaultValue} name={name} native={true} allowNull={false}  {...rest}>

            <option value={null} style={{display: 'none'}}/>


            {roles.map((rw) => {

                const {key, role} = rw;
                return <option key={key} value={key}
                               disabled={key == "admin" || key === "superuser"}> {toRoleText(key)} </option>
            })}
        </FormSelect>

    )
}

export default RoleSelect
