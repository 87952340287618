import {action, computed, observable, toJS} from "mobx";
import labsApi from "../../../services/labsApi";


export class LabsStore {

    constructor() {


    }


    @observable  _predictionsLogs = null;


    @computed get predictionsLogs() {
        return toJS(this._predictionsLogs)
    }


    @observable  _snapshotsLogs = null;


    @computed get snapshotsLogs() {
        return toJS(this._snapshotsLogs)
    }

    @observable  _heartbeats = null;


    @computed get heartbeats() {
        return toJS(this._heartbeats)
    }


    @action
    async getPredictionsLogs(params) {
        const response = await labsApi.get('/labs/predictions', {params});

        this._predictionsLogs = response.data;
        return response.data.reverse();
    }

    @action
    async getSnapshotsLogs(params) {
        const response = await labsApi.get('/labs/snapshots', {params});
        this._snapshotsLogs = response.data;
        return response.data.reverse();

    }

    @action
    async getHeartbeats(params) {
        const response = await labsApi.get('/labs/heartbeats', {params});
        this._heartbeats = response.data;
        return response.data.reverse();
    }

    @action
    async deleteAllLabsLog(params) {
        const response = await labsApi.delete('/labs/delete-all-logs', {params});
        this._snapshotsLogs = null;
        this._predictionsLogs = null;
        this._heartbeats = null;

    }


}
