import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../entities/zones/panels/ZonePanels";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";

const ReferralForm = ({applicationConfigs}) => {

    const {
        referral_recruiter_interaction_bonus,
        referral_recruiter_streak_bonus,
        referral_recruit_interaction_bonus,
        referral_recruit_streak_bonus,
        referral_week_streak_count,
        referral_enabled,
        referral_min_followers
    } = applicationConfigs;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3>Referral </h3>
                </Grid>
                <Grid item sm={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={referral_enabled}
                                    name='referral_enabled'
                                    label='Referral Enabled'
                                    validation={{}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Recruiter Interaction Bonus'}
                                    defaultValue={referral_recruiter_interaction_bonus}
                                    type={"number"}
                                    name='referral_recruiter_interaction_bonus'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Recruiter Streak Bonus'}
                                    defaultValue={referral_recruiter_streak_bonus}
                                    type={"number"}
                                    name='referral_recruiter_streak_bonus'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Recruit Interaction Bonus'}
                                    defaultValue={referral_recruit_interaction_bonus}
                                    type={"number"}
                                    name='referral_recruit_interaction_bonus'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Recruit Streak Bonus'}
                                    defaultValue={referral_recruit_streak_bonus}
                                    type={"number"}
                                    name='referral_recruit_streak_bonus'
                                    validation={formValidation.required}/>
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <FormInputText*/}
                            {/*        label={"Weeks for streak"}*/}
                            {/*        defaultValue={referral_week_streak_count}*/}
                            {/*        type={"number"}*/}
                            {/*        name='referral_week_streak_count'*/}
                            {/*        validation={formValidation.required}/>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <FormInputText*/}
                            {/*        label={"Minimum Followers"}*/}
                            {/*        defaultValue={referral_min_followers}*/}
                            {/*        type={"number"}*/}
                            {/*        name='referral_min_followers'*/}
                            {/*        validation={formValidation.required}/>*/}
                            {/*</Grid>*/}

                        </Grid>
                    </form>

                </Grid>


            </Grid>
        </>
    )

}

export default ReferralForm
