import React from "react";
import ReactJson from "react-json-view";

const GiftsMappingLabel = ({value}) => {

    return (
        <>
            <ReactJson  theme={'threezerotwofour'} enableClipboard={false} displayObjectSize={false} quotesOnKeys={false}
                       displayDataTypes={false} name={null} src={value || {}}/>
        </>
    )

}

export default GiftsMappingLabel