import {AuthStore} from '@PlaystreamENV/front-lib/src/mbox/stores/auth/AuthStore'
import {GamesStore} from "./entities/GamesStore";
import {TransactionsStore} from "./entities/TransactionsStore";
import {TicketsStore} from "./entities/TicketsStore";
import {StreamsStore} from "./entities/StreamsStore";
import {DashboardStore} from "./entities/DashboardStore";
import {UsersStore} from "./entities/UsersStore";
import {AdminStore} from "./admin/AdminStore";
import {SocketStore} from "@PlaystreamENV/front-lib/src/mbox/stores/socket/SocketStore";
import {LabsStore} from "./admin/LabsStore";
import {InteractionsStore} from "./entities/InteractionsStore";
import {CardsStore} from "./entities/CardsStore";
import {CategoriesStore} from "./entities/CategoriesStore";
import {ZonesStore} from "./entities/ZonesStore";
import {AchievementsStore} from "./entities/AchievementsStore";
import {ReferralsStore} from "./entities/ReferralsStore";
import {CouponsStore} from "./entities/CouponsStore";
import {EventsStore} from "./entities/EventsStore";
import {BotsStore} from "./entities/BotsStore";

export default class RootStore {
    authStore = new AuthStore();
    gamesStore = new GamesStore();
    eventsStore = new EventsStore();
    botsStore = new BotsStore();
    interactionsStore = new InteractionsStore();
    zonesStore = new ZonesStore();
    cardsStore = new CardsStore();
    achievementsStore = new AchievementsStore();
    categoriesStore = new CategoriesStore;
    transactionsStore = new TransactionsStore();
    ticketsStore = new TicketsStore();
    streamsStore = new StreamsStore();
    usersStore = new UsersStore();
    dashboardStore = new DashboardStore();
    adminStore = new AdminStore();
    socketStore = new SocketStore();
    referralsStore = new ReferralsStore();
    couponsStore = new CouponsStore();
    labsStore = new LabsStore();
};
