import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import {Box} from "@material-ui/core";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import FeaturesLabel from "../labels/FeaturesLabel";
import CouponCommunityLabel from "../labels/CouponCommunityLabel";


function ClaimedCouponsTable({}) {
    const {couponsStore} = useStores();
    const filtersOptions = [
        {
            type: 'text',
            name: "created_by",
            label: "Created by",
            style: {width: '250px'},
            rules: {required: false},
        },
    ];
    //todo
    const getClaimedCoupons = async (params) => {
        const {data} = await couponsStore.getClaimedCoupons(params)
        return {data: data.coupons, count: data.count};
    }
    return (
        <>
            <Table
                rowProps={{hover: true}}
                filtersOptions={filtersOptions}
                loadData={getClaimedCoupons}
                columns={[
                    {
                        key: 'id',
                        title: 'ID',
                        cell: ({row}) => <>

                            {row.id}
                        </>
                    },
                    {
                        key: 'key',
                        title: 'Key',
                        cell: ({row}) => <>

                            {row.coupon.key}
                        </>
                    },
                    {
                        key: 'description',
                        title: 'description',
                        cell: ({row}) => <>

                            {row.coupon.description}
                        </>
                    },
                    {
                        key: 'rarity',
                        title: 'rarity',
                        cell: ({row}) => <>

                            {row.coupon.rarity}
                        </>
                    },
                    {
                        key: 'streamer',
                        sortable: false,
                        title: 'streamer',
                        cell: ({row}) => <>

                            {row.streamer && <>

                                <span className='text-bold'>{row.streamer.username}</span>
                                <Box mb='1'>
                                    <span>{row.streamer.email} </span>
                                </Box>
                                <Box marginTop='5px'>
                                    {row.streamer.id}
                                </Box>
                            </>}
                        </>
                    },
                    {
                        key: 'discount',
                        title: 'discount',
                        cell: ({row}) => <>

                            {row.coupon.discount}
                        </>
                    },
                    {
                        key: 'limit',
                        title: 'limit',

                        cell: ({row}) => <>

                            {row.coupon.limit}
                        </>
                    },
                    {
                        key: 'features',
                        title: 'Features',
                        cell: ({row}) => <>
                            <FeaturesLabel coupon={row.coupon}/>
                        </>
                    },
                    {
                        key: 'community',
                        title: 'Community',
                        cell: ({row}) => <>

                            <CouponCommunityLabel coupon={row.coupon}/>
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'CreatedAt',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />
        </>
    )
}


export default ClaimedCouponsTable
