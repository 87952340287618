import React, {useEffect} from "react";
import GamesTabs from "./tabs/GamesTabs";
import LabsCharts from "./LabsCharts";
import Box from "@material-ui/core/Box";
import {observer} from "mobx-react-lite";

import EventsPicker from "./events/EventsPicker";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";

const GamesPredictionLogsView = observer(({streamer, game}) => {
        const {eventsStore} = useStores();
        const [selectedGame, setSelectedGame] = React.useState(game);
        const events = eventsStore.events.filter(e => e.game.key === selectedGame).map(e => e.key);

        const [selectedEvent, setSelectedEvent] = React.useState(events[0]);

        useEffect(() => {
            setSelectedEvent(events[0])
        }, [selectedGame])
        //
        // useEffect(() => {
        //     setSelectedGame(game)
        // }, [game])

        return (
            <>
                {!!selectedGame && <GamesTabs selectedGame={selectedGame} onChange={(val) => {
                    setSelectedGame(val)
                }}/>}
                <Box pt={2}>
                    <EventsPicker events={events} value={selectedEvent} onChange={(ev) => {
                        setSelectedEvent(ev)
                    }}/>
                </Box>

                {events.filter(ev => ev === selectedEvent).map(event => {
                    return <Box key={`prediction-logs-${event}`} pb={5}>


                        <LabsCharts streamer={streamer} game={selectedGame} event={event}/>
                    </Box>
                })}

            </>
        )
    }
)

export default React.memo(GamesPredictionLogsView)
