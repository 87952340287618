import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import UserForm from "../forms/UserForm";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog} from "@material-ui/core";


function EditUserDialog({user, onClose,onDeleted}) {
    const {handleSubmit} = useForm();
    const {usersStore} = useStores();
    const onSave = () => {

        handleSubmit(async (formData) => {
            await usersStore.editUser({id: user.id, ...formData})
            onClose();

        })();
    }
    return (
        <Dialog open={!!user} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit User'} onClose={onClose}/>
            {user && <DialogContent>
                <UserForm user={user} onDeleted={onDeleted}/>
            </DialogContent>}
            <DialogActions onSave={onSave} onCancel={onClose}/>
        </Dialog>
    )
}

export default withForm(EditUserDialog)
