import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Slide} from "@material-ui/core";
import UsersTabs from "../tabs/UsersTabs";

const UserTabsDialog = ({user, onClose, ...rest}) => {
    return (
        <>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'down'
                }}
                maxWidth={"xl"}

                open={!!user}
                fullWidth={true}
                onClose={onClose}
                {...rest}
            >

                <DialogTitle title={<div>User Payments </div>} onClose={onClose}/>
                <DialogContent style={{height: 800}}>
                    <UsersTabs user={user}/>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default UserTabsDialog
