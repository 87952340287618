import React from "react";
import CardTypeLabel from "@PlaystreamENV/front-lib/src/zone/labels/CardTypeLabel";
import Box from "@material-ui/core/Box";
import {Tooltip} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const InteractionCardLabel = ({interaction}) => {
    const {push} = useHistory();
    const {type, card} = interaction;

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            style={{cursor: 'pointer'}}
            onClick={() => push(`/cards/?card=${interaction.card.id}`)}
        >
            <CardTypeLabel type={card.type} showText={false}/>
            <Tooltip title={card.description} arrow placement="top">
                <Box display={"flex"} flexDirection={"column"}>
                    <span className={'primary-color'}>{type || card.type}</span>
                    <span>{card.name}</span>
                </Box>
            </Tooltip>
        </Box>
    )

}

export default InteractionCardLabel
