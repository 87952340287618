import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import {Box, Button} from "@material-ui/core";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import FeaturesLabel from "../labels/FeaturesLabel";
import CouponCommunityLabel from "../labels/CouponCommunityLabel";
import YesNoLabel from "../../games/labels/YesNoLabel";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import CreateCouponDialog from "../dialogs/CreateCouponDialog";
import EditCouponDialog from "../dialogs/EditCouponDialog";

function CouponsTable() {
    const [rowToEdit, setRowToEdit] = useState(null);
    const [lastCreated, setLastCreated] = useState(new Date());
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const {couponsStore} = useStores();
    const filtersOptions = [
        {
            type: 'text',
            name: "created_by",
            label: "Created by",
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "key",
            label: "Coupon Key",
            defaultValue: "",
            style: {width: '250px'},
            rules: {required: false},
        }
    ];
    const getCoupons = async (params) => {
        const {data} = await couponsStore.getCoupons(params)
        return {data: data.coupons, count: data.count};
    }
    return (
        <>
            <CreateCouponDialog
                onCreated={() => {
                    setLastCreated(new Date())
                }}
            />
            <Table
                rowProps={{hover: true}}
                loadCounter={lastUpdated.getTime() + lastCreated.getTime()}
                filtersOptions={filtersOptions}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => setRowToEdit(row)}
                                >
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',
                        cell: ({row}) => <>

                            {row.id}
                        </>
                    },
                    {
                        key: 'key',
                        title: 'Key',
                        cell: ({row}) => <>

                            {row.key}
                        </>
                    },
                    {
                        key: 'description',
                        title: 'description',
                        cell: ({row}) => <>

                            {row.description}
                        </>
                    },
                    {
                        key: 'rarity',
                        title: 'rarity',
                        cell: ({row}) => <>

                            {row.rarity}
                        </>
                    },
                    {
                        key: 'created_by',
                        sortable: false,
                        title: 'Created by',
                        cell: ({row}) => <>

                            {row.created_by && <>

                                <span className='text-bold'>{row.created_by.username}</span>
                                <Box mb='1'>
                                    <span>{row.created_by.email} </span>
                                </Box>
                                <Box marginTop='5px'>
                                    {row.created_by.id}
                                </Box>
                            </>}
                        </>
                    },
                    {
                        key: 'discount',
                        title: 'discount',
                        cell: ({row}) => <>

                            {row.discount}%
                        </>
                    },
                    {
                        key: 'usage',
                        title: 'usage',

                        cell: ({row}) => <>

                            {row.usage}
                        </>
                    },
                    {
                        key: 'features',
                        title: 'Features',
                        cell: ({row}) => <>
                            <FeaturesLabel coupon={row}/>
                        </>
                    },
                    {
                        key: 'community',
                        title: 'Community',
                        cell: ({row}) => <>

                            <CouponCommunityLabel coupon={row}/>
                        </>
                    },
                    {
                        key: 'published',
                        title: 'published',

                        cell: ({row}) => <>
                            <YesNoLabel value={row.published}/>
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'CreatedAt',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
                loadData={getCoupons}
            />

            <EditCouponDialog
                coupon={rowToEdit}
                onSaved={() => {
                    setLastUpdated(new Date());
                }}
                onClose={() => setRowToEdit(null)}
            />

        </>
    )
}


export default CouponsTable
