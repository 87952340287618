import React, {useState, useEffect} from 'react'
import Fade from "@material-ui/core/Fade";
import {BaseAlert} from "./alerts/BaseAlert";
import {useForm} from "./context";


function FormGlobalErrorMessage() {
    const {errors, clearErrors} = useForm();

    const error = errors["global"];

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {

        setIsVisible(!!error)

    }, [error]);


    if (!error) return <></>;
    return (
        <Fade in={isVisible} onExited={() => {
            clearErrors('global')
        }}>
            <BaseAlert message={error.message} variant='error' onClose={() => setIsVisible(false)}/>
        </Fade>
    )
}

export {FormGlobalErrorMessage};
