export class ChallengeModel {

    constructor(props) {

        for (const prop in props) this[prop] = props[prop];

        // this.isCompletedSteps = this.card.type === "ai" && this.progression && this.progression.step < this.progression.steps_to_complete;
        // console.log(this.isCompletedSteps);
    }

    getTimeLeft() {
        const now = new Date().getTime()
        return this.expiration_time - now;
    }

    getIsTicking() {
        return this.getIsActive() && this.getTimeLeft() > 0;
    }

    getPercent() {
        return this.progression ? (this.progression.step / this.progression.steps_to_complete * 100) : 0;
    }

    getIsActive() {
        const now = new Date().getTime()
        return ((!this.expiration_time || this.expiration_time > now) && this.getPercent() < 100) && this.status.indexOf("refund") === -1;
    }

    // getIsExpired() {
    //     const now = new Date().getTime()
    //     const {status, expiration, expiration_time} = this;
    //     const isExpired = (expiration_time < now && expiration > 0) || status === "refund-expired";
    //     return isExpired;
    //
    // }
    //
    // getIsRefunded() {
    //     const {status} = this;
    //     const isRefunded = status.indexOf("refund") > -1 && status !== "refund-expired";
    //
    //     return isRefunded;
    //
    // }

    getStatus() {

        // const {steps_to_complete} = metadata;
        return (() => {


            if (!this.getIsTicking() && !this.getIsActive() && this.getPercent() === 100) {
                return "completed"
            }

            if (this.is_donation && this.status === "donated")
                return "donated";


            return 'created';
        })();

    }


    // getIsActive() {
    //     return this.getStatus() === "uncompleted"
    // }
}
