import axios from 'axios'

const labsApi = axios.create({
    baseURL: process.env.REACT_APP_LABS_URL + "api/v1",
    withCredentials: true,
    timeout: 2 * 60 * 1000,
    headers: {
        'Content-Type': 'application/json'
    },
});



export default labsApi

