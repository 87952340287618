import React, {useState} from "react";
import TusDropZone from "../tus/TusDropZone";
import {Controller} from "react-hook-form";
import CardItem from "../obs/cards/CardItem";
import "./card-image-uploader.scss";

const AiDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/ai-default.png";
const ContractDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/contract-default.png";
const EndorsementDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/endorsement-default.png";
const DigitalAssetDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/super-card-default.png";
const MerchandiseDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/merch-default.png"; 

export const defaultCardImages = {
    "ai": AiDefault,
    "contract": ContractDefault,
    "endorsement": EndorsementDefault,
    "digital-asset" : DigitalAssetDefault,
    "merchandise" : MerchandiseDefault

}
const CardImageUploader = ({card, getBlob}) => {

    const imagePreview = !card.image ? defaultCardImages[card.type] : card.image;
    const [isOpen, setIsOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    return (
        <>

            <Controller
                defaultValue={card.image}
                name={"image"}
                render={(
                    {onChange},
                ) => (
                    <div className={'card-uploader-container'}
                         onMouseEnter={() => setIsOpen(true)}
                         onMouseLeave={() => setIsOpen(false)}
                    >
                        <CardItem card={{...card, image: imagePreview}}/>
                        {(isOpen || isUploading) && <div className={'card-upload-cover'}>
                            <TusDropZone getBlob={getBlob} isUploading={isUploading} onFileStarted={() => {
                                setIsUploading(true)
                            }} onFileCompleted={(url) => {
                                console.log("upload completed", url)
                                if (url) {
                                    onChange(url)
                                }
                                setIsUploading(false)
                            }}/>
                        </div>}
                    </div>

                )}
            />
        </>
    )

}

export default CardImageUploader