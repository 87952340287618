import React from "react";
import {IoIosClose} from "react-icons/all";

import {DialogTitle as MdDialogTitle, IconButton, useTheme} from '@material-ui/core';

function DialogTitle({title, onClose, style = {}, className, ...rest}) {
    const theme = useTheme()
    // backgroundColor: theme.palette.primary.main
    return (
        <MdDialogTitle style={{padding: '0 24px'}} className={className} {...rest}>
            <div style={{
                // color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 80,
                ...style
            }}>
                <div>
                    {title}
                </div>

                {!!onClose && <IconButton
                    color="inherit"
                    onClick={onClose}>
                    <IoIosClose/>

                </IconButton>
                }
            </div>
        </MdDialogTitle>
    )
}

export default DialogTitle
