import React from "react";
import FormSelect from "@PlaystreamENV/front-lib/src/form/FormSelect";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";


const GameEvents = observer(({defaultGame, defaultEvent, disabled, name, ...rest}) => {
    const {eventsStore} = useStores();
    const events = eventsStore.events.filter(e => e.game.key === defaultGame).map(e => e.key);

    const isDisabled = !events || !events.length || disabled;

    return (
        <FormSelect label={isDisabled ? "Offline" : "Event"} defaultValue={defaultEvent} name={name}
                    native={true} allowNull={false}  {...rest}
                    disabled={isDisabled}>

            <option value={null} style={{display: 'none'}}/>
            {events.map(key => {
                return <option key={key} value={key}>  {key} </option>
            })}
        </FormSelect>

    )
})

export default GameEvents
