import React from "react";

import {useUser} from "./role/VisibleByRole";
import UserAvatar from "../entities/users/UserAvatar";


function LoggedUserAvatar({size, ...rest}) {

    const user = useUser();

    return <UserAvatar size={size} user={user} {...rest}/>

}

export default LoggedUserAvatar