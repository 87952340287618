import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "@PlaystreamENV/front-lib/src/dialog/ConfirmDialog";
import {observer} from "mobx-react-lite";

const ButtonWithConfirm = observer(({children, onConfirm, ...rest}) => {
    const [showConfirm, setShowConfirm] = useState(false);

    return <>

        <Button variant={'contained'}  {...rest}
                onClick={() => {
                    setShowConfirm(true)
                }}>{children}</Button>
        <ConfirmDialog open={showConfirm} onConfirm={() => {
            onConfirm();
            setShowConfirm(false)

        }} onClose={() => {
            setShowConfirm(false)
        }}/>
    </>
})

export default ButtonWithConfirm;
