import {sleep} from "../debug/sleep";

export default class SoundService {

    static async preLoad(uri) {
        if (!uri) return;
        // await sleep(4000)
        return new Promise((resolve) => {
            const audio = new Audio(uri)
            audio.oncanplaythrough = () => {
                resolve({
                    play: (volume = 1) => {
                        return new Promise((resolve) => {

                            audio.onended = () => {
                                resolve();
                            };
                            audio.onpause = () => {
                                resolve();
                            }

                            audio.volume = isNaN(volume) ? 1 : volume;
                            audio.play();
                        });
                    },
                    stop: () => {
                        audio.pause();
                        audio.currentTime = 0;
                    }
                });
            };
        });
    }


}
