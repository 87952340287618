import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import EditEventDialog from "../dialogs/EditEventDialog";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import YesNoLabel from "../../games/labels/YesNoLabel";
import LocalizedText from "@PlaystreamENV/front-lib/src/l10n/LocalizedText";

function EventsTable() {
    const [rowToEdit, setRowToEdit] = useState(null);
    const {eventsStore} = useStores();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const filtersOptions = [];
    const getEvents = async (params) => {
        const {data} = await eventsStore.getEvents(params)
        return {data: data.events, count: data.count};
    }
    return (
        <>
            <Table
                rowProps={{hover: true}}
                loadCounter={lastUpdated}
                filtersOptions={filtersOptions}
                loadData={getEvents}
                defaultLimit={100}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setRowToEdit(row)
                                    }}
                                >
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',
                        style: {
                            width: 370,
                        },
                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        sortable: false,
                        key: 'game',
                        title: 'Game',
                        cell: ({row}) => <>
                            {row.game.name}
                        </>
                    },
                    {
                        key: 'key',
                        title: 'Key',
                        cell: ({row}) => <>
                            {row.key}
                        </>
                    },
                    {
                        key: 'key',
                        sortable: false,
                        title: 'Single',
                        cell: ({row}) => <>
                            <LocalizedText id={`${row.game.key}_${row.key}_single`}/>

                        </>
                    },
                    {
                        key: 'key',
                        sortable: false,
                        title: 'Plural',
                        cell: ({row}) => <>
                            <LocalizedText id={`${row.game.key}_${row.key}_many`}/>
                        </>
                    },
                    // {
                    //     key: 'time_unit',
                    //     title: 'Time unit',
                    //     cell: ({row}) => <>
                    //         {row.time_unit}
                    //     </>
                    // },
                    {
                        key: 'enabled',
                        title: 'Enabled',
                        cell: ({row}) => <>
                            <YesNoLabel value={row.enabled}/>
                        </>
                    },

                    {
                        key: 'steps',
                        title: 'Steps',
                        cell: ({row}) => <>
                            <span> {row.low_step} -</span>
                            <span> {row.medium_step} -</span>
                            <span> {row.high_step} - </span>
                            <span> <YesNoLabel value={row.disable_custom_steps}/> </span>
                        </>
                    },
                ]}
            />
            <EditEventDialog
                event={rowToEdit}
                onClose={() => {
                    setRowToEdit(null)
                }}
                onSaved={() => {
                    setLastUpdated(new Date())
                }}
            />
        </>
    )
}

export default EventsTable
