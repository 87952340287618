import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import ChipsSelect from "@PlaystreamENV/front-lib/src/form/select/chips-select/ChipsSelect";


function AchievementForm({achievement}) {
    return (
        <Box>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Name'}
                            name='name'
                            defaultValue={achievement.name}
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Description'}
                            name='description'
                            defaultValue={achievement.description}
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Exp'}
                            name='exp'
                            type='number'
                            defaultValue={achievement.exp}
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Steps To Complete'}
                            name='steps_to_complete'
                            type='number'
                            defaultValue={achievement.steps_to_complete}
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <FormInputCheckbox name={'published'} defaultChecked={achievement.published}
                                               label={"Published"}
                                               validation={{}}/>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <ChipsSelect
                            name='groups'
                            rules={{
                                //     validate: {
                                //         not_empty: val => {
                                //             if (!val.length) {
                                //                 return 'Please have at least one option.'
                                //             }
                                //         }

                            }}
                            defaultValues={achievement.groups}

                        />
                    </Grid>

                </Grid>
                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default AchievementForm
