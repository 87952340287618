import React, {useEffect} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ZonesTable from "../../../components/entities/zones/table/ZonesTable";
import useQueryFilters from "../../hooks/useQueryFilters";

const ZonesRoute = ({}) => {
    const { user: userId = "" } = useQueryFilters();
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Zones
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
               <ZonesTable defaultFilters={{userId}}/>
            </Box>
        </>
    )
}

export default ZonesRoute
