import React, {useMemo} from "react";
import debounce from 'lodash/debounce'
import {Box} from "@material-ui/core";
import FilterActions from "./filterActions/FilterActions";
import FilterInputs from "./filterInputs/FilterInputs";
import {useForm, withForm} from "../../../form/context";
import {FormGlobalErrorMessage} from "../../../form/FormGlobalErrorMessage";

export const filtersFormatter = (filters) => {
    let formattedFilters = {};
    Object
        .entries(filters)
        .forEach(([key, value]) => {
            formattedFilters[key] = value === '' ? undefined : value
        })
    return formattedFilters;
}

const TableFilters = ({filtersOptions, setFilterParams, setPagination}) => {
    const {getValues} = useForm()

    const filterDebounced = useMemo(() => debounce(() => {
        const filters = getValues();
        const formattedFilters = filtersFormatter(filters)
        setFilterParams(formattedFilters)
        setPagination({skip: 0, limit: 25})
    }, 350,), [])

    const refreshData = () => setPagination(prev => ({...prev}))
    return (
        <Box p={1}>
            <form onChange={() => filterDebounced()}>
                <Box display="flex" justifyContent="space-between" alignItems='center'>
                    <FilterInputs filtersOptions={filtersOptions}/>
                    <FilterActions refreshData={refreshData}/>
                </Box>
            </form>
            <FormGlobalErrorMessage/>
            <hr/>
        </Box>
    );
};

export default withForm(TableFilters);
