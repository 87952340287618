export const Colors = {


    challenges :[

        '#7FC6A4',
        '#5D737E',
        '#55505C',
    ],
    // challenges :
    //     low :"#4CAF50",
    //     medium :"#FFC107",
    //     high :"#F44336",
    // },
    // propertyTypes: {
    //     house: '#700548',
    //     hotel: "#7272AB",
    //     building: "#7899D4",
    //     restaurant: "#362023",
    // }

}

