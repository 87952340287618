import React, {useState, useEffect} from 'react';
import "./table.scss"
import TableFilters, {filtersFormatter} from "./table-components/table-filters/TableFilters";
import DefaultEmptyTable from "./table-components/default-empty-table/DefaultEmptyTable";
import TableView from "./TableView";


const getValuesFromFiltersOptions = (filtersOptions) => {
    let defaultFilters = {};
    filtersOptions.forEach(filter => {
        defaultFilters[filter.name] = filter.defaultValue
    })
    return filtersFormatter(defaultFilters);
}


export default function Table({
                                  columns,
                                  rowProps,
                                  renderEmpty = <DefaultEmptyTable/>,
                                  size = 'small',
                                  showHeader = true,
                                  filtersOptions,
                                  loadData,
                                  defaultOrderBy = "createdAt",
                                  defaultOrderDirection = "desc",
                                  defaultLimit = 25,
                                  loadCounter,
                                  rowsPerPageOptions = [10, 25, 50, 100]
                              }) {

    const [isLoading, setIsLoading] = useState(true);
    const [pagination, setPagination] = useState({skip: 0, limit: defaultLimit});
    const [filterParams, setFilterParams] = useState(getValuesFromFiltersOptions(filtersOptions));
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [orderDirection, setOrderDirection] = useState(defaultOrderDirection);
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);

    const loadTable = async () => {
        // console.log("loadTable")
        setIsLoading(true)
        let sortBy = undefined
        if (orderDirection && orderBy) {
            sortBy = `${orderBy} ${orderDirection}`
        }
        const {data, count} = await loadData({...pagination, ...filterParams, sortBy});
        setRows(data);
        setCount(count);
        setIsLoading(false)
    }


    const onOrder = (orderProperty, direction) => {
        const newDirection = orderProperty === orderBy && direction === "asc" ? "desc" : "asc";
        console.log("onOrderChanged", orderProperty, direction, newDirection)
        setOrderDirection(newDirection);
        setOrderBy(orderProperty)
    }

    useEffect(() => {
        // console.log("loadTable hook")
        loadTable();
    }, [pagination, orderBy, orderDirection, loadCounter]);


    return (

        <>
            {!!filtersOptions.length && <TableFilters
                filtersOptions={filtersOptions}
                setFilterParams={setFilterParams}
                setPagination={setPagination}
            />}
            <TableView
                rows={rows}
                columns={columns}
                count={count}
                isLoading={isLoading}
                setFilterParams={setFilterParams}
                pagination={pagination}
                orderDirection={orderDirection}
                orderBy={orderBy}
                onOrder={onOrder}
                setPagination={setPagination}
                rowProps={rowProps}
                renderEmpty={renderEmpty}
                size={size}
                showHeader={showHeader}
                rowsPerPageOptions={rowsPerPageOptions}
            />
        </>

    );
}
