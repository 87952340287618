import React from 'react';
import {useForm} from "./context";
import {Button} from '@material-ui/core';
import {BaseLoader} from "../loaders/BaseLoader";

function FormSubmitButton({text, color, disabled, ...rest}) {

    const {formState} = useForm();

    return (
        <Button disabled={disabled || formState.isSubmitting}  variant='contained'
                style={{  minHeight:40}} color={color || 'primary'} size="large" fullWidth type='submit' { ...rest} >

            {formState.isSubmitting && <BaseLoader color={color !== 'secondary' ? 'secondary' : 'inherit'} size={24}/>}

            {!formState.isSubmitting && text}
        </Button>
    )
}

export {FormSubmitButton}
