import React from "react";
import RootRouter from "./routing/RootRouter";
import {observer} from "mobx-react-lite";
import darkTheme from "@PlaystreamENV/front-lib/src/themes/default/darkTheme";
import ThemeProvider from "@PlaystreamENV/front-lib/src/themes/provider/ThemeProvider";

const Root = observer(({location}) => {

    return (
        <ThemeProvider defaultTheme={darkTheme} disabled={true}>
            <RootRouter/>
        </ThemeProvider>
    )
})

export default Root
