import React from "react";


function ChallengesMetricLabel({stream}) {


    const {progression} = stream;

    const activeChallenges = progression.reduce((keys, item) => {
        if (item.event) {
            keys[item.event] = keys[item.event] ? keys[item.event] + 1 : 1;
        } else {
            keys["contracts"] = keys["contracts"] ? keys["contracts"] + 1 : 1;
        }
        keys["total"] = keys["total"] ? keys["total"] + 1 : 1

        return keys;
    }, {});


    const hasActiveChallenges = activeChallenges.total > 0;
    const gross = 0;
    return (

        <div style={{width: 130}}>
            <div
                className={hasActiveChallenges ? "primary-color text-bold" : "text-muted"}>
                total: {activeChallenges.total || 0} </div>

            <div>
                {Object.keys(activeChallenges).filter(key => key !== "total").map((key) => {
                    return <div
                        key={key}
                        className={activeChallenges[key] ? "primary-color text-bold" : ""}>
                        {key}: {activeChallenges[key]} </div>
                })}
            </div>

            {!!gross && <>
                <hr/>
                <div className={'text-bold'}> ${gross} stream gross</div>
            </>}
        </div>
    )
}

export default ChallengesMetricLabel;
