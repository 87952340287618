import {action, computed, observable, toJS} from 'mobx'
import api from '../../../services/api'

const localStorage = window && window.localStorage;

export class AuthStore {


    constructor() {

        if (localStorage) {
            const userString = localStorage.getItem('user');
            this._user = userString ? JSON.parse(userString) : null;
        }


    }

    @observable _user = null;


    @computed get user() {
        return toJS(this._user)
    }

    setUser(user) {
        this._user = user;
        if (localStorage) {
            localStorage.setItem('user', JSON.stringify(this.user));
            localStorage.setItem('last-email', user.email);
        }

    }


    @action
    async doLogin(user) {

        const response = await api.put('/auth/login', {...user, rememberMe: true});
        this.setUser(response.data.user);

    }

    @action
    async doRegister(userModel) {
        userModel.avg_viewers = userModel.avg_viewers || 0;
        userModel.avg_donations = userModel.avg_donations || 0;
        const response = await api.put('/auth/register', userModel);
        // console.log("register-response", response)
        this.setUser(response.data.user);

    }



    @action
    async doLogout() {

        const response = await api.put('/auth/logout');
        this._user = null;
        if (localStorage) {
            localStorage.removeItem('user');
        }
    }


    @action
    async checkIfResetPasswordTokenValid(token, password) {
        // console.log("checkIfResetPasswordTokenValid")
        const response = await api.put('/auth/verify-reset-password-token', {token});

        return response.data;

    }

    @action
    async setPassword(token, password) {
        // console.log("setPassword")
        const response = await api.put('/auth/set-password', {token, password});

        return response;

    }




}
