import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";
import update from "immutability-helper";

export class BotsStore {

    constructor() {
    }


    @action
    async getBots(params) {
        return await adminApi.get('/bots', {
            params
        })


    }

    @action
    async editBot(bot) {
        return await adminApi.patch('/bots/' + bot.id, {
            bot: convertToServerBot(bot)
        })

    }


    @action
    async createBot(bot) {

        return await adminApi.post('/bots', {
            bot: convertToServerBot(bot)
        })


    }

    @action
    async sendMessage(streamer, bot_id, message) {
        return await adminApi.put('/actions/streamer-send-bot-msg', {
            streamer,
            bot: bot_id,
            message
        })

    }

}


const convertToServerBot = (bot) => {
    return {
        ...bot,
        streamer: bot.streamer.id,

    }
}