import React, {useState} from "react";
import {Container, Tab, Tabs as TabsMaterial, withStyles} from "@material-ui/core";
import TabLabel from "./TabLabel";

const CustomTabs = withStyles({
    root: {
        marginBottom: '35px',
    },
    flexContainer: {
        padding: '10px 10px 0 !important',
        borderBottom: '1px solid #939495 !important',
        justifyContent: 'space-around !important',
    }
})(TabsMaterial)

const Tabs = ({labels, panels}) => {
    const [displayedPanel, setDisplayedPanel] = useState(0)
    const onTabChange = (e, tabIndex) => {
        setDisplayedPanel(tabIndex)
    }
    return (
        <div>
            <CustomTabs
                // scrollButtonsDesktop='auto'
                variant="scrollable"
                value={displayedPanel}
                indicatorColor='secondary'
                onChange={onTabChange}
                textColor='secondary'>
                {labels.map(label => <Tab
                    disabled={label.disabled}
                    label={<TabLabel {...label} />}
                    key={label.text}
                    style={{fontSize: '16px'}}/>
                )}
            </CustomTabs>
            <Container style={{padding: 0}}>
                {panels.map((panel, index) => {
                    const shouldDisplayed = index === displayedPanel;
                    return <div
                        key={index}
                        style={{
                            display: shouldDisplayed ? 'inherit' : 'none',
                        }}>
                        {panel}
                    </div>
                })}
            </Container>
        </div>

    )
}

export default Tabs;
