import React from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import {useTheme} from "@material-ui/core";

function InputSelect({children, name, label, allowNull = true, variant = "outlined", defaultValue, ...rest}) {
    const labelId = `select-id-${name}`;
    const theme = useTheme();
    return (
        <FormControl variant={variant} style={{width: '100%'}} disabled={false}>
            <InputLabel id={labelId}
                        style={{background: theme.palette.background.default, padding: '0 7px'}}>{label}</InputLabel>
            <Select
                name={name}
                labelId={labelId}
                defaultValue={defaultValue}
                {...rest}
            >
                {allowNull && <MenuItem style={{display: 'none'}} value=""/>}
                {children}

            </Select>
        </FormControl>
    )
}

export default InputSelect
