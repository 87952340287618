import React from "react";
import {Tooltip} from "@material-ui/core";

const MessageLabel = ({interaction}) => {
    const {message} = interaction;
    return (
        <Tooltip title={message || ""} arrow placement="top">
            <small className={'message-label'}>{message}</small>
        </Tooltip>
    )

}

export default MessageLabel