import React from "react";
import {GoPerson, RiCommunityLine} from "react-icons/all";
import {Tooltip} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const CouponCommunityLabel = ({coupon, showText = true}) => {
    const {community} = coupon;
    const text = community ? "Community" : "Personal";
    return (
        <Box display={"flex"} alignItems={"center"}>
            <Box style={{marginRight: 5}} display={"flex"} alignItems={"center"}>
                {community && <RiCommunityLine/>}
                {!community && <GoPerson/>}
            </Box>
            {showText && <span>{text}</span>}
        </Box>
    )

}

export default CouponCommunityLabel