import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../panels/ZonePanels";
import FormVoicePicker from "@PlaystreamENV/front-lib/src/form/form-voice-picker/FormVoicePicker";
import FormVolumeSlider from "@PlaystreamENV/front-lib/src/form/volume-slider/FormVolumeSlider";

const TTSForm = ({zone}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h5>Text to speech</h5>
            </Grid>
            <Grid item xs={12}>
                <FormInputText
                    label={'TTS Min amount'}
                    defaultValue={zone.tts_min_amount}
                    name='tts_min_amount'
                    validation={formValidation.required}/>
            </Grid>
            <Grid item xs={12}>
                <FormVoicePicker
                    label='Voices'
                    defaultValue={zone.tts_voice}
                    name='tts_voice'
                />
            </Grid>
            <Grid item xs={12}>
                <FormVolumeSlider
                    label='TTS Volume'
                    name='tts_volume'
                    defaultValue={zone.tts_volume}
                />

            </Grid>
        </Grid>
    )
}
export default TTSForm

