import React from "react";
import easy from '../../../assets/images/cards/levels/Dif_E.png'
import medium from '../../../assets/images/cards/levels/Dif_M.png'
import hard from '../../../assets/images/cards/levels/Dif_H.png'
import friendly from '../../../assets/images/cards/levels/Dif_F.png'

const images = {
    "easy": easy,
    "medium": medium,
    "hard": hard,
    "friendly": friendly,
}
const CardLevel = ({level}) => {

    return (
        <>
            {!!level && <div className={'card-level'}>
                <img className={'bg-level'} src={images[level]}/>
            </div>}
        </>
    )
}


export default CardLevel