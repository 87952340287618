import React, {useEffect, useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import ReactJson from "react-json-view";

function TransactionDialog({transaction, onClose}) {


    return (
        <Dialog open={!!transaction} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Transaction'} onClose={onClose}/>
            <DialogContent>

                <ReactJson theme={'threezerotwofour'} enableClipboard={false} displayObjectSize={false}
                           quotesOnKeys={false}
                           displayDataTypes={false} name={null} src={{...transaction, streamer: undefined}}/>
            </DialogContent>

            <DialogActions>

                <Button onClick={onClose} variant='contained'
                        color='primary'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withForm(TransactionDialog)
