import React, {useState} from "react";
import {Dialog, DialogContent} from '@material-ui/core';
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import CardItem from "@PlaystreamENV/front-lib/src/obs/cards/CardItem";
import {ChallengeModel} from "@PlaystreamENV/front-lib/src/mbox/stores/live-stream/ChallengeModel";
import Box from "@material-ui/core/Box";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import Grid from "@material-ui/core/Grid";

const options = [
    {id: "common"},
    {id: "rare"},
    {id: "epic"},
    {id: "legendary"},
]

function CardsCategoryViewerDialog({onClose, cards}) {
    const {  watch} = useForm();
    const rarity = watch("rarity", "common")

    return (
        <>
            <Dialog open={cards && cards.length > 0} onClose={onClose} maxWidth={"xl"} fullWidth={true}>
                <DialogTitle title={'Cards viewer'} onClose={onClose}/>
                <DialogContent>
                    <form>
                        <Grid container>
                            <Grid item xs={3}>
                                <SingleSelect
                                    label='Rarity'
                                    name={'rarity'}
                                    defaultValue={rarity}
                                    onChange={() => {

                                    }}
                                    options={options}
                                />
                            </Grid>
                        </Grid>
                        <hr/>
                        <Box display={"flex"} flexWrap={'wrap'}>
                            {cards.map((c) => new ChallengeModel(c)).map(c => {
                                return <Box m={1} key={c.id}>
                                    <CardItem card={c} rarity={rarity}/>
                                </Box>
                            })}
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default withForm(CardsCategoryViewerDialog)
