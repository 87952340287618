import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import BotForm from "../forms/BotForm";

import Dialog from "@material-ui/core/Dialog";

function EditBotDialog({bot, onClose, onSaved}) {
    const {handleSubmit} = useForm();
    const {botsStore} = useStores();

    const onSave = () => {

        handleSubmit(async (formData) => {
            const _bot = {...formData}
            await botsStore.editBot({id: bot.id, ..._bot})
            onClose();
            onSaved && onSaved();

        })();
    }

    return (
        <Dialog open={!!bot} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit bot'} onClose={onClose}/>
            <DialogContent>
                <BotForm bot={bot || {}}/>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} variant='contained'
                        color='secondary'>
                    Cancel
                </Button>
                <Button onClick={onSave} variant='contained'
                        color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withForm(EditBotDialog)
