import React from "react";

const StatusLabel = ({transaction}) => {
    return (
        <span className={transaction.status === "captured" ? 'primary-color' : ""}>
            {transaction.status}
        </span>
    )

}

export default StatusLabel