import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import {
    BiSupport,
    FaCoins,
    FaDollarSign,
    FaStream,
    FaUsers,
    GiPiggyBank, GiTakeMyMoney, GrStorage,
    GrTrophy,
    RiGamepadLine, VscReferences
} from "react-icons/all";
import StatIconWidgetCard from "./widgets/StatIconWidgetCard";
import ChallengesOverTimeChart from "./widgets/ChallengesOverTimeChart";
import RevenuesOverTimeChart from "./widgets/RevenuesOverTimeChart";
import ChallengesByTypeChart from "./widgets/ChallengesByTypeChart";
import {BaseLoader} from "@PlaystreamENV/front-lib/src/loaders/BaseLoader";
import Box from "@material-ui/core/Box";
import {bytesToMegaBytes} from "../metrics/labels/UploadsMetricsLabel";
import BytesByTypeChart from "./widgets/BytesByTypeChart";
import BytesOverTimeChart from "./widgets/BytesOverTimeChart";
import LiveByGameChart from "./widgets/LiveByGameChart";

const Dashboard = observer(({}) => {

        const {dashboardStore} = useStores();

        const {
            dashboardData,
            challengesOverTimeChartData,
            challengesByTypeChartData,
            bytesByTypeChartData,
            revenuesOverTimeChartData,
            bytesOverTimeChartData
        } = dashboardStore;


        const total_bytes = (bytesByTypeChartData || []).reduce((sum, obj) => {
            return sum + obj.sum;
        }, 0);

        return (
            <>

                {!!dashboardData && <>
                    <Grid container spacing={3}>
                        {/*<Grid item md={6}>*/}
                        {/*    <StatIconWidgetCard title={'Total Gross'} icon={<GiPiggyBank size={30}/>}*/}
                        {/*                        value={<Box>*/}
                        {/*                            ${dashboardData.total_gross}*/}
                        {/*                        </Box>*/}
                        {/*                        }/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item md={6}>*/}
                        {/*    <StatIconWidgetCard title={'Total net'} icon={<GiPiggyBank size={30}/>}*/}
                        {/*                        value={<Box>*/}
                        {/*                            ${dashboardData.total_net}*/}
                        {/*                        </Box>}/>*/}
                        {/*</Grid>*/}


                        <Grid item md={4}>
                            <StatIconWidgetCard title={'Live Streams'} icon={<FaStream size={30}/>}
                                                value={dashboardData.active_streams}/>
                        </Grid>
                        <Grid item md={4}>
                            <StatIconWidgetCard title={'Live Challenges'}
                                                icon={<GrTrophy className={'gr-trophy-icon'} size={30}/>}
                                                value={dashboardData.active_challenges}/>
                        </Grid>
                        <Grid item md={4}>
                            <StatIconWidgetCard title={'MB uploaded'}
                                                icon={<GrStorage className={'gr-trophy-icon'} size={30}/>}
                                                value={bytesToMegaBytes(total_bytes)}/>
                        </Grid>
                        <Grid item md={2}>
                            <StatIconWidgetCard title={'Challenges'}
                                                icon={<GrTrophy className={'gr-trophy-icon'} size={30}/>}
                                                value={dashboardData.total_challenges}/>
                        </Grid>
                        <Grid item md={2}>
                            <StatIconWidgetCard title={'Users'} icon={<FaUsers size={30}/>}
                                                value={dashboardData.total_users}/>
                        </Grid>
                        <Grid item md={2}>
                            <StatIconWidgetCard title={'Tickets'} icon={<BiSupport size={30}/>}
                                                value={dashboardData.total_tickets}/>
                        </Grid>
                        <Grid item md={2}>
                            <StatIconWidgetCard title={'Withdraws'} icon={<BiSupport size={30}/>}
                                                value={dashboardData.tickets_withdraw_open}/>
                        </Grid>
                        <Grid item md={2}>
                            <StatIconWidgetCard title={'Referrals'} icon={<VscReferences size={30}/>}
                                                value={dashboardData.referrals_count}/>
                        </Grid>
                        <Grid item md={2}>
                            <StatIconWidgetCard title={'Bonuses'} icon={<GiTakeMyMoney size={30}/>}
                                                value={`$${dashboardData.bonuses_count}`}/>
                        </Grid>

                        {/*<Grid item md={12} lg={6}>*/}
                        {/*    <ChallengesOverTimeChart data={challengesOverTimeChartData}/>*/}
                        {/*</Grid>*/}

                        {/*<Grid item md={12} lg={6}>*/}

                        {/*    <RevenuesOverTimeChart data={revenuesOverTimeChartData}/>*/}
                        {/*</Grid>*/}
                        <Grid item md={12} lg={6}>

                            <BytesOverTimeChart data={bytesOverTimeChartData}/>
                        </Grid>
                        <Grid item md={12} lg={6}>
                            <LiveByGameChart data={dashboardData.games}/>
                        </Grid>
                        <Grid item md={12} lg={6}>

                            <ChallengesByTypeChart data={challengesByTypeChartData}/>

                        </Grid>
                        <Grid item md={12} lg={6}>

                            <BytesByTypeChart data={bytesByTypeChartData}/>

                        </Grid>

                        {/*<Grid item md={12} lg={12} style={{opacity: 0.3}}>*/}
                        {/*    <MyStreamActivityFeed/>*/}
                        {/*</Grid>*/}


                    </Grid></>}
                {!dashboardData && <>
                    <BaseLoader/>
                </>}

            </>
        )
    }
)


export default Dashboard
