import React from "react";
import { differenceBy } from 'lodash';
import Autocomplete from "@PlaystreamENV/front-lib/src/form/autocomplete/Autocomplete";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import Box from "@material-ui/core/Box";
import CategoryCommunityLabel from "../../categories/labels/CategoryCommunityLabel";

const CardsAutoComplete = ({
                               name = "cards",
                               label = "Cards",
                               rules,
                               defaultValue,
                               searchByFilters = {},
                           }) => {

    const {cardsStore} = useStores();
    const {getValues} = useForm()
    const loadCards = async (input) => {
        const selectedCards = getValues(name)
        const response = await cardsStore.getCards({name: input, limit: 25, ...searchByFilters});
        return differenceBy(response.data.cards, selectedCards, 'id');
    }
    return (
        <Autocomplete
            defaultValue={defaultValue}
            label={label}
            loadData={loadCards}
            rules={rules}
            name={name}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => <Box display='flex' justifyContent='space-between' style={{width: '100%'}}>
                <span>{option.name}</span>
                <CategoryCommunityLabel category={option} />
            </Box>}
            multiple
        />
    )

}

export default CardsAutoComplete
