import React from "react";
import AiProgressionLabel from "./progresssion/AiProgressionLabel";
import ContractStatusLabel from "./progresssion/ContractStatusLabel";
import EndorsementStatusLabel from "./progresssion/EndorsementStatusLabel";

const ProgressionLabel = ({interaction}) => {
    const {type} = interaction.card;
    return (
        <>

            {type === "ai" &&
            <AiProgressionLabel interaction={interaction}/>}
            {type === "contract" &&
            <ContractStatusLabel interaction={interaction}/>}
            {(type === "endorsement" || type === "digital-asset" || type === "merchandise") &&
            <EndorsementStatusLabel interaction={interaction}/>}
        </>
    )

}

export default ProgressionLabel