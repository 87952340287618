import React, {useEffect, useState} from "react";
import ReactJson from "react-json-view";
import {Tooltip} from "@material-ui/core";

function UploadsMetricsLabel({stream, isLive}) {


    const {livePredictions} = stream;

    const {bedroom, metrics, stream_id} = livePredictions;
    const events = Object.keys(bedroom).map(k => {
        return {key: k, value: bedroom[k].current};
    })
    const {total_bytes} = (metrics || {});

    return (

        <div style={{width: 360 , fontSize:21}}>

            <div><small className={'text-bold'}>total: {bytesToMegaBytes(total_bytes || 0)}MB</small></div>

            {events.map(e => {
                return <EventCounter key={`${stream_id}_${e.key}`} event={e} isLive={isLive} metrics={metrics}/>
            })}

            {/*<ReactJson  theme={'threezerotwofour'} displayDataTypes={false} src={livePredictions.bedroom}/>*/}
        </div>
    )
}

const EventCounter = ({event, isLive, metrics}) => {

    const {key, value} = event;
    const event_uploads = (metrics || {})[key] || {uploads: [], bytes: 0, last_seen: []};
    const uploads_diff = (() => {
        if (event_uploads && event_uploads.uploads && event_uploads.uploads[0] && event_uploads.uploads[1]) {
            return event_uploads.uploads[0] - event_uploads.uploads[1]
        }
        return 0;
    })();

    const last_seen_diff = (() => {
        if (event_uploads && event_uploads.last_seen && event_uploads.last_seen[0] && event_uploads.last_seen[1]) {
            return event_uploads.last_seen[0] - event_uploads.last_seen[1]
        }
        return 0;
    })();

    const last_upload_time = event_uploads && event_uploads.uploads && event_uploads.uploads[0];
    const diff_from_now = new Date().getTime() - last_upload_time;

    const has_uploads_delay = isLive && diff_from_now > 5000;
    const bytes = event_uploads.bytes;

    const tooltipTitle = isLive ? `Last upload: ${parseInt(diff_from_now / 1000)} seconds ago` : "";
    return <div className={has_uploads_delay ? 'secondary-color' : ""}>

        <Tooltip title={tooltipTitle} arrow
                 placement="top">
            <small className={'text-bold '}>{key}: </small>
        </Tooltip>


        {!has_uploads_delay && <>
            <IntervalCounter isVisible={isLive && uploads_diff} value={uploads_diff} title={"uploads"}/>
            <IntervalCounter isVisible={isLive && last_seen_diff} value={last_seen_diff} title={"post prediction"}/>


            {!!bytes && <Tooltip title={"Data received"} arrow placement="top">
                <small> <span className={'text-bold primary-color'}>{bytesToMegaBytes(bytes)}MB</span></small>
            </Tooltip>
            }
        </>}
        <Tooltip title={`Frames count`} arrow placement="top">
            <small>({value})</small>
        </Tooltip>
        {has_uploads_delay && <Tooltip title={tooltipTitle} arrow placement="top">
            <small>
                &nbsp;no upload for a while
            </small>
        </Tooltip>}
    </div>

}


const IntervalCounter = (
    {
        isVisible, value, title
    }
) => {

    return <>

        {isVisible && <Tooltip title={title} arrow placement="top">

            <small> (<span className={'text-bold primary-color'}>{value}ms</span>)</small>

        </Tooltip>}
    </>
}

export const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(2)


export default React.memo(UploadsMetricsLabel)
