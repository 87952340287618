import React, {PureComponent} from 'react';
import {PieChart as ReChartsPieChart, Pie, Sector, ResponsiveContainer, Cell, Tooltip} from 'recharts';
import Box from "@material-ui/core/Box";
import {BaseLoader} from "../loaders/BaseLoader";
import NoDataMessage from "../widgets/NoDataMessage";


const PieChart = function ({
                               data,
                               nameKey,
                               dataKey,
                               height = 200,
                               tooltip,
                               colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
                               label,
                               ...rest
                           }) {


    return (
        <Box display={"flex"} alignItems={'center'} justifyContent={'space-between'} height={height}
             style={{position: 'relative'}}>
            {!!data && !!data.length && <>

                <ResponsiveContainer width={'100%'} height={'100%'}>
                    <ReChartsPieChart>
                        <Pie
                            isAnimationActive={false}
                            data={data}
                            paddingAngle={4}
                            // innerRadius={'30%'}
                            outerRadius={'65%'}
                            stroke="transparent"

                            fill="#8884d8"
                            // paddingAngle={5}
                            nameKey={nameKey}
                            dataKey={dataKey}
                            {...rest}
                        >
                            {
                                data.map((entry, index) => <Cell key={`cell-${index}`}
                                                                 fill={colors[index % colors.length]}/>)
                            }
                        </Pie>
                        {tooltip || <Tooltip wrapperStyle={{backgroundColor: '#fff', zIndex: 9}}/>}

                    </ReChartsPieChart>
                </ResponsiveContainer>
                {!!label && <Box className={'chart-labels'} style={{minWidth: 150}}>
                    {data.map(row => {

                        return label({row})
                    })}
                </Box>}
            </>

            }

            {!data && <BaseLoader/>}
            {!!data && !data.length && <Box width={"100%"}>
                <NoDataMessage/>
            </Box>}
        </Box>
    )
}

export default React.memo(PieChart)
