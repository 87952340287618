import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "../../../form";
import {FormGlobalErrorMessage} from "../../../form/FormGlobalErrorMessage";
import './ticket-form.scss';

const translations = {
    required_field: "Field is required",
}
export const formValidation = {

    email: {
        required: translations.required_field,
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
        }
    },
    required: {
        required: translations.required_field,
    },

};

function TicketForm({email, subject, hideMessage, hideEmail}) {


    return (
        <Box>
            <form>

                <Grid container spacing={2}>


                    <Grid item xs={12} style={hideEmail ? {padding: 0} : null}>

                        <FormInputText label={'Email Address'}
                                       className={hideEmail ? 'hidden' : ''}
                                       defaultValue={email}
                                       name='email'
                                       InputLabelProps={{shrink: true}}
                                       validation={formValidation.email}/>


                        <FormInputText label={'Subject'}
                                       className={'hidden'}
                                       defaultValue={subject}
                                       name='subject'
                                       InputLabelProps={{shrink: true}}
                                       validation={formValidation.required}/>
                    </Grid>


                    {!hideMessage && <Grid item xs={12}>
                        <FormInputText label={'Message'} name='message' className={'input-message'}
                                       variant={"outlined"}
                                       InputLabelProps={{shrink: true}}
                                       multiline={true}
                                       validation={{}}

                        />
                    </Grid>}

                </Grid>

                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default TicketForm
