import React from 'react';
import {useFormContext} from "react-hook-form";


export function useForm() {
    const {handleSubmit, setError, clearErrors, formState, ...rest} = useFormContext();

    const _handleSubmit = function (onSubmit) {
        return async (e) => {
            e && e.preventDefault();
            clearErrors("global");

            let submitResult = null;
            await handleSubmit(async (data) => {
                try {
                    submitResult = await onSubmit(data)
                } catch (e) {
                    submitResult = false;
                    setGlobalFormError(e);
                }
            }, (err) => {
                console.log("error on submit", err)
            })();

            return submitResult;
        }
    };

    const setGlobalFormError = function (error) {
        console.log("global error", error)
        setError("global", {
            type: "manual",
            message: error.message || "Something went wrong"
        });
    };

    const getError = (name) => {
        return byString(formState.errors, name);
    }
    return {handleSubmit: _handleSubmit, setGlobalFormError, getError, setError, formState, clearErrors, ...rest}
}

const byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}