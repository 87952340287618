import React from "react";
import ChipsArray from "@PlaystreamENV/front-lib/src/form/chips-array/ChipsArray";

const CategoriesLabel = ({categories}) => {
    return (
        <>
            <ChipsArray chips={categories} prop={"name"}/>
        </>
    )

}

export default CategoriesLabel