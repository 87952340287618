import React, {useEffect, useState} from 'react';
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {BaseLoader} from "@PlaystreamENV/front-lib/src/loaders/BaseLoader";
import {observer} from "mobx-react-lite";

const withAppRouterData = (Component) => {

    const WithAppRouterData = observer(({...rest}) => {

            const {gamesStore, eventsStore} = useStores();
            const [isReady, setIsReady] = useState(false);
            const [hasError, setHasError] = useState(false);

            useEffect(() => {
                (async () => {
                    try {
                        await Promise.all([
                            gamesStore.getGames({sortBy: "index asc"}),
                            eventsStore.getEvents(),

                        ]);

                        setIsReady(true);

                    } catch (err) {

                        setHasError(true)
                    }
                })();
            }, [])

            return (
                <>
                    {isReady && <Component {...rest}/>}
                    {!isReady && !hasError && <BaseLoader/>}

                    {/*{hasError && <>*/}
                    {/*   */}
                    {/*    <SomethingWentWrong/>*/}
                    {/*</>}*/}
                </>
            )
        }
    )

    return WithAppRouterData
};

export
{
    withAppRouterData
}
