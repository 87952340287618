import React from "react";
import {AppBar, makeStyles, Toolbar} from "@material-ui/core";
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
    fixedBar: {
        zIndex: theme.zIndex.drawer + 1,
        // background: 'transparent'

    },
    toolbar: {
        // paddingLeft: 0
    },

}));

function FixedNavBar({children, className, color, height = 100, style}) {
    const classes = useStyles();
    return (
        <div>
            <AppBar elevation={0} position="fixed" className={clsx(classes.fixedBar, className)}
                    style={{...style, height}}
                    color={color}>
                <Toolbar style={{height: '100%'}} classes={{
                    gutters: classes.toolbar
                }}>

                    {children}
                </Toolbar>
            </AppBar>
            <div style={{height}}/>
        </div>
    )
}

export {FixedNavBar}