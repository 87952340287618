import React from "react";
import Box from "@material-ui/core/Box";
import StatsIconWithText from "@PlaystreamENV/front-lib/src/entities/stream/StatsIconWithText";
import {BiDollar, FaSkullCrossbones, GiCrestedHelmet, GrTrophy} from "react-icons/all";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const CustomizationThemeIconsForm = ({}) => {
    const mr =2;
    return (

        <Box>
            <Box mb={2}>
                <Typography variant="body2">
                    Click any icon to change it.
                </Typography>
            </Box>

            <Box display={'flex'}  style={{color:"#000"}}  >
                <Box mr={mr}>
                    <StatsIconWithText count={null} icon={<FaSkullCrossbones/>} text={'Death Count'} size={'sm'} />

                </Box>
                <Box mr={mr}>
                    <StatsIconWithText count={null} icon={<GiCrestedHelmet/>} text={'Headshots'}  size={'sm'} />

                </Box>
                <Box mr={mr}>
                    <StatsIconWithText count={null} icon={<GrTrophy/>} text={'Wins Count'}  size={'sm'} />

                </Box>


            </Box>
        </Box>

    )
}

export default CustomizationThemeIconsForm
