import React from "react";
import Grid from "@material-ui/core/Grid";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import FormInputDate from "@PlaystreamENV/front-lib/src/form/FormInputDate";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import {Box} from "@material-ui/core";

const {REACT_APP_CDN_URL} = process.env;
const GoalsForm = ({zone}) => {
    const {
        goals_enabled,
        goals_end_date,
        goals_start_date,
        goals_target,
        goals_type,
        goals_description,
        goals_factor,
        goals_timer_enabled,
        goals_theme,
        streamer
    } = zone;
    const {secret} = streamer;
    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <h5>Goals</h5>
                <Box mb={1}>

                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}goals/${secret}`}
                       target={"_blank"}>Goals overlay</a>

                </Box>
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    label='Enable Goals'
                    name='goals_enabled'
                    defaultChecked={goals_enabled}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    label='Enable Timer'
                    name='goals_timer_enabled'
                    defaultChecked={goals_timer_enabled}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputDate
                    label='Starting Date'
                    name='goals_start_date'
                    defaultValue={parseInt(goals_start_date) || new Date().getTime()}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputDate
                    label='Ends On'
                    name='goals_end_date'
                    defaultValue={parseInt(goals_end_date) || new Date().setDate(new Date().getDate() + 7)}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputText
                    label='Goal Target'
                    name='goals_target'
                    type='number'
                    defaultValue={goals_target}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputText
                    label='Goal Factor'
                    name='goals_factor'
                    type='number'
                    defaultValue={goals_factor}
                />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <SingleSelect*/}
            {/*        defaultValue={goals_theme}*/}
            {/*        name='goals_theme'*/}
            {/*        options={[{id: 'streamer'}, {id: 'halloween'}]}*/}
            {/*        rules={{required: "A goal theme is required"}}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
                <SingleSelect
                    defaultValue={goals_type}
                    name='goals_type'
                    options={[{id: 'revenues'}, {id: 'cards'}]}
                    rules={{required: "A goal type is required"}}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputText
                    label='Goal Description'
                    defaultValue={goals_description}
                    name='goals_description'
                    validation={{
                        validate: (val) => val.length <= 25 || 'Description has to be shorter than 25'
                    }}

                />
            </Grid>
        </Grid>

    )
}

export default GoalsForm
