import React, {memo} from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableHeaderCell from "./table-header-cell/TableHeaderCell";

function TableHeader({columns, onOrder, orderBy, orderDirection}) {

    return (
        <TableHead>
            <TableRow>
                {columns.map((column, index) => (
                    <TableHeaderCell
                        key={`${column.key}-${index}`}
                        cell={column}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                        onOrder={onOrder}
                    />
                ))}
            </TableRow>
        </TableHead>
    );
}

export default memo(TableHeader);
