import {action, computed, observable, toJS} from "mobx";
import update from 'immutability-helper'
import adminApi from "../../../services/adminApi";

export class UsersStore {

    @observable  _users = null;

    @computed get users() {
        return toJS(this._users)
    }

    @action
    async getUsers(params) {
        const response = await adminApi.get('/users', {
            params
        })
        this._users = response.data.users;

        return response;
    }

    @action
    async editUser(user) {
        const response = await adminApi.patch('/users/' + user.id, {
            user
        })

        const index = this.users.findIndex((item) => item.id === user.id)
        if (index > -1) {
            this._users = update(this._users, {
                [index]: {$merge: {...response.data}}
            })

        }
        // this._users = response.data;
    }

    @action
    async deleteUser(id) {
        const response = await adminApi.delete('/users/' + id)
    }

    @action
    async validateUsername(username) {
        const response = await adminApi.get(`/users/validate-username`, {
            params: {username}
        })
        return response.data
    }
}
