import React from "react";
import {Box, Typography} from "@material-ui/core";
import ChallengePageLabel from "../../streams/labels/ChallengePageLabel";
import StreamingPlatformLabel from "../../streams/labels/StreamingPlatformLabel";
import StreamerVideosPageLabel from "../../streams/labels/StreamerVideosPageLabel";

const {REACT_APP_CDN_URL, REACT_APP_HOME_PAGE_URL} = process.env;
const StreamerWebSourcesLinks = ({user}) => {
    const {secret, username} = user;
    return (
        <>


            <Box className={'streamer-links'}>
                <h5>Web sources </h5>

                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}chef/${secret}`}
                       target={"_blank"}>chef</a>
                </Box>

                <Box mb={1}>

                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}alerts/${secret}`}
                       target={"_blank"}>Alerts slick</a>

                </Box>
                <Box mb={1}>

                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}cards-new/${secret}`}
                       target={"_blank"}>Alerts cards</a>

                </Box>
                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}timer/${secret}`} target={"_blank"}>Timer
                        Bar</a>

                </Box>
                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}cool-bar/${secret}`} target={"_blank"}>Slick
                        Bar</a>
                </Box>
                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}cards/${secret}`}
                       target={"_blank"}>Cards bar</a>
                </Box>
                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}holiday/${secret}`}
                       target={"_blank"}>Holiday</a>
                </Box>

                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}charity/${secret}`}
                       target={"_blank"}>Charity</a>
                </Box>

                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}gg/${secret}`}
                       target={"_blank"}>Overlays dashboard</a>
                </Box>
                <Box mb={1}>

                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}goals/${secret}`}
                       target={"_blank"}>Goals overlay</a>

                </Box>
                <h5 style={{marginTop: 30}}>General links </h5>

                <ChallengePageLabel username={username}/>

                <Box my={1}>
                    <StreamingPlatformLabel username={user.streaming_platform_username}
                                            streamingPlatform={user.streaming_platform}/>


                </Box>
                <StreamerVideosPageLabel username={user.streaming_platform_username}
                                         streamingPlatform={user.streaming_platform}/>
            </Box>

            {/*<Box mb={3}>*/}
            {/*    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}clear/${secret}`}*/}
            {/*       target={"_blank"}>Clear</a>*/}
            {/*</Box>*/}
        </>
    )
}

export default StreamerWebSourcesLinks
