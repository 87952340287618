import React from "react";
import Button from "@material-ui/core/Button";
import {MdEdit} from "react-icons/all";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";

const {REACT_APP_HOME_PAGE_URL} = process.env;


const ButtonRequestStreamerPasswordLink = observer(({streamer}) => {
    const {adminStore} = useStores();

    const requestStreamerPasswordLink = async () => {

        const {token} = await adminStore.getStreamerPasswordToken(streamer);
        const resetUrl = `${REACT_APP_HOME_PAGE_URL}set-password/${token}`
        window.open(resetUrl, "_blank")
    }

    return (
        <>

            <Button size='small' variant='contained' color='secondary'
                    startIcon={<MdEdit/>}
                    onClick={requestStreamerPasswordLink}>
                Request Reset Password
            </Button>

        </>
    )
})

export default ButtonRequestStreamerPasswordLink
