import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import ZonePanels from "../panels/ZonePanels";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";

function EditZoneDialog({zone, onClose, onSaved}) {
    const {handleSubmit} = useForm();
    const {zonesStore} = useStores();
    const title = (() => {
        if (zone && zone.streamer) {
            return `Edit zone for  ${zone.streamer.email}`
        }

    })();
    const onSave = () => {
        handleSubmit(async (formData) => {
            const {bot} = formData
            const _zone = {...formData}
            await zonesStore.editZone({
                id: zone.id,
                ...zone,
                ..._zone,
                bot: bot ? bot : undefined
            })
            onSaved && onSaved();
            onClose();
        })();
    }
    return (
        <Dialog open={!!zone} onClose={onClose} maxWidth={"xl"} fullWidth>
            <DialogTitle title={title} onClose={onClose}/>
            <DialogContent>
                <ZonePanels zone={zone || {streamer: {}}} onSaved={()=>{
                    onSaved && onSaved();
                    onClose();
                }}/>
                <FormGlobalErrorMessage />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant='outlined'
                    color='secondary'>
                    Close
                </Button>
                <Button
                    onClick={onSave}
                    variant='contained'
                    color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withForm(EditZoneDialog)
