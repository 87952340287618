import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {observer} from "mobx-react-lite";
import TransactionsTable from "../../../components/entities/transactions/table/TransactionsTable";
import useQueryFilters from "../../hooks/useQueryFilters";

const TransactionsRoute = observer(({}) => {
    const { user: userId = "", withdrawStatus} = useQueryFilters();
    return (
        <>
            <PageTitleNavBar color={'primary'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Transactions
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <TransactionsTable defaultFilters={{userId, withdrawStatus}}/>
            </Box>
        </>
    )
})

export default TransactionsRoute
