import React, {useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import Grid from "@material-ui/core/Grid";
import CategoriesAutoComplete from "../../categories/forms/CategoriesAutoComplete";

function CloneCategoriesDialog({userId, onSaved}) {
    const [isOpen, setIsOpen] = useState(false);
    const {handleSubmit} = useForm();
    const {adminStore} = useStores();

    const onClose = () => setIsOpen(false)

    const onSave = () => {
        handleSubmit(async (formData) => {
            const categoriesIds = formData.categories.map(c => c.id)
            await adminStore.cloneCategories(userId, categoriesIds)
            onSaved && onSaved();
            onClose();
        })();
    }

    return (

        <>
            <Grid item xs={12}>
                <Button variant='outlined' color='secondary' onClick={() => setIsOpen(true)}>
                    Clone Categories
                </Button>
            </Grid>
            <Dialog open={isOpen} onClose={onClose} maxWidth={"md"} fullWidth>
                <DialogTitle title='Clone Categories' onClose={onClose}/>
                <DialogContent>
                    <Grid item xs={12}>
                        <CategoriesAutoComplete rules={{required: 'at-least one category is required'}}/>
                    </Grid>
                    <FormGlobalErrorMessage/>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        variant='outlined'
                        color='secondary'>
                        Close
                    </Button>
                    <Button
                        onClick={onSave}
                        variant='contained'
                        color='primary'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withForm(CloneCategoriesDialog)
