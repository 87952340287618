import React from "react";
import {differenceBy} from 'lodash';
import Autocomplete from "@PlaystreamENV/front-lib/src/form/autocomplete/Autocomplete";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import Box from "@material-ui/core/Box";
import CategoryCommunityLabel from "../labels/CategoryCommunityLabel";

const CategoriesAutoComplete = ({
                                    name = "categories",
                                    label = "Categories",
                                    rules,
                                    defaultValue,
                                    searchByFilters = {},
                                }) => {

    const {categoriesStore} = useStores();
    const {getValues} = useForm()
    const loadCategories = async (input) => {
        const selectedCategories = getValues(name)
        const response = await categoriesStore.getCategories({name: input, limit: 75, ...searchByFilters});
        return differenceBy(response.data.categories, selectedCategories, 'id');
    }
    return (
        <Autocomplete
            defaultValue={defaultValue}
            label={label}
            loadData={loadCategories}
            rules={rules}
            name={name}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => <Box display='flex' justifyContent='space-between' style={{width: '100%'}}>
                <span>{option.name}</span>
                <CategoryCommunityLabel category={option} />
            </Box>}
            multiple
        />
    )

}

export default CategoriesAutoComplete
