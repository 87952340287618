import React from "react";

const DefaultEmptyTable = ({}) => {
    return (
        <div className={'default-empty-msg'}>
            <h1>No Results Found.</h1>
            <p>Try Adjusting your search or filter to find what you're looking for</p>
        </div>
    )
}
export default DefaultEmptyTable