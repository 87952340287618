import React, {useEffect} from "react";

import {observer} from "mobx-react-lite";

import LineChart from "@PlaystreamENV/front-lib/src/charts/LineChart";
import {Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import DashboardCard from "../../dashboard/cards/DashboardCard";
import colors from "./colors";
import {Box} from "@material-ui/core";
import NoDataContainer from "./NoDataContainer";

const PredictionTensorScoreChart = observer(({syncId, data, snapshotsLogs}) => {

    const title = 'Prediction Tensor score';


    const [value, setValue] = React.useState(null);


    useEffect(() => {

        if (!data) return;
        const _data = data.map(r => {
            const date = new Date(r.createdAt);
            const date_text = date.toLocaleDateString('en', {day: 'numeric', month: 'short', weekday: 'long'});
            const time_text = date.toLocaleTimeString('en', {
                hour12: false,
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            });
            let dimensions;
            r.data.predictions.forEach((groupData, groupIndex) => {
                dimensions = groupData.reduce((last, obj, index) => {
                    const key = `${groupIndex}_${index}`
                    last[key] = obj.isMatch ? 1 : 0;
                    return last;
                }, {});
            })

            const snapshots = ((snapshotsLogs || []).filter(s => {
                return s.job_id === r.job_id
            })[0])

            return {
                createdAt: `${date_text}  ${time_text}`,
                dimensions,
                snapshots: snapshots ? snapshots.data.snapshots : null

            }
        })

        setValue(_data)

    }, [data, snapshotsLogs])

    return (
        <>
            <DashboardCard title={title}>
                <NoDataContainer data={data}>
                    <ResponsiveContainer width={'100%'} height={150}>
                        <LineChart syncId={syncId} data={value} margin={{
                            top: 10, right: 10, left: -15, bottom: 10,
                        }}>
                            {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                            <XAxis hide={true} dataKey="createdAt"/>
                            <YAxis hide={false}/>
                            <Tooltip content={<SnapshotsScoreTooltip/>}/>
                            {/*<Legend />*/}
                            <Line dataKey={'dimensions.0_0'} stroke={colors[0]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.0_1'} stroke={colors[1]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.0_2'} stroke={colors[2]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.0_3'} stroke={colors[3]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.0_4'} stroke={colors[4]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>

                            <Line dataKey={'dimensions.1_0'} stroke={colors[0]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.1_1'} stroke={colors[1]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.1_2'} stroke={colors[2]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.1_3'} stroke={colors[3]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.1_4'} stroke={colors[4]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                        </LineChart>
                    </ResponsiveContainer>
                </NoDataContainer>
            </DashboardCard>

        </>
    )
})

const SnapshotsScoreTooltip = React.memo(({active, payload = {}, label}) => {

    const groups = payload && payload.length ? payload[0].payload.snapshots : [];
    const dimensions = payload && payload.length ? payload[0].payload.dimensions : [];
    // console.log("SnapshotsScoreTooltip", groups)
    return <div className={'recharts-custom-tooltip'}>
        <div>{label} </div>

        <div>
            {(groups || []).map((snapshots, index) => {
                return snapshots.map((snapshot, index) => {
                    // console.log("image", snapshot)
                    return <Box key={`img-${index}`}>
                        {/*{dimensions && dimensions[0] && dimensions[0][index] && <> {dimensions[0][index].snapshotScore.toFixed(2)} vs {dimensions[0][index].imgScore.toFixed(2)}</>}*/}
                        <br/>
                        <img className={'snapshot-img'} src={`data:image/png;base64,${snapshot}`}/>
                    </Box>
                });

            })}
        </div>

    </div>
}, ((prevProps, nextProps) => {

    return false
}))

export default React.memo(PredictionTensorScoreChart)
