import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog} from "@material-ui/core";
import EditTicketForm from "../forms/EditTicketForm";


function EditTicketDialog({ticket, onClose}) {
    const {handleSubmit} = useForm();
    const {ticketsStore} = useStores();
    const onSave = () => {

        handleSubmit(async (formData) => {
            await ticketsStore.editTicket({id: ticket.id, ...formData})
            onClose();
        })();
    }
    return (
        <Dialog open={!!ticket} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit Ticket'} onClose={onClose}/>
            {ticket && <DialogContent>
                <EditTicketForm ticket={ticket} />
            </DialogContent>}
            <DialogActions onSave={onSave} onCancel={onClose}/>
        </Dialog>
    )
}

export default withForm(EditTicketDialog)
