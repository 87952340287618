import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";
import update from "immutability-helper";
import defaultBrand from "@PlaystreamENV/front-lib/src/themes/default/defaultBrand";

export class ZonesStore {

    constructor() {
    }


    @action
    async getZones(params) {
        const response = await adminApi.get('/zones', {
            params
        })
        response.data.zones = response.data.zones.map(z => {
            z.brand = z.brand || defaultBrand;
            z.bot_action_triggers = z.bot_action_triggers || []
            return z;
        })
        return response;

    }

    @action
    async editZone(zone) {
        return await adminApi.patch('/zones/' + zone.id, {
            ...zone,
            cards: zone.cards.map(c => c.id),
            categories: zone.categories.map(c => c.id)
        })


    }


}
