import React, {useState} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";


function EventsPicker({onChange, value, events, ...rest}) {


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const _events = events.filter(g => {
            return g.key === value
        }
    )[0];

    const onItemClick = (game) => {
        handleClose();
        onChange && onChange(game)
    }


    return (
        <div>


            <Button color={"primary"} onClick={handleClick} style={{width: 250}} variant={'contained'} {...rest}>
                {value || "No event selected"}
            </Button>

            <Menu
                id="events-menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: "left",
                }}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {events.map((ev) => {
                    return <MenuItem key={ev} selected={ev === value}
                                     onClick={() => {
                                         onItemClick(ev)
                                     }}> {ev} </MenuItem>
                })}


            </Menu>
        </div>
    );
}

export default EventsPicker
