import React, {useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Button, Dialog} from "@material-ui/core";
import AchievementForm from "../../forms/AchievementForm";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";


function CreateAchievementDialog({onCreated}) {
    const {handleSubmit} = useForm();
    const [open, setOpen] = useState(false);
    const {achievementsStore} = useStores();

    const onSave = () => {
        handleSubmit(async (formData) => {
            // console.log(formData, 'FORM_DATA')
            await achievementsStore.createAchievement(formData)
            onCreated();
            closeModal();
        })();
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <>
            <Button
                variant='contained'
                color='primary'
                onClick={() => setOpen(true)}
            >
                Create Achievement
            </Button>
            <Dialog open={open} onClose={closeModal} maxWidth={"md"}>
                <DialogTitle title={'Create Achievement'} onClose={closeModal}/>
                <DialogContent>
                    <AchievementForm achievement={{}}/>
                </DialogContent>
                <DialogActions onSave={onSave} onCancel={closeModal}/>
            </Dialog>
        </>

    )
}

export default withForm(CreateAchievementDialog)
