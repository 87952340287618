import React, {useState} from 'react';

import {BaseDrawer, DrawerListItem} from "./BaseDrawer";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import {
    MdHome,
    MdLock,
    MdSettings,
    FaChartBar,
    FaPeopleCarry,
    FaVolumeUp,
    IoIosSpeedometer,
    GiSpeedBoat,
    RiGamepadLine,
    BiSupport,
    FaPiggyBank,
    GrTrophy,
    MdDashboard,
    MdColorLens,
    FaUsers,
    ImLab,
    FiCreditCard,
    FaLayerGroup,
    MdEvent,
    MdGroupWork,
    RiTapeLine,
    FaVideo,
    CgScreen, SiQzone, GiAchievement, GrAchievement, RiCouponLine, VscReferences, FaRobot, FaArrowUp, GiTakeMyMoney
} from "react-icons/all";
import ShowForDevOnly from "@PlaystreamENV/front-lib/src/debug/ShowForDevOnly";
import LoggedInUserAvatarMenu from "../menu/LoggedInUserAvatarMenu";
import {VisibleByRole} from "@PlaystreamENV/front-lib/src/auth/role/VisibleByRole";
import {Accordion, AccordionDetails} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DrawerAccordion from "./DrawerAccordion";
import BonusesRoute from "../routes/app/BonusesRoute";


export default function MainDrawer({children}) {
    const [openedPanel, setOpenedPanel] = useState('');
    const handleAccordion = (panel) => (event, isExpanded) => {
        setOpenedPanel(isExpanded ? panel : false);
    };
    const iconStyle = {color: '#fff', marginLeft: 5, fontSize: 32}
    return (
        <BaseDrawer
            fixedBarContent={<Box alignItems='center' flex={1} display='flex' justifyContent='space-between'>

                <Box alignItems='center' display='flex' flex={1} pr={2}>
                    {/*<StreamerStatusSummaryIcons/>*/}
                </Box>
                <Box alignItems='center' display='flex'>
                    <Box mr={3}>
                        {/*<StartStreamButton/>*/}
                    </Box>
                    <Box alignItems='center' display='flex'>
                        <LoggedInUserAvatarMenu/>
                    </Box>
                </Box>
            </Box>} drawerList={<List disablePadding>
            <DrawerListItem to={'/'} icon={<MdDashboard style={iconStyle}/>} text={'Dashboard'}/>
            <DrawerListItem
                to={'/users'}
                icon={<FaUsers style={iconStyle}/>}
                text={'Users'}/>
            <DrawerListItem
                to={'/streams'}
                icon={<CgScreen style={iconStyle}/>}
                text={'Streams'}/>
            <DrawerListItem
                to={'/tickets'}
                icon={<BiSupport style={iconStyle}/>}
                text={'Tickets'}/>
            <DrawerAccordion title='Content'>

                <DrawerListItem
                    to={'/categories'}
                    icon={<MdGroupWork style={iconStyle}/>}
                    text={'Categories'}/>
                <DrawerListItem
                    to={'/cards'}
                    icon={<FiCreditCard style={iconStyle}/>}
                    text={'Cards'}/>

                <DrawerListItem
                    to={'/zones'}
                    icon={<SiQzone style={iconStyle}/>}
                    text={'Zones'}/>
                <DrawerListItem
                    to={'/achievements'}
                    icon={<GrAchievement style={iconStyle}/>}
                    text={'Achievements'}/>

            </DrawerAccordion>
            <DrawerAccordion title='Finance'>
                <DrawerListItem
                    to={'/interactions'}
                    icon={<FaPeopleCarry style={iconStyle}/>}
                    text={'Interactions'}/>
                <DrawerListItem
                    to={'/transactions'}
                    icon={<FaPiggyBank style={iconStyle}/>}
                    text={'Transactions'}/>
                <DrawerListItem
                    to={'/coupons'}
                    icon={<RiCouponLine style={iconStyle}/>}
                    text={'Coupons'}
                />
                <DrawerListItem
                    to={'/referrals'}
                    icon={<VscReferences style={iconStyle}/>}
                    text={'Referrals'}
                />
                <DrawerListItem
                    to={'/bonuses'}
                    icon={<GiTakeMyMoney style={iconStyle}/>}
                    text={'Bonuses'}
                />

            </DrawerAccordion>

            <DrawerAccordion title='Games'>
                <DrawerListItem
                    to={'/games'}
                    icon={<RiGamepadLine style={iconStyle}/>}
                    text={'Games'}/>
                <DrawerListItem
                    to={'/events'}
                    icon={<RiGamepadLine style={iconStyle}/>}
                    text={'Events'}/>

            </DrawerAccordion>

            <DrawerAccordion title='System'>
                <DrawerListItem
                    to={'/application'}
                    icon={<MdSettings
                        style={iconStyle}/>}
                    text={'Application'}/>
                <DrawerListItem
                    to={'/bots'}
                    icon={<FaRobot style={iconStyle}/>}
                    text={'Bots'}/>

            </DrawerAccordion>
            {/*<DrawerListItem to={'/customization'} icon={<MdColorLens style={iconStyle}/>}*/}
            {/*                text={'Customization'}/>*/}

            {/*<DrawerListItem to={'/labs'} icon={<ImLab style={iconStyle}/>}*/}
            {/*                text={'Labs'}/>*/}
            <VisibleByRole role={'superuser'}>
                <DrawerListItem to={'/metrics'} icon={<IoIosSpeedometer style={iconStyle}/>}
                                text={'Metrics'}/>
            </VisibleByRole>
            {/*<ShowForDevOnly>*/}
            {/*    <DrawerListItem to={'/test'} icon={<ImLab style={iconStyle}/>}*/}
            {/*                    text={'Test'}/>*/}
            {/*</ShowForDevOnly>*/}

        </List>}>
            {children}
        </BaseDrawer>
    );
}

