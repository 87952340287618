import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {formValidation} from "../panels/ZonePanels";
import LanguageSelect from "./language-select/LanguageSelect";

const DetailsForm = ({zone}) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h5>Zone details</h5>
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        label={'Name'}
                        defaultValue={zone.name}
                        name='name'
                        validation={formValidation.required}/>
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        label={'Description'}
                        defaultValue={zone.description}
                        className={"multiline"}
                        multiline={true}
                        name='description'
                        validation={{}}/>
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        label={'Interaction minimum'}
                        type={"number"}
                        defaultValue={zone.interaction_min_amount}
                        name='interaction_min_amount'
                        validation={formValidation.required}/>
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        label={'Avatar'}
                        defaultValue={zone.avatar}
                        name='avatar'
                        validation={{}}/>
                </Grid>
                <Grid item sm={12}>
                    <Grid item xs={12}>
                        <LanguageSelect language={zone.language}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormInputCheckbox
                        name={'coupons_enabled'}
                        defaultChecked={zone.coupons_enabled}
                        label={"Coupons enabled"}
                        validation={{}}/>
                </Grid>
            </Grid>
        </>
    )
}
export default DetailsForm
