import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Dashboard from "../../../components/dashboard/Dashboard";
import Container from "@material-ui/core/Container";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";



const DashboardRoute = observer(() => {

    const {dashboardStore} = useStores();


    useEffect(() => {


        dashboardStore.getDashboardData({})
        dashboardStore.getChallengesByTypeChart({})
        dashboardStore.getChallengesOverTimeChart({})
        dashboardStore.getRevenuesOverTimeChart({})
        dashboardStore.getBytesByTypeChart({});
        dashboardStore.getBytesOverTimeChart({ });

    }, []);

    return (
        <>

            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Dashboard
                    </Typography>


                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>

                <Container>

                    <Dashboard/>

                </Container>

            </Box>

        </>
    )
})

export default DashboardRoute
