import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";

export class DashboardStore {

    constructor() {


    }

    @computed get dashboardData() {
        return toJS(this._dashboardData)
    }


    @observable  _dashboardData = null;


    @action
    async getDashboardData(params) {
        // console.log("StreamerStore - getDashboardData");
        const response = await adminApi.get('/dashboard', {
            params
        })
        this._dashboardData = response.data;
    }


    @computed get challengesOverTimeChartData() {
        return toJS(this._challengesOverTimeChartData)
    }


    @observable  _challengesOverTimeChartData = null;


    @action
    async getChallengesOverTimeChart(params) {
        // console.log("StreamerStore - getChallengesOverTimeChart");
        const response = await adminApi.get('/charts/challenges-over-time-chart', {
            params
        })
        this._challengesOverTimeChartData = response.data;
    }

    @computed get revenuesOverTimeChartData() {
        return toJS(this._revenuesOverTimeChartData)
    }


    @observable  _revenuesOverTimeChartData = null;


    @action
    async getRevenuesOverTimeChart(params) {
        // console.log("StreamerStore - getRevenuesOverTimeChart");
        const response = await adminApi.get('/charts/revenues-over-time-chart', {
            params
        })
        this._revenuesOverTimeChartData = response.data;
    }


    @computed get challengesByTypeChartData() {
        return toJS(this._challengesByTypeChartData)
    }


    @observable  _challengesByTypeChartData = null;


    @action
    async getChallengesByTypeChart(params) {
        // console.log("StreamerStore - getChallengesByTypeChart");
        const response = await adminApi.get('/charts/challenges-by-type-chart', {
            params
        })
        this._challengesByTypeChartData = response.data;
    }


    @computed get bytesByTypeChartData() {
        return toJS(this._bytesByTypeChartData)
    }


    @observable  _bytesByTypeChartData = null;


    @action
    async getBytesByTypeChart( ) {
        // console.log("StreamerStore - getBytesByTypeChart");
        const response = await adminApi.post('/charts/bytes-by-type-chart', {
            // ...params
        })
        this._bytesByTypeChartData = response.data;
        // this._bytesByTypeChartData = [{"key":"win","sum":3079574114},{"key":"kill","sum":2201787063},{"key":"crown","sum":806938070},{"key":"qualified","sum":5433136505},{"key":"gold_medal","sum":774498299},{"key":"down","sum":6363694988},{"key":"bounty","sum":1112540010},{"key":"kill_leader","sum":1438439408},{"key":"legend_weapon","sum":810630},{"key":"rank_up","sum":1405957},{"key":"rift","sum":1461014930},{"key":"ace","sum":454865504},{"key":"baron","sum":1456818424},{"key":"escape","sum":222377650},{"key":"totem","sum":213051583},{"key":"sabotage","sum":2893525},{"key":"unhook","sum":12337592},{"key":"hook","sum":757582362},{"key":"generator","sum":403649171},{"key":"ruthless","sum":16206124},{"key":"victory","sum":70206863},{"key":"sage","sum":122517752},{"key":"raze","sum":122489554}];
    }

    @computed get bytesOverTimeChartData() {
        return toJS(this._bytesOverTimeChartData)
    }


    @observable  _bytesOverTimeChartData = null;


    @action
    async getBytesOverTimeChart(params) {
        // console.log("StreamerStore - getBytesOverTimeChart");
        const response = await adminApi.get('/charts/bytes-over-time-chart', {
            params
        })
        this._bytesOverTimeChartData = response.data;
    }


}

