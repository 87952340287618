import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Slide} from "@material-ui/core";
import ReactJson from "react-json-view";

const StreamHistoryDialog = ({stream, onClose, ...rest}) => {
    return (
        <>
            <Dialog
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'down'
                }}
                maxWidth={"xl"}
                open={!!stream}
                fullWidth
                onClose={onClose}
                {...rest}
            >

                <DialogTitle title={<div>Stream Raw Json </div>} onClose={onClose}/>
                <DialogContent style={{height: 800}}>
                    {!!stream && <>

                        {!!stream.json && <ReactJson  theme={'threezerotwofour'} displayDataTypes={false} src={stream.json}/>}
                        {!stream.json &&
                        <span>no json to show<br/>if this streamer is still online use the 'metrics' page, otherwise contact the system admin.</span>}
                    </>}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default StreamHistoryDialog
