import React, {useEffect, useState} from "react";
import {Controller} from 'react-hook-form';
import {Button, IconButton, TextField} from "@material-ui/core";
import {BiPlus, FaTimesCircle} from "react-icons/all";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useForm} from "../../context";
import {makeStyles} from "@material-ui/core/styles";
import {FormInputErrorMessage} from "../../FormInputErrorMessage";

const useStyles = makeStyles({
    featureContainer: {
        width: '100%',
        border: '1px solid #00c9ff4d',
        margin: '10px 0',
        padding: '8px',
        fontSize: '24px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#ff2c90',
    },
    textContainer: {
        maxWidth: '90%',
        wordBreak: 'break-word',
    },
    iconContainer: {
        display: 'flex',
    }
})

const ChipsSelect = ({name, rules, defaultValues = []}) => {
    const [newChip, setNewChip] = useState('')
    const classes = useStyles();
    const {watch} = useForm();
    // Forcing re-rendering when we update this component so we can render-list
    const watchOptions = watch(name, defaultValues);
    // Handling submit enter on the input to submit
    const onKeyPress = (keyCode, onChangeForm) => {
        if (keyCode === 'Enter') {
            return onAddChip(onChangeForm)
        }
    }
    const onAddChip = (onChangeForm) => {
        if (newChip.trim() !== '') {
            const updatedFeatures = new Set([...watchOptions, newChip])
            onChangeForm([...updatedFeatures,])
            setNewChip('')
        }
    }
    const onRemoveChip = (el, onChangeForm) => {
        const updatedFeatures = new Set([...watchOptions])
        updatedFeatures.delete(el);
        onChangeForm([...updatedFeatures])
    }
    return (
        <Controller
            defaultValue={defaultValues}
            name={name}
            rules={rules}
            render={({onChange}) => <>
                <TextField
                    onKeyDown={e => onKeyPress(e.key, onChange)}
                    variant='outlined'
                    label={`Add ${name}`}
                    value={newChip}
                    onChange={(e) => setNewChip(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={() => onAddChip(onChange)}
                                    type='button'>
                                    <BiPlus/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <FormInputErrorMessage name={name}/>
                <h3>{name} Included</h3>
                {/*here  we can send the render from outside (renderList(features)*/}
                {watchOptions && watchOptions.map(el => (
                    <div key={el} className={classes.featureContainer}>
                        <span className={classes.textContainer}>{el}</span>
                        <span className={classes.iconContainer}>
                        <Button
                            variant='text'
                            size='large'
                            onClick={() => onRemoveChip(el, onChange)}>
                        <FaTimesCircle/>
                        </Button>
                        </span>
                    </div>
                ))}
            </>
            }/>
    )
}

export default ChipsSelect