import React from "react";
import {Box} from "@material-ui/core";
import FormSelect from "../../../../../form/FormSelect";
import {FormInputText} from "../../../../../form";

const FilterInput = ({filter}) => {
    const {type, name, rules, label, style, variant, defaultValue} = filter;
    let input = null;
    switch (type) {
        case('select'): {
            const {options} = filter
            return <div style={{marginRight: 5}}>
                <FormSelect
                    validation={rules}
                    name={name}
                    label={label}
                    style={style}
                    allowNull={false}
                    variant={variant}
                    defaultValue={defaultValue}
                    native={true}
                >
                    <option aria-label="None" value=""/>
                    {options.map(({value, label}) => (
                        <option value={value} key={value} name='omer'>
                            {label}
                        </option>
                    ))}
                </FormSelect>
            </div>
        }
        case('text'):
            input = <FormInputText {...filter}  inputProps={{
                autoComplete: 'off'
            }} />
    }
    return (
        <Box mx='5px'>
            {input}
        </Box>
    )
}


export default FilterInput

