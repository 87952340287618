import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../CardForm";
import ChallengeExpirationPicker from "../ChallengeExpirationPicker";

const ContractMetaForm = ({metadata}) => {


    return (
        <>
            <Grid item xs={12}>
                <FormInputText
                    label={'Step to complete'}
                    type={"number"}
                    defaultValue={metadata.steps_to_complete}
                    name='metadata.steps_to_complete'
                    validation={formValidation.required}
                />
            </Grid>

            <ChallengeExpirationPicker metadata={metadata} type='contract'/>
        </>
    )

}

export default ContractMetaForm
