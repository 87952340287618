import React from "react";
import Grid from "@material-ui/core/Grid";
import FormInputDate from "@PlaystreamENV/front-lib/src/form/FormInputDate";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {Box} from "@material-ui/core";
const {REACT_APP_CDN_URL} = process.env;

const DeadlineForm = ({zone}) => {
    const {
        deadline_end_date,
        deadline_steps_minute,
        deadline_description,
        deadline_enabled,
        streamer
    } = zone;
    const {secret} = streamer;
    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <h5>Deadline</h5>
                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}deadline/${secret}`}
                       target={"_blank"}>deadline overlay</a>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    label='Enable Deadline'
                    name='deadline_enabled'
                    defaultChecked={deadline_enabled}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputDate
                    label='Ends On'
                    name='deadline_end_date'
                    defaultValue={parseInt(deadline_end_date) || new Date().getTime() + 1000 * 60 * 60 * 5}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputText
                    type='number'
                    label='Deadline Steps Minutes'
                    defaultValue={deadline_steps_minute}
                    name='deadline_steps_minute'
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputText
                    label='Deadline Description'
                    defaultValue={deadline_description}
                    name='deadline_description'
                />
            </Grid>
        </Grid>
    )
}

export default DeadlineForm
