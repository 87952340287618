import React from "react";
import {Tooltip} from "@material-ui/core";

const GuestIdLabel = ({interaction}) => {
    const {guest_username, guest_id} = interaction;
    return (
        <Tooltip title={guest_id} arrow placement="top">
            <small>{guest_username}</small>
        </Tooltip>
    )

}

export default GuestIdLabel