import React from "react";
import {observer} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import SingleSelect from "../form/select/single-select/SingleSelect";
import "./sound-select.scss";
import useLanguage from "../l10n/hooks/useLanguage";

const sounds = [
    {
        id: "cyborg",
        name: "Cyborg"
    },
    {
        id: "male",
        name: "Male"
    },
    {
        id: "female",
        name: "Female"
    },
    {
        id: "instrumental",
        name: "Instrumental"
    }
];
const AiSoundSelect = observer(({defaultValue, rules, onChange, volume = 1}) => {

        const {translate} = useLanguage();
        return (
            <>
                <div className='text-muted ca_and_contract_sound-label'>
                    {translate('ca_and_contract_sound')}
                </div>
                <SingleSelect name={"alert_challenge_sound"} rules={rules}
                              defaultValue={(defaultValue)}
                              options={(sounds)} className={"sound-select"} onChange={onChange}>
                    {({option, isSelected}) => {
                        return <Box className={"sound-select-item"} display={"flex"} alignItems={"center"} width={"100%"}
                                    justifyContent={"space-between"}>
                            <span>
                                {translate(`ca_and_contract_sound_option_${option.name.toLowerCase()}`)}
                            </span>
                        </Box>
                    }}
                </SingleSelect>
            </>
        )

    }
)

export default AiSoundSelect
