import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import YesNoLabel from "../../games/labels/YesNoLabel";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import CreateAchievementDialog from "../dialogs/create/CreateAchievementDialog";
import EditAchievementDialog from "../dialogs/edit/EditAchievementDialog";
import * as PropTypes from "prop-types";
import GroupsLabel from "../../cards/labels/GroupsLabel";

GroupsLabel.propTypes = {};
const AchievementsTable = observer(({lastCreated}) => {
    const {achievementsStore} = useStores();
    const [rowToEdit, setRowToEdit] = useState(null);


    const [lastUpdated, setLastUpdated] = useState(new Date());


    const getAchievements = async (params) => {
        const {data} = await achievementsStore.getAchievements(params)
        return {data: data.achievements, count: data.count};
    }


    return (
        <>
            <Table
                rowProps={{hover: true}}
                filtersOptions={[]}
                loadCounter={lastUpdated.getTime() + lastCreated.getTime()}
                loadData={getAchievements}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => setRowToEdit(row)}
                                >
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',

                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        key: 'name',
                        title: 'name',

                        cell: ({row}) => <>
                            {row.name}
                        </>
                    },
                    {
                        key: 'description',
                        title: 'description',

                        cell: ({row}) => <>
                            {row.description}
                        </>
                    },
                    {
                        key: 'exp',
                        title: 'exp',

                        cell: ({row}) => <>
                            {row.exp}
                        </>
                    },
                    {
                        key: 'steps_to_complete',
                        title: 'steps_to_complete',

                        cell: ({row}) => <>
                            {row.steps_to_complete}
                        </>
                    },
                    {
                        key: 'published',
                        title: 'published',

                        cell: ({row}) => <>
                            <YesNoLabel value={row.published}/>
                        </>
                    },

                    {
                        key: "groups",
                        title: 'groups',
                        style: {
                            width: 320
                        },
                        cell: ({row}) => <>
                             <GroupsLabel groups={row.groups}/>
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'createdAt',

                        cell: ({row}) => <>
                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />
            <EditAchievementDialog
                onClose={() => {
                    setRowToEdit(null);
                }}
                onSaved={() => {
                    setLastUpdated(new Date());
                }}
                achievement={rowToEdit}
            />

        </>
    )
})

export default AchievementsTable
