import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import UsersAutocomplete from "../../users/forms/UsersAutocomplete";
import {FormInputPassword} from "@PlaystreamENV/front-lib/src/form/FormInputPassword";


const translations = {
    required_field: "Field is required",
}
export const formValidation = {


    required: {
        required: translations.required_field
    },
    enabled: {
        // required: translations.required_field
    }

};

const BotForm = ({bot}) => {


    return (
        <>
            <form>

                <Grid container spacing={2}>

                    <Grid item sm={12}>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <h5 className={'text-muted'}> Bot</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'Name'} defaultValue={bot.name}
                                               name='name'
                                               validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Key'} defaultValue={bot.key}
                                               disabled={!!bot.id}
                                               name='key'
                                               validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputPassword label={'Token'} defaultValue={bot.token}
                                                   name='token'
                                                   validation={formValidation.required}/>

                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Platform'} defaultValue={bot.platform}
                                               name='platform'
                                               validation={formValidation.required}/>

                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox name={'enabled'} defaultChecked={bot.enabled}
                                                   label={"Enabled"}
                                                   validation={{}}/>
                            </Grid>

                            {/*<Grid item xs={12}>*/}
                            {/*    <UsersAutocomplete name={'streamer'} label={'Streamer'}*/}
                            {/*                       defaultValue={bot.streamer}*/}
                            {/*                       rules={formValidation.required}/>*/}

                            {/*</Grid>*/}


                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <FormGlobalErrorMessage/>

                    </Grid>
                </Grid>

            </form>

        </>
    )

}

export default BotForm