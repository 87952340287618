import {useState} from "react";
import {TusClient} from "../client/TusClient";


export default () => {

    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const isFinished = progress === 100 && !isUploading;

    const upload = async (file) => {

        try {
            setIsUploading(true);
            console.log("useTus: upload", file);
            setProgress(0);
            const filename = await TusClient.upload(file, ({progress}) => {
                console.log("useTus: progress", progress);
                setProgress(progress);
            });
            setProgress(100);
            console.log("useTus: file upload SUCCESS", filename)
            setIsUploading(false);
            return filename;
        } catch (err) {
            console.log("useTus: error", err)
            setError(err)
        }

    }

    // const isUploading = uploadProgress !== null && uploadProgress <= 100 && !file;

    return {isUploading, setIsUploading, upload, progress, error, isFinished}
}