import React, {useEffect} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {observer} from "mobx-react-lite";
import GamesPredictionLogsView from "../../../components/labs/GamesPredictionLogsView";

const LabsRoute = observer(({}) => {



    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Labs Route
                    </Typography>


                </Box>

            </PageTitleNavBar>


            <Box mt={4} px={3}>

                <GamesPredictionLogsView game={"fortnite"}/>


            </Box>
        </>
    )
})

export default LabsRoute
