import React, {useEffect, useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import Box from "@material-ui/core/Box";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";

import StreamStreamerLabel from "../../entities/challenges/labels/StreamStreamerLabel";
import UserActions from "../../entities/users/actions/UserActions";
import ChallengePageLabel from "../../entities/streams/labels/ChallengePageLabel";
import StreamingPlatformLabel from "../../entities/streams/labels/StreamingPlatformLabel";
import StreamerVideosPageLabel from "../../entities/streams/labels/StreamerVideosPageLabel";
import UploadsMetricsLabel from "../labels/UploadsMetricsLabel";
import ChallengesMetricLabel from "../labels/ChallengesMetricLabel";
import SummaryMetricLabel from "../labels/SummaryMetricLabel";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import IconButton from "@material-ui/core/IconButton";
import {BsTerminalFill, ImLab} from "react-icons/all";
import LabsDialog from "../../labs/dialogs/LabsDialog";
import LiveStreamDialog from "../../live-stream-panel/LiveStreamDialog";
import StreamStatusLabel from "../../entities/streams/labels/StreamStatusLabel";
import StreamConfigsLabel from "../labels/StreamConfigsLabel";

const StreamMetricsTable = observer(({}) => {
    const {adminStore} = useStores();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [rowForLabs, setRowForLabs] = useState(null);
    const [rowForTerminal, setRowForTerminal] = useState(null);
    const defaultLimit = 200;
    const filtersOptions = [
        {
            type: 'text',
            name: "streamer",
            label: "User-ID",
            defaultValue: "",
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "streamId",
            label: "Stream-ID",
            defaultValue: '',
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "status",
            label: "Status",
            defaultValue: 'online',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Online", value: 'online'},
                {label: "Completed", value: 'completed'},
                {label: "Forced-Close", value: 'forced-close'},
            ]
        }


    ]


    const getStreamMetrics = async (params) => {
        const {data} = await adminStore.getStreamMetrics({...params, limit: defaultLimit});

        return {data: data, count: data.length};
    }

    useEffect(() => {

        const interval = setInterval(() => {
            setLastUpdated(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        }

    }, []);

    return (
        <div className={'metrics-table'}>
            <Table
                loadCounter={lastUpdated}
                defaultLimit={defaultLimit}

                rowProps={{hover: true}}
                columns={[
                    {
                        title: "Actions",
                        sortable: false,
                        cell: ({row}) => <Box display={"flex"} alignItems={"center"}>
                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {
                                    setRowForTerminal(row)
                                }}
                            >
                                <BsTerminalFill/>
                            </IconButton>

                            <IconButton
                                color="inherit"
                                edge="start"
                                onClick={() => {
                                    setRowForLabs(row)
                                }}
                            >
                                <ImLab/>
                            </IconButton>
                        </Box>
                    },
                    {
                        key: 'id',
                        title: 'Stream ID',
                        cell: ({row}) => <Box display='flex'>
                            {/*<Clipboard text={row.id} btnText='Stream-ID' tooltip={row.id}/>*/}
                            {row.id}
                        </Box>
                    },
                    {
                        key: 'streamer',
                        title: 'Streamer',
                        sortable: false,
                        cell: ({row}) => <>
                            <span className='text-bold'>{row.streamer.username}</span>
                            <Box mb='1'>
                                <StreamStreamerLabel stream={row}/>
                            </Box>
                            <Box marginTop='5px'>
                                {/*<Clipboard*/}
                                {/*    text={row.streamer.id}*/}
                                {/*    btnText='Streamer-ID*'*/}
                                {/*    tooltip={row.streamer.id}/>*/}
                                {row.streamer.id}
                            </Box>
                        </>
                    },
                    {
                        sortable: false,
                        key: 'links',
                        title: 'Links',
                        cell: ({row}) => <>
                            <Box className={'streamer-links'}>
                                <ChallengePageLabel username={row.streamer.username}/>

                                <Box my={1}>
                                    <StreamingPlatformLabel username={row.streamer.streaming_platform_username}
                                                            streamingPlatform={row.streamer.streaming_platform}/>


                                </Box>
                                <StreamerVideosPageLabel username={row.streamer.streaming_platform_username}
                                                         streamingPlatform={row.streamer.streaming_platform}/>
                            </Box>
                        </>
                    },
                    {
                        key: 'followers_count',
                        title: 'Followers',
                        cell: ({row}) => <>
                            {row.streamer.followers_count}
                        </>
                    },
                    // {
                    //     key: 'status',
                    //     title: 'Status',
                    //     cell: ({row}) => <>
                    //
                    //         <StreamStatusLabel status={row.status}/>
                    //     </>
                    // },
                    {
                        sortable: false,
                        key: 'uploads',
                        title: 'Uploads',
                        cell: ({row}) => <>

                            <UploadsMetricsLabel stream={row} isLive={true}/>
                        </>
                    },
                    {
                        sortable: false,
                        key: 'challenges',
                        title: 'Challenges',
                        cell: ({row}) => <>

                            <ChallengesMetricLabel stream={row}/>
                        </>
                    },
                    {
                        sortable: false,
                        key: 'predictions',
                        title: 'Predictions',
                        cell: ({row}) => <>

                            <SummaryMetricLabel stream={row}/>
                        </>
                    },

                    {
                        key: 'configs',
                        sortable: false,
                        title: 'Configs',
                        cell: ({row}) => <>
                            <div>
                                game: <span className={'primary-color'}>{row.game_key}</span>
                            </div>
                            <StreamConfigsLabel stream={row}/>
                        </>
                    },

                    // {
                    //     key: 'status',
                    //     title: 'Status',
                    //     cell: ({row}) => <>
                    //
                    //         <StreamStatusLabel status={row.status}/>
                    //     </>
                    // },

                    {
                        key: 'createdAt',
                        title: 'Created',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },

                ]}

                filtersOptions={filtersOptions}
                loadData={getStreamMetrics}/>

            <LabsDialog stream={rowForLabs} onClose={() => {
                setRowForLabs(null)
            }}/>
            <LiveStreamDialog stream={rowForTerminal} onClose={() => {
                setRowForTerminal(null)
            }}/>
        </div>

    )
})

export default StreamMetricsTable
