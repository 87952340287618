import React from "react";
import ChipsArray from "@PlaystreamENV/front-lib/src/form/chips-array/ChipsArray";

const CardsLabel = ({cards}) => {
    return (
        <>
            <ChipsArray chips={cards} prop={"name"}/>
        </>
    )

}

export default CardsLabel