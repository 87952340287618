import React from "react";
import InteractionIcon from "../../icons/InteractionIcon";
import SvgSoundIcon from "../../../themes/svg/cards/SvgSoundIcon";
import SvgGifIcon from "../../../themes/svg/cards/SvgGifIcon";


const icons = {
    "sound": () => <SvgSoundIcon/>,
    "gif": () => <SvgGifIcon/>,
}
const FeatureList = ({card}) => {
    const {features} = card;
    return (
        <div className={'features'}>
            {(features || []).map(f => <span key={f} className={`feature`}>
                {icons[f] && icons[f]()}
            </span>)}
        </div>
    )

}

export default FeatureList