import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
process.env.REACT_APP_ENV !== "dev" && console.log("                        _    |     _\n" +
    "                         ~,   \\  ,~\n" +
    "                          \\  |  |   ,^,\n" +
    "                 __ ,--,__|-----.__// |\n" +
    "                /  '   '          /|#_| \n" +
    "               ,-~-/~~\\               \\\n" +
    "              /__ __   \\               \\\n" +
    "             ;~__\"_ ~; |    ___        |\n" +
    "     ____   / / /  | | | ,-'   '-,     \\\n" +
    " .--'    ~-{  \\/  /  | |/         \\     \\\n" +
    "/  _  ,-,   \\_/~-~  /  |           |    |\n" +
    "| | \\ \\  \\   \\~~--~~   / }         \\    |\n" +
    "\\  \\_| ~-/    |-____--~             |   |\n" +
    " ~\\_          /              __     |   |\n" +
    "    ~-+---.__/                |     |   |\n" +
    "   /                        _/      |   |\n" +
    "  |                  __ ___/        |   |\n" +
    "  \\____/\\_______----\\  V  \\        /    |\n" +
    "        |            \\  \\  \\      /    /|\n" +
    "        |            |  !  |    ,-,_  / |\n" +
    "        /             \\___/   _|.   ~-,  )\n" +
    "       |                     /       _| |\n" +
    "       |   ,-.              |        |   \\\n" +
    "       |    o               \\_/ / /  |   |\n" +
    "       |                      ~Y_/ _/    |\n" +
    "       \\                       |~~      /\n" +
    "         ~-_                   /     _-~\n" +
    "            ~~----,________,-~~ _--~~\n" +
    "                 /    /   /    / \n" +
    "                |    |   |    |\n" +
    "                \\     \\   \\    \\\n" +
    "         _,-----_\\  _,----_\\    \\\n" +
    "        /          /             \\\n" +
    "    ###|          |               |#####\n" +
    "   ####\\          \\              /#######\n" +
    "    ####################################    \n" +
    "        ######    #########     ####")
