import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import StatIconWidgetCard from "../../dashboard/widgets/StatIconWidgetCard";
import {
    FaStream,
    RiGamepadLine
} from "react-icons/all";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";

const MetricsDashboard = observer(({}) => {

    const {dashboardStore} = useStores();

    const {
        dashboardData,
    } = dashboardStore;
    useEffect(() => {

        const interval = setInterval(() => {
            dashboardStore.getDashboardData({})
        }, 1000);

        return () => {
            clearInterval(interval);
        }

    }, []);

    return (
        <Grid container spacing={3}>

            {!!dashboardData && <>
                <Grid item md={2}>
                    <StatIconWidgetCard title={'Live Streams'} icon={<FaStream size={30}/>}
                                        value={dashboardData.active_streams}/>
                </Grid>


                <LiveGames dashboardData={dashboardData.games} size={2}/>

                {/*<Grid item md={12} lg={12} style={{opacity: 0.3}}>*/}
                {/*    <MyStreamActivityFeed/>*/}
                {/*</Grid>*/}
            </>}


        </Grid>
    )

})

export const LiveGames = ({dashboardData, size = 4}) => {
    return <>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live Call Of Duty'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_call_of_duty}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live Fortnite'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_fortnite}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live Fallguys'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_fall_guys}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live Apex'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_apex}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live LoL'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_lol}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live DBD'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_dbd}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live Among us'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_among_us}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live Valorant'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_valorant}/>
        </Grid>
        <Grid item md={size}>
            <StatIconWidgetCard title={'Live Splitgate'} icon={<RiGamepadLine size={30}/>}
                                value={dashboardData.live_splitgate}/>
        </Grid>
    </>
}

export default MetricsDashboard