import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CouponsTable from "../../../components/entities/coupons/table/CouponsTable";
import Tabs from "../../../components/tabs/Tabs";
import ClaimedCouponsTable from "../../../components/entities/coupons/table/ClaimedCouponsTable";

const CouponsRoute = ({}) => {

    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Coupons
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <Tabs
                    labels={['Coupons', 'Claimed-Coupons']}
                    panels={ [<CouponsTable/>, <ClaimedCouponsTable />]}
                />
            </Box>
        </>
    )
}

export default CouponsRoute
