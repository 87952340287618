import React from "react";
import VoicePicker from "./VoicePicker";
import {Controller} from 'react-hook-form';

const FormVoicePicker = ({defaultValue, name, label}) => {
    return (
        <>
            <label>{label || name}</label>
            <Controller
                defaultValue={defaultValue}
                rules={{required: "Field is required",}}
                name={name}
                render={({value, onChange}) => <VoicePicker value={value} onChange={onChange}/>}
            />
        </>
    )
}
export default FormVoicePicker
