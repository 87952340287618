import React, {useEffect, useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmDialog from "@PlaystreamENV/front-lib/src/dialog/ConfirmDialog";
import DialogActions from "@material-ui/core/DialogActions";
import {Button, Dialog} from "@material-ui/core";
import EventForm from "../forms/EventForm";


function EditEventDialog({event, onClose, onSaved}) {
    const {handleSubmit} = useForm();
    const {eventsStore} = useStores();
    const [showConfirm, setShowConfirm] = useState(false);

    const onSave = () => {

        handleSubmit(async (formData) => {
            const _event = {...formData}
            await eventsStore.editEvent({id: event.id, ..._event})
            onClose();
            onSaved();
        })();
    }

    useEffect(() => {
        if (!event)
            setShowConfirm(false)
    }, [event])
    const onPreSave = () => {
        setShowConfirm(true);
    }
    return (
        <Dialog open={!!event} onClose={onClose}>
            <DialogTitle title={'Edit Event'} onClose={onClose}/>
            {event && <DialogContent>
                <EventForm event={event}/>
            </DialogContent>}
            <ConfirmDialog open={showConfirm} onConfirm={onSave} onClose={() => {
                setShowConfirm(false)
            }}/>
            <DialogActions>
                <Button onClick={onClose} variant='contained'
                        color='secondary'>
                    Cancel
                </Button>
                <Button onClick={onPreSave} variant='contained'
                        color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withForm(EditEventDialog)
