import React, {useMemo} from "react";
import Grid from "@material-ui/core/Grid";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import FormInputDate from "@PlaystreamENV/front-lib/src/form/FormInputDate";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import {Box} from "@material-ui/core";
import CardsAutoComplete from "../../cards/forms/CardsAutoComplete";

const {REACT_APP_CDN_URL} = process.env;

const PollForm = ({zone}) => {
    const {
        poll_timer_enabled,
        poll_start_date,
        poll_end_date,
        poll_type,
        poll_description,
        streamer,
        poll_enabled,
        poll_cards,
    } = zone;
    const {secret} = streamer;
    const searchByFilters = useMemo(() => {
        return ({
            streamer: zone && zone.streamer.id || undefined,
            community: true
        })
    }, [zone && zone.created_by])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h5>Poll</h5>
                <Box mb={1}>
                    <a className={'secondary-color'} href={`${REACT_APP_CDN_URL}polls/${secret}`}
                       target={"_blank"}>poll overlay</a>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    label='Enable Poll'
                    name='poll_enabled'
                    defaultChecked={poll_enabled}
                />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <FormInputCheckbox*/}
            {/*        label='Enable Poll Timer'*/}
            {/*        name='poll_timer_enabled'*/}
            {/*        defaultChecked={poll_timer_enabled}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
                <FormInputDate
                    label='Starting Date'
                    name='poll_start_date'
                    defaultValue={parseInt(poll_start_date) || new Date().getTime()}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputDate
                    label='Ends On'
                    name='poll_end_date'
                    defaultValue={parseInt(poll_end_date) || new Date().setDate(new Date().getDate() + 7)}
                />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <SingleSelect*/}
            {/*        defaultValue={poll_type}*/}
            {/*        name='poll_type'*/}
            {/*        options={[{id: 'poll_1'}, {id: 'poll_2'}]}*/}
            {/*        rules={{}}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
                <FormInputText
                    label='Poll Description'
                    defaultValue={poll_description}
                    name='poll_description'
                />
            </Grid>
            <Grid item xs={12}>
                <CardsAutoComplete
                    name='poll_cards'
                    label='Poll Cards'
                    searchByFilters={searchByFilters}
                    defaultValue={poll_cards ? poll_cards : []}
                />
            </Grid>
        </Grid>

    )
}

export default PollForm
