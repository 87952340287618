import React from "react";
import {Slide, Dialog, DialogContent} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DialogTitle from "./DialogTitle";
import DialogActions from "./DialogActions";
import useLanguage from "../l10n/hooks/useLanguage";


const useStyles = makeStyles({
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '35px',
        paddingTop: '0',
    },
    desc: {
        fontSize: 25,
        textAlign: 'center',
    },
    buttonsContainer: {
        display: 'flex',

    }
})


function ConfirmDialog({
                           open,
                           title,
                           message,
                           onConfirm,
                           onClose,
                           maxWidth = 'xs',
                           ...rest
                       }) {

    const classes = useStyles();
    const {translate} = useLanguage();
    return (
        <Dialog
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'down'
            }}

            maxWidth={maxWidth}
            open={open}
            fullWidth
            onClose={onClose}
            {...rest}
        >

            <DialogTitle
                title={title || translate('confirm_action_dialog_title_message')}
                onClose={onClose}
                className='draggable-dialog-title'
            />
            <DialogContent>
                <div className={classes.contentContainer}>
                    <h1 className={classes.desc}>{message || translate('confirm_action_dialog_default_message')}</h1>
                    <div className={classes.buttonsContainer}>
                        <Button
                            variant='contained'
                            color='secondary'
                            className='skew-btn'
                            onClick={onClose}>
                            {translate('cancel')}
                        </Button>
                        <Box ml={1}>
                            <Button
                                variant='contained'
                                color='primary'
                                className='skew-btn'
                                onClick={onConfirm}>
                                {translate('yes_please')}
                            </Button>
                        </Box>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialog

