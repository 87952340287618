import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tabs from "../../../components/tabs/Tabs";
import AchievementsPanel from "../../../components/entities/achievements/panels/AchievementsPanel";
import PersonalAchievementsPanel from "../../../components/entities/achievements/panels/PersonalAchievementsPanel";

const AchievementsRoute = ({}) => {
    return (
        <>
            <PageTitleNavBar >
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Achievements
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px= {3}>
                <Tabs
                    labels={['Achievements', 'Personal Achievements']}
                    panels={[<AchievementsPanel/>,<PersonalAchievementsPanel /> ]}
                />
            </Box>
        </>
    )

}

export default AchievementsRoute