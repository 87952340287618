import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import EditGameDialog from "../dialogs/EditGameDialog";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import YesNoLabel from "../labels/YesNoLabel";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";

function GamesTable() {
    const [rowToEdit, setRowToEdit] = useState(null);
    const {gamesStore} = useStores();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const filtersOptions = [];
    const getGames = async (params) => {
        const {data} = await gamesStore.getGames(params)
        return {data: data.games, count: data.count};
    }
    return (
        <>
            <Table
                rowProps={{hover: true}}
                defaultOrderDirection="asc"
                loadCounter={lastUpdated}
                defaultOrderBy="index"
                filtersOptions={filtersOptions}
                loadData={getGames}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setRowToEdit(row)
                                    }}
                                >
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',
                        style: {
                            width: 370,
                        },
                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        key: 'key',
                        title: 'Key',
                        cell: ({row}) => <>
                            {row.key}
                        </>
                    },
                    {
                        key: 'name',
                        title: 'Name',
                        cell: ({row}) => <>
                            {row.name}
                        </>
                    },
                    // {
                    //     key: 'default_event',
                    //     title: 'Default event',
                    //     cell: ({row}) => <>
                    //         {row.default_event}
                    //     </>
                    // },
                    {
                        key: 'index',
                        title: 'Index',
                        cell: ({row}) => <>
                            {row.index}
                        </>
                    },
                    {
                        key: 'enabled',
                        title: 'Enabled',
                        cell: ({row}) => <>
                            <YesNoLabel value={row.enabled}/>
                        </>
                    },
                ]}
            />
            <EditGameDialog
                game={rowToEdit}
                onClose={() => {
                    setRowToEdit(null)
                }}
                onSaved={() => {
                    setLastUpdated(new Date())
                }}
            />
        </>
    )
}

export default GamesTable
