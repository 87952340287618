import React, {useEffect} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import StreamMetricsTable from "../../../components/metrics/table/StreamMetricsTable";
import MetricsDashboard from "../../../components/metrics/dashboard/MetricsDashboard";

const MetricsRoute = ({}) => {


    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Metrics
                    </Typography>


                </Box>

            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <MetricsDashboard />
            </Box>
            <Box mt={4} px={3}>

                <StreamMetricsTable/>
            </Box>
        </>
    )
}

export default MetricsRoute
