import {action, computed, observable, toJS} from 'mobx'
import {getSailsClient} from "../../../socket/SailsClient";

const io = getSailsClient();

export class SocketStore {

    constructor() {

    }

    @observable  _secret = null;

    @computed get secret() {
        return toJS(this._secret)
    }

    @computed get isConnected() {
        return toJS(!!this._socket)
    }

    @observable  _socket = null;


    @computed get socket() {
        return toJS(this._socket)
    }

    @action
    connect(secret) {
        // console.log("SocketStore - connect");
        this._secret = secret;
        const socket = io.sails.connect();
        return this._socket = socket;
    }

    @action
    disconnect() {
        console.log("SocketStore - disconnect");
        this._secret = null;
        io.sails.disconnect();
        return this._socket = null;
    }

}


