import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import GiftsMappingLabel from "../labels/GiftsMappingLabel";
import EditZoneDialog from "../dialogs/EditZoneDialog";
import CategoriesLabel from "../../cards/labels/CategoriesLabel";
import CardsLabel from "../../categories/labels/CardsLabel";
import YesNoLabel from "../../games/labels/YesNoLabel";

const ZonesTable = observer(({defaultFilters = {}}) => {
    const {userId = ""} = defaultFilters;
    const {zonesStore} = useStores();
    const [rowToEdit, setRowToEdit] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const filtersOptions = [
        {
            type: 'text',
            name: "streamer",
            label: "User-ID",
            defaultValue: userId,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "name",
            label: "Zone Name",
            defaultValue: "",
            style: {width: '250px'},
            rules: {required: false},
        }
    ];

    const getZones = async (params) => {
        const {data} = await zonesStore.getZones(params)

        return {data: data.zones, count: data.count};
    }
    return (
        <>
            <Table
                rowProps={{hover: true}}
                loadCounter={lastUpdated}
                filtersOptions={filtersOptions}
                loadData={getZones}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setRowToEdit(row)
                                    }}
                                >
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',

                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        sortable: false,
                        key: 'streamer',
                        title: 'Streamer',
                        cell: ({row}) => <>
                            {row.streamer && row.streamer.email}
                        </>
                    },

                    {
                        key: 'name',
                        title: 'Name',

                        cell: ({row}) => <>
                            {row.name}
                        </>
                    },
                    {
                        key: 'description',
                        title: 'Description',

                        cell: ({row}) => <>
                            {row.description}
                        </>
                    },

                    {
                        key: 'gifts',
                        style: {
                            width: 200
                        },
                        title: 'Gifts',

                        cell: ({row}) => <>
                            <GiftsMappingLabel value={row.gifts}/>
                        </>
                    },
                    // {
                    //     key: 'rarity',
                    //     style: {
                    //         width: 250
                    //     },
                    //     title: 'Rarity',
                    //
                    //     cell: ({row}) => <>
                    //         <RarityMappingLabel value={row.rarity}/>
                    //     </>
                    // },

                    {
                        key: 'coupons_enabled',
                        title: 'Coupons',

                        cell: ({row}) => <>
                            <YesNoLabel value={row.coupons_enabled}/>
                        </>
                    },
                    // {
                    //     key: 'sorting',
                    //     title: 'Sorting',
                    //
                    //     cell: ({row}) => <>
                    //         <GiftsMappingLabel value={row.sorting}/>
                    //     </>
                    // },
                    {
                        key: 'categories',
                        style: {
                            width: 220
                        },
                        title: 'Active categories',
                        cell: ({row}) => <>
                            <CategoriesLabel categories={row.categories}/>
                        </>
                    },
                    {
                        key: 'cards',
                        title: 'Hidden Cards',
                        style: {
                            width: 170
                        },
                        cell: ({row}) => <>
                            <CardsLabel cards={row.cards}/>
                        </>
                    },

                    {
                        key: 'createdAt',
                        title: 'Created At',

                        cell: ({row}) => <>
                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />

            <EditZoneDialog zone={rowToEdit}
                            onClose={() => {
                                setRowToEdit(null)
                            }}
                            onSaved={() => {
                                setLastUpdated(new Date())
                            }}
            />
        </>
    )
})

export default ZonesTable
