import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import BotChatTerminalForm from "../forms/BotChatTerminalForm";

function BotChatTerminalDialog({bot, onClose}) {


    return (
        <Dialog open={!!bot} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Bot chat terminal'} onClose={onClose}/>
            <DialogContent>
                <BotChatTerminalForm bot={bot}/>
            </DialogContent>

            <DialogActions>

                <Button onClick={onClose} variant='contained'
                        color='primary'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withForm(BotChatTerminalDialog)
