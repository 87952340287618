import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";

const translations = {
    required_field: "Field is required",
}
export const formValidation = {

    email: {
        required: translations.required_field,
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
        }
    },
    required: {
        required: translations.required_field,
    },

};

function TicketForm({ticket}) {
    const {status, subject, email, message, notes} = ticket
    return (
        <Box>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SingleSelect
                            options={[{id: 'closed'}, {id: 'open'}]}
                            label={'Status'}
                            defaultValue={status}
                            name='status'
                            prop='id'
                            InputLabelProps={{shrink: true}}
                            validation={formValidation.required}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelect
                            options={[
                                {id: 'partnership'},
                                {id: 'support'},
                                {id: 'withdraw'},
                                {id: 'home-page-support'}
                            ]}
                            label={'Subject'}
                            defaultValue={subject}
                            name='subject'
                            prop='id'
                            InputLabelProps={{shrink: true}}
                            validation={formValidation.required}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Email Address'}
                            defaultValue={email}
                            name='email'
                            InputLabelProps={{shrink: true}}
                            validation={formValidation.email}/>
                        <FormInputText
                            label={'Subject'}
                            className={'hidden'}
                            defaultValue={subject}
                            name='subject'
                            InputLabelProps={{shrink: true}}
                            validation={formValidation.required}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Message'}
                            name='message'
                            className={'input-message'}
                            defaultValue={message}
                            variant={"outlined"}
                            InputLabelProps={{shrink: true}}
                            multiline={true}
                            validation={{}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Notes'}
                            name='notes'
                            className={'input-message'}
                            defaultValue={notes}
                            variant={"outlined"}
                            InputLabelProps={{shrink: true}}
                            multiline={true}
                            validation={{}}
                        />
                    </Grid>

                </Grid>
                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default TicketForm
