import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {observer} from "mobx-react-lite";
import UsersTable from "../../../components/entities/users/UsersTable";
import useQueryFilters from "../../hooks/useQueryFilters";

const UsersRoute = observer(({}) => {
    const { user: userId = "", email } = useQueryFilters();

    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Users
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <UsersTable defaultFilters={{userId, email}} />
            </Box>
        </>
    )
})

export default UsersRoute
