import React, {useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {
    Drawer,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import playstreamLogo from '../../assets/images/playstreamBig.png';

import clsx from 'clsx';
import Box from "@material-ui/core/Box";
import {FixedNavBar} from "@PlaystreamENV/front-lib/src/navbars";
import {FaStream} from "react-icons/all";


const drawerWidth = 240;


const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex'

    },
    fixedBar: {
        left: drawerWidth,
        width: 'auto',
        background: theme.palette.background.default,
    },
    hide: {
        display: 'none',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logo: {},

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: "#242423",
        color: '#fff',
    },
    drawerRoot: {
        paddingTop: 0
    },
    divider: {
        // margin: theme.spacing(2, 0)
    },
    content: {
        width: '100%',
        position: 'relative',
        minHeight: 600
        // flexGrow: 1,
        // transition: theme.transitions.create('margin', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        // marginLeft: -drawerWidth,

    },
    spacer: theme.mixins.toolbar,
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }
}));

export function BaseDrawer({children, fixedBarContent, drawerList, paperClass}) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);


    return (
        <>
            <FixedNavBar className={classes.fixedBar} height={80} color={'transparent'}>
                {fixedBarContent}
            </FixedNavBar>

            <div className={clsx(classes.root)}>
                <Drawer
                    className={classes.drawer}
                    open={open}
                    variant="persistent"
                    classes={{
                        paper: clsx(classes.drawerPaper, paperClass),
                    }}
                >

                    <div className={clsx('base-drawer-list', classes.drawerRoot)}>
                        <Box>
                            <Box display={'flex'} alignItems='center' justifyContent={'center'} height={80} style={{marginLeft:0}}>
                                <img src={playstreamLogo} style={{width: '180px'}} alt='playstream'/>
                            </Box>
                            {drawerList}
                        </Box>
                    </div>

                </Drawer>
                <main className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}>

                    {children}
                </main>
            </div>
        </>
    );
}

const useDrawerStyles = makeStyles(theme => ({

    root: {
        padding: "6px 16px",
        // borderBottom: '1px solid #ffffff26'

    },
}))

export function DrawerListItem({to, icon, text, onClick}) {
    const classes = useDrawerStyles();
    const {pathname} = useLocation();
    const history = useHistory();
    const selected = pathname === to;

    return (
        <ListItem selected={selected} button classes={{
            root: clsx(classes.root),

        }} onClick={() => {
            onClick ? onClick() : history.push(to)
        }}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text}/>
        </ListItem>
    )


}
