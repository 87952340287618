import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";

const io = sailsIOClient(socketIOClient);
io.sails.transports = ['websocket'];
io.sails.url = process.env.REACT_APP_SOCKET_BASE_URL;
io.sails.autoConnect = false;
io.sails.reconnection = true;
io.sails.reconnectionDelay = 2000;
io.sails.reconnectionAttempts = 10000;

const getSailsClient = () => io;

export {getSailsClient}
