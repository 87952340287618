import React from "react";
import price_c from '../../../assets/images/cards/price/CashLabel_C.png'
import price_r from '../../../assets/images/cards/price/CashLabel_R.png'
import price_e from '../../../assets/images/cards/price/CashLabel_E.png'
import price_l from '../../../assets/images/cards/price/CashLabel_L.png'

const images = {
    "common": price_c,
    "rare": price_r,
    "epic": price_e,
    "legendary": price_l,
}
const CardPrice = ({amount, rarity = "rare"}) => {


    return (
        <>
            {
                !!amount && <div className={'card-price'}>
                    <div className={'card-price-content'}>
                        <img className={'bg-price'} src={images[rarity]}/>
                        <span>    ${amount}</span>
                    </div>
                </div>
            }
        </>
    )

}


export default CardPrice
