import React from "react";
import {AiFillPicture, AiTwotoneSound} from "react-icons/all";
import Box from "@material-ui/core/Box";
import {Tooltip} from "@material-ui/core";
import SvgAI from "../../themes/svg/interactions/SvgAI";
import SvgContract from "../../themes/svg/interactions/SvgContract";
import SvgEndorsement from "../../themes/svg/interactions/SvgEndorsement";
import SvgDigitalAsset from "../../themes/svg/interactions/SvgDigitalAsset"; 
import SvgMerch from "../../themes/svg/interactions/SvgMerch"; 
import useLanguage from "../../l10n/hooks/useLanguage";


const icons = {
    "ai": <SvgAI size={30}/>,
    "contract": <SvgContract size={30}/>,
    "gif": <AiFillPicture size={30}/>,
    "sound": <AiTwotoneSound size={30}/>,
    "endorsement": <SvgEndorsement size={26}/>,
    "digital-asset": <SvgDigitalAsset size={26}/>,
    "merchandise" : <SvgMerch size={26}/>

}
const CardTypeLabel = ({type, showText = false}) => {

    const {translate} = useLanguage();
    const text = translate(`challenge_type_${type}`)
    const description = translate(`challenge_type_${type}_description`)
    return (
        <Tooltip title={!showText ? description : ""} arrow placement="top">
            <Box display={"flex"} alignItems={"center"}>
                <Box className={'card-type-icon'} style={{marginRight: 15}} display={"flex"} alignItems={"center"}>
                    {icons[type]}
                </Box>
                {showText && <span className={'card-type-text'}>{text}</span>}
            </Box>
        </Tooltip>

    )

}

export default CardTypeLabel
