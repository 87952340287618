import React, {useEffect} from "react";

import {observer} from "mobx-react-lite";

import LineChart from "@PlaystreamENV/front-lib/src/charts/LineChart";
import {Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Card from "@material-ui/core/Card";
import DashboardCard from "../cards/DashboardCard";
import Box from "@material-ui/core/Box";

const RevenuesOverTimeChart = observer(({data}) => {


    const formatXAxis = (tickItem) => {
        return tickItem.split("T")[0].replace("2021-", "");
    }
    const labelFormatter = (tickItem) => {
        return tickItem.split("T")[0];
    }


    return (
        <>
            <DashboardCard title={<Box display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
                Revenues over time
                <small style={{fontSize: 11, color: '#666'}}>(last 30 days)</small>
            </Box>}>

                <ResponsiveContainer width={'100%'} height={350}>
                    <LineChart  syncId={'dashboard-over-time'}  data={data} margin={{
                        top: 10, right: 15, left: -15, bottom: 10,
                    }}>
                        <XAxis dataKey="day" tickFormatter={formatXAxis} tick={{dy: 10, fontSize: 11}}/>
                        <YAxis dataKey={'revenues'} domain={['dataMin', 'dataMax']} tick={{fontSize: 11}}/>
                        <Tooltip labelFormatter={labelFormatter} formatter={(value) => {
                            return `${value}$`
                        }}/>
                        <Line dataKey={'revenues'} strokeWidth={3} stroke={"#8884d8"}
                              dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                    </LineChart>
                </ResponsiveContainer>
            </DashboardCard>

        </>
    )
})


export default RevenuesOverTimeChart
