import React from "react";
import frame_c from '../../../assets/images/cards/borders/Frame_C.png'
import frame_r from '../../../assets/images/cards/borders/Frame_R.png'
import frame_e from '../../../assets/images/cards/borders/Frame_E.png'
import frame_l from '../../../assets/images/cards/borders/Frame_L.png'

const images = {
    "common": frame_c,
    "rare": frame_r,
    "epic": frame_e,
    "legendary": frame_l,
}
const CardBackground = ({rarity = "rare"}) => {
    return (
        <img className={'card-bg'} src={images[rarity]}/>
    )

}

export default CardBackground