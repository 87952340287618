import React, {useState} from "react";
import TusDropZone from "../tus/TusDropZone";
import {Controller} from "react-hook-form";
import CardItem from "../obs/cards/CardItem";
import "./card-sound-uploader.scss";
import TusBrowseButton from "../tus/TusBrowseButton";
import IconButtonPlaySound from "../obs/cards/buttons/play-sound/IconButtonPlaySound";
import {useForm} from "../form/context";
import {FormInputErrorMessage} from "../form/FormInputErrorMessage";
import useLanguage from "../l10n/hooks/useLanguage";

const AiDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/ai-default.png";
const ContractDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/contract-default.png";
const EndorsementDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/endorsement-default.png";
const DigitalAssetDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/super-card-default.png"; 
const MerchandiseDefault = process.env.REACT_APP_CDN_URL + "cdn/images/cards/merch-default.png"; 

export const defaultCardImages = {
    "ai": AiDefault,
    "contract": ContractDefault,
    "endorsement": EndorsementDefault,
    "digital_asset" : DigitalAssetDefault,
    "merchandise" : MerchandiseDefault
}
const CardSoundUploader = ({metadata, getBlob, volume = 1}) => {

    const {watch, setError, clearErrors} = useForm();
    const sound = watch("metadata.sound", metadata.sound);
    console.log("la sound", sound)
    const {translate} = useLanguage();
    return (
        <>

            <Controller
                defaultValue={metadata.sound}
                name={"metadata.sound"}
                // rules={{required: "Field is required"}}
                render={(
                    {onChange},
                ) => (
                    <div className={'card-sound-uploader-container'}>

                        <TusBrowseButton text={"Upload Sound"} maxMegaByte={2}
                                         getBlob={getBlob}
                                         onFileStarted={() => {
                                             clearErrors("metadata.sound")
                                         }}
                                         onError={(err) => {
                                             console.log("CardSoundUploader -error", err)
                                             const fileToBigMessage = translate(`sound_${err}`)
                                             setError("metadata.sound", {
                                                 type: "manual",
                                                 message: fileToBigMessage
                                             });

                                         }}
                                         onFileCompleted={(path) => {
                                             console.log("file uploaded ", path)
                                             onChange(path)
                                         }}
                        />

                        {sound && <IconButtonPlaySound sound={sound} volume={volume}/>}
                    </div>

                )}
            />
            <FormInputErrorMessage style={{alignSelf: 'end'}} name={'metadata.sound'}/>
        </>
    )

}

export default CardSoundUploader
