import React, {useEffect, useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {Slide} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import ToggleLabsForStreamer from "../../live-stream-panel/ToggleLabsForStreamer";
import GamesPredictionLogsView from "../GamesPredictionLogsView";

const LabsDialog = observer(({stream, onClose}) => {

    const {adminStore} = useStores();
    const gameKey = stream && stream.game_key;
    const is_labs_enabled = stream && stream.liveConfigs && stream.liveConfigs.is_labs_enabled || false;

    const title = (() => {

        if (stream)
            return stream.streamer.email
    })();

    // console.log("stream", stream)

    return (
        <Dialog
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'down'
            }}
            maxWidth={"xl"}
            open={!!stream}
            fullWidth
            onClose={onClose}

        >
            <DialogTitle title={<>
                <Box> Labs <small>{title}</small></Box>
            </>} onClose={onClose}/>


            <DialogContent>


                {stream && <div style={{minHeight: 900}}>

                    <ToggleLabsForStreamer streamer={stream.streamer.id} is_labs_enabled={is_labs_enabled}/>
                    <GamesPredictionLogsView streamer={stream.streamer.id} game={gameKey}/>
                </div>}


            </DialogContent>
            <DialogActions cancelText={'Close'} onCancel={onClose}/>
        </Dialog>
    )
})

export default withForm(LabsDialog)
