import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {observer} from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import {withForm} from "@PlaystreamENV/front-lib/src/form/context";
import Card from "@material-ui/core/Card";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import ApplicationForm from "../../../components/application/ApplicationForm";
import Container from "@material-ui/core/Container";
import ParseLogsDialog from "../../../components/labs/logs/ParseLogsDialog";
import ButtonWithConfirm from "../../../components/entities/users/forms/ButtonWithConfirm";
import {VisibleByRole} from "@PlaystreamENV/front-lib/src/auth/role/VisibleByRole";
import {Toaster} from "@PlaystreamENV/front-lib/src/toast/toaster";


const ApplicationRoute = observer(() => {

    const {labsStore, adminStore} = useStores();


    const deleteLabsLogs = async () => {
        console.log("deleteLabsLogs");
        await labsStore.deleteAllLabsLog();
        window.location.reload();
    }

    const refreshAllAchievements = async () => {
        console.log("refreshAllAchievements");
        try {
            await adminStore.refreshAllAchievements();
            Toaster.create(`Achievements refreshed successfully`, {autoClose: true})

        } catch (err) {
            Toaster.error(`Something went wrong with the achievements refresh, Contact RND and don't try again, thanks`, {autoClose: false})

        }

    }


    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Application
                    </Typography>

                </Box>
            </PageTitleNavBar>

            <Box pt={5} px={2}>


                <Container>

                    <Box mb={2}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <h3>Labs</h3>
                                <Box display={'flex'}>
                                    <ParseLogsDialog/>
                                    <VisibleByRole role={'superuser'}>
                                        <Box ml={2}>
                                            <ButtonWithConfirm color={'primary'}
                                                               onConfirm={deleteLabsLogs}> Delete All labs
                                                data</ButtonWithConfirm>
                                        </Box>
                                    </VisibleByRole>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <VisibleByRole role={'superuser'}>

                        <Box mt={5}>
                            <h3>Achievements</h3>
                            <Box>
                                <ButtonWithConfirm color={'primary'}
                                                   onConfirm={refreshAllAchievements}> Refresh All Achievements
                                </ButtonWithConfirm>
                            </Box>
                        </Box>
                    </VisibleByRole>

                    <Box mt={5}>
                        <h3>Application Configs</h3>

                    </Box>

                    <Card style={{padding: 20}} title={
                        'Application'
                    }>

                        <Grid item xs={8}>
                            <ApplicationForm/>

                        </Grid>


                    </Card>


                </Container>


            </Box>

        </>
    )
})


export default withForm(ApplicationRoute)
