import React, {useEffect} from "react";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import RequiredLabel from "@PlaystreamENV/front-lib/src/form/labels/RequiredLabel";
import Grid from "@material-ui/core/Grid";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {MdEdit} from "react-icons/all";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import {observer} from "mobx-react-lite";
import {Toaster} from "@PlaystreamENV/front-lib/src/toast/toaster";
import RarityForm from "./RarityForm";
import LevelsForm from "./LevelsForm";
import TournamentForm from "./TournamentForm";
import HolidayGoalsForm from "./HolidayGoalsForm";
import ReferralForm from "./ReferralForm";
import CouponCategoriesForm from "./CouponCategoriesForm";
import CharityForm from "./CharityForm";

const ApplicationForm = observer(({}) => {
        const {adminStore, labsStore} = useStores();

        const {applicationConfigs} = adminStore;
        const {
            min_withdraw_amount,
            partnership_goal_exp,
            downtime_published,
            downtime_start,
            downtime_end
        } = (applicationConfigs || {});

        const {handleSubmit} = useForm();
        const handleChange = () => {
            handleSubmit(async (formData) => {
                // const coupon_categories = formData.coupon_categories.map(category => category.id);
                const charity_streamers = formData.charity_streamers.map(streamer => streamer.id).filter(s=>!!s);
                const charity2_streamers = formData.charity2_streamers.map(streamer => streamer.id).filter(s=>!!s);
                const charity3_streamers = formData.charity3_streamers.map(streamer => streamer.id).filter(s=>!!s);
                const charity4_streamers = formData.charity4_streamers.map(streamer => streamer.id).filter(s=>!!s);
                const charity5_streamers = formData.charity5_streamers.map(streamer => streamer.id).filter(s=>!!s);

                await adminStore.setApplicationConfigs({
                    ...applicationConfigs,
                    ...formData,
                    // coupon_categories,
                    charity_streamers,
                    charity2_streamers,
                    charity3_streamers,
                    charity4_streamers,
                    charity5_streamers
                });
                Toaster.create(`Application configs updated`, {autoClose: true})

            })();
        };

        useEffect(() => {
            adminStore.getApplicationConfigs();
        }, [])

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        Use this section to edit the application configs
                    </Typography>
                </Grid>

                {applicationConfigs && <>
                    <Grid item xs={12}>
                        <FormInputText name='min_withdraw_amount'
                                       InputProps={{inputProps: {min: 0}}}
                                       InputLabelProps={{shrink: true}}
                                       label={<RequiredLabel>min_withdraw_amount</RequiredLabel>}
                                       variant={"outlined"}
                                       defaultValue={min_withdraw_amount}
                                       color={"secondary"}
                                       type={"number"}
                                       validation={{}}

                        />

                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText name='partnership_goal_exp'
                                       InputProps={{inputProps: {min: 0}}}
                                       InputLabelProps={{shrink: true}}
                                       label={<RequiredLabel>partnership_goal_exp</RequiredLabel>}
                                       variant={"outlined"}
                                       defaultValue={partnership_goal_exp}
                                       color={"secondary"}
                                       type={"number"}
                                       validation={{}}

                        />

                    </Grid>
                    <Grid item xs={12}>
                        <RarityForm rarityModel={applicationConfigs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <LevelsForm levelModel={applicationConfigs}/>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <TournamentForm tournamentModel={applicationConfigs}/>*/}
                    {/*</Grid>*/}

                    <Grid item xs={12}>
                        <ReferralForm applicationConfigs={applicationConfigs}/>
                    </Grid>

                    {/*<Grid item xs={12}>*/}
                    {/*    <CouponCategoriesForm applicationConfigs={applicationConfigs}/>*/}
                    {/*</Grid>*/}

                    <Grid item xs={12}>
                        <HolidayGoalsForm applicationConfigs={applicationConfigs}/>
                    </Grid>

                    <Grid item xs={12}>
                        <CharityForm applicationConfigs={applicationConfigs}/>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGlobalErrorMessage/>
                    </Grid>
                </>}

                <Grid item xs={8}>


                    <Box mt={2}>
                        <Button size='small' variant='contained' color='secondary'
                                startIcon={<MdEdit/>}
                                onClick={handleChange}>
                            Save Changes
                        </Button>
                    </Box>

                </Grid>
            </Grid>

        )

    }
)

export default ApplicationForm
