import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../entities/zones/panels/ZonePanels";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import FormInputDate from "@PlaystreamENV/front-lib/src/form/FormInputDate";

const HolidayGoalsForm = ({applicationConfigs}) => {

    const {
        holiday_goals_enabled,
        holiday_goals_target,
        holiday_goals_start_date,
        holiday_goals_timer_enabled,
        holiday_goals_end_date,
        holiday_goals_description,
    } = applicationConfigs;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3>Holiday Goals</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={holiday_goals_enabled}
                                    name='holiday_goals_enabled'
                                    label='Holiday Goals Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={holiday_goals_timer_enabled}
                                    name='holiday_goals_timer_enabled'
                                    label='Holiday Timer Enabled'
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <FormInputText*/}
                            {/*        label={'Holiday Goals Target'}*/}
                            {/*        defaultValue={holiday_goals_target}*/}
                            {/*        type={"number"}*/}
                            {/*        name='holiday_goals_target'*/}
                            {/*        validation={formValidation.required}/>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Starts on'
                                    name='holiday_goals_start_date'
                                    defaultValue={holiday_goals_start_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Ends on'
                                    name='holiday_goals_end_date'
                                    defaultValue={holiday_goals_end_date}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <FormInputText*/}
                            {/*        type='text'*/}
                            {/*        name='holiday_goals_description'*/}
                            {/*        label='Holiday Goals Description'*/}
                            {/*        defaultValue={holiday_goals_description}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>
                    </form>

                </Grid>


            </Grid>
        </>
    )

}

export default HolidayGoalsForm
