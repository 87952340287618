import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";

export class ReferralsStore {

    constructor() {
    }


    @action
    getReferrals(params) {
        return adminApi.get('/referrals', {params})
    }

    @action
    getBonuses(params) {
        return adminApi.get('/bonuses', {params})
    }

    @action
    editBonusModel(bonus) {
        return adminApi.patch(`/bonuses/${bonus.id}`, {bonus});
    }

    @action
    editReferralModel(referral) {
        return adminApi.patch(`/referrals/${referral.id}`, {referral});
    }

}
