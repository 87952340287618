import React from "react";

const streamConfigs = {
    "twitch": "https://twitch.tv/{{username}}",
    "facebook": "https://www.facebook.com/{{username}}/videos/",
    "youtube": "https://youtube.com/{{username}}",
    "dlive": "https://dlive.tv/{{username}}",
    "other": "https://playstream.gg/live-stream/{{username}}"
}

const StreamingPlatformLabel = ({streamingPlatform, username}) => {
    return (
        <a className={'secondary-color'} target={"_blank"}
           href={streamConfigs[streamingPlatform].replace("{{username}}", username)}> {username}@{streamingPlatform}  </a>
    )
}

export default StreamingPlatformLabel
