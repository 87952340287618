import React from "react";
import by_ca from '../../../assets/images/cards/labels/CA_Label.png'

const CaByCa = ({card}) => {
    const {type} = card;
    const isAi = type === "ai";

    return (
        <>
            {!!isAi && <div className={'ca-by-ca'}>
                <img className={'img-by-ca'} src={ by_ca}/>
            </div>}
        </>
    )
}


export default CaByCa