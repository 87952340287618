import React, {useEffect} from "react";
import PieChart from "@PlaystreamENV/front-lib/src/charts/PieChart";
import ChartLabelItem from "@PlaystreamENV/front-lib/src/charts/ChartLabelItem";
import {observer} from "mobx-react-lite";
import {Colors} from "../../../theme/Colors";
import DashboardCard from "../cards/DashboardCard";
import {bytesToMegaBytes} from "../../metrics/labels/UploadsMetricsLabel";
import {Tooltip} from "recharts";


const BytesByTypeChart = observer(({data}) => {

    const title = 'Bytes by event';

    const _data = (data || []).sort((a, b) => {
        if (a.sum > b.sum) return -1;
        if (a.sum < b.sum) return 1;
        return 0;
    })
    const topBytes = _data.splice(0, 10)
    const others = _data.reduce((last, obj, index) => {
        last.sum += obj.sum;
        return last;
    }, {key: "Others", sum: 0})
    return (
        <>
            <DashboardCard title={title} className={'bytes-by-type-chart'}>


                <PieChart data={[...topBytes, others].filter(i => !!i.sum)} nameKey={'key'} dataKey={'sum'}
                          height={400}
                          tooltip={<Tooltip formatter={(value) => {
                              return `${bytesToMegaBytes(value)}MB`
                          }}/>}
                          label={({row}) => {
                              return (
                                  <ChartLabelItem key={row.key}
                                                  selected={false}
                                                  onClick={() => {
                                                      // setFilters(row)
                                                  }}>
                                      <span>{row.key}</span>
                                      <span> {bytesToMegaBytes(row.sum)}MB</span>
                                  </ChartLabelItem>
                              )


                          }}
                          colors={getColors()}/>
            </DashboardCard>

        </>
    )
})

function getColors() {

    return Colors.challenges;
}


export default BytesByTypeChart
