import React from "react";
import Box from "@material-ui/core/Box";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import AchievementsAutoComplete from "./AchievementsAutoComplete";
import UsersAutocomplete from "../../users/forms/UsersAutocomplete";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";


function PersonalAchievementForm({personalAchievement = {}}) {
    const {achievement, step, streamer} = personalAchievement
    const {watch} = useForm()
    const watchAchievement = watch('achievement', achievement);
    const {steps_to_complete = 0} = watchAchievement || {};
    // console.log(personalAchievement)
    return (
        <Box>
            <form>
                <UsersAutocomplete
                    defaultValue={streamer}
                    name='streamer' rules={{required: 'Required Field'}}
                />
                <Box mt={3}/>
                <AchievementsAutoComplete
                    defaultValue={achievement}
                    rules={{required: 'Required Field'}}
                />
                <Box mt={3}/>
                <FormInputText
                    label='Step'
                    name='step'
                    type='number'
                    max={15}
                    defaultValue={step}
                    InputProps={{
                        inputProps: {
                            min: 0,
                        }
                    }}
                    validation={{
                        required: 'Required Field',
                        validate: (val) => {
                            if (val > steps_to_complete) {
                                return 'Completed Steps cannot Exceed Achievement Steps'
                            }
                        }
                    }}
                />
                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default PersonalAchievementForm
