import React from "react";
import useLanguage from "./hooks/useLanguage";

const LocalizedText = ({id}) => {

    const {translate} = useLanguage();
    const value = translate(id);
    return <>
        {value || `#${id}`}
    </>;

}

export default LocalizedText