import React, {useMemo, useState} from "react";
import {Slider} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import throttle from "lodash/throttle";

const VolumeSlider = observer(({value, onChange}) => {
    const [defaultValue] = useState(value);
    const change = useMemo(
        () =>
            throttle(async (event, val) => {
                await onChange(val);
            }, 300),
        [],
    );

    return (
        <div style={{maxWidth: '225px'}}>
            <Slider
                color='secondary'
                defaultValue={defaultValue}
                min={0}
                step={0.05}
                max={1}
                onChange={change}
                aria-labelledby="continuous-slider"
            />
        </div>
    )

})

export default VolumeSlider
