import React, {useEffect, useMemo, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import MdAutocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import {Controller} from "react-hook-form";
import {FormInputErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormInputErrorMessage";

export default function Autocomplete({
                                         label,
                                         loadData,
                                         renderOption,
                                         getOptionLabel,
                                         defaultValue,
                                         name,
                                         rules,
                                         ...rest
                                     }) {
    const [value, setValue] = useState(defaultValue);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);


    const fetch = useMemo(
        () =>
            throttle(async (input, callback) => {
                const data = await loadData(input);
                callback(data);
            }, 200),
        [],
    );

    useEffect(() => {

        fetch(inputValue, (results) => {
            setOptions([...results].filter(v => !!v));
        });

    }, [inputValue, value]);

    // console.log("render", value)
    return (
        <>
            <Controller
                rules={rules}
                defaultValue={defaultValue}
                name={name}
                render={({onChange, onBlur, value}) =>
                    <MdAutocomplete
                        getOptionLabel={getOptionLabel}
                        filterOptions={(x) => x}
                        options={options}
                        autoComplete
                        onOpen={() => {
                            setInputValue('')
                        }}
                        includeInputInList
                        filterSelectedOptions
                        defaultValue={defaultValue}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            onChange(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={label} variant="outlined" fullWidth/>
                        )}
                        renderOption={renderOption}
                        {...rest}
                    />
                }

            />
            <FormInputErrorMessage style={{alignSelf: 'end'}} name={name}/>
        </>

    );
}