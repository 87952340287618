import React from "react";
import {useTheme} from "@material-ui/core";

const SvgMerch = ({size = 40}) => {
    const theme = useTheme();

    return (
        <svg style={{width: size, height: size}} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M213.129 36.1301C196.813 19.8144 170.223 19.8144 153.873 36.1301C149.231 40.7723 144.998 47.1552 141.653 54.3915L128.307 52.5824C118.921 51.3195 109.363 54.5621 102.673 61.2523L32.8021 131.123C20.9237 143.002 20.9237 162.355 32.8021 174.234L75.025 216.457C80.9642 222.396 88.7808 225.365 96.5632 225.365C104.38 225.365 112.162 222.396 118.101 216.457L187.972 146.586C194.662 139.895 197.905 130.304 196.642 120.951L194.833 107.571C202.103 104.226 208.452 100.028 213.129 95.3515C229.444 79.0358 229.444 52.4459 213.129 36.1301ZM178.347 136.926L108.476 206.797C101.922 213.35 91.2384 213.35 84.6848 206.797L42.4619 164.574C35.9083 158.02 35.9083 147.337 42.4619 140.783L112.333 70.912C115.507 67.7376 119.842 65.9968 124.279 65.9968C125.03 65.9968 125.747 66.031 126.498 66.1334L136.943 67.5328C134.178 78.1483 134.076 88.1494 136.875 95.9318C134.656 100.54 135.407 106.206 139.23 110.029C144.043 114.842 151.893 114.842 156.706 110.029C161.519 105.216 161.519 97.3654 156.706 92.5526C154.556 90.4022 151.859 89.2757 149.06 89.0368C147.866 83.712 148.446 76.7488 150.596 69.376L163.669 71.151C171.145 72.1408 177.084 78.08 178.074 85.5552L183.091 122.761C183.808 127.915 182.033 133.205 178.347 136.926ZM203.469 85.7259C200.772 88.4225 197.12 91.0507 192.922 93.3035L191.625 83.7462C189.781 70.1952 179.063 59.4432 165.513 57.6341L155.955 56.3371C158.242 52.1387 160.836 48.4864 163.533 45.7899C174.558 34.7989 192.478 34.7648 203.503 45.7899C214.46 56.7808 214.46 74.7009 203.469 85.7259Z" fill="#00EFF8"/>
            </svg>
    )

}

export default SvgMerch
