import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import ConfirmDialog from "@PlaystreamENV/front-lib/src/dialog/ConfirmDialog";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import Box from "@material-ui/core/Box";

const ButtonResetUserBalance = ({user, onCompleted, style, disabled, setIsLoadingBalance}) => {
    const [open, setOpen] = useState(false);
    const {adminStore} = useStores();
    const {partnership} = user || {};
    const onConfirm = async () => {
        setIsLoadingBalance(true)
        await adminStore.resetStreamerBalance({
            streamer: user.id
        })
        setIsLoadingBalance(false)

        onCompleted();
        onClose();
    }
    const onClose = () => {

        setOpen(false)
    }
    return (
        <>
            <Box
                style={{marginLeft: 'auto'}}
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
            >
                <Button
                    disabled={disabled}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => setOpen(true)}>
                    Reset Balance
                </Button>
                <PartnershipLevel partnership={partnership}/>

            </Box>

            <ConfirmDialog
                open={open}
                maxWidth='md'
                onConfirm={onConfirm}
                onClose={onClose}
                message={<div className={'confirm-withdraw-msg'}>
                    please verify the commission that playstream should charge for that partnership&nbsp;
                    <PartnershipLevel partnership={partnership}/>&nbsp;status before you proceed with withdrawal.
                </div>}
            />
        </>
    )
}

const getPartnershipColor = (partnership) => {
    if (partnership === 'streamer') return '#89FC7B'
    if (partnership === 'affiliate') return '#3FDAFF'
    if (partnership === 'partner') return '#FD3E98'
}
const PartnershipLevel = ({partnership}) => {
    const partnerColor = getPartnershipColor(partnership);

    return (
        <span style={{color: partnerColor, textTransform: 'uppercase', fontWeight: 'bold'}}>
                {partnership}
                </span>
    )

}

export default ButtonResetUserBalance
