import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";

const translations = {
    required_field: "Field is required",
}
export const formValidation = {


    required: {
        required: translations.required_field
    },
    enabled: {
        // required: translations.required_field
    },

};

function EventForm({event}) {
    const {watch} = useForm()
    const disable_custom_steps = watch("disable_custom_steps", event.disable_custom_steps);

    return (
        <Box>
            <form>

                <Grid container spacing={2}>


                    <Grid item xs={12}>
                        <FormInputCheckbox name={'enabled'} defaultChecked={event.enabled}
                                           label={"Enabled"}
                                           validation={formValidation.disabled}/>
                    </Grid>

           

                    <Grid item xs={12}>

                        <FormInputCheckbox name={'disable_custom_steps'} defaultChecked={event.disable_custom_steps}
                                           label={"Disabled custom steps"}
                                           validation={formValidation.disabled}/>
                    </Grid>

                    <Grid item xs={12}>
                        <FormInputText label={'Low_step'} defaultValue={event.low_step}
                                       name='low_step'
                                       validation={formValidation.required}/>
                    </Grid>

                    <Grid item xs={12}>
                        <FormInputText label={'Medium_step'} defaultValue={event.medium_step}
                                       disabled={disable_custom_steps}

                                       name='medium_step'
                                       validation={formValidation.required}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText label={'High_step'} defaultValue={event.high_step}
                                       disabled={disable_custom_steps}
                                       name='high_step'
                                       validation={formValidation.required}/>
                    </Grid>
                </Grid>

                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default EventForm
