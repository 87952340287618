export class DelayedPromise {

    constructor(func, delay = 1000) {

        this.promise = Promise.all([
            new Promise(func),
            new Promise((resolve) => {
                setTimeout(() => {
                    // console.log("minimumTimePromise - done")
                    resolve();
                }, delay)

            }),
        ]).then(res => res[0])
    }

    then() {
        return this.promise.then.apply(this.promise, arguments)
    }


}

// new DelayedPromise((resolve, reject) => {
//     console.log("DelayedPromise before start");
//     setTimeout(() => {
//         console.log("DelayedPromise resolved");
//         resolve();
//     }, 1000)
// }).then(() => {
//     // console.log("DelayedPromise after all")
// })