import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";

const translations = {
    required_field: "Field is required",
}
export const formValidation = {


    required: {
        required: translations.required_field
    },
    enabled: {
        // required: translations.required_field
    },

};

function GameForm({game}) {
    return (
        <Box>
            <form>

                <Grid container spacing={2}>


                    <Grid item xs={12}>
                        <FormInputText label={'Name'} defaultValue={game.name}
                                       name='name'
                                       validation={formValidation.required}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputCheckbox name={'enabled'} defaultChecked={game.enabled}
                                           label={"Enabled"}
                                           validation={formValidation.disabled}/>
                    </Grid>

                    <Grid item xs={12}>
                        <FormInputText label={'Index'} defaultValue={game.index}
                                       name='index'
                                       validation={formValidation.required}/>

                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <FormInputText label={'Default Event'} defaultValue={game.default_event}*/}
                    {/*                   name='default_event'*/}
                    {/*                   validation={formValidation.required}/>*/}

                    {/*</Grid>*/}

                    {/*<Grid item xs={12}>*/}
                    {/*    <EventsToDisableMultiSelect game={game.key} defaultEvents={game.events_to_disable}/>*/}

                    {/*</Grid>*/}
                </Grid>

                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default GameForm
