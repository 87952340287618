import React from "react";
import {useTheme} from "@material-ui/core";

const SvgBannerBig = ({size = 50}) => {
    const theme = useTheme()
    return (
        <svg style={{width: size}} width="96" height="24" viewBox="0 0 96 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.6553 4.43077H11.169C11.7621 4.43077 12.2563 4.93427 12.2563 5.53846V6.37762C12.2563 7.01538 12.4869 7.11608 13.0141 7.01538L16.2099 6.37762C16.8689 6.24336 17.0336 6.04196 17.0336 5.33706V3.99441C17.0336 1.77902 15.9793 0 12.8164 0H4.87616C1.71324 0 0.65894 1.77902 0.65894 3.99441V10.0028C0.65894 12.2182 1.71324 13.8629 4.87616 13.9972L10.7078 14.2657C11.9268 14.3329 12.2892 14.635 12.2892 15.7762V18.4615C12.2892 19.0657 11.795 19.5692 11.202 19.5692H5.86457C5.27152 19.5692 4.77732 19.0657 4.77732 18.4615V17.4881C4.77732 16.8168 4.57963 16.649 3.95364 16.7832L0.823675 17.421C0.164735 17.5552 0 17.7566 0 18.4615V20.0056C0 22.221 1.0543 24 4.21722 24H13.0141C16.177 24 17.2313 22.221 17.2313 20.0056V13.6615C17.2313 11.4462 16.177 9.76783 13.0141 9.63357L7.1495 9.36503C5.99636 9.2979 5.56804 8.9958 5.56804 7.78741V5.53846C5.56804 4.93427 6.06225 4.43077 6.6553 4.43077Z" fill={theme.palette.secondary.main}/>
            <path d="M33.0042 0H22.9553C19.7924 0 18.7381 1.77902 18.7381 3.99441V20.0056C18.7381 22.221 19.7924 24 22.9553 24H33.0042C36.1671 24 37.2214 22.221 37.2214 20.0056V3.99441C37.2214 1.77902 36.1671 0 33.0042 0ZM23.6472 19.5692V4.43077H32.2794V19.5692H23.6472Z" fill={theme.palette.secondary.main}/>
            <path d="M39.1983 20.0056C39.1983 22.221 40.2526 24 43.4155 24H52.3441C55.507 24 56.5284 22.221 56.5284 20.0056V0.872728C56.5284 0.201398 56.3307 0 55.6718 0H52.4759C51.817 0 51.6193 0.201398 51.6193 0.872728V19.4685H44.1403V0.872728C44.1403 0.201398 43.9426 0 43.2837 0H40.0549C39.3959 0 39.1983 0.201398 39.1983 0.872728V20.0056Z" fill={theme.palette.secondary.main}/>
            <path d="M72.0704 23.2615C72.4328 23.8657 72.6964 24 73.3883 24H75.5957C76.2547 24 76.4524 23.7986 76.4524 23.1273V0.872728C76.4524 0.201398 76.2547 0 75.5957 0H72.5975C71.9386 0 71.7409 0.201398 71.7409 0.872728V14.0308H71.708L63.5371 0.738462C63.1747 0.134266 62.9111 0 62.2192 0H59.5176C58.8587 0 58.661 0.201398 58.661 0.872728V23.1273C58.661 23.7986 58.8587 24 59.5176 24H62.5487C63.2077 24 63.4053 23.7986 63.4053 23.1273V9.1972H63.4383L72.0704 23.2615Z" fill={theme.palette.secondary.main}/>
            <path d="M87.9609 0H79.4935C78.8346 0 78.6369 0.201398 78.6369 0.872728V23.1273C78.6369 23.7986 78.8346 24 79.4935 24H87.9609C92.4088 24 96 21.5497 96 15.5748V8.42517C96 2.45035 92.4088 0 87.9609 0ZM91.0909 8.59301V15.407C91.0909 17.1524 90.2672 19.5692 87.1043 19.5692H83.579V4.43077H87.1043C90.2672 4.43077 91.0909 6.84755 91.0909 8.59301Z" fill={theme.palette.secondary.main}/>
        </svg>


    )

}

export default SvgBannerBig