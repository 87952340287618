import React from "react";
import InputCheckbox from "./inputs/InputCheckbox";
import {useForm} from "./context";
import {FormInputErrorMessage} from "./FormInputErrorMessage";
import clsx from "clsx";

const FormInputCheckbox = ({name, validation, className, ...rest}) => {
    const {errors, register, formState} = useForm();

    return (
        <>
            <InputCheckbox name={name} {...rest} inputRef={register(validation)}   className={clsx('text-muted',className)}/>
            <FormInputErrorMessage style={{alignSelf: 'end'}} name={name}/>
        </>
    )
}

export default FormInputCheckbox
