import React from "react";
import {useTheme} from "@material-ui/core";

const SvgBannerBig = ({id, size = 30}) => {
    const theme = useTheme()
    return (
        <svg style={{width: size}} width="60" height="24" viewBox="0 0 60 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.46477 5.26739V18.7326H17.2476V14.518H14.5114C13.0002 14.518 11.779 13.3384 11.779 11.8843C11.779 10.4301 13.0015 9.25056 14.5114 9.25056H19.98C21.4912 9.25056 22.7124 10.4301 22.7124 11.8843V21.3663C22.7124 22.8204 21.4899 24 19.98 24H2.73238C1.22121 24 0 22.8204 0 21.3663V2.6337C0 1.17956 1.22248 0 2.73238 0H19.9813C21.4924 0 22.7136 1.17956 22.7136 2.6337C22.7136 4.08783 21.4912 5.26739 19.9813 5.26739H5.46477ZM30.9108 0C29.3996 0 28.1784 1.17956 28.1784 2.6337V21.3663C28.1784 22.8204 29.4009 24 30.9108 24C32.4207 24 33.6432 22.8204 33.6432 21.3663V2.6337C33.6432 1.17956 32.4207 0 30.9108 0ZM57.2676 0H42.2082C40.697 0 39.4758 1.17956 39.4758 2.6337V21.3663C39.4758 22.8204 40.6983 24 42.2082 24C43.7194 24 44.9406 22.8204 44.9406 21.3663V15.3368H52.1669C53.6781 15.3368 54.8993 14.1572 54.8993 12.7031C54.8993 11.2489 53.6768 10.0694 52.1669 10.0694H44.9406V5.26739H57.2676C58.7788 5.26739 60 4.08783 60 2.6337C60 1.17956 58.7788 0 57.2676 0Z" fill={theme.palette.secondary.main}/>
        </svg>


    )
}

export default SvgBannerBig