import React, {useEffect} from "react";
import Box from "@material-ui/core/Box";
import CardTypeLabel from "../labels/CardTypeLabel";
import SingleSelect from "../../form/select/single-select/SingleSelect";
import useLanguage from "../../l10n/hooks/useLanguage";

const types = [
    {
        id: "contract"
    },
    {
        id: "endorsement"
    }
]

const typeswithAI = [
    {
        id: "ai"
    },
    {
        id: "contract"
    },
    {
        id: "endorsement"
    }
]

const adminCardTypes = [
    {
        id: "ai"
    },
    {
        id: "contract"
    },
    {
        id: "endorsement"
    },
    {
        id : "digital-asset",
    },
    {
        id : "merchandise",
    }
]

const typesWithAll = [
    {
        id: 'all'
    },
    ...types
]

const typesWithAIandAll = [
    {
        id: 'all'
    },
    ...typeswithAI
]

const adminTypesWithAll = [
    {
        id: 'all'
    },
    ...adminCardTypes
]

const CardTypeSelect = ({defaultValue, disabled, rules,adminTypes = false, withEmpty = false}) => {
    const {translate} = useLanguage();
    return (
        <>
            <SingleSelect
                label={translate('type')}
                name={"type"}
                prop={"id"}
                options= {CardOptionsSelect(adminTypes,withEmpty,defaultValue)}
                defaultValue={defaultValue}
                rules={rules}
                disabled={disabled}>

                {({option, isSelected}) => {
                    const text = translate(`challenge_type_${option.id}`)
                    return <Box display={"flex"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
                        <span style={{textTransform: 'capitalize'}}>{text}</span>
                        <CardTypeLabel type={option.id}/>
                    </Box>
                }}
            </SingleSelect>
        </>
    )

}

const CardOptionsSelect = (adminTypes,withEmpty,defaultValue) => {
    if (adminTypes) {
        return withEmpty ? adminTypesWithAll : adminCardTypes;
    }
    else {
        if (defaultValue === "ai") {
            return withEmpty ? typesWithAIandAll : typeswithAI;
        }
        
        return withEmpty ? typesWithAll : types;
    }
}

export default CardTypeSelect
