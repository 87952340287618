import React, {useState, useCallback, useRef, useEffect} from "react";
import Countdown from 'react-countdown';


function format(num) {
    const prefix = num.toString().length <= 1 ? "0" : ""
    return (prefix + num).slice(-3);
}

const LiveTimer = ({id, date, onComplete, pause = false, overtime = false, ...rest}) => {

    const ref = useRef();

    console.log("LiveTimer", date)
    const renderer = useCallback(({days, hours, minutes, seconds, api}) => {
        const daysHours = days * 24
        return <span>
           {format(hours + daysHours)}:{format(minutes)}:{format(seconds)}
        </span>;
    }, [id]);

    useEffect(() => {

        if (pause && ref.current) {
            ref.current.api.pause();
        }
    }, [ref, pause]);


    return (
        <>
            {!!date &&
            <Countdown controlled={false} ref={ref} key={id} overtime={overtime} date={date} renderer={renderer}
                       onComplete={onComplete} {...rest}/>}
        </>
    )
}

export default React.memo(LiveTimer, (prevMovie, nextMovie) => {
    return prevMovie.date === nextMovie.date;
})
