import React from 'react';
import {FormInputErrorMessage} from "./FormInputErrorMessage";
import {useForm} from "./context";
import {InputPassword} from "./inputs";

function FormInputPassword({name, disabled, validation, ...rest}) {

    const {errors, register, formState} = useForm();

    return (
        <>
            <InputPassword
                disabled={disabled || formState.isSubmitting}
                name={name}
                inputRef={register(validation)}
                error={!!errors[name]}
                {...rest}

            />
            <FormInputErrorMessage style={{alignSelf: 'end'}} name={name}/>
        </>
    )
}


export {FormInputPassword}