import React from "react";
import LiveTimer from "@PlaystreamENV/front-lib/src/live-timer/LiveTimer";

const LevelLabel = ({interaction}) => {
    const {id, level, expiration_minutes, expiration_time, progression, status} = interaction;
    const now = new Date().getTime()
    const isRefunded = status.indexOf("refund") > -1;
    const hasCountdown = expiration_time > now && progression && progression.step < progression.steps_to_complete && !isRefunded;
    return (
        <>
            <div style={{width: 120}}>
                <div>
                    {level}
                </div>
                {!!expiration_minutes && <small>
                    {expiration_minutes} minutes
                </small>}
                {!!expiration_time && hasCountdown &&
                <div>
                    <LiveTimer id={id} date={expiration_time} onComplete={() => {
                        if (!expiration_time) {
                            console.log("LevelLabel prevented onExpired event for interaction", interaction)
                            return;
                        }

                        console.log("LevelLabel onExpired start for interaction", interaction)
                        // onExpired && onExpired(interaction);
                    }}/>

                </div>}
                <div>
                    {!expiration_minutes && !hasCountdown && <span className={'text-muted'}>Forever</span>}
                </div>
                <div>
                    {!!expiration_minutes && !hasCountdown && <span className={'text-muted'}>Processed</span>}
                </div>
            </div>


        </>
    )

}

export default LevelLabel