import React from "react";
import {useTheme} from "@material-ui/core";

const SvgContract = ({size = 40}) => {
    const theme = useTheme();

    return (
        <svg style={{width: size, height: size}} version="1.1" id="svg_contract_01" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 80 80"  xmlSpace="preserve">
            <g>
                <defs>
                    <path id="contract_id_01" d="M24.8,45.5c-0.8,0.8-0.8,2,0,2.8c0.7,0.7,1.7,0.8,2.5,0.2l0.3-0.2L35,41c1-1,2.5-1,3.5,0
			c0.8,0.8,1,2.3,0.2,3.4l-0.2,0.3l-7.4,7.4l0,0c-0.8,0.8-0.8,2,0,2.8c0.7,0.7,1.7,0.8,2.5,0.2l0.3-0.2l7.4-7.4c1-1,2.5-1,3.5,0
			c0.8,0.8,1,2.3,0.2,3.4l-0.2,0.3l-5.4,5.4l-0.2,0.3c-0.5,0.8-0.5,1.8,0.2,2.5c0.7,0.7,1.7,0.8,2.5,0.2l0.3-0.2l7.4-7.4
			c1-1,2.5-1,3.5,0c0.8,0.8,1,2.3,0.2,3.4l-0.2,0.3l-5.2,5.2l0,0c-0.8,0.8-0.8,2,0,2.8c0.7,0.7,1.7,0.8,2.5,0.2l0.3-0.2l14.7-14.7
			c7.4-7.4,7.4-19.3,0-26.6c-3.5-3.5-8.4-5.5-13.4-5.5h-0.2c1.8,1.3,3,3.4,3,5.7c0,3.9-3,6.9-6.7,7.2h-0.5h-6.5L24.8,45.5z
			 M28.3,16.2h-0.8c-4,0.2-7.7,1.5-10.9,3.9c-0.7,0.5-1.3,1.2-2,1.7c-7.2,7.2-7.4,18.6-0.5,26l0.5,0.7l9.2,9.2c0.8,0.8,2,0.8,2.8,0
			c0,0,0.2-0.2,0.3-0.2c-0.7-1-1.2-2.2-1.3-3.5c-1.5-0.2-3.2-0.8-4.4-2c-2.5-2.5-2.7-6.7-0.3-9.4l0.3-0.5l16.3-16.3
			c1-1.2,1.3-1.7,3.9-1.7h0.3h6.2c1.2,0,2.2-1,2.2-2.2c0-0.8-0.5-1.7-1.3-1.8l-0.3-0.2l-16.8-3.4c-0.8-0.2-1.7-0.3-2.5-0.3H28.3z
			 M69,18.2c9,9,9.4,23.8,0.7,33.2L69,52.1L54.3,66.8c-2.7,2.7-7.2,2.7-9.9,0c-0.7-0.7-1.2-1.5-1.5-2.5c-2.3,0.7-5,0-6.9-1.8
			c-0.7-0.7-1.2-1.5-1.5-2.5c-1.2,0.3-2.5,0.3-3.7,0c-0.2,0.3-0.3,0.7-0.7,1c-2.5,2.5-6.7,2.7-9.4,0.3l-0.5-0.3L11,51.7
			c-9.4-9.4-9.4-24.5,0-33.8c5.4-5.2,13.1-7.5,20.6-6.4l0.8,0.2l7.5,1.5l7.4-1.5C55.3,10,63.3,12.5,69,18.2z"/>
                </defs>
                <use xlinkHref="#contract_id_01" fill={theme.palette.secondary.main}/>
                <clipPath>
                    <use xlinkHref="#contract_id_01"/>
                </clipPath>
            </g>
        </svg>
    )

}

export default SvgContract
