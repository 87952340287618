import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import ChallengePageLabel from "../labels/ChallengePageLabel";
import StreamingPlatformLabel from "../labels/StreamingPlatformLabel";
import StreamStreamerLabel from "../../challenges/labels/StreamStreamerLabel";
import StreamerVideosPageLabel from "../labels/StreamerVideosPageLabel";
import Box from "@material-ui/core/Box";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import StreamStatusLabel from "../labels/StreamStatusLabel";
import {VscJson} from "react-icons/all";
import IconButton from "@material-ui/core/IconButton";
import StreamHistoryDialog from "../dialogs/StreamHistoryDialog";


const StreamsTable = observer(({defaultFilters = {}}) => {
    const { userId = "" } = defaultFilters;
    const [activeRow, setActiveRow] = useState(null);
    const {streamsStore} = useStores();
    const filtersOptions = [
        {
            type: 'text',
            name: "streamer",
            label: "User-ID",
            defaultValue: userId,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "streamId",
            label: "Stream-ID",
            defaultValue: '',
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "status",
            label: "Status",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Online", value: 'online'},
                {label: "Completed", value: 'completed'},
                {label: "Forced-Close", value: 'forced-close'},
            ]
        }

    ]
    const getStreams = async (params) => {
        const {data} = await streamsStore.getStreams(params)
        return {data: data.streams, count: data.count};
    }

    return (
        <>
            <Table
                rowProps={{hover: true}}
                columns={[
                    {
                        title: "Actions",
                        sortable: false,
                        cell: ({row}) => {
                            const isOnline = row.status === "online";
                            return <>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    disabled={isOnline}
                                    onClick={() => {
                                        setActiveRow(row)
                                    }}
                                >
                                    <VscJson/>
                                </IconButton>

                            </>
                        }
                    },

                    {
                        key: 'id',
                        title: 'Stream ID',
                        cell: ({row}) => <Box display='flex'>
                            {/*<Clipboard text={row.id} btnText='Stream-ID' tooltip={row.id}/>*/}
                            {row.id}
                        </Box>
                    },
                    {
                        key: 'game_key',
                        title: 'Game',
                        cell: ({row}) => (
                            <span
                                style={{fontWeight: 'bold'}}>
                                {row.game_key}
                            </span>
                        )
                    },
                    {
                        key: 'streamer',
                        title: 'Streamer',
                        sortable: false,
                        cell: ({row}) => <>

                            {row.streamer && <>
                                <span className='text-bold'>{row.streamer.username}</span>
                                <Box mb='1'>
                                    <StreamStreamerLabel stream={row}/>
                                </Box>
                                <Box marginTop='5px'>
                                    {/*<Clipboard*/}
                                    {/*    text={row.streamer.id}*/}
                                    {/*    btnText='Streamer-ID*'*/}
                                    {/*    tooltip={row.streamer.id}/>*/}
                                    {row.streamer.id}
                                </Box>
                            </>}

                        </>
                    },
                    {
                        key: 'status',
                        title: 'Status',
                        cell: ({row}) => <>

                            <StreamStatusLabel status={row.status}/>
                        </>
                    },

                    {
                        sortable: false,
                        key: 'links',
                        title: 'Links',
                        cell: ({row}) => <>
                            {row.streamer && <Box className={'streamer-links'}>
                                <ChallengePageLabel username={row.streamer.username}/>

                                <Box my={1}>
                                    <StreamingPlatformLabel username={row.streamer.streaming_platform_username}
                                                            streamingPlatform={row.streamer.streaming_platform}/>


                                </Box>
                                <StreamerVideosPageLabel username={row.streamer.streaming_platform_username}
                                                         streamingPlatform={row.streamer.streaming_platform}/>
                            </Box>}
                        </>
                    },
                    {
                        key: 'updatedAt',
                        title: 'Updated',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.updatedAt}/>
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'Created',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
                filtersOptions={filtersOptions}
                loadData={getStreams}/>

            <StreamHistoryDialog stream={activeRow} onClose={() => {
                setActiveRow(null)
            }}/>

        </>

    )
})

export default StreamsTable
