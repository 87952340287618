import React from "react";
import Autocomplete from "@PlaystreamENV/front-lib/src/form/autocomplete/Autocomplete";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import Grid from "@material-ui/core/Grid";
import {FaUser} from "react-icons/all";
import Typography from "@material-ui/core/Typography";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import {differenceBy} from "lodash";

const UsersAutocomplete = ({name = "streamer", label = "User", rules, defaultValue, multiple = false}) => {

    const {usersStore} = useStores();
    const {getValues} = useForm()

    const loadUsers = async (input) => {
        const selectedUsers = getValues(name)
        const response = await usersStore.getUsers({email: input, limit: 25});
        const filteredUsers = response.data.users.filter(u => u.email !== "admin@playstream.gg"); //hide admin as a hack
        return differenceBy(filteredUsers, selectedUsers, 'id');

    }
    return (
        <Autocomplete label={label} loadData={loadUsers}
                      rules={rules}
                      multiple={multiple}
                      name={name}
                      defaultValue={defaultValue}
                      getOptionLabel={(option) => option.firstname + " " + option.lastname}
                      renderOption={(option) => {
                          return (
                              <Grid container alignItems="center">
                                  <Grid item>
                                      <FaUser style={{marginRight: 10}}/>
                                  </Grid>
                                  <Grid item xs>
                                      {option.firstname} {option.lastname}
                                      <Typography variant="body2"
                                                  color="textSecondary">
                                          {option.email}
                                      </Typography>
                                  </Grid>
                              </Grid>
                          );
                      }}
        />


    )

}

export default UsersAutocomplete
