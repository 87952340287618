import {Upload} from "tus-js-client";
import {DelayedPromise} from "../../promise/DelayedPromise";
import {sleep} from "../../debug/sleep";

const UPLOAD_URL = process.env.REACT_APP_TUS_BASE_URL + "files";
const FILES_URL = process.env.REACT_APP_PUBLIC_FILES_BASE_URL;

const regex_ext = /(?:\.([^.]+))?$/;

export class TusClient {

    static async upload(file, onProgress) {
        // let loggedInUser = JSON.parse(localStorage.getItem("user"))
        const ext = (regex_ext.exec(file.name)[1] || "").toLowerCase();
        // file.type = file.type ;
        return new DelayedPromise((resolve, reject) => {
            let upload = new Upload(file, {
                endpoint: UPLOAD_URL,
                withCredentials: true,
                // removeFingerprintOnSuccess: true,
                headers: {
                    // "Access-Control-Request-Headers:" : "Authorization",
                   // "Authorization": "Bearer la_token_1234"
                },
                // retryDelays: [0, 3000, 5000, 10000, 20000],
                onBeforeRequest: function (req) {
                    let xhr = req.getUnderlyingObject()
                    xhr.withCredentials = true
                },
                metadata: {
                    ext: ext,
                    filetype: file.type || 'text/plain',
                    // user_guid: loggedInUser.user_guid,
                },
                onError: function (error) {
                    console.log("error", error);
                    reject(error);
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                    let progress = parseInt((bytesUploaded / bytesTotal * 100).toFixed(2))
                    onProgress && onProgress({progress, bytesUploaded, bytesTotal})
                },
                onSuccess: async () => {
                    const fileId = upload.url.split("/files/")[1].split("+")[0] + "." + ext;
                    await sleep(2000);
                    resolve(FILES_URL + fileId)
                }
            });

            upload.start();
        }, 1000);

    }
}