import React from "react";
import ReactJson from "react-json-view";

const FeaturesLabel = ({coupon}) => {
    return (
        <>
            <ReactJson theme={'threezerotwofour'} enableClipboard={false} displayObjectSize={false} quotesOnKeys={false}
                       displayDataTypes={false} name={null} src={coupon.features}/>
        </>
    )

}

export default FeaturesLabel