import React from "react";
import {toRoleText} from "./roles";

function RoleLabel({user}) {

    const {role} = user;
    return (
        <>
            {toRoleText(role)}
        </>
    )
}



export default RoleLabel