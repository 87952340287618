import React from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import YesNoLabel from "../../games/labels/YesNoLabel";
import {Box, Tooltip} from "@material-ui/core";
import StreamStreamerLabel from "../../challenges/labels/StreamStreamerLabel";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import ActionsLabel from "../labels/ActionsLabel";
import BalanceLabel from "../labels/BalanceLabel";
import StatusLabel from "../labels/StatusLabel";
import GuestIdLabel from "../../interactions/labels/GuestIdLabel";
import InteractionCardLabel from "../../interactions/labels/InteractionCardLabel";

function TransactionsTable({defaultFilters = {}}) {
    const {userId = '', withdrawStatus} = defaultFilters
    const {transactionsStore} = useStores();

    const filtersOptions = [
        {
            type: 'text',
            name: "user_id",
            label: "User-ID",
            defaultValue: userId,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "status",
            label: "Status",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Created", value: 'created'},
                {label: "Voided", value: 'voided'},
                {label: "Captured", value: 'captured'},
                {label: "Refund Manual", value: 'refund-manual'},
                {label: "Refund Expired", value: 'refund-expired'},
                {label: "Refund end-stream", value: 'refund-end-stream'},
            ]
        },
        {
            type: 'select',
            name: "withdraw_status",
            label: "Withdraw",
            defaultValue: withdrawStatus,
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Yes", value: 'yes'},
                {label: "No", value: 'no'},
            ]
        }
    ];
    const getTransactions = async (params) => {
        const {data} = await transactionsStore.getTransactions(params)
        return {data: data.transactions, count: data.count};
    }
    return (
        <Table
            rowProps={{hover: true}}
            filtersOptions={filtersOptions}
            columns={[
                {
                    sortable: false,
                    title: "Actions",
                    cell: ({row}) => <>
                        <ActionsLabel transaction={row}/>
                    </>
                },
                {
                    key: 'id',
                    title: 'ID',
                    cell: ({row}) => <>

                        {row.id}
                    </>
                },

                {
                    key: 'streamer',
                    sortable: false,
                    title: 'Streamer',
                    cell: ({row}) => <>

                        {row.streamer && <>
                            <span className='text-bold'>{row.streamer.username}</span>
                            <Box mb='1'>
                                <StreamStreamerLabel stream={row}/>
                            </Box>
                            <Box marginTop='5px'>
                                {row.streamer.id}
                            </Box>
                        </>}

                    </>
                },

                {
                    key: 'guest_username',
                    title: 'Guest',
                    cell: ({row}) => <>
                        <GuestIdLabel interaction={row.interaction}/>
                    </>
                },
                {
                    key: 'card',
                    title: 'card',
                    style: {
                        width: 190
                    },
                    cell: ({row}) => <>
                        <InteractionCardLabel interaction={row}/>
                    </>
                },
                {
                    key: 'status',
                    title: 'Status',
                    cell: ({row}) => <>

                        <StatusLabel transaction={row}/>
                    </>
                },
                {
                    key: 'withdraw_status',
                    title: 'Withdraw',
                    cell: ({row}) => <>
                        <YesNoLabel value={row.withdraw_status === "yes"}/>
                    </>
                },

                {
                    key: 'isEstimation',
                    title: 'Estimation',
                    cell: ({row}) => <>
                        <YesNoLabel value={row.isEstimation}/>
                    </>
                },

                {
                    sortable: false,
                    key: 'balance',
                    title: 'Balance',
                    cell: ({row}) => <>
                        <BalanceLabel transaction={row}/>
                    </>
                },
                // {
                //     key: 'metadata',
                //     style: {
                //         width: 400
                //     },
                //     title: 'metadata',
                //
                //     cell: ({row}) => <>
                //         <MetaDataLabel value={row}/>
                //     </>
                // },
                // {
                //     key: 'card',
                //     title: 'Card',
                //     style: {
                //         width: 190
                //     },
                //     cell: ({row}) => <>
                //
                //         <EventCardLabel card={row.card}/>
                //     </>
                // },

                {
                    key: 'captureId',
                    title: 'Capture Id',
                    style: {width: 100},

                    cell: ({row}) => <>

                        {row.captureId}
                    </>
                },
                {
                    key: 'ppAuthId',
                    title: 'Auth ID',
                    cell: ({row}) => <>

                        {row.ppAuthId}
                    </>
                },
                {
                    key: 'ppOrderId',
                    title: 'Order ID',
                    cell: ({row}) => <>

                        {row.ppOrderId}
                    </>
                },

                {
                    key: 'updatedAt',
                    title: 'Updated',
                    cell: ({row}) => <>

                        <DateTimeLabel value={row.updatedAt}/>
                    </>
                },
            ]}
            loadData={getTransactions}
        />
    )
}


export default TransactionsTable
