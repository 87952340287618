import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import GamePicker from "../entities/games/pickers/GamePicker";
import GameEvents from "./GameEvents";
import ButtonWithConfirm from "../entities/users/forms/ButtonWithConfirm";
import {BaseLoader} from "@PlaystreamENV/front-lib/src/loaders/BaseLoader";
import {FaMinus, FaPlus} from "react-icons/all";


const EventProgressForm = observer(({stream, games, isOnline}) => {
    const {adminStore} = useStores();
    const [game, setGame] = useState(stream.game_key);
    const [event, setEvent] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const streamer = stream.streamer.id;


    const startStream = async () => {
        setIsLoading(true)
        await adminStore.startStreamForStreamer(streamer, game);
        setIsLoading(false)

    }
    const stopStream = async () => {
        setIsLoading(true)
        await adminStore.stopStreamForStreamer(streamer);

        setIsLoading(false)
    }
    const updateEvent = async (step) => {
        console.log("updateStreamerEvent", step)
        await adminStore.updateStreamerEvent(streamer, game, event, step)

    }
    const requestElectronLogs = async () => {
        console.log("requestElectronLogs");
        // await adminStore.updateStreamerEvent(streamer, event)

    }
    const pingAllClients = async () => {
        console.log("pingAllClients");
        // await adminStore.updateStreamerEvent(streamer, event)

    }


    // useEffect(() => {
    //     if (!user)
    //         setShowActivateConfirm(false)
    // }, [user])

    return (
        <Box>
            <form>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <h5 className={'text-muted'}>Event progression</h5>
                    </Grid>


                    <Grid item xs={12}>
                        {games && <Box display={'flex'} alignItems={'center'}>

                            <Box mr={2} style={{width: 140}}> Choose game:</Box>
                            <GamePicker direction={'left'} value={game} disabled={isOnline || isLoading}
                                        onChange={(game) => {
                                            setGame(game)
                                        }} games={games}/>
                        </Box>}
                    </Grid>


                    <Grid item xs={12}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box mr={2} style={{width: 140}}> Stream options:</Box>

                            <Box display={'flex'} alignItems={'center'}>
                                <ButtonWithConfirm size={"large"} color={'primary'}
                                                   disabled={isOnline || isLoading}
                                                   onConfirm={startStream}> Activate</ButtonWithConfirm>
                                <Box mx={2}>


                                    <ButtonWithConfirm size={"large"} color={'primary'}
                                                       disabled={!isOnline || isLoading}
                                                       onConfirm={stopStream}> Stop</ButtonWithConfirm>
                                </Box>

                                <Box>
                                    {!!isLoading && <BaseLoader style={{position: 'relative', top: 0, left: 0}}/>}
                                </Box>

                            </Box>
                        </Box>


                    </Grid>

                    <Grid item xs={12}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box mr={2} style={{width: 140}}> Choose event:</Box>
                            <Box mr={2}>
                                <GameEvents defaultGame={game} name={"events"} disabled={!isOnline} style={{width: 200}}
                                            onChange={(ev) => {
                                                setEvent(ev.target.value)
                                            }}/>
                            </Box>
                            <Box display={'flex'}>
                                <ButtonWithConfirm size={"large"} color={'secondary'} onConfirm={() => {
                                    updateEvent(-1)
                                }}
                                                   disabled={!isOnline || !event}>
                                    <FaMinus/>
                                </ButtonWithConfirm>
                                <Box ml={2}>
                                    <ButtonWithConfirm size={"large"} color={'secondary'} onConfirm={() => {
                                        updateEvent(1)
                                    }}
                                                       disabled={!isOnline || !event}>
                                        <FaPlus/>
                                    </ButtonWithConfirm>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>

                    {/*<Grid item xs={12}>*/}
                    {/*    <Box display={'flex'} mb={5}>*/}
                    {/*        <ButtonWithConfirm disabled={true} size={"large"} color={'secondary'}*/}
                    {/*                           onConfirm={requestElectronLogs}*/}
                    {/*        > Request Electron Logs</ButtonWithConfirm>*/}

                    {/*    </Box>*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12}>*/}
                    {/*    <Box display={'flex'} mb={5}>*/}
                    {/*        <ButtonWithConfirm disabled={true} size={"large"} color={'secondary'}*/}
                    {/*                           onConfirm={pingAllClients}*/}
                    {/*        > Ping </ButtonWithConfirm>*/}

                    {/*    </Box>*/}
                    {/*</Grid>*/}

                </Grid>

                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
})


export default EventProgressForm
