import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const TableHeaderCell = ({cell, orderBy, orderDirection, onOrder}) => {
    const {style, key, title, sortable = true} = cell;
    return (
        <TableCell style={style}>
            {sortable ? (
                <TableSortLabel
                    active={orderBy === key}
                    direction={orderDirection }
                    onClick={() => onOrder(key, orderDirection)}
                >
                    {title}
                </TableSortLabel>
            ) : (
                title
            )}
        </TableCell>
    );
};
export default TableHeaderCell;
