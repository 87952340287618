import React from "react";
import {TablePagination} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';


const BasicTablePagination = ({count, setPagination, pagination, rowsPerPageOptions}) => {
    const {limit, skip} = pagination
    const onChangeRowsPerPage = (limit) => {
        setPagination({
            skip: 0,
            limit: parseInt(limit, 10),
        });
    };
    const onPageChange = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            skip: page * prevPagination.limit,
        }));
    };
    const getPageNum = () => {
        let page = skip / limit;
        if (page < 1 && page !== 0) {
            page = 0;
        }
        return page;
    };
    return (
        <TablePagination
            labelRowsPerPage=''
            component={"div"}
            rowsPerPageOptions={rowsPerPageOptions}
            colSpan={5}
            count={count}
            page={getPageNum()}
            rowsPerPage={limit}
            SelectProps={{
                inputProps: {"aria-label": "Rows"},
                native: true,
            }}
            onChangePage={() => {
            }}
            labelDisplayedRows={({count}) => <span className='text-muted text-bold'>
                Total: {count}
            </span>}
            ActionsComponent={() => <Pagination
                page={getPageNum() + 1}
                onChange={(e, pageNum) => onPageChange(pageNum - 1)}
                classes={{
                    root: 'pagination-navigation'
                }}
                count={Math.ceil(count / limit)}
                showFirstButton
                showLastButton
                size='small'/>}
            onChangeRowsPerPage={(e) => onChangeRowsPerPage(e.target.value)}
        />
    );
};

export default BasicTablePagination;
