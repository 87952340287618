import React from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        background: "#fff",
        color: theme.palette.primary.main,
        "& input:-webkit-autofill": {
            "-webkit-text-fill-color": `${theme.palette.primary.light} !important`

        }
    }
}));

function InputText({startAdornment, endAdornment, variant = 'outlined', ...rest}) {
    const classes = useStyles();
    return (
        <TextField
            variant={variant}
            fullWidth
            InputProps={{
                endAdornment,
                startAdornment,
                // classes: {
                //     root: classes.root
                // }
            }}

            {...rest}
        />
    )
}

export {InputText}
