import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import CardMetadataForm from "./CardMetadataForm";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import UsersAutocomplete from "../../users/forms/UsersAutocomplete";
import CardTypeSelect from "@PlaystreamENV/front-lib/src/zone/forms/CardTypeSelect" //TODO : Card Type Select should include admin mode
import CardImageUploader from "@PlaystreamENV/front-lib/src/card-image-uploader/CardImageUploader";
import TusBrowseButton from "@PlaystreamENV/front-lib/src/tus/TusBrowseButton";
import CategoriesAutoComplete from "../../categories/forms/CategoriesAutoComplete";


const translations = {
    required_field: "Field is required",
    sudo_error: "Only sudo can be community and only streamers can be personal",
    only_one_personal_category: "Personal card can be attached to only one personal category"
}
export const formValidation = {


    required: {
        required: translations.required_field
    },
    maxLength: {value: 16, message: 'Name is limited to 16 characters'},
    enabled: {
        // required: translations.required_field
    }

};

const CardForm = ({card}) => {

    const {watch} = useForm();
    const {metadata} = card;
    const cardFormModel = watch(undefined, card);
    const type = watch("type", card.type);
    const community = watch("community", card.community);
    const created_by = watch("created_by", card.created_by);
    console.log("cardFormModel", cardFormModel.image)
    return (
        <>
            <form>

                <Grid container spacing={2}>

                    <Grid item sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h5 className={'text-muted'}>Card</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <CardTypeSelect
                                     adminTypes={true}
                                    // disabled={!!card.id}
                                    rules={formValidation.required}
                                    defaultValue={card.type}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CategoriesAutoComplete
                                    defaultValue={card.categories || []}
                                    rules={{
                                    validate: {
                                        not_empty: value => value.length > 0 || translations.required_field,
                                    }
                                }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Name'}
                                    defaultValue={card.name}
                                    name='name'
                                    validation={{
                                        required: formValidation.required,
                                        maxLength: {value: 24, message: 'Name is limited to 24 characters'}
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Description'} defaultValue={card.description}
                                               name='description'
                                               className={"multiline"}
                                               multiline={true}
                                               validation={{}}/>

                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox name={'published'} defaultChecked={card.published}
                                                   label={"Published"}
                                                   validation={{}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox name={'community'} defaultChecked={card.community}
                                                   label={"Community"}
                                                   validation={{
                                                       validate: {
                                                           sudo_cannot_be_personal: value => value || (!value && created_by && created_by.email !== "sudo@playstream.gg") || translations.sudo_error,
                                                           sudo_must_be_community: value => !value || (value && created_by && created_by.email === "sudo@playstream.gg") || translations.sudo_error,
                                                       }
                                                   }}/>
                            </Grid>

                            <Grid item xs={12}>
                                <UsersAutocomplete name={'created_by'} label={'Created by'}
                                                   defaultValue={card.created_by}
                                                   rules={formValidation.required}/>

                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText
                                    defaultValue={card.min_amount}
                                    name={'min_amount'}
                                    type='number'
                                    label={'Min amount'}
                                    validation={formValidation.required}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <FormInputText label={'Min price'} type={"number"} defaultValue={card.min_price}*/}
                            {/*                   name='min_price'*/}
                            {/*                   validation={formValidation.required}/>*/}
                            {/*</Grid>*/}

                        </Grid>

                    </Grid>
                    {type && <Grid item sm={6}>
                        <Grid container spacing={2}>

                            {/*<Grid item sm={12}>*/}
                            {/*    <Box display={"flex"} justifyContent={"center"}>*/}
                            {/*        <CardItem card={cardFormModel}/>*/}
                            {/*    </Box>*/}
                            {/*</Grid>*/}
                            <Grid item sm={12}>
                                <Box display={"flex"} justifyContent={"center"}>
                                    {/*<TusBrowseButton/>*/}
                                    <CardImageUploader card={cardFormModel}/>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <CardMetadataForm metadata={metadata} type={type}/>

                            </Grid>
                        </Grid>

                    </Grid>}
                    <Grid item xs={12}>
                        <FormGlobalErrorMessage/>

                    </Grid>
                </Grid>

            </form>

        </>
    )

}

export default CardForm
