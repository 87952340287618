import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
// import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";

const translations = {
    required_field: "Field is required",
}
export const formValidation = {


    required: {
        required: translations.required_field,
    },

};

function BonusForm({bonus}) {
    const {withdraw_status, amount, type, value, enabled} = bonus;

    // const {watch} = useForm();
    // const valueOnForm = parseInt(watch('value', value));
    return (
        <Box>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {type}
                    </Grid>
                    <Grid item xs={12}>
                        <SingleSelect
                            options={[{id: 'yes'}, {id: 'no'}]}
                            label={'Withdraw Status'}
                            defaultValue={withdraw_status}
                            name='withdraw_status'
                            prop='id'
                            InputLabelProps={{shrink: true}}
                            validation={formValidation.required}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Amount'}
                            defaultValue={amount}
                            type={"number"}
                            name='amount'
                            validation={formValidation.required}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            // disabled={type === "first-interaction"}
                            label={'Value'}
                            defaultValue={value}
                            type={"number"}
                            name='value'
                            validation={formValidation.required}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputCheckbox
                            label='Enabled'
                            name='enabled'
                            defaultChecked={enabled}
                        />
                    </Grid>
                </Grid>
                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default BonusForm
