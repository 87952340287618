import React from 'react';
import Chip from '@material-ui/core/Chip';
import "./chips-array.scss"

export default function ChipsArray({chips, prop, onDelete, style}) {
    // const classes = useStyles();
    // const [chipData, setChipData] = React.useState(chips);

    const handleDelete = (chipToDelete) => () => {
        // setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
        onDelete(chipToDelete)
    };

    return (
        <ul className={'chips-array'} style={style}>
            {chips.map((data) => {

                return (
                    <li key={data[prop]}>
                        <Chip
                            // icon={<MdRemoveCircleOutline/>}
                            label={data[prop]}
                            onDelete={onDelete && handleDelete(data)}
                            className={"chip"}
                        />
                    </li>
                );
            })}
        </ul>
    );
}