import React from "react";
import {Box, Tooltip} from "@material-ui/core";

const BalanceLabel = ({transaction}) => {
    return (
        <>
            <Box style={{width: 150}}>

                {transaction.status === "captured" && <>
                    {/*<NumberWithTooltip title={`Playstream fee percent`}>  </NumberWithTooltip>*/}


                    <div>
                        +<NumberWithTooltip
                        title={"Playstream Gross Amount"}>${transaction.ps_gross_amount} </NumberWithTooltip>
                    </div>
                    <div>
                        - <NumberWithTooltip title={`Paypal fee`}> ${transaction.pp_fee} </NumberWithTooltip>
                        <NumberWithTooltip
                            title={`Amount left after Paypal Fee`}> <span
                            className={"amount-after-paypal-fee"}>(${transaction.pp_net_amount}) </span>
                        </NumberWithTooltip>
                    </div>
                    <div>
                        - <NumberWithTooltip
                        title={`Playstream Fee - %{transaction.ps_fee_percent}`}> ${transaction.ps_fee} </NumberWithTooltip>
                        <NumberWithTooltip
                            title={`Playstream Fee in percent`}> <span
                            className={"amount-after-paypal-fee"}>(%{transaction.ps_fee_percent * 100}) </span>
                        </NumberWithTooltip>

                    </div>
                    <div className={'primary-color'}>= <NumberWithTooltip
                        title={"Amount left for streamer"}>
                        <span className={"amount-left-for-streamer"}>   ${transaction.ps_net_amount}</span>
                    </NumberWithTooltip>


                    </div>

                </>}

                {transaction.status !== "captured" && <div className={'text-muted'}>
                    ---
                </div>}

            </Box>
        </>
    )

}

const NumberWithTooltip = (
    {
        title, children
    }
) => {
    return <Tooltip title={title} arrow placement="top" classes={{
        tooltip: "table-tooltip",
        arrow: "table-tooltip-arrow"
    }}>
        <span>{children}</span>

    </Tooltip>
}

export default BalanceLabel