import React, {useEffect} from "react";

import {observer} from "mobx-react-lite";

import LineChart from "@PlaystreamENV/front-lib/src/charts/LineChart";
import {Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import DashboardCard from "../../dashboard/cards/DashboardCard";
import colors from "./colors";
import NoDataContainer from "./NoDataContainer";


const PredictionVerifiedChart = observer(({syncId, data}) => {

    const title = 'Prediction Verified';


    const [value, setValue] = React.useState(null);


    useEffect(() => {

        if (!data) return;
        const _data = data.map(r => {
            const date = new Date(r.createdAt);
            const date_text = date.toLocaleDateString('en', {day: 'numeric', month: 'short', weekday: 'long'});
            const time_text = date.toLocaleTimeString('en', {
                hour12: false,
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            });

            const dimensions = r.data.results.reduce((last, obj, index) => {
                last[index] = {canvasVerified: obj.canvasVerified ? 1 : 0, framesArray: obj.framesArray};
                return last;
            }, {});
            return {
                createdAt: `${date_text}  ${time_text}`,
                dimensions

            }
        })
        setValue(_data)

    }, [data])

    return (
        <>
            <DashboardCard title={title}>
                <NoDataContainer data={data}>
                    <ResponsiveContainer width={'100%'} height={150}>
                        <LineChart syncId={syncId} data={value} margin={{
                            top: 10, right: 10, left:  -15, bottom: 10,
                        }}>
                            {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                            <XAxis hide={true} dataKey="createdAt"/>
                            <YAxis hide={false} domain={[0, 'dataMax']}/>
                            <Tooltip content={<FramesHistoryTooltip/>}/>
                            {/*<Legend />*/}
                            <Line dataKey={'dimensions.0.canvasVerified'} stroke={colors[0]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.1.canvasVerified'} stroke={colors[1]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.2.canvasVerified'} stroke={colors[2]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                        </LineChart>
                    </ResponsiveContainer>
                </NoDataContainer>
            </DashboardCard>

        </>
    )
})

const FramesHistoryTooltip = ({active, payload, label}) => {

    // console.log("payload", payload)
    return <div className={'recharts-custom-tooltip'}>
        {label}
    </div>
}

export default React.memo(PredictionVerifiedChart)
