import React, {useState, createContext, useEffect} from 'react';
import englishKeys from "../languages/en-US.json";
import spanishKeys from "../languages/sp.json";

export const LanguageContext = createContext({});
const LanguageContextProvider = LanguageContext.Provider;


export const LanguageProvider = ({children, extend, defaultIso = "en"}) => {


    const [languages] = useState((() => {
        const languages = [
            {key: "en-US", text: "English", resources: englishKeys, country: "US", iso: "en"},
            {key: "sp", text: "Español", resources: spanishKeys, country: "ES", iso: "es"}
        ];
        (extend && extend() || []).forEach(({key, resources}) => {
            const index = languages.findIndex(l => l.key === key)
            languages[index].resources = {...languages[index].resources, ...resources};
        });

        return languages;

    })());

    const defaultLanguage = languages.find(l => l.iso === defaultIso) || languages[0];
    const [context, setContext] = useState({
        isDev: false,
        languages,
        activeLanguage: defaultLanguage,
        setActiveLanguage: (iso, triggerChange) => {
            const language = languages.find(l => l.iso === iso) || languages[0];
            console.log("setActiveLanguage", language)
            localStorage.setItem('language-iso', language.iso)
            setContext((prevState) => {

                if (prevState.activeLanguage.iso !== iso && triggerChange) {
                    prevState.onChange && prevState.onChange(prevState.activeLanguage.iso, language.iso);
                }
                return {...prevState, activeLanguage: language}
            })
        },
        toggleDevMode: () => {
            setContext((prevState) => {
                return {...prevState, isDev: !prevState.isDev}
            })
        },
        onLanguageChange: (func) => {
            setContext((prevState) => {
                return {...prevState, onChange: func}
            })
        }
    });

    // useEffect(() => {
    //     const preLanguage = JSON.parse(localStorage.getItem('language'));
    //     if (preLanguage) {
    //         context.setActiveLanguage(preLanguage)
    //     }
    // }, []);

    return (
        <LanguageContextProvider value={[context, setContext]}>
            {children}
        </LanguageContextProvider>
    )
};

