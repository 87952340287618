import React, {useState} from "react";
import TusDropZone from "../tus/TusDropZone";
import {Controller} from "react-hook-form";
import "./category-image-uploader.scss";

const {REACT_APP_CDN_URL} = process.env;
const CategoryImageUploader = ({category, getBlob}) => {

    const imagePreview = category.image || `${REACT_APP_CDN_URL}cdn/images/categories/default.png`;
    const [isOpen, setIsOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    console.log("imagePreview", imagePreview)
    return (
        <>

            <Controller
                defaultValue={category.image}
                name={"image"}
                render={(
                    {onChange},
                ) => (
                    <div className={'category-uploader-container'}
                         onMouseEnter={() => setIsOpen(true)}
                         onMouseLeave={() => setIsOpen(false)}
                    >
                        <CategoryImage src={imagePreview}/>
                        {(isOpen || isUploading) && <div className={'card-upload-cover'}>
                            <TusDropZone getBlob={getBlob} isUploading={isUploading} onFileStarted={() => {
                                setIsUploading(true)
                            }} onFileCompleted={(url) => {
                                console.log("upload completed", url)
                                if (url) {
                                    onChange(url)
                                }
                                setIsUploading(false)
                            }}/>
                        </div>}
                    </div>

                )}
            />
        </>
    )

}

const CategoryImage = ({src}) => {
    return <img className='category-image' src={src}/>
}

export default CategoryImageUploader