import React from "react";
import Grid from "@material-ui/core/Grid";
import AiSoundSelect from "@PlaystreamENV/front-lib/src/sound/AiSoundSelect";
import FormVolumeSlider from "@PlaystreamENV/front-lib/src/form/volume-slider/FormVolumeSlider";
import AnimationOutPicker from "../pickers/AnimationOutPicker";
import AnimationInPicker from "../pickers/AnimationInPicker";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";

const AlertForm = ({zone}) => {
    const {watch} = useForm()
    const alert_sound_volume = watch("alert_sound_volume", zone.alert_sound_volume)
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h5>Alert</h5>
            </Grid>
            <Grid item xs={12}>
                <AnimationInPicker defaultValue={zone.alert_animation_entrance}/>
            </Grid>
            <Grid item xs={12}>
                <AnimationOutPicker defaultValue={zone.alert_animation_exit}/>
            </Grid>
            <Grid item xs={12}>
                <FormVolumeSlider
                    label='Alert Volume'
                    name='alert_sound_volume'
                    defaultValue={zone.alert_sound_volume}
                />
            </Grid>
            <Grid item xs={12}>
                <AiSoundSelect defaultValue={zone.alert_challenge_sound} volume={alert_sound_volume}/>
            </Grid>
        </Grid>
    )
}
export default AlertForm
