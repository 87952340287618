import React from "react";
import Autocomplete from "@PlaystreamENV/front-lib/src/form/autocomplete/Autocomplete";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import Grid from "@material-ui/core/Grid";
import {FaUser} from "react-icons/all";
import Typography from "@material-ui/core/Typography";

const AchievementsAutoComplete = ({rules, defaultValue}) => {

    const {achievementsStore} = useStores();

    const loadAchievements = async (input) => {
        const response = await achievementsStore.getAchievements({name: input, limit: 25});
        return response.data.achievements.filter(u => u.email !== "admin@playstream.gg"); //hide admin as a hack
    }
    return (
        <Autocomplete
            label={'Achievement'} loadData={loadAchievements}
            rules={rules}
            name={'achievement'}
            defaultValue={defaultValue}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item xs>
                            {option.name}
                        </Grid>
                    </Grid>
                );
            }}
        />


    )

}

export default AchievementsAutoComplete