import React from "react";
import Grid from "@material-ui/core/Grid";
import CaMetaForm from "./metadata/CaMetaForm";
import ContractMetaForm from "./metadata/ContractMetaForm";
import EndorsementMetaForm from "./metadata/EndorsementMetaForm";


const types = {
    "ai": (metadata) => <CaMetaForm metadata={metadata}/>,
    "contract": (metadata) => <ContractMetaForm metadata={metadata}/>,
    "endorsement": (metadata) => <EndorsementMetaForm metadata={metadata}/>,
    "digital-asset": (metadata) => <EndorsementMetaForm metadata={metadata} /> ,
    "merchandise": (metadata) => <EndorsementMetaForm metadata={metadata} /> ,
}
const CardMetadataForm = ({metadata, type}) => {


    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h5 className={'text-muted'}> Metadata</h5>
                </Grid>
                {types[type](metadata)}
            </Grid>
        </>
    )

}

export default CardMetadataForm