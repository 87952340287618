import React from "react";
import {Controller} from 'react-hook-form'
import {TextField} from "@material-ui/core";
import moment from 'moment';
import './inputs.scss';

const FormInputDate = ({name, defaultValue, label}) => {
    const defaultValueFormat = moment(defaultValue).format('YYYY-MM-DDTHH:mm')
    return (
        <>
            <Controller
                defaultValue={defaultValue}
                name={name}
                render={(
                    {onChange, ref},
                ) => (
                    <TextField
                        defaultValue={defaultValueFormat}
                        inputRef={ref}
                        onChange={(e) => onChange(new Date(e.target.value).getTime())}
                        variant='outlined'
                        label={label}
                        type="datetime-local"
                        InputLabelProps={{shrink: true}}/>
                )}
            />
        </>
    )
}
export default FormInputDate
