import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    no: {
        color: 'red'
    },

    yes: {
        color: 'green'
    }

}));

function YesNoLabel({value}) {

    const classes = useStyles();
    const key = value ? 'yes' : 'no'
    return (

        <span className={clsx('text-capitalize',classes[key])}>{key} </span>
    )
}

export default YesNoLabel;
