import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BonusesTable from "../../../components/entities/bonuses/table/BonusesTable";
import useQueryFilters from "../../hooks/useQueryFilters";

const BonusesRoute = ({}) => {

    const {streamer = "", referral = ""} = useQueryFilters();
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Bonuses
                    </Typography>


                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>

                <BonusesTable defaultFilters={{streamer, referral}}/>
            </Box>
        </>
    )
}

export default BonusesRoute
