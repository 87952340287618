import React, {useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from "@material-ui/core/Button";
import {DropZone} from "./dropzone/Dropzone";
import useTus from "./hooks/useTus";
import useLanguage from "../l10n/hooks/useLanguage";


const TusBrowseButton = observer(({
                                      text = "Browse",
                                      getBlob,
                                      onError,
                                      maxMegaByte = 0,
                                      onFileStarted,
                                      onFileCompleted
                                  }) => {

    const {progress, isUploading, upload} = useTus();
    const hiddenFileInput = useRef(null);

    const onFilesChanged = async (files) => {
        const file = files[0];
        onFileStarted && await onFileStarted(file)
        const size = file.size / 1024 / 1024;
        console.log("TusBrowseButton onFilesChanged", size, maxMegaByte,)
        if (size > maxMegaByte && maxMegaByte !== 0) {
            return onError("error_file_too_big");
        }
        const url = await upload(file)
        onFileCompleted && onFileCompleted(url);
    }

    const handleClick = event => {

        if (getBlob) {

            (async () => {
                const blob = await getBlob();
                await onFilesChanged([blob])
            })();

        } else {
            hiddenFileInput.current.value = null;
            hiddenFileInput.current.click();
        }

    };

    const handleChange = event => {
        console.log("files changed")
        onFilesChanged(event.target.files)
    };

    console.log("TusBrowseButton render", progress, isUploading)
    return (
        <Box display={'flex'} alignItems={'center'}>
            <Box mr={2}>
                <Button variant='contained' size={'large'} color={'secondary'} onClick={handleClick}>{text}</Button>
            </Box>
            <input
                type="file"
                ref={hiddenFileInput}
                accept=".mp3"
                onChange={handleChange}
                style={{display: 'none'}}/>
            {isUploading && <CircularProgress size={30} color={'primary'}/>}
        </Box>

    )
});

export default TusBrowseButton