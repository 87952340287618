import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";
import update from "immutability-helper";

export class CategoriesStore {

    constructor() {
    }


    @action
    async getCategories(params) {
        const response = await adminApi.get('/categories', {
            params
        })
        return response;
    }

    @action
    async editCategory(category) {
        const response = await adminApi.patch('/categories/' + category.id, {
            category: convertToServerCategory(category)
        })

    }

    @action
    async createCategory(category) {
        const response = await adminApi.post('/categories', {
            category: convertToServerCategory(category)
        })

    }

    @action
    async deleteCategory(categoryId) {
        return await adminApi.delete(`/categories/${categoryId}`)
    }

}


const convertToServerCategory = (category) => {
    return {
        ...category,
        cards: category.cards.map(c => c.id),
        created_by: category.created_by.id,

    }
}