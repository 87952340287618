import React, {useState} from 'react';
import {InputText} from "./index";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import {MdLock, MdVisibility, MdVisibilityOff} from "react-icons/all";

function InputPassword({disabled, ...rest}) {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <InputText
            startAdornment={<InputAdornment position="start">
                <MdLock/>
            </InputAdornment>}
            endAdornment={<InputAdornment position="end">
                <IconButton
                    tabIndex={-1}
                    color={'primary'}
                    edge="end"
                    disabled={disabled}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={event => event.preventDefault()}
                >
                    {showPassword ? <MdVisibility/> : <MdVisibilityOff/>}
                </IconButton>
            </InputAdornment>}
            {...rest}
            type={showPassword ? 'text' : 'password'}
        />
    )
}

export {InputPassword}