import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../CardForm";
import CardSoundUploader from "@PlaystreamENV/front-lib/src/card-sound-uploader/CardSoundUploader";

const EndorsementMetaForm = ({metadata}) => {
    return (
        <>
            {/*<Grid item xs={12}>*/}
            {/*    <FormInputText label={'Sound'} defaultValue={metadata.sound}*/}
            {/*                   name='metadata.sound'*/}
            {/*                   validation={{}}/>*/}

            {/*</Grid>*/}
            <Grid item xs={12}>
                <CardSoundUploader metadata={metadata}/>
            </Grid>


        </>
    )

}

export default EndorsementMetaForm