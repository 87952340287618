export const roles = [
    {key: "guest", label: "Guest", value: 5},
    {key: "viewer", label: "Viewer", value: 25},
    {key: "mod", label: "Moderator", value: 40},
    {key: "streamer", label: "Streamer", value: 50},
    {key: "tester", label: "Tester", value: 55},
    {key: "admin", label: "Admin", value: 75},
    {key: "superuser", label: "Superuser", value: 100},
]

export function toRoleText(role) {

    return role;
    // return roles[role];

}

export function getRoleByKey(key) {

    return roles.find(r => r.key === key);
}