import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import RoleSelect from "@PlaystreamENV/front-lib/src/auth/role/RoleSelect";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import CountrySelect from "@PlaystreamENV/front-lib/src/country-select/CountrySelect";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    FaPortrait,
    FaUser,
    FaUserFriends,
    FaUsers,
    GiShadowFollower,
    GrChannel,
    MdAttachMoney,
    MdEmail,
    MdFlag,
    MdLock, SiGravatar, VscReferences
} from "react-icons/all";
import ButtonRequestStreamerPasswordLink from "../../../forgot-password/ButtonRequestStreamerPasswordLink";
import PartnershipSelect from "./PartnershipSelect";
import DeleteUserDialog from "../dialogs/DeleteUserDialog";
import StreamerWebSourcesLinks from "./StreamerWebSourcesLinks";
import {SocialPlatformPicker} from "@PlaystreamENV/front-lib/src/pickers";
import UserAuditSection from "./UserAuditSection";
import ReferralLabel from "../labels/ReferralLabel";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";

const translations = {
    required_field: "Field is required",
}
export const formValidation = {

    required: {
        required: translations.required_field
    },
    email: {
        required: translations.required_field
    },
    firstname: {
        required: translations.required_field
    },
    lastname: {
        required: translations.required_field
    },
    password: {
        required: translations.required_field
    },
    role: {
        required: translations.required_field
    },
};

function UserForm({user, onDeleted}) {
    const {usersStore} = useStores();
    const onValidateUsername = async (username) => {
        // when we change the username to the same username.
        if (user && user.username === username) {
            return true
        }
        const {isValid} = await usersStore.validateUsername(username)
        return isValid;
    }
    return (
        <Box>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <h5 className={'text-muted'}>Basic Profile</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'First Name'} defaultValue={user.firstname}
                                               name='firstname'
                                    // startAdornment={<InputAdornment position="start">
                                    //     <FaUser/>
                                    // </InputAdornment>}
                                               validation={{}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Last Name'} defaultValue={user.lastname}
                                               name='lastname'
                                    // startAdornment={<InputAdornment position="start">
                                    //     <FaUser/>
                                    // </InputAdornment>}
                                               validation={{}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Username'} defaultValue={user.username}
                                               name='username'
                                               startAdornment={<InputAdornment position="start">
                                                   <FaUser/>
                                               </InputAdornment>}
                                               validation={{
                                                   required: "Field is required",
                                                   validate: async (username) => await onValidateUsername(username) || "Username already exists"
                                               }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Email'} defaultValue={user.email}
                                               name='email'
                                               autoComplete="new-password"
                                               startAdornment={<InputAdornment position="start">
                                                   <MdEmail/>
                                               </InputAdornment>}
                                               validation={formValidation.email}/>
                            </Grid>
                            <Grid item xs={12}>

                                {/*<FormInputText label={'Role'} defaultValue={user.role}*/}
                                {/*               name='role'*/}
                                {/*               startAdornment={<InputAdornment position="start">*/}
                                {/*                   <MdLock/>*/}
                                {/*               </InputAdornment>}*/}
                                {/*               validation={formValidation.role}/>*/}
                                <RoleSelect defaultValue={user.role}/>
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonRequestStreamerPasswordLink streamer={user.id}/>

                            </Grid>
                            <Grid item xs={12} style={{marginTop: 20}}>
                                <h5 className={'text-muted'}>Partnership</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <PartnershipSelect defaultValue={user.partnership} rules={formValidation.required}/>
                            </Grid>

                            {/*<Grid item xs={12}>*/}
                            {/*    <FormInputText label={'Avatar'} defaultValue={user.avatar}*/}
                            {/*                   name='avatar'*/}
                            {/*                   startAdornment={<InputAdornment position="start">*/}
                            {/*                       <FaPortrait/>*/}
                            {/*                   </InputAdornment>}*/}
                            {/*                   validation={{}}/>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <FormInputText label={'Referral Key'} defaultValue={user.referral_key}
                                               name='referral_key'
                                               startAdornment={<InputAdornment position="start">
                                                   <VscReferences/>
                                               </InputAdornment>}
                                               validation={formValidation.lastname}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}/>
                    <Grid item xs={5}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <h5 className={'text-muted'}>Paypal</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'PayPal Email'} name='paypal_email'
                                               defaultValue={user.paypal_email}
                                               validation={{}}
                                               startAdornment={<InputAdornment position="start">
                                                   <MdEmail/>
                                               </InputAdornment>}/>
                            </Grid>

                            <Grid item xs={12}>
                                {/*<FormInputText label={'Country'} name='country'*/}
                                {/*               defaultValue={user.country}*/}
                                {/*               validation={formValidation.required}*/}
                                {/*               startAdornment={<InputAdornment position="start">*/}
                                {/*                   <MdFlag/>*/}
                                {/*               </InputAdornment>}/>*/}
                                <CountrySelect defaultCountryCode={user.country}/>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 20}}>
                                <h5 className={'text-muted'}>Statistics</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Followers'} name='followers_count'

                                               type={'number'}
                                               defaultValue={user.followers_count}
                                               validation={{}}
                                               startAdornment={<InputAdornment position="start">
                                                   <FaUserFriends/>
                                               </InputAdornment>}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Average Donations Per Month'} name='avg_donations'

                                               type={'number'}
                                               defaultValue={user.avg_donations}
                                               validation={{}}
                                               startAdornment={<InputAdornment position="start">
                                                   <MdAttachMoney/>
                                               </InputAdornment>}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Average Concurrent Viewers'} name='avg_viewers'

                                               type={'number'}
                                               defaultValue={user.avg_viewers}
                                               validation={{}}
                                               startAdornment={<InputAdornment position="start">
                                                   <FaUsers/>
                                               </InputAdornment>}/>
                            </Grid>
                            <Grid item xs={12}>
                                <SocialPlatformPicker defaultValue={user.streaming_platform}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText name='streaming_platform'
                                               value={user.streaming_platform}
                                               validation={formValidation.required_platform}/>


                            </Grid>
                            <Grid item xs={12}>

                                <FormInputText label={'Username of your channel'} name='streaming_platform_username'

                                               validation={formValidation.required}
                                               defaultValue={user.streaming_platform_username}
                                               startAdornment={<InputAdornment position="start">
                                                   <GrChannel className={'gr-icon'}/>
                                               </InputAdornment>}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 20}}>
                                <h5 className={'text-muted'}>Social</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Twitch Id'}
                                    name='twitch_id'
                                    validation={{}}
                                    defaultValue={user.twitch_id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Facebook Id'}
                                    name='facebook_id'
                                    validation={{}}
                                    defaultValue={user.facebook_id}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Youtube Id'}
                                    name='youtube_id'
                                    validation={{}}
                                    defaultValue={user.youtube_id}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <UserAuditSection user={user}/>
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <RoleSelect name={"role"} validation={formValidation.role} defaultValue={user.role}/>*/}
                            {/*</Grid>*/}

                            {/*<Grid item xs={12}>*/}
                            {/*    <FormInputPassword name='password' label={'Password'}*/}
                            {/*                       autoComplete="new-password"*/}
                            {/*                       defaultValue={user.password}*/}
                            {/*                       validation={!user._id ? formValidation.password : {}}/>*/}
                            {/*</Grid>*/}

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {/*<DeleteUserDialog user={user} onDeleted={onDeleted}/>*/}
                        <h5>Referral</h5>

                        <ReferralLabel user={user}/>
                    </Grid>
                    <Grid item xs={12}>
                        <StreamerWebSourcesLinks user={user}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGlobalErrorMessage/>

                    </Grid>
                </Grid>

            </form>
        </Box>
    )
}

export default UserForm
