import React from "react";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import Box from "@material-ui/core/Box";
import useLanguage from "@PlaystreamENV/front-lib/src/l10n/hooks/useLanguage";


const LanguageSelect = ({language}) => {
    const {languages} = useLanguage();
    return (
        <>
            <SingleSelect
                name={"language"}
                prop={"id"}
                defaultValue={language}
                options={languages.map(language => ({...language, id: language.key}))}
                rules={{required: 'Field is required'}}>
                {({option}) => {
                    return <Box
                        display={"flex"}
                        alignItems={"center"}
                        width={"100%"}>
                        <span>{option.text}</span>
                    </Box>
                }}
            </SingleSelect>
        </>
    )
}
export default LanguageSelect