import React from "react";
import {Avatar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({

    big: {
        width: 60,
        height: 60,

    },
    default: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        textTransform: 'uppercase'
    }

}));

function UserAvatar({user, size, className, ...rest}) {
    const classes = useStyles();
    const email = user.email || user.emailAddress;
    const avatar_name = email[0];
    return (
        <Avatar
            alt="Person"
            className={clsx(classes[size], classes.default, className)}
            {...rest}
        >
            {avatar_name}
        </Avatar>
    )

}

export default UserAvatar
