import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";


function UserAuditSection({user}) {

    const {adminStore} = useStores();
    const [userAudit, setUserAudit] = useState(null);


    useEffect(() => {

        if (!user) return;
        (async () => {
            const audit = await adminStore.getStreamerAudit(user.id);
            setUserAudit(audit);
            console.log("audit", audit);
        })();

    }, [user]);


    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <h5 className={'text-muted'}>User Audit</h5>
                        </Grid>

                        {!!userAudit && <>
                            <Grid item xs={12}>
                                <AuditTimestamp id={"electron first login"} date={userAudit.electron_last_seen}/>
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <AuditTimestamp id={"goal_custom_last_seen"} date={userAudit.goal_custom_last_seen}/>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <AuditTimestamp id={"bar_cool_last_seen"} date={userAudit.bar_cool_last_seen}/>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <AuditTimestamp id={"bar_slick_last_seen"} date={userAudit.bar_slick_last_seen}/>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <AuditTimestamp id={"bar_card_slider_last_seen"} date={userAudit.bar_card_slider_last_seen}/>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <AuditTimestamp id={"alerts_card_last_seen"} date={userAudit.alerts_card_last_seen}/>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <AuditTimestamp id={"alerts_slick_last_seen"} date={userAudit.alerts_slick_last_seen}/>*/}
                            {/*</Grid>*/}
                        </>}


                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const AuditTimestamp = ({id, date}) => {

    return <Box display={"flex"} width={"100%"}>
        <div style={{width: 300}}>{id}</div>
        <div style={{width: 300}}>
            {date > 0 && <DateTimeLabel value={date}/>}
            {!date && "No Time"}
        </div>
    </Box>
}

export default UserAuditSection
