import React from "react";
    import Box from "@material-ui/core/Box";

const TabPanel = ({panelIndex, displayedPanel, children}) => {
    const shouldDisplay = panelIndex === displayedPanel;
    return (
        <div>
            {shouldDisplay && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export default TabPanel