import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../entities/zones/panels/ZonePanels";

const LevelsForm = ({levelModel}) => {
    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <h3>Level multiplier</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <FormInputText label={'Easy'} defaultValue={levelModel.easy_multiplier} type={"number"}
                                               name='easy_multiplier'
                                               validation={formValidation.required}/>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'Medium'} defaultValue={levelModel.medium_multiplier}
                                               type={"number"}
                                               name='medium_multiplier'
                                               validation={formValidation.required}/>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'Hard'} defaultValue={levelModel.hard_multiplier} type={"number"}
                                               name='hard_multiplier'
                                               validation={formValidation.required}/>
                            </Grid>


                        </Grid>
                    </form>

                </Grid>


            </Grid>
        </>
    )

}

export default LevelsForm
