import React from "react";
import NotFoundView from "@PlaystreamENV/front-lib/src/construction/NotFoundView";
import {FixedNavBar} from "@PlaystreamENV/front-lib/src/navbars";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    brush: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'

    },
    heroContent: {
        // backgroundColor: theme.palette.background.paper,
        marginTop: 120
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },

}));


const NotFoundRoute = ({}) => {
    const classes = useStyles();
    return (
        <>

            <div className={classes.heroContent}>
                <Container maxWidth="lg">
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                    >
                        <Grid item xs={12} md={6}>
                            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                                Page cannot be found 404
                            </Typography>
                            {/*<Typography variant="h4" align="center" color="textSecondary" paragraph>*/}


                            {/*    <span className={classes.brush}>hartatovich </span>*/}
                            {/*    is offline*/}

                            {/*</Typography>*/}
                            <Typography variant="body1" align="center" color="textSecondary" paragraph>
                                The page that you are looking for cannot be found, if you think that this is a mistake
                                please contact us.


                            </Typography>
                            <div className={classes.heroButtons}>
                                <Grid container spacing={2} justify="center">
                                    {/*<Grid item>*/}
                                    {/*    <Button size={'large'} variant="contained" color="primary">*/}
                                    {/*      Start Game*/}
                                    {/*    </Button>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item>*/}
                                    {/*    <Button variant="outlined" color="primary">*/}
                                    {/*        Secondary action*/}
                                    {/*    </Button>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </div>

                        </Grid>
                    </Grid>

                    <Box>
                        <NotFoundView/>
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default NotFoundRoute
