import React from "react";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import YesNoLabel from "../../entities/games/labels/YesNoLabel";

function StreamConfigsLabel({stream}) {


    const {dyno_key, electron_version, electron_time} = stream;

    return (

        <div style={{width: 295}}>

            <div className={'primary-color'}>Electron: {electron_version}</div>
            <div>Dyno: {dyno_key}</div>
            <div>Labs: <YesNoLabel value={stream.is_labs_enabled}/></div>
            <div>
                Started: <DateTimeLabel value={electron_time}/>

            </div>
        </div>
    )
}

export default StreamConfigsLabel;
