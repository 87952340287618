import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import EditTicketDialog from "../dialogs/EditTicketDialog";

function TicketsTable({user}) {
    const {email = ''} = user || {}
    const [loadCounter, setLoadCounter] = useState(0);
    const [ticketToEdit, setTicketToEdit] = useState(null)
    const {ticketsStore} = useStores();
    const filtersOptions = [
        {
            type: 'text',
            name: "email",
            label: "Email",
            defaultValue: email,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "status",
            label: "Status",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Open", value: 'open'},
                {label: "Closed", value: 'closed'},
            ]
        },
        {
            type: 'select',
            name: "subject",
            label: "Subject",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Partnership", value: 'partnership'},
                {label: "Withdraw", value: 'withdraw'},
                {label: "home-page-support", value: 'home-page-support'},
                {label: "Support", value: 'support'},
                {label: "private-download-link", value: 'private-download-link'},


            ]
        }
    ];
    const getOrders = async (params) => {
        const {data} = await ticketsStore.getTickets(params)
        return {data: data.tickets, count: data.count};
    }
    const onCloseEditDialog = () => {
        setTicketToEdit(null);
        setLoadCounter(prevState => prevState + 1)
    }
    return (
        <>
            <Table
                loadCounter={loadCounter}
                rowProps={{hover: true}}
                loadData={getOrders}
                filtersOptions={filtersOptions}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setTicketToEdit(row)
                                    }}>
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',
                        cell: ({row}) => <>

                            {row.id}
                        </>
                    },
                    {
                        key: 'email',
                        title: 'Email',
                        cell: ({row}) => <>

                            {row.email}
                        </>
                    },
                    {
                        key: 'subject',
                        title: 'Subject',
                        cell: ({row}) => <>

                            {row.subject}
                        </>
                    },
                    {
                        key: 'message',
                        title: 'Message',
                        cell: ({row}) => <>

                            {row.message}
                        </>
                    },
                    {
                        key: 'notes',
                        title: 'Notes',
                        cell: ({row}) => <>
                            {row.notes}
                        </>
                    },
                    {
                        key: 'status',
                        title: 'Status',
                        cell: ({row}) => <>

                            {row.status}
                        </>
                    },
                    {
                        key: 'updatedAt',
                        title: 'Updated',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.updatedAt}/>
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'Created',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />
            <EditTicketDialog
                ticket={ticketToEdit}
                onClose={onCloseEditDialog}
            />
        </>
    )
}

export default TicketsTable
