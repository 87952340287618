import React, {useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import CardForm from "../forms/CardForm";

import Dialog from "@material-ui/core/Dialog";
import ConfirmDialog from "@PlaystreamENV/front-lib/src/dialog/ConfirmDialog";

function EditCardDialog({card, onClose, onSaved}) {
    const {id} = card || {}
    const [deleteModalStatus, setDeleteModalStatus] = useState(false);
    const {handleSubmit} = useForm();
    const {cardsStore} = useStores();
    const onSave = () => {

        handleSubmit(async (formData) => {
            const _card = {...formData}
            await cardsStore.editCard({id: card.id, ..._card})
            onClose();
            onSaved && onSaved();

        })();
    }
    const onDelete = async () => {
        await cardsStore.deleteCard(id)
        setDeleteModalStatus(false)
        onSaved()
        onClose();
    }

    return (
        <Dialog open={!!card} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit Card'} onClose={onClose}/>
            <DialogContent>
                <CardForm card={card || {metadata: {timer_enabled: false}}}/>
            </DialogContent>

            <DialogActions>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div>
                        {/*<Button variant='contained' onClick={() => setDeleteModalStatus(true)}>*/}
                        {/*    Delete*/}
                        {/*</Button>*/}
                    </div>
                    <div>
                        <Button
                            style={{marginRight: '5px'}}
                            onClick={onClose}
                            variant='contained'
                            color='secondary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={onSave}
                            variant='contained'
                            color='primary'>
                            Save
                        </Button>
                    </div>
                </div>
            </DialogActions>
            <ConfirmDialog
                onConfirm={onDelete}
                onClose={() => setDeleteModalStatus(false)}
                open={deleteModalStatus}/>
        </Dialog>
    )
}

export default withForm(EditCardDialog)
