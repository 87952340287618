import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import EditCardDialog from "../dialogs/EditCardDialog";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import YesNoLabel from "../../games/labels/YesNoLabel";
import CategoriesLabel from "../labels/CategoriesLabel";
import CardTypeLabel from "@PlaystreamENV/front-lib/src/zone/labels/CardTypeLabel";
import CategoryCommunityLabel from "../../categories/labels/CategoryCommunityLabel";
import MetaDataLabel from "../labels/MetaDataLabel";
import useLanguage from "@PlaystreamENV/front-lib/src/l10n/hooks/useLanguage";
import IconButtonPlaySound from "@PlaystreamENV/front-lib/src/obs/cards/buttons/play-sound/IconButtonPlaySound";


const CardsTable = observer(({lastCreated, defaultFilters = {}}) => {
    const {userId = "", cardId = ""} = defaultFilters;
    const {cardsStore} = useStores();
    const [rowToEdit, setRowToEdit] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const {translate} = useLanguage();
    const filtersOptions = [
        {
            type: 'text',
            name: "streamer",
            label: "User-ID",
            defaultValue: userId,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "card_id",
            label: "Card-ID",
            defaultValue: cardId,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "name",
            label: "Card Name",
            defaultValue: "",
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "type",
            label: "Type",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {value: "endorsement", label: translate(`challenge_type_endorsement`)},
                {value: "contract", label: translate(`challenge_type_contract`)},
                {value: "ai", label: translate(`challenge_type_ai`)},
                {value: "digital-asset", label: translate(`challenge_type_digital-asset`)},
                {value: "merchandise", label: translate(`challenge_type_merchandise`)}, 
            ]
        },
        {
            type: 'select',
            name: "published",
            label: "Publish",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Published", value: true},
                {label: "Not Published", value: false},
            ]
        },
    ];

    const getCards = async (params) => {
        const {data} = await cardsStore.getCards({
            ...params,
        })

        return {data: data.cards, count: data.count};
    }
    return (
        <>
            <Table
                rowProps={{hover: true}}
                loadCounter={lastUpdated.getTime() + lastCreated.getTime()}
                filtersOptions={filtersOptions}
                loadData={getCards}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => setRowToEdit(row)}
                                >
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',

                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        key: 'type',
                        title: 'type',

                        cell: ({row}) => <>

                            <CardTypeLabel type={row.type} showText={true}/> 

                        </>
                    },
                    {
                        key: 'name',
                        title: 'name',

                        cell: ({row}) => <>
                            {row.name}
                        </>
                    },
                    {
                        key: 'description',
                        title: 'description',

                        cell: ({row}) => <>
                            {row.description}
                        </>
                    },
                    {
                        key: 'min_amount',
                        title: 'min_amount',

                        cell: ({row}) => <>
                            {row.min_amount}
                        </>
                    },

                    {
                        key: 'published',
                        title: 'published',

                        cell: ({row}) => <>
                            <YesNoLabel value={row.published}/>
                        </>
                    },

                    {
                        key: 'sound',
                        title: 'sounds',

                        cell: ({row}) => <>
                            {!!row.metadata.sound && <IconButtonPlaySound sound={row.metadata.sound}/>}
                        </>
                    },
                    {
                        key: 'metadata',
                        style: {
                            width: 400
                        },
                        title: 'metadata',

                        cell: ({row}) => <>
                            <MetaDataLabel value={row}/>
                        </>
                    },
                    {
                        key: 'categories',
                        title: 'categories',
                        cell: ({row}) => <>
                            <CategoriesLabel categories={row.categories}/>
                        </>
                    },
                    {
                        key: 'community',
                        title: 'community',

                        cell: ({row}) => <>
                            <CategoryCommunityLabel category={row}/>
                        </>
                    },
                    {
                        key: 'created_by',
                        title: 'created_by',
                        cell: ({row}) => <>
                            {row.created_by && row.created_by.email}
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'createdAt',

                        cell: ({row}) => <>
                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />

            <EditCardDialog
                card={rowToEdit}
                onClose={async () => setRowToEdit(null)}
                onSaved={() => setLastUpdated(new Date())}
            />
        </>
    )
})

export default CardsTable
