import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";
import labsApi from "../../../services/labsApi";


export class AdminStore {

    constructor() {


    }

    @action
    async startStreamForStreamer(streamer, game_key) {
        await adminApi.post('/actions/streamer-start-stream', {streamer, game_key});
    }

    @action
    async stopStreamForStreamer(streamer) {
        const response = await adminApi.post('/actions/streamer-stop-stream', {streamer})

    }

    @action
    async updateStreamerEvent(streamer, game, event, step) {
        const response = await adminApi.post('/actions/streamer-update-event', {
            streamer, game, event, step
        })
    }

    @action
    async updateStreamerAi(streamer, progression_id, step) {
        const response = await adminApi.post('/actions/streamer-update-ai', {
            streamer, progression_id, step
        })
    }

    @action
    async updateStreamerContract(streamer, progression_id, step) {
        const response = await adminApi.post('/actions/streamer-update-contract', {
            streamer, progression_id, step
        })
    }

    @computed get streamerStats() {
        return toJS(this._streamerStats)
    }


    @action
    async getStreamerPasswordToken(streamer,) {
        const response = await adminApi.post('/actions/streamer-reset-password', {streamer});
        return response.data;
    }

    @observable  _streamerStats = null;


    @action
    async getStreamerStats(params) {
        this._streamerStats = null;
        const response = await adminApi.get('/streamer-stats', {
            params
        })
        this._streamerStats = response.data;
    }

    @action
    async resetStreamerBalance(params) {
        const response = await adminApi.get('/actions/streamer-reset-balance', {
            params
        })
        // this._challenges = response.data;
    }


    @action
    async getStreamerLiveStreamStorage(params) {
        const response = await adminApi.get('/live-stream', {
            params
        })
        return response.data;
    }

    @action
    async toggleLabsForStreamer(streamer, is_labs_enabled) {
        await adminApi.post('/labs/streamer-toggle-labs', {streamer, is_labs_enabled});
    }


    @computed get applicationConfigs() {
        return toJS(this._applicationConfigs)
    }


    @observable  _applicationConfigs = null;


    @action
    async getApplicationConfigs() {
        const response = await adminApi.get('/application-configs')
        this._applicationConfigs = response.data;
    }

    @action
    async setApplicationConfigs(applicationConfigs) {
        const response = await adminApi.put('/application-configs', {...applicationConfigs})
        this._applicationConfigs = response.data;
    }


    @computed get streamMetrics() {
        return toJS(this._streamMetrics)
    }


    @observable  _streamMetrics = null;


    @action
    async getStreamMetrics(params,) {
        const response = await adminApi.get('/labs/stream-metrics', {params})
        this._streamMetrics = response.data;
        return response;
    }

    @action
    async pushOverlayAction(action, streamer, data) {
        return await adminApi.put('/actions/streamer-overlay-action', {
            action,
            streamer,
            data
        })

    }

    @action
    async refreshAllAchievements(params) {
        const response = await adminApi.post('/achievements/refresh-all-achievements', {params});
    }

    @action
    async cloneCategories(userId, categories) {
        return await adminApi.post('/streamer/clone-categories', {
            user_id: userId,
            categories,
        })
    }

    @action
    async getStreamerAudit(streamer) {
        const {data} = await adminApi.get('/streamer-audit', {
            params: {streamer}
        })

        return data.audit;
    }
}
