import React, {useEffect} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog, DialogContent} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import PersonalAchievementsTable from "../../achievements/table/PersonalAchievementsTable";
import PersonalAchievementsPanel from "../../achievements/panels/PersonalAchievementsPanel";

function UserPersonalAchievementsDialog({user, onClose, onSaved}) {
    const {handleSubmit} = useForm();

    const onSave = () => {

        handleSubmit(async (formData) => {
            console.log("edit achievement", formData);
            // const _category = {...formData}
            // await achievementsStore.editCategory({id: user.id, ..._category})
            // onClose();
            // onSaved && onSaved();

        })();
    }


    const title = (() => {
        if (user) {
            return `${(user).email} Personal Achievements`
        }
    })();

    return (
        <Dialog open={!!user} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={title} onClose={onClose}/>
            <DialogContent>

                <PersonalAchievementsPanel  defaultFilters={{
                    userId: user && user.id || ""
                }}/>

            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} variant='contained'
                        color='secondary'>
                    Cancel
                </Button>
                <Button onClick={onSave} variant='contained'
                        color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withForm(UserPersonalAchievementsDialog)
