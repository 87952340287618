import React from "react";
import TableCell from "@material-ui/core/TableCell";

const TableBodyCell = ({ column, row, index }) => {
    const { style } = column;
    return (
        <TableCell style={style}>
            {column.cell({ row, index: index + 1 })}
        </TableCell>
    );
};
export default TableBodyCell;
