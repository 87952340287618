import React from 'react';
import  {FormProvider,useForm} from 'react-hook-form'

const withForm = (Component) => {

    function WithForm({...rest}) {
        const methods = useForm({mode: 'onBlur', submitFocusError: false});
        return (
            <FormProvider {...methods}>
                <Component {...rest} methods={methods}/>
            </FormProvider>
        )
    }

    return WithForm
};

export {withForm}
