import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog} from "@material-ui/core";
import ReferralForm from "../forms/ReferralForm";


function EditReferralDialog({referral, onClose}) {
    const {handleSubmit} = useForm();
    const {referralsStore} = useStores();
    const onSave = () => {

        handleSubmit(async (formData) => {
            await referralsStore.editReferralModel({id: referral.id, ...formData})
            onClose();
        })();
    }
    return (
        <Dialog open={!!referral} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit Referral'} onClose={onClose}/>
            {referral && <DialogContent>
                <ReferralForm bonus={referral}/>
            </DialogContent>}
            <DialogActions onSave={onSave} onCancel={onClose}/>
        </Dialog>
    )
}

export default withForm(EditReferralDialog)
