import {useContext} from "react";
import {LanguageContext} from "../provider/LanguageProvider";


export default () => {
    const [context] = useContext(LanguageContext);
    const {isDev, languages, activeLanguage, setActiveLanguage, toggleDevMode, onLanguageChange} = context;

    const activeLanguageResources = (languages.filter(l => l.key === activeLanguage.key)[0] || {resources: {}}).resources

    const translate = (key, ...rest) => {
        if (isDev) return `#${key}`;
        const value = activeLanguageResources[key];
        let output = (() => {
            if (value) {
                return value;
            } else {
                const english = languages.filter(l => l.key === "en-US")[0];
                return english.resources[key];
            }
        })();

        for (let i = 0; i < rest.length; i++) {
            output = output.replace(`{${i}}`, rest[i]);
        }
        return output;
    }

    // const isLanguageExist = (iso) => {
    //     return languages.some(l => l.iso === iso);
    // }

    return {activeLanguage, setActiveLanguage, languages, translate, toggleDevMode, isDev, onLanguageChange}
}

