import React from "react";

const ReferralBonusLabel = ({referral}) => {

    const {bonuses} = referral;

    const sum = bonuses.reduce((last, obj, index) => {
        last += obj.amount;
        return last;
    }, 0);
    return (
        <>
            {bonuses.map(b => {
                return <div key={b.id}>{b.type}:<span className={'text-bold primary-color'}> ${b.amount}</span></div>
            })}
        </>
    )
}

export default ReferralBonusLabel