import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {IoMdAddCircle} from "react-icons/all";
import {Dialog, DialogContent} from '@material-ui/core';
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import BotForm from "../forms/BotForm";
import DialogActions from "@material-ui/core/DialogActions";

function CreateBotDialog({onCreated}) {
    const {handleSubmit} = useForm();
    const [open, setOpen] = useState(false);
    const {botsStore} = useStores();
    const onSave = () => {
        handleSubmit(async (formData) => {
            await botsStore.createBot(formData)
            closeModal();
            onCreated && onCreated();
        })();
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <>
            <Button size='small' variant='contained' color='default' onClick={() => {
                setOpen(!open)
            }} startIcon={<IoMdAddCircle/>}>
                Create Bot
            </Button>
            <Dialog open={open} onClose={closeModal} maxWidth={"lg"}>
                <DialogTitle title={'Create Bot'} onClose={closeModal}/>
                <DialogContent>
                    <BotForm bot={{}}/>
                </DialogContent>

                <DialogActions>
                    <Button onClick={closeModal} variant='contained'
                            color='secondary'>
                        Cancel
                    </Button>
                    <Button onClick={onSave} variant='contained'
                            color='primary'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default withForm(CreateBotDialog)
