import React from "react";
import {Tooltip} from "@material-ui/core";

const StatusLabel = ({interaction}) => {
    const {status, is_donation} = interaction;
    return (

        <div className={status === "captured" ? 'primary-color' : ''}>
            {status}
        </div>

    )

}

export default StatusLabel