import React, {useState} from "react";
import {Controller} from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import "../select.scss";
import {Menu, MenuItem} from "@material-ui/core";
import {FormInputErrorMessage} from "../../FormInputErrorMessage";
import {useForm} from "../../context";

const SingleSelect = ({
                          name,
                          defaultValue,
                          options,
                          rules,
                          disabled,
                          label,
                          className,
                          prop = "id",
                          displayProp = "id",
                          onChange,
                          children
                      }) => {

    const {getError} = useForm();
    const error = getError(name);

    return (
        <div className={`single-select ${className} ${!!error ? "has-error" : ""}`}>
            {!!options && !!options.length && <>
                <InputLabel className={'select-label'}>{label || name}</InputLabel>
                <Controller
                    name={name}
                    rules={rules}
                    defaultValue={defaultValue || ""}
                    render={(
                        {onChange: _onChange, value}
                    ) => (
                        <Select
                            value={value}
                            label={label}
                            variant='outlined'
                            className={'select single-select'}
                            disabled={disabled}
                            MenuProps={{
                                classes: {paper: `single-select-paper menu-paper-${name}`},
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                            onChange={(e) => {
                                const val = e.target.value;
                                onChange && onChange(val);
                                _onChange(val)
                            }}
                        >
                            {options.map(option => (
                                <MenuItem value={option[prop]} key={option[prop]}>
                                    {children && children({option}) || option[displayProp]}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />
                <FormInputErrorMessage style={{alignSelf: 'end'}} name={name}/>

            </>
            }
        </div>
    )

}


// const PopperMenu = ({onChange, label, options, renderOption}) => {
//     const [anchorEl, setAnchorEl] = React.useState(null);
//
//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null);
//     };
//     return <>
//
//         <Button
//             onClick={handleClick}
//             fullWidth
//             variant='outlined'>
//             {label}
//         </Button>
//         <Menu
//             id="simple-menu"
//             anchorEl={anchorEl}
//             keepMounted
//             disableScrollLock
//
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//         >
//             {options && options.map(o => {
//                 return <MenuItem key={o.id} onClick={() => {
//                     handleClose();
//                     onChange && onChange(o.id)
//
//                 }}>{o.id}
//                 </MenuItem>
//             })}
//
//         </Menu>
//     </>
// }
export default SingleSelect
