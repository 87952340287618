import React from "react";

function SummaryMetricLabel({stream}) {


    const {livePredictions} = stream;
    const {last_verified_event, summary} = (livePredictions || {summary: {}});

    const metrics = Object.keys(summary).map(k => {
        return {key: k, value: summary[k].count};
    });


    return (

        <div style={{width: 165}}>
            {metrics.map(m => {
                return <div key={m.key}>

                    {m.key}: {m.value}

                </div>
            })}


            {last_verified_event && <>
                <hr/>
                <div>Last prediction: <span className={'primary-color'}>{last_verified_event}</span></div>
            </>}
        </div>
    )
}

export default SummaryMetricLabel;
