import React from "react";
import {Controller} from "react-hook-form";
import VolumeSlider from "./VolumeSlider";

const FormVolumeSlider = ({defaultValue, name, label}) => {

    return (
        <div>
            <label>{label || name}</label>
            <Controller
                name={name}
                defaultValue={defaultValue}
                render={({value, onChange}) => <VolumeSlider value={value} onChange={onChange}/>}
            />
        </div>
    )
}

export default FormVolumeSlider
