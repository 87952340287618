import React, {useEffect} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {observer} from "mobx-react-lite";
import CustomizationThemeColorsForm from "../../../components/customization/CustomizationThemeColorsForm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@PlaystreamENV/front-lib/src/tabs/TabPanel";
import Grid from "@material-ui/core/Grid";
import CustomizationThemeIconsForm from "../../../components/customization/CustomizationThemeIconsForm";
import Card from "@material-ui/core/Card";

const CustomizationRoute = observer(() => {


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Customization
                    </Typography>

                </Box>
            </PageTitleNavBar>

            <Box pt={5} px={2}>

                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">

                    <Tab label="Theme"/>
                    <Tab label="Language"/>
                    <Tab label="Sound"/>
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                                <Card style={{height:340}} title={
                                    'Colors'
                                }>

                                            <CustomizationThemeColorsForm customizationModel={{}}/>

                                </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{height:340}} title={
                                'Icons'
                            }>

                                <CustomizationThemeIconsForm customizationModel={{}}/>

                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <Card title={
                                'Language'
                            }>

                                Language stuff goes here

                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <Card title={
                                'Sound'
                            }>

                                Sound stuff goes here

                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>

        </>
    )
})

export default CustomizationRoute
