import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import './labels.scss'

const {REACT_APP_HOME_PAGE_URL} = process.env;

function ReferralLabel({user}) {


    const {referral_key} = user;
    const link = `${REACT_APP_HOME_PAGE_URL}register/${referral_key}`

    return (

        <a className={'secondary-color link'} href={link} target={"_blank"} >Referral Link </a>
    )
 }

export default ReferralLabel;
