import React from "react";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import Box from "@material-ui/core/Box";


const partnerships = [
    {id: "streamer", fee: 0.2},
    {id: "affiliate", fee: 0.15},
    {id: "partner", fee: 0.1},
    {id: "lord", fee: 0.05},
    {id: "king", fee: 0}
]
const PartnershipSelect = ({defaultValue, rules}) => {
    return (
        <SingleSelect name={"partnership"} prop={"id"} options={partnerships} defaultValue={defaultValue}
                      rules={rules}>
            {({option}) => {
                return <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}>
                    <span>{option.id}</span>
                    <small className={'text-muted'} style={{marginRight: 5}}>{option.fee}</small>
                </Box>
            }}
        </SingleSelect>
    )

}

export default PartnershipSelect