import React, {useState} from "react";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import ConfirmDialog from "@PlaystreamENV/front-lib/src/dialog/ConfirmDialog";
import Button from "@material-ui/core/Button";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";

function DeleteUserDialog({user, onDeleted, onClose}) {
    const {usersStore} = useStores();
    const {handleSubmit} = useForm();
    const [showConfirm, setShowConfirm] = useState(false);

    const onDelete = () => {
        console.log("onDelete", user.id)
        handleSubmit(async (formData) => {
            await usersStore.deleteUser(user.id)
            onDeleted && onDeleted();
        })();

    }

    return (
        <>
            <Button variant={'contained'}
                    color={"primary"}
                    onClick={() => {
                        setShowConfirm(true)
                    }}>Delete User</Button>
            <ConfirmDialog open={showConfirm} onConfirm={() => {
                onDelete();
                setShowConfirm(false)

            }} onClose={() => {
                setShowConfirm(false)
            }}/>
        </>
    )
}

export default DeleteUserDialog
