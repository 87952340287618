export default {
    typography: {
        fontFamily: [
            'BrandonText-Regular',
            'Muli',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        "fontSize": 20,
    },
    palette: {
        type: "dark",
        // contrastText: "#fff",
        background: {paper: '#191d23', default: '#1B1D21'},
        primary: {
            // light: "#3A4258",
            main: "#FF2C90",
            // contrastText: "#fff"
        },
        secondary: {
            main: "#00EFF8",
            // dark: "#54426B",
            // contrastText: "#fff"
        }
    },
}
