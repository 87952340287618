import React, {useEffect} from "react";
import PieChart from "@PlaystreamENV/front-lib/src/charts/PieChart";
import ChartLabelItem from "@PlaystreamENV/front-lib/src/charts/ChartLabelItem";
import {observer} from "mobx-react-lite";
import {Colors} from "../../../theme/Colors";
import DashboardCard from "../cards/DashboardCard";
import {Tooltip} from "recharts";


const LiveByGameChart = observer(({data}) => {

    const title = 'Online by game';

    const _data = Object.keys(data).map(k => {
        return {game: k.replace("live_", ""), live: data[k]}
    }).sort((a, b) => {
        if (a.live > b.live) return -1;
        if (a.live < b.live) return 1;
        return 0;
    }).filter(i => !!i.live)
    return (
        <>
            <DashboardCard title={title}>


                <PieChart data={_data} nameKey={'game'} dataKey={'live'}
                          height={350}
                          tooltip={<Tooltip formatter={(value) => {
                              return value
                          }}/>}
                          label={({row}) => {
                              return (
                                  <ChartLabelItem key={row.game}
                                                  selected={false}
                                                  onClick={() => {
                                                      // setFilters(row)
                                                  }}>
                                      <span>{row.game}</span>
                                      <span className={`label-live-${row.game}`}> {row.live}</span>
                                  </ChartLabelItem>
                              )


                          }}
                          colors={getColors()}/>
            </DashboardCard>

        </>
    )
})

function getColors() {

    return Colors.challenges;
}


export default LiveByGameChart
