import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";
import update from "immutability-helper";

export class CardsStore {

    constructor() {
    }


    @action
    async getCards(params) {
        return await adminApi.get('/cards', {
            params
        })


    }

    @action
    async editCard(card) {
        return await adminApi.patch('/cards/' + card.id, {
            card: convertToServerCard(card)
        })

    }


    @action
    async createCard(card) {

        return await adminApi.post('/cards', {
            card: convertToServerCard(card)
        })


    }

    @action
    async deleteCard(cardId) {
        return await adminApi.delete(`/cards/${cardId}`)
    }

}


const convertToServerCard = (card) => {
    return {
        ...card,
        categories: card.categories.map(c => c.id),
        created_by: card.created_by.id,
        metadata: {
            ...card.metadata,
            step: card.metadata.step ? parseInt(card.metadata.step) : undefined
        }
    }
}