import React, {useState} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardsTable from "../../../components/entities/cards/table/CardsTable";
import CreateCardDialog from "../../../components/entities/cards/dialogs/CreateCardDialog";
import useQueryFilters from "../../hooks/useQueryFilters";

const CardsRoute = ({}) => {
    const {user: userId = "", card: cardId = ""} = useQueryFilters();
    const [lastCreated, setLastCreated] = useState(new Date());
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Cards
                    </Typography>
                    <Box display={"flex"}>
                        <CreateCardDialog onCreated={() => {
                            setLastCreated(new Date())
                        }}/>
                    </Box>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <CardsTable
                    defaultFilters={{userId, cardId}}
                    lastCreated={lastCreated}/>
            </Box>
        </>
    )
}

export default CardsRoute
