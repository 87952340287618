import React from "react";
import {useTheme} from "@material-ui/core";

const SvgDigitalAsset = ({size = 40}) => {
    const theme = useTheme();

    return (
        <svg style={{width: size, height: size}} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M200.964 152.14C197.395 152.14 193.939 152.677 190.711 153.713L153.079 87.7853C159.915 81.4151 164.206 72.3203 164.206 62.1893C164.206 42.9636 148.712 27.3067 129.688 27.3067C110.663 27.3067 95.1697 42.9636 95.1697 62.1893C95.1697 72.3203 99.4987 81.4151 106.334 87.7853L68.6641 153.675C65.4364 152.639 61.9808 152.101 58.4113 152.101C39.3865 152.101 23.8933 167.758 23.8933 186.984C23.8933 206.21 39.3865 221.867 58.4113 221.867C74.8538 221.867 88.6003 210.201 92.0559 194.659H167.357C170.851 210.201 184.597 221.867 201.002 221.867C220.027 221.867 235.52 206.21 235.52 186.984C235.52 167.758 219.989 152.14 200.964 152.14ZM129.65 42.6566C140.32 42.6566 148.978 51.406 148.978 62.1893C148.978 72.9726 140.32 81.7221 129.65 81.7221C118.979 81.7221 110.321 72.9726 110.321 62.1893C110.321 51.406 118.979 42.6566 129.65 42.6566ZM58.3353 206.594C47.6647 206.594 39.0068 197.844 39.0068 187.061C39.0068 176.277 47.6647 167.528 58.3353 167.528C69.0059 167.528 77.6639 176.277 77.6639 187.061C77.6639 197.844 69.0059 206.594 58.3353 206.594ZM167.281 179.347H91.9799C90.423 172.325 86.7396 166.147 81.727 161.426L119.397 95.537C122.625 96.5731 126.08 97.1103 129.65 97.1103C133.219 97.1103 136.675 96.5731 139.941 95.537L177.572 161.465C172.522 166.147 168.838 172.363 167.281 179.347ZM200.964 206.594C190.293 206.594 181.635 197.844 181.635 187.061C181.635 176.277 190.293 167.528 200.964 167.528C211.635 167.528 220.293 176.277 220.293 187.061C220.293 197.844 211.635 206.594 200.964 206.594Z" fill="#00EFF8"/>
</svg>
    )

}

export default SvgDigitalAsset
