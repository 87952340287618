import React from "react";

const AiProgressionLabel = ({interaction}) => {
    // console.log("AiProgressionLabel", interaction)
    const {progression} = interaction;

    const {step, steps_to_complete} = (progression || {});
    const progress = parseInt(step / steps_to_complete * 100);
    return (
        <div className={'status-container'}>

            {!!steps_to_complete && <span className='status__progress'>{step}/{steps_to_complete}</span>}
            {!steps_to_complete && <span className={'text-muted'}>No live data</span>}

        </div>

    )
}
export default AiProgressionLabel