import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog} from "@material-ui/core";
import CouponForm from "../forms/CouponForm";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";


function EditCouponDialog({coupon, onSaved, onClose}) {
    const {handleSubmit} = useForm();
    const {couponsStore} = useStores();

    const onSave = () => {

        handleSubmit(async (formData) => {
            await couponsStore.editCoupon({id: coupon.id, ...formData});
            onSaved();
            onClose()
        })();
    }
    return (
        <Dialog open={!!coupon} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit Coupon'} onClose={onClose}/>
            {coupon && <DialogContent>
                <CouponForm coupon={coupon}/>
            </DialogContent>}
            <DialogActions onSave={onSave} onCancel={onClose}/>
        </Dialog>
    )
}

export default withForm(EditCouponDialog)
