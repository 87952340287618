import React from "react";
import Button from "@material-ui/core/Button";
import {Box} from "@material-ui/core";

const FilterActions = ({refreshData}) => {
    return (
        <Box>
            <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={refreshData}
                style={{marginRight: "10px"}}
            >
                Reload
            </Button>

        </Box>
    )
}
export default FilterActions
