import React, {useState, createContext} from 'react';
// import 'mobx-react-lite/batchingForReactDom'

export const RootStoreContext = createContext({});
const RootStoreContextProvider = RootStoreContext.Provider;

export const RootStoreProvider = ({children, getRootStore}) => {

    const [context, setContext] = useState({
        rootStore: getRootStore(),
        getRootStore: getRootStore
    });
    return (
        <RootStoreContextProvider value={[context, setContext]}>
            {children}
        </RootStoreContextProvider>
    )
};

