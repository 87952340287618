import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {observer} from "mobx-react-lite";
import TicketsTable from "../../../components/entities/tickets/table/TicketsTable";

const TicketsRoute = observer(({}) => {
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Tickets
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <TicketsTable />
            </Box>
        </>
    )
})

export default TicketsRoute
