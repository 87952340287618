import React, {useState} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import LoggedUserAvatar from "@PlaystreamENV/front-lib/src/auth/LoggedUserAvatar";
import {useUser} from "@PlaystreamENV/front-lib/src/auth/role/VisibleByRole";
import LogoutMenuItem from "./LogoutMenuItem";

function LoggedInUserAvatarMenu({}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const user = useUser();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    if (!user) return <></>
    return (
        <div>

            <IconButton
                color="inherit"
                edge="start"
                onClick={handleClick}
            >

                <LoggedUserAvatar/>
            </IconButton>

            <Menu
                id="user-menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                variant={'menu'}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                autoFocus={false}
                disableAutoFocusItem={true}
                disableRestoreFocus={true}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled>
                    Signed as {user.email}
                </MenuItem>
                <LogoutMenuItem onClick={handleClose}/>

            </Menu>
        </div>
    );
}

export default LoggedInUserAvatarMenu
