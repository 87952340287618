import React, {useEffect, useState} from "react";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import OnlineLabel from "../entities/users/labels/OnlineLabel";
import EventProgressForm from "./EventProgressForm";
import StreamerWebSourcesLinks from "../entities/users/forms/StreamerWebSourcesLinks";
import ReactJson from "react-json-view";
import Grid from "@material-ui/core/Grid";
import "./live-stream-panel.scss";
import OverlayActionForm from "./OverlayActionForm";
import {BaseLoader} from "@PlaystreamENV/front-lib/src/loaders/BaseLoader";
import LiveChallengesTable from "./LiveChallengesTable";


const TerminalPanel = observer(({stream}) => {

        const {adminStore, gamesStore} = useStores();
        const [streamInTerminal, setStreamInTerminal] = useState(null);

        const {games} = gamesStore;

        const isOnline = streamInTerminal && streamInTerminal.status === "online";
        useEffect(() => {

            if (!stream) return;

            gamesStore.getGames({limit: 50});
            loadLiveStreamData();
            const interval = startLoadInterval();
            return () => {
                clearInterval(interval);
            }

        }, [stream])

        const loadLiveStreamData = async () => {
            const _stream = await adminStore.getStreamerLiveStreamStorage({streamer: stream.streamer.id});
            setStreamInTerminal(_stream);
        }
        const startLoadInterval = () => {
            return setInterval(async () => {
                if (!stream) return;
                await loadLiveStreamData();

            }, 1000)
        }


        return (
            <Box className={'live-stream-panel'}>


                {!streamInTerminal && <><BaseLoader/></>}


                {!!streamInTerminal && <>

                    <Grid container spacing={4}>


                        <Grid item xs={8}>
                            <Box style={{maxWidth: 600}}>
                                <Box display={'flex'} flex={1} mb={1} justifyContent={'space-between'}>
                                    {/*<h3 style={{margin: 0}}> last updated <DateTimeLabel value={lastUpdated.getTime()}/>*/}
                                    {/*</h3>*/}
                                    <OnlineLabel value={isOnline}/>
                                </Box>

                                <EventProgressForm stream={streamInTerminal} games={games} isOnline={isOnline}/>
                            </Box>
                            <Box mt={3}>
                                <h5 className={'text-muted'}>Challenges progression</h5>

                                <LiveChallengesTable isOnline={isOnline} stream={streamInTerminal}/>
                                {/*<ChallengesProgressForm isOnline={isOnline} stream={streamInTerminal}/>*/}
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            {!!streamInTerminal.streamer && streamInTerminal.streamer.id &&
                            <StreamerWebSourcesLinks user={streamInTerminal.streamer}/>}
                            <Box>
                                {!!streamInTerminal.streamer && streamInTerminal.streamer.id &&
                                <OverlayActionForm streamer={streamInTerminal.streamer.id}/>}
                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <Box mt={8}>
                                <h3>Stream raw data</h3>

                                <ReactJson theme={'threezerotwofour'} displayDataTypes={false}
                                           src={{...streamInTerminal, streamer: undefined}}/>
                            </Box>

                        </Grid>

                    </Grid>
                </>}


            </Box>
        )

    }
)

export default TerminalPanel