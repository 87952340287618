import {toast} from 'react-toastify';

export class Toaster {


    static create(msg, options) {
        console.log("creating toast")
        const notify = () => toast.dark(msg, options);
        return notify();
    }

    static error(msg, options) {
        console.log("creating error toast")
        const notify = () => toast.error(msg, options);
        return notify();
    }

    static update(toastId, options) {
        // console.log("update")
        toast.update(toastId, options);
    }

    static done(toastId) {
        // console.log("done")
        toast.done(toastId);

    }

    static dismiss() {
        // console.log("dismiss")
        toast.dismiss()

    }


}
