import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";

export class InteractionsStore {

    constructor() {
    }

    @observable  _interactions = null;

    @computed get interactions() {
        return toJS(this._interactions)
    }


    @action
    async getInteractions(params) {
        const response = await adminApi.get('/interactions', {
            params
        })
        this._interactions = response.data.interactions;
        return response;
    }


}
