import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog} from "@material-ui/core";
import AchievementForm from "../../forms/AchievementForm";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";


function EditAchievementDialog({achievement, onSaved, onClose}) {
    const {handleSubmit} = useForm();
    const {achievementsStore} = useStores();
    const onSave = () => {

        handleSubmit(async (formData) => {
            await achievementsStore.editAchievement({id: achievement.id, ...formData})
            onSaved();
            onClose();
        })();
    }
    return (
        <Dialog open={!!achievement} onClose={onClose} maxWidth={"md"}>
            <DialogTitle title={'Edit Achievement'} onClose={onClose}/>
            {achievement && <DialogContent>
                <AchievementForm achievement={achievement}/>
            </DialogContent>}
            <DialogActions onSave={onSave} onCancel={onClose}/>
        </Dialog>
    )
}

export default withForm(EditAchievementDialog)
