import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";
import update from "immutability-helper";

export class AchievementsStore {

    constructor() {
    }


    @action
    async getAchievements(params) {
        return await adminApi.get('/achievements', {
            params
        })


    }

    @action
    async getPersonalAchievements(params) {
        return await adminApi.get('/personal-achievements', {
            params
        })


    }

    @action
    async createAchievement(achievement) {
        return await adminApi.post('/achievement', {
            achievement: {...achievement}
        })
    }

    @action
    async editAchievement(achievement) {
        await adminApi.patch(`/achievement/${achievement.id}`, {
            achievement: {...achievement}
        })
    }

    @action
    async editPersonalAchievement(personalAchievement) {
        await adminApi.patch(`/personal-achievements/${personalAchievement.id}`, {
            ...convertToServerPersonalAchievement(personalAchievement)
        })
    }

    @action
    async createPersonalAchievement(personalAchievement) {

        await adminApi.post('/personal-achievements', {
            ...convertToServerPersonalAchievement(personalAchievement)
        })

    }


}

const convertToServerPersonalAchievement = (personalAchievement) => {
    return {
        ...personalAchievement,
        streamer: personalAchievement.streamer.id,
        achievement: personalAchievement.achievement.id,
    }
}