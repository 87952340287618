import React, {useMemo, useState} from "react";
import Grid from "@material-ui/core/Grid";
import CategoriesAutoComplete from "../../categories/forms/CategoriesAutoComplete";
import CardsAutoComplete from "../../cards/forms/CardsAutoComplete";
import CloneCategoriesDialog from "../dialogs/CloneCategoriesDialog";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";


const CategoriesForm = ({zone,onSaved}) => {

    const {setValue} = useForm();
    const searchByFilters = useMemo(() => {
        return ({
            streamer: zone && zone.streamer.id || undefined,
            community: true
        })
    }, [zone && zone.created_by])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h5>Active Categories</h5>
            </Grid>
            <Grid item xs={12}>
                <CategoriesAutoComplete
                    defaultValue={zone.categories}
                    rules={{}}
                    searchByFilters={{created_by: zone.streamer.id}}
                />
            </Grid>
            <Grid item xs={12}>
                <h5>Hidden Cards</h5>
            </Grid>
            <Grid item xs={12}>
                <CardsAutoComplete
                    searchByFilters={searchByFilters}
                    defaultValue={zone.cards}
                />

            </Grid>

            <CloneCategoriesDialog userId={zone.streamer.id} onSaved={onSaved}/>

        </Grid>
    )
}
export default CategoriesForm
