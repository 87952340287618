import {action, computed, observable, toJS} from "mobx";
import update from "immutability-helper";
import adminApi from "../../../services/adminApi";

export class EventsStore {

    constructor() {}

    @observable  _events = null;

    @computed get events() {
        return toJS(this._events)
    }


    @action
    async getEvents(params) {
        const response = await adminApi.get('/events', {
            params
        })
        this._events = response.data.events;
        return response;
    }

    @action
    async editEvent(event) {
        const response = await adminApi.patch('/events/' + event.id, {
            event
        })

        if (this.events) {
            const index = this.events.findIndex((item) => item.id === event.id)
            if (index > -1) {
                this._events = update(this._events, {
                    [index]: {$merge: {...response.data}}
                })
            }
        }

        // this._users = response.data;
    }

}
