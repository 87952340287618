import React from "react";
import {Box} from "@material-ui/core";
import FilterInput from "./filterInput/FilterInput";

const FilterInputs = ({filtersOptions }) => {
    return (
        <Box display='flex' justifyContent='space-around'>
            {filtersOptions.map(filter => (
                <FilterInput filter={filter} key={filter.name}/>
            ))}
        </Box>
    )
}
export default FilterInputs