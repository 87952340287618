import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL + "api/v1",
    withCredentials: true,
    timeout: 2 * 60 * 1000,
    headers: {
        'Content-Type': 'application/json'
    },
});


export const setupInterceptors = (onForbidden) => {


    api.interceptors.response.use(
        config => {
            return config;
        },
        (ex) => {
            const exception = ex && ex.response && ex.response.data ? ex.response.data : ex;
            if (ex && ex.response && ex.response.status === 403) {
                console.log("Forbidden 403")
                onForbidden && onForbidden(ex);

            }
            console.error(exception);
            throw (exception);
        }
    );
}
export default api

