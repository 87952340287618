import React, {useEffect, useState} from "react";
import {MenuItem, Select} from "@material-ui/core";
import {TextToSpeech} from "@PlaystreamENV/front-lib/src/sound/TextToSpeech";
import {BiMaleSign, BiFemaleSign} from "react-icons/all";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/core/styles";
import {Controller} from "react-hook-form";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    playBtn: {
        marginLeft: '8px'
    },
    paper: {
        height: 200,
        marginTop: 133
    }
})

const VoicePicker = ({value, onChange, name}) => {
    const classes = useStyles()
    const [voices, setVoices] = useState([]);

    useEffect(() => {
        (async () => {
            const voices = await TextToSpeech.getVoices();
            // console.log("voices", voices)
            setVoices(voices);
        })()
    }, []);
    return (
        <div className={classes.root}>
            <Select
                variant='outlined'
                className={'tts-select'}
                labelId="tts-picker-label"
                id="tts-picker"
                name={name}
                value={value}
                MenuProps={{classes: {paper: classes.paper}}}
                onChange={(e) => onChange(e.target.value)}
            >
                {voices.map((voice) => {
                    return <MenuItem key={voice.name} value={voice.name} className={'tts-item'}>
                        <div className={'tts-v'}>{voice.name}</div>
                        <VoiceIcon gender={voice.ssmlGender}/>
                    </MenuItem>
                })}
            </Select>
        </div>
    )

}

const VoiceIcon = ({gender}) => {
    return (
        <Tooltip title={gender === "MALE" ? "Male" : "Female"} arrow placement="top">
            <div>
                {gender === "MALE" ? <BiMaleSign/> : <BiFemaleSign/>}
            </div>
        </Tooltip>
    )
}

export default VoicePicker
