import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import UsersAutocomplete from "../../users/forms/UsersAutocomplete";
import {Button} from "@material-ui/core";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {Toaster} from "@PlaystreamENV/front-lib/src/toast/toaster";


const translations = {
    required_field: "Field is required",
}
export const formValidation = {


    required: {
        required: translations.required_field
    },
    enabled: {
        // required: translations.required_field
    }

};

const BotChatTerminalForm = ({bot}) => {
    const {botsStore} = useStores();
    const {handleSubmit} = useForm();

    const sendMessage = () => {

        handleSubmit(async (formData) => {
            // console.log(formData)
            await botsStore.sendMessage(formData.streamer.id, bot.id, formData.message)

            Toaster.create(`Bot message sent`, {autoClose: true})

        })();
    }


    return (
        <>
            <form>

                <Grid container spacing={2}>

                    <Grid item sm={12}>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <h5 className={'text-muted'}> Message</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <UsersAutocomplete name={'streamer'} label={'Streamer'}
                                                   rules={formValidation.required}/>

                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'Message'}
                                               name='message'
                                               className={"multiline"}
                                               multiline={true}
                                               validation={formValidation.required}/>
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item sm={12}>
                        <Button onClick={sendMessage} variant='contained'
                                color='secondary'>
                            Send Message
                        </Button>
                    </Grid>

                </Grid>

            </form>

        </>
    )

}

export default BotChatTerminalForm