import React from "react";
import {useTheme} from "@material-ui/core";

const SvgAI = ({size = 40}) => {
    const theme = useTheme();

    return (
        <svg version="1.1" id="svg_ai_01" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             style={{width: size, height: size}}
             viewBox="0 0 80 80" xmlSpace="preserve">
            <g>
                <defs>
                    <path id="ca_id_01" d="M53.3,30.4l0.2,0.4l7.4,14.8c0.7,1.4,0.1,3-1.2,3.7c-1.3,0.6-2.8,0.2-3.5-0.9L56,48l-4.9-9.8L46.2,48
			c-0.6,1.3-2.1,1.8-3.4,1.4l-0.3-0.1c-1.3-0.6-1.8-2.1-1.4-3.4l0.1-0.3l7.4-14.8C49.6,28.8,52.2,28.7,53.3,30.4z M36.1,32.2
			c1.1,1.1,1.1,2.8,0,3.9c-1.1,1.1-2.8,1.1-3.9,0c-1.8-1.8-4.7-1.8-6.5,0c-1.8,1.8-1.8,4.7,0,6.5s4.7,1.8,6.5,0
			c1.1-1.1,2.8-1.1,3.9,0c1.1,1.1,1.1,2.8,0,3.9c-4,4-10.4,4-14.4,0c-4-4-4-10.4,0-14.4C25.7,28.2,32.1,28.2,36.1,32.2z M15.4,11.4
			c-2.5-0.7-5,0.8-5.7,3.2c-0.1,0.3-0.1,0.5-0.1,0.8l0,0.4v33.9c0,1.5,0.7,2.9,2,3.8l0.4,0.3l25.8,14.4c1.2,0.7,2.7,0.8,4,0.2
			l0.5-0.2l25.8-14.4c1.3-0.7,2.2-2.1,2.3-3.5l0-0.5V30.2c0-1.9-1.2-3.6-2.9-4.3l-0.4-0.2L15.4,11.4z M4.6,12.5
			c1.7-4.8,6.7-7.7,11.7-6.6l0.6,0.2l51.7,14.4c4.2,1.2,7.1,4.8,7.4,9.1l0,0.7v19.6c0,3.5-1.8,6.7-4.6,8.5l-0.6,0.4L44.9,73
			c-2.8,1.6-6.3,1.7-9.2,0.3L35.1,73L9.2,58.7c-3-1.7-5-4.8-5.2-8.2l0-0.7V15.9c0-0.7,0.1-1.4,0.2-2l0.2-0.7L4.6,12.5z"/>
                </defs>
                <use xlinkHref="#ca_id_01" fill={theme.palette.secondary.main}/>
                <clipPath>
                    <use xlinkHref="#ca_id_01"/>
                </clipPath>
            </g>
        </svg>
    )

}

export default SvgAI
