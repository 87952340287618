import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import StatIconWidgetCard from "../../../dashboard/widgets/StatIconWidgetCard";
import {FaStream, FaUsers, GiPiggyBank, GiTakeMyMoney, GrTrophy} from "react-icons/all";
import Box from "@material-ui/core/Box";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";

const UserStats = ({stats}) => {


    return (
        <Grid container spacing={3}>

            <Grid item md={3}>
                <StatIconWidgetCard title={'Streams'} icon={<FaStream size={30}/>}
                                    value={stats.total_streams}/>
            </Grid>

            <Grid item md={3}>
                <StatIconWidgetCard title={'All Challenges'} icon={<GrTrophy className={'gr-trophy-icon'} size={30}/>}
                                    value={stats.total_transactions}/>
            </Grid>
            <Grid item md={3}>
                <StatIconWidgetCard title={'Balance'} icon={<GiPiggyBank size={30}/>}
                                    value={<Box>
                                        ${stats.balance}
                                    </Box>}/>
            </Grid>
            {/*<Grid item md={3}>*/}
            {/*    <StatIconWidgetCard title={'Captured'} icon={<GiPiggyBank size={30}/>}*/}
            {/*                        value={<Box>*/}
            {/*                            ${stats.total_captured}*/}
            {/*                        </Box>*/}
            {/*                        }/>*/}
            {/*</Grid>*/}
            <Grid item md={3}>
                <StatIconWidgetCard title={'Lifetime Revenues'} icon={<GiPiggyBank size={30}/>}
                                    value={<Box>
                                        ${stats.total_revenues}
                                    </Box>}/>
            </Grid>
            <Grid item md={3}>
                <StatIconWidgetCard title={'Captured Challenges'}
                                    icon={<GrTrophy className={'gr-trophy-icon'} size={30}/>}
                                    value={stats.captured_transactions}/>
            </Grid>


            <Grid item md={3}>
                <StatIconWidgetCard title={'Challengers'} icon={<FaUsers size={30}/>}
                                    value={<Box>
                                        {stats.total_challengers}
                                    </Box>}/>
            </Grid>

            <Grid item md={3}>
                <StatIconWidgetCard title={'Referral Bonus Balance'} icon={<GiTakeMyMoney size={30}/>}
                                    value={<Box>
                                        {stats.referral_balance}
                                    </Box>}/>
            </Grid>

            <Grid item md={3}>
                <StatIconWidgetCard title={'Referral Lifetime Bonus'} icon={<GiTakeMyMoney size={30}/>}
                                    value={<Box>
                                        {stats.total_referral_revenues}
                                    </Box>}/>
            </Grid>
        </Grid>
    )
}

export default UserStats
