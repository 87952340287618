import React, {useEffect, useState} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CategoriesTable from "../../../components/entities/categories/table/CategoriesTable";
import CreateCategoryDialog from "../../../components/entities/categories/dialogs/CreateCategoryDialog";

const CategoriesRoute = ({}) => {
    const [lastCreated, setLastCreated] = useState(new Date());

    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Categories
                    </Typography>

                    <CreateCategoryDialog onCreated={() => {
                        setLastCreated(new Date())
                    }}/>
                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>

                <CategoriesTable lastCreated={lastCreated}/>
            </Box>
        </>
    )
}

export default CategoriesRoute
