import React from "react";
import Button from "@material-ui/core/Button";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";

const ToggleLabsForStreamer = observer(({streamer, is_labs_enabled}) => {

    // const [isEnabled, setIsEnabled] = React.useState(is_labs_enabled);
    const {adminStore} = useStores();

    const toggleLabsForStreamer = async (value) => {
        await adminStore.toggleLabsForStreamer(streamer, value);
        // setIsEnabled(value)
    }

    return (
        <>
            <Button variant={"contained"} color={"secondary"} disabled={is_labs_enabled} onClick={() => {
                toggleLabsForStreamer(true)
            }}>Enable Labs For Streamer</Button>
            <Button variant={"contained"} color={"secondary"} onClick={() => {
                toggleLabsForStreamer(false)
            }} disabled={!is_labs_enabled}>Disable Labs For Streamer</Button>
        </>
    )

})

export default ToggleLabsForStreamer