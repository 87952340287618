import React from "react";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";

function ChartLabelItem({children, selected, onClick, ...rest}) {
    return (

        <ListItem selected={selected} button={!!onClick}
                  style={{padding: '2px 4px'}}
                  onClick={onClick} {...rest}>

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className={'text-capitalize'}
                 pr={2}
                 style={{fontSize: 13}}
                 width={'100%'}>

                {children}

            </Box>


        </ListItem>


    )
}

export default ChartLabelItem