import React, {useEffect, useState} from "react";
import SoundService from "../../../../sound/SoundService";

const useSound = ({sound, volume}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [preload, setPreload] = useState(null);

    const play = async () => {
        setIsLoading(true)
        const audio = await preload;
        setIsLoading(false)
        setIsPlaying(true);
        audio && await audio.play(volume);
        setIsPlaying(false);
    }
    const pause = async () => {
        const audio = await preload;
        audio && audio.stop();
        setIsPlaying(false);
    }

    useEffect(() => {
        (async () => {
            const preload = SoundService.preLoad(sound);
            setPreload(preload)
        })();

    }, [sound])

    useEffect(() => {

        return () => {
            if (isPlaying) {
                console.log("useSound unMount", preload)
                pause();
            }
        }
    }, [isPlaying, preload])

    return {play, pause, isLoading, isPlaying}

}
export default useSound
