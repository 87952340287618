import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import PredictionWorkTimeChart from "./charts/PredictionWorkTimeChart";
import PredictionVerifiedChart from "./charts/PredictionVerifiedChart";
import PredictionMatchedChart from "./charts/PredictionMatchedChart";
import PredictionSnapshotsScoreChart from "./charts/PredictionSnapshotsScoreChart";
import PredictionFramesHistoryChart from "./charts/PredictionFramesHistoryChart";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import PredictionTensorScoreChart from "./charts/PredictionTensorScoreChart";
import IconButton from "@material-ui/core/IconButton";
import {BiRefresh, VscLoading} from "react-icons/all";
import Box from "@material-ui/core/Box";
import {BaseLoader} from "@PlaystreamENV/front-lib/src/loaders/BaseLoader";
import SnapshotUploadTimeHistoryChart from "./charts/SnapshotUploadTimeHistoryChart";
import SnapshotBytesHistoryChart from "./charts/SnapshotBytesHistoryChart";
import HeartbeatChart from "./charts/HeartbeatChart";


const LabsCharts = observer(({streamer, game, event}) => {
    const {labsStore} = useStores();
    const [predictionsLogs, setPredictionsLogs] = useState(null);
    const [heartbeats, setHeartbeats] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [snapshotsLogs, setSnapshotsLogs] = useState(null);


    useEffect(() => {

        // if (!user) return;
        loadCharts();


    }, [streamer, game]);

    const loadCharts = async () => {
        setIsLoading(true)

        const predictionsLogs = await labsStore.getPredictionsLogs({streamer, game, event})
        setPredictionsLogs(predictionsLogs);

        const snapshotsLogs = await labsStore.getSnapshotsLogs({streamer, game, event})
        const heartbeats = await labsStore.getHeartbeats({streamer})
        setHeartbeats(heartbeats);
        setSnapshotsLogs(snapshotsLogs);
        setIsLoading(false)

    }

    const syncId = `${game}-${event}`;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>

                <Box display={'flex'} justifyContent={"space-between"}>
                    <h3>Event: {event}</h3>
                    <IconButton disabled={isLoading} onClick={loadCharts}>
                        {!isLoading && <BiRefresh/>}
                        {isLoading && <VscLoading/>}
                    </IconButton>
                </Box>
            </Grid>

            <Grid item md={12} lg={4}>
                <HeartbeatChart syncId={syncId} data={heartbeats}/>
            </Grid>

            <Grid item md={12} lg={4}>
                <SnapshotUploadTimeHistoryChart syncId={syncId} data={snapshotsLogs}/>
            </Grid>
            <Grid item md={12} lg={4}>

                <SnapshotBytesHistoryChart syncId={syncId} data={snapshotsLogs}/>
            </Grid>
            <Grid item md={12} lg={4}>
                <PredictionWorkTimeChart syncId={syncId} data={predictionsLogs}/>
            </Grid>
            <Grid item md={12} lg={4}>
                <PredictionSnapshotsScoreChart syncId={syncId} data={predictionsLogs} snapshotsLogs={snapshotsLogs}/>
            </Grid>
            <Grid item md={12} lg={4}>
                {/*<PredictionVerifiedChart syncId={syncId} data={predictionsLogs}/>*/}
            </Grid>

            <Grid item md={12} lg={4}>
                <PredictionMatchedChart syncId={syncId} data={predictionsLogs}/>
            </Grid>
            <Grid item md={12} lg={4}>
                <PredictionTensorScoreChart syncId={syncId} data={predictionsLogs} snapshotsLogs={snapshotsLogs}/>
            </Grid>
            <Grid item md={12} lg={4}>a
                {/*<PredictionFramesHistoryChart syncId={syncId} data={predictionsLogs}/>*/}
            </Grid>

        </Grid>
    )
})

export default LabsCharts
