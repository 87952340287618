import React, {useEffect, useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {Slide} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import Box from "@material-ui/core/Box";
import TerminalPanel from "./TerminalPanel";


const LiveStreamDialog = observer(({stream, onClose}) => {

    const title = (() => {

        if (stream)
            return stream.streamer.email
    })();

    return (
        <Dialog
            TransitionComponent={Slide}
            TransitionProps={{
                direction: 'down'
            }}
            maxWidth={"xl"}
            open={!!stream}
            fullWidth
            onClose={onClose}

        >
            <DialogTitle title={<>
                <Box> Terminal <small>{title}</small></Box>
            </>} onClose={onClose}/>


            <DialogContent>


                {!!stream && <TerminalPanel stream={stream}/>}


            </DialogContent>
            <DialogActions cancelText={'Close'} onCancel={onClose}/>
        </Dialog>
    )
})

export default withForm(LiveStreamDialog)
