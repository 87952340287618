import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../entities/zones/panels/ZonePanels";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import FormInputDate from "@PlaystreamENV/front-lib/src/form/FormInputDate";
import UsersAutocomplete from "../entities/users/forms/UsersAutocomplete";

const CharityForm = ({applicationConfigs}) => {

    const {
        charity_goals_enabled,
        charity_goals_target,
        charity_goals_timer_enabled,
        charity_goals_start_date,
        charity_goals_end_date,
        charity_goals_description,
        charity_streamers,
        charity2_goals_enabled,
        charity2_goals_target,
        charity2_goals_timer_enabled,
        charity2_goals_start_date,
        charity2_goals_end_date,
        charity2_goals_description,
        charity2_streamers,
        charity3_goals_enabled,
        charity3_goals_target,
        charity3_goals_timer_enabled,
        charity3_goals_start_date,
        charity3_goals_end_date,
        charity3_goals_description,
        charity3_streamers,
        charity4_goals_enabled,
        charity4_goals_target,
        charity4_goals_timer_enabled,
        charity4_goals_start_date,
        charity4_goals_end_date,
        charity4_goals_description,
        charity4_streamers,
        charity5_goals_enabled,
        charity5_goals_target,
        charity5_goals_timer_enabled,
        charity5_goals_start_date,
        charity5_goals_end_date,
        charity5_goals_description,
        charity5_streamers,
    } = applicationConfigs;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h3>Charity 1</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity_goals_enabled}
                                    name='charity_goals_enabled'
                                    label='Charity Goals Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity_goals_timer_enabled}
                                    name='charity_goals_timer_enabled'
                                    label='Charity Timer Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UsersAutocomplete
                                    label={"Users"}
                                    multiple
                                    defaultValue={charity_streamers || []}
                                    name='charity_streamers'/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Charity Goals Target'}
                                    defaultValue={charity_goals_target}
                                    type={"number"}
                                    name='charity_goals_target'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Starts on'
                                    name='charity_goals_start_date'
                                    defaultValue={charity_goals_start_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Ends on'
                                    name='charity_goals_end_date'
                                    defaultValue={charity_goals_end_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    type='text'
                                    name='charity_goals_description'
                                    label='Charity Goals Description'
                                    defaultValue={charity_goals_description}
                                />
                            </Grid>
                        </Grid>
                    </form>

                </Grid>

                <Grid item xs={12}>
                    <h3>Charity 2</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity2_goals_enabled}
                                    name='charity2_goals_enabled'
                                    label='Charity Goals Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity2_goals_timer_enabled}
                                    name='charity2_goals_timer_enabled'
                                    label='Charity Timer Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UsersAutocomplete
                                    label={"Users"}
                                    multiple
                                    defaultValue={charity2_streamers || []}
                                    name='charity2_streamers'/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Charity Goals Target'}
                                    defaultValue={charity2_goals_target}
                                    type={"number"}
                                    name='charity2_goals_target'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Starts on'
                                    name='charity2_goals_start_date'
                                    defaultValue={charity2_goals_start_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Ends on'
                                    name='charity2_goals_end_date'
                                    defaultValue={charity2_goals_end_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    type='text'
                                    name='charity2_goals_description'
                                    label='Charity Goals Description'
                                    defaultValue={charity2_goals_description}
                                />
                            </Grid>
                        </Grid>
                    </form>

                </Grid>

                <Grid item xs={12}>
                    <h3>Charity 3</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity3_goals_enabled}
                                    name='charity3_goals_enabled'
                                    label='Charity Goals Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity3_goals_timer_enabled}
                                    name='charity3_goals_timer_enabled'
                                    label='Charity Timer Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UsersAutocomplete
                                    label={"Users"}
                                    multiple
                                    defaultValue={charity3_streamers || []}
                                    name='charity3_streamers'/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Charity Goals Target'}
                                    defaultValue={charity3_goals_target}
                                    type={"number"}
                                    name='charity3_goals_target'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Starts on'
                                    name='charity3_goals_start_date'
                                    defaultValue={charity3_goals_start_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Ends on'
                                    name='charity3_goals_end_date'
                                    defaultValue={charity3_goals_end_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    type='text'
                                    name='charity3_goals_description'
                                    label='Charity Goals Description'
                                    defaultValue={charity3_goals_description}
                                />
                            </Grid>
                        </Grid>
                    </form>

                </Grid>

                <Grid item xs={12}>
                    <h3>Charity 4</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity4_goals_enabled}
                                    name='charity4_goals_enabled'
                                    label='Charity Goals Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity4_goals_timer_enabled}
                                    name='charity4_goals_timer_enabled'
                                    label='Charity Timer Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UsersAutocomplete
                                    label={"Users"}
                                    multiple
                                    defaultValue={charity4_streamers || []}
                                    name='charity4_streamers'/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Charity Goals Target'}
                                    defaultValue={charity4_goals_target}
                                    type={"number"}
                                    name='charity4_goals_target'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Starts on'
                                    name='charity4_goals_start_date'
                                    defaultValue={charity4_goals_start_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Ends on'
                                    name='charity4_goals_end_date'
                                    defaultValue={charity4_goals_end_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    type='text'
                                    name='charity4_goals_description'
                                    label='Charity Goals Description'
                                    defaultValue={charity4_goals_description}
                                />
                            </Grid>
                        </Grid>
                    </form>

                </Grid>

                <Grid item xs={12}>
                    <h3>Charity 5</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity5_goals_enabled}
                                    name='charity5_goals_enabled'
                                    label='Charity Goals Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    defaultChecked={charity5_goals_timer_enabled}
                                    name='charity5_goals_timer_enabled'
                                    label='Charity Timer Enabled'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <UsersAutocomplete
                                    label={"Users"}
                                    multiple
                                    defaultValue={charity5_streamers || []}
                                    name='charity5_streamers'/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Charity Goals Target'}
                                    defaultValue={charity5_goals_target}
                                    type={"number"}
                                    name='charity5_goals_target'
                                    validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Starts on'
                                    name='charity5_goals_start_date'
                                    defaultValue={charity5_goals_start_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputDate
                                    label='Ends on'
                                    name='charity5_goals_end_date'
                                    defaultValue={charity5_goals_end_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText
                                    type='text'
                                    name='charity5_goals_description'
                                    label='Charity Goals Description'
                                    defaultValue={charity5_goals_description}
                                />
                            </Grid>
                        </Grid>
                    </form>

                </Grid>


            </Grid>
        </>
    )

}

export default CharityForm
