import "@PlaystreamENV/front-lib/src/debug/redirectFromLocalhost";
import React from 'react';
import './style/style.scss'
import {RootStoreProvider} from "@PlaystreamENV/front-lib/src/mbox/provider/RootStoreProvider";
import RootStore from "./mobx/stores/RootStore";
import '@PlaystreamENV/front-lib/src/assets/fonts-face.scss';
import Root from "./Root";
import ToastContainer from "@PlaystreamENV/front-lib/src/toast/ToastContainer";
import {setupInterceptors} from "./services/adminApi";
import {LanguageProvider} from "@PlaystreamENV/front-lib/src/l10n/provider/LanguageProvider";
import {languages} from "./components/l10n";

setupInterceptors(() => {
    window.location.href = "/login"
});

function App() {
    return (
        <RootStoreProvider getRootStore={() => {
            return new RootStore()
        }}>
            <LanguageProvider extend={() => {
                return languages;
            }}>
                <Root/>
                <ToastContainer/>
            </LanguageProvider>
        </RootStoreProvider>
    );
}

export default App;
