import React from "react";
import {ToastContainer as ToastifyContainer} from 'react-toastify';
import './toast.scss';
import './toastify.scss';

const ToastContainer = ({...rest}) => {
    return (
        <>
            <ToastifyContainer position="bottom-right"
                               autoClose={5000}
                               hideProgressBar={false}
                               newestOnTop={false}
                               closeOnClick
                               rtl={false}
                               pauseOnFocusLoss
                               pauseOnHover
                               {...rest}
            />
        </>
    )
}

export default ToastContainer
