import {action, computed, observable, toJS} from "mobx";
import update from "immutability-helper";
import adminApi from "../../../services/adminApi";

export class GamesStore {

    constructor() {}

    @observable  _games = null;

    @computed get games() {
        return toJS(this._games)
    }


    @action
    async getGames(params) {
        const response = await adminApi.get('/games', {
            params
        })
        this._games = response.data.games;
        return response;
    }

    @action
    async editGame(game) {
        const response = await adminApi.patch('/games/' + game.id, {
            game
        })

        if (this.games) {
            const index = this.games.findIndex((item) => item.id === game.id)
            if (index > -1) {
                this._games = update(this._games, {
                    [index]: {$merge: {...response.data}}
                })
            }
        }

        // this._users = response.data;
    }

}
