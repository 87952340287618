import React from "react";

function DateTimeLabel({value, noTime}) {

    const date = new Date(value)
    const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
    const mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date);
    const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date);
    return (
        <>
            {`${da}/${mo}/${ye}`} {!noTime && date.toLocaleTimeString("en", {hour12: false})}
        </>
    )
}

export default DateTimeLabel
