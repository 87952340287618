import React from "react";
import {useTheme} from "@material-ui/core";

const SvgEndorsement = ({size = 40}) => {
    const theme = useTheme();

    return (
        <svg version="1.1" id="svg_endorsement_01" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 80 80"
             style={{enableBackground: `new 0 0 80 80`, width: size, height: size}}
             xmlSpace="preserve"
        >
            <g>
                <defs>
                    <path id="treat_id_01" d="M23.8,11.3c-1.5,2.2-4.3,3.2-6.7,2.6c-1.1-0.2-2,0-2.8,0.7c-0.7,0.7-1.1,1.9-0.9,2.8
			c0.7,2.6-0.2,5.2-2.6,6.7c-1.1,0.7-1.9,2-1.5,3.3c0.4,1.1,1.3,2,2.6,2.2h0.4H20l9.3-9.3v-7.8c0-1.3-0.9-2.6-2.2-3
			C25.8,9.5,24.5,10,23.8,11.3z M52,30.1v20.4l6.9-6.9c1.7-1.7,1.9-4.5,0.4-6.1l-0.4-0.4L52,30.1z M27.8,30.1l-7.1,7.1
			c-1.7,1.7-1.9,4.5-0.4,6.1l0.4,0.4l7.1,7.1V30.1z M43.2,21.2c-1.7-1.7-4.5-1.9-6.1-0.4l-0.4,0.4l-3.2,3.2v31.8l3.2,3.2
			c1.7,1.7,4.5,1.9,6.1,0.4l0.4-0.4l3.2-3.2V24.5L43.2,21.2z M67.6,50.7h-8L50.3,60v8c0,1.3,0.9,2.6,2.2,3c1.3,0.4,2.8-0.2,3.5-1.5
			c1.5-2.2,4.1-3.3,6.5-2.6c1.1,0.2,2,0,2.8-0.7c0.7-0.7,1.1-1.9,0.9-2.8c-0.7-2.6,0.2-5.2,2.6-6.7c1.1-0.7,1.9-2,1.5-3.3
			c-0.4-1.1-1.3-2-2.6-2.2L67.6,50.7z M28.6,4.3c3.5,0.9,6.1,4.1,6.3,7.6v0.6v2.8c3.7-2,8.4-1.7,11.5,1.3l0.6,0.6l15.8,15.8
			c3.3,3.3,3.9,8.4,1.7,12.1h3c3.9,0,7.2,2.6,8.4,6.3c0.9,3.7-0.7,7.8-3.9,9.7c-0.2,0-0.2,0.2-0.2,0.4v0.2c0.7,3-0.2,5.9-2.2,8
			s-5.2,3-8.2,2.2c-0.2,0-0.4,0-0.4,0c-1.9,3.3-5.9,5-9.7,4.1c-3.5-0.9-6.1-4.1-6.3-7.6v-0.6v-3c-3.7,2-8.4,1.7-11.5-1.1l-0.6-0.6
			L17.1,47.1c-3.3-3.3-3.9-8.2-1.7-12.1h-2.8c-3.9,0-7.2-2.6-8.4-6.3C3.3,25.1,5,21,8.2,19.1c0.2,0,0.2-0.2,0.2-0.4v-0.2
			c-0.9-2.6-0.2-5.8,2-7.8s5.2-3,8.2-2.2c0.2,0,0.4,0,0.4,0C20.8,5,24.9,3.3,28.6,4.3z"/>
                </defs>
                <use xlinkHref="#treat_id_01" style={{overflow: 'visible'}} fill={theme.palette.secondary.main}/>
                <clipPath>
                    <use xlinkHref="#treat_id_01"/>
                </clipPath>
            </g>
        </svg>

    )

}

export default SvgEndorsement
