import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import './labels.scss'

function OnlineLabel({value}) {


    const key = value ? 'online' : 'offline'
    return (

        <span className={clsx('text-capitalize', `label-${key}`)}>{key} </span>
    )
}

export default OnlineLabel;
