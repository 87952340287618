import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    center: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
}));

function BaseLoaderFunc({center = false, size = 40, color = 'primary', ...rest}) {
    const classes = useStyles();
    // console.log("BaseLoaderFunc")
    return (

        <CircularProgress size={size} style={{marginTop: size / -2, marginLeft: size / -2}} color={color}
                          className={classes.center} {...rest}/>

    )

}

const BaseLoader = React.memo(BaseLoaderFunc);
export {BaseLoader}
