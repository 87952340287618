import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableBodyCell from "./table-body-cell/TableBodyCell";

const TableBodyComp = ({rows, columns, rowProps}) => {

    const getRowProps = () => {
        let props = Object.assign({}, rowProps)
        delete props["getRowClass"];
        return props;
    }
    return (
        <TableBody>
            {rows.map((row) => {
                return (
                    <TableRow
                        hover
                        tabIndex={-1}
                        key={`${row.id}`}
                        {...getRowProps()}

                        className={rowProps && rowProps.getRowClass && rowProps.getRowClass(row)}
                    >
                        {columns.map((column, index) => (
                            <TableBodyCell column={column} key={index} row={row}/>
                        ))}
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
export default TableBodyComp;
