import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {HiOutlineDotsCircleHorizontal, BiDotsHorizontal, FaRegPlayCircle} from "react-icons/all";
import {Tooltip} from "@material-ui/core";
import useLanguage from "../../../l10n/hooks/useLanguage";
import CardTypeLabel from "../../../zone/labels/CardTypeLabel";
import Box from "@material-ui/core/Box";

const CardActions = ({card}) => {

    const {description} = card;
    return (
        <div className={'overlay-actions'}>

            <Box display={"flex"}>
                <div className={'action-card-type'}>
                    <TypeTooltip card={card}/>
                </div>
                {/*{description && <DescriptionTooltip card={card}/>}*/}

            </Box>

        </div>
    )

}


const TypeTooltip = ({card}) => {
    const {type, description} = card;
    const {translate} = useLanguage();

    const tag = translate(`challenge_type_${type}`)
    const info = translate(`challenge_type_${type}_description`)
    return <Tooltip title={<div>
        <p>
            {description}
        </p>
        <p className={'text-muted'}>
            {info}
        </p>
        {type === "ai" && <div className={'primary-color automatic-verification'}>Automatic verification</div>}
        {type === "contract" && <div className={'text-muted automatic-verification'}>Streamer verification</div>}
        {type === "endorsement" && <div className={'text-muted automatic-verification'}>Instant Gratification</div>}
        {type === "digital-asset" && <div className={'text-muted automatic-verification'}>Instant Gratification</div>}
        {type === "merchandise" && <div className={'text-muted automatic-verification'}>Merchandise</div>}

    </div>} arrow placement="top" classes={{
        tooltip: "default-tooltip",
        arrow: "default-tooltip-arrow"
    }}>
        <div>
            <CardTypeLabel type={type} showText={true}/>
        </div>
    </Tooltip>
}

const DescriptionTooltip = ({card}) => {
    const {description} = card;

    return <Tooltip title={<div>
        {description}
    </div>} arrow placement="top" classes={{
        tooltip: "default-tooltip",
        arrow: "default-tooltip-arrow"
    }}>
        <div>
            <HiOutlineDotsCircleHorizontal size={24} color={"#fff"}/>

        </div>
    </Tooltip>
}
export default CardActions