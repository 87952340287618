import React from "react";
import {GiAstronautHelmet, FaStream} from "react-icons/all";
import Box from "@material-ui/core/Box";

const NotFoundView = ({message}) => {
    return (
        <Box display={'flex'} alignItems='center' justifyContent={'center'} flexDirection={'column'} mt={5} pt={5}
             style={{color: "#555", opacity: 0.2}}>

            <div style={{position: 'relative'}}>
                <GiAstronautHelmet size={430}/>
                <FaStream size={90}
                          style={{marginRight: 15, position: 'absolute', opacity: 0.2, top: 140, left: 175}}/>
            </div>





        </Box>
    )
}

export default NotFoundView
