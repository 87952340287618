import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import {Box, Tooltip} from "@material-ui/core";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit, SiQzone, VscReferences} from "react-icons/all";
import {useHistory} from "react-router-dom";
import YesNoLabel from "../../games/labels/YesNoLabel";
import EditBonusDialog from "../dialogs/EditBonusDialog";

function BonusesTable({defaultFilters}) {
    const {referralsStore} = useStores();
    const {
        streamer = '',
        withdrawStatus = "",
        referral = "",
        completionStatus = "",
        enabledStatus = ""
    } = defaultFilters || {}
    const {push} = useHistory();
    const [modelToEdit, setModelToEdit] = useState(null)
    const [loadCounter, setLoadCounter] = useState(0);
    const filtersOptions = [
        {
            type: 'text',
            name: "referral",
            label: "Referral ID",
            defaultValue: referral,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "streamer",
            label: "Streamer ID",
            defaultValue: streamer,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "type",
            label: "Type",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "First Interaction", value: 'first-interaction'},
                {label: "Weekly streak", value: 'weekly-streak'},

            ]
        },
        {
            type: 'select',
            name: "withdraw_status",
            label: "Withdraw",
            defaultValue: withdrawStatus,
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Yes", value: 'yes'},
                {label: "No", value: 'no'},
            ]
        },
        {
            type: 'select',
            name: "completion",
            label: "Completion",
            defaultValue: completionStatus,
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Completed", value: 'completed'},
            ]
        },
        {
            type: 'select',
            name: "enabled",
            label: "Enabled",
            defaultValue: enabledStatus,
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Enabled", value: true},
                {label: "Disabled", value: false},
            ]
        }
    ];
    const getBonuses = async (params) => {
        const {data} = await referralsStore.getBonuses(params)
        return {data: data.bonuses, count: data.count};
    }
    return (
        <>
            <Table
                loadCounter={loadCounter}
                rowProps={{hover: true}}
                filtersOptions={filtersOptions}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        cell: ({row}) => <Box display={"flex"}>
                            <Tooltip title='Referrals' placement="top" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => push(`/referrals?id=${row.referral.id}`)}>
                                    <VscReferences size={27}/>
                                </IconButton>
                            </Tooltip>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setModelToEdit(row)
                                    }}>
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </Box>
                    },
                    {
                        key: 'id',
                        sortable: false,
                        title: 'Bonus ID',
                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        key: 'referral',
                        sortable: false,
                        title: 'Referral',
                        cell: ({row}) => <>
                            {row.referral && row.referral.id}
                        </>
                    },
                    {
                        key: 'relationship',
                        sortable: false,
                        title: 'relationship',
                        cell: ({row}) => <>
                            {row.referral && <>
                                <span>{row.referral.source.username}</span> --&gt;&nbsp;
                                <span>{row.referral.target.username}</span>
                                {/*{row.referral.target.id}*/}
                            </>}

                            {/*{row.referral && row.referral.target.id === row.streamer.id && <>Owner</>}*/}
                        </>
                    },
                    {
                        key: 'streamer',
                        sortable: false,
                        title: 'Bonus owner',
                        cell: ({row}) => <>
                            <span className='text-bold primary-color'>{row.streamer.username}</span>
                            {/*<Box marginTop='5px'>*/}
                            {/*    {row.streamer.id}*/}
                            {/*</Box>*/}

                        </>
                    },


                    {
                        key: 'type',
                        title: 'Type',
                        cell: ({row}) => <>
                            {row.type}
                        </>
                    },
                    {
                        key: 'value',
                        title: 'Value',
                        cell: ({row}) => <>
                            {row.value}
                        </>
                    },

                    {
                        key: 'amount',
                        title: 'Amount',
                        cell: ({row}) => <>
                            <span className={'primary-color'}> ${row.amount}</span>
                        </>
                    },
                    {
                        key: 'withdraw_status',
                        title: 'Withdraw',
                        cell: ({row}) => <>
                            <YesNoLabel value={row.withdraw_status === "yes"}/>
                        </>
                    },
                    {
                        key: 'enabled',
                        title: 'Enabled',
                        cell: ({row}) => <>
                            <YesNoLabel value={row.enabled}/>
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'CreatedAt',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
                loadData={getBonuses}
            />
            <EditBonusDialog bonus={modelToEdit} onClose={() => {
                setModelToEdit(null);
                setLoadCounter(prevState => prevState + 1)
            }}/>
        </>

    )
}


export default BonusesTable
