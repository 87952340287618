import React from "react";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import Box from "@material-ui/core/Box";
import { animations } from "@PlaystreamENV/front-lib/src/obs/pickers/AnimationOutPicker";

const options = animations.map(language => ({text: language, id: language}));
const AnimationOutPicker = ({defaultValue}) => {
    return (
        <>
            <SingleSelect
                name={"alert_animation_exit"}
                label='Exit Animation'
                prop={"id"}
                defaultValue={defaultValue}
                options={options}
                rules={{required: 'Field is required'}}>
                {({option}) => {
                    return <Box
                        display={"flex"}
                        alignItems={"center"}
                        width={"100%"}>
                        <span>{option.text}</span>
                    </Box>
                }}
            </SingleSelect>
        </>
    )
}
export default AnimationOutPicker