import React, {useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Button, Dialog} from "@material-ui/core";
import CouponForm from "../forms/CouponForm";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";


function CreateCouponDialog({onCreated}) {
    const {handleSubmit} = useForm();
    const {couponsStore} = useStores();
    const [open, setOpen] = useState(false);
    const onSave = () => {
        handleSubmit(async (formData) => {
            await couponsStore.createCoupon({...formData, discount: 100});
            onCreated();
            closeModal();
        })();
    }

    const closeModal = () => {
        setOpen(false)
    }
    return (
        <>
            <Button
                variant='contained'
                color='primary'
                onClick={() => setOpen(true)}
            >
                Create Coupon
            </Button>
            <Dialog open={open} onClose={closeModal} maxWidth={"lg"}>
                <DialogTitle title={'Create Coupon'} onClose={closeModal}/>
                <DialogContent>
                    <CouponForm coupon={{limit: 1, discount: 100}}/>
                </DialogContent>
                <DialogActions onSave={onSave} onCancel={closeModal}/>
            </Dialog>
        </>

    )
}

export default withForm(CreateCouponDialog)
