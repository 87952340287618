import React from 'react';
import {Route, Switch} from "react-router-dom";
import AppRouter from "./AppRouter";
import {BrowserRouter as Router} from 'react-router-dom';
import {PrivateRoute} from "@PlaystreamENV/front-lib/src/auth/routing/PrivateRoute";
import LoginRoute from "./routes/auth/LoginRoute";

function RootRouter() {

    return (

        <Router>
            <Switch>
                <Route component={LoginRoute} exact path='/login'/>

                <PrivateRoute Component={AppRouter} path='/'/>
            </Switch>
        </Router>

    )

}

export default RootRouter
