import React, {useEffect} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {observer} from "mobx-react-lite";
import EventsTable from "../../../components/entities/events/table/EventsTable";

const EventsRoute = observer(({}) => {

    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Events
                    </Typography>


                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>

                <EventsTable  />


            </Box>
        </>
    )
})

export default EventsRoute
