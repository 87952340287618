import React, {useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Button, Dialog} from "@material-ui/core";
import PersonalAchievementForm from "../../forms/PersonalAchievementForm";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";


function CreatePersonalAchievementDialog({onCreated}) {
    const {handleSubmit} = useForm();
    const {achievementsStore} = useStores();
    const [open, setOpen] = useState(false);
    const onSave = () => {

        handleSubmit(async (formData) => {
            const {achievement, streamer, step} = formData
            await achievementsStore.createPersonalAchievement({
                streamer: streamer,
                achievement: achievement,
                step,
            });
            onCreated();
            closeModal();
        })();
    }
    const closeModal = () => {
        setOpen(false)
    }
    return (
        <>
            <Button
                variant='contained'
                color='primary'
                onClick={() => setOpen(true)}
            >
                Create Personal Achievement
            </Button>
            <Dialog open={open} onClose={closeModal} maxWidth={"md"} fullWidth>
                <DialogTitle title={'Create Personal Achievement'} onClose={closeModal}/>
                <DialogContent>
                    <PersonalAchievementForm/>
                </DialogContent>
                <DialogActions onSave={onSave} onCancel={closeModal}/>
            </Dialog>
        </>

    )
}

export default withForm(CreatePersonalAchievementDialog)
