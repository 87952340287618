import React, {useEffect, useState} from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmDialog from "@PlaystreamENV/front-lib/src/dialog/ConfirmDialog";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import CategoryForm from "../forms/CategoryForm";

function EditCategoryDialog({category, onClose, onSaved}) {
    const {id} = category || {}
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const {handleSubmit} = useForm();
    const {categoriesStore} = useStores();

    const onSave = () => {

        handleSubmit(async (formData) => {
            const _category = {...formData}
            await categoriesStore.editCategory({id: category.id, ..._category})
            onClose();
            onSaved && onSaved();

        })();
    }
    const onDelete = async () => {
        await categoriesStore.deleteCategory(id);
        setDeleteModalStatus(false);
        onSaved();
        onClose();
    }


    return (
        <Dialog open={!!category} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit Category'} onClose={onClose}/>
            <DialogContent>
                <CategoryForm category={category || {created_by: {}}}/>
            </DialogContent>

            <DialogActions>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div>
                        {/*<Button variant='contained' onClick={() => setDeleteModalStatus(true)}>*/}
                        {/*    Delete*/}
                        {/*</Button>*/}
                    </div>
                    <div>
                        <Button
                            style={{marginRight: '5px'}}
                            onClick={onClose}
                            variant='contained'
                            color='secondary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={onSave}
                            variant='contained'
                            color='primary'>
                            Save
                        </Button>
                    </div>
                </div>
            </DialogActions>
            <ConfirmDialog
                onConfirm={onDelete}
                open={deleteModalStatus}
                onClose={() => setDeleteModalStatus(false)}
            />
        </Dialog>
    )
}

export default withForm(EditCategoryDialog)
