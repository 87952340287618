import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import {Box, Tooltip} from "@material-ui/core";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import ReferralBonusLabel from "../labels/ReferralBonusLabel";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";
import {GiTakeMyMoney, MdEdit} from "react-icons/all";
import YesNoLabel from "../../games/labels/YesNoLabel";
import EditReferralDialog from "../dialogs/EditReferralDialog";

function ReferralsTable({defaultFilters}) {
    const {referralsStore} = useStores();
    const {source = '', id = ""} = defaultFilters || {}
    const {push} = useHistory();
    const [modelToEdit, setModelToEdit] = useState(null)
    const [loadCounter, setLoadCounter] = useState(0);

    const filtersOptions = [
        {
            type: 'text',
            name: "id",
            label: "Referral ID",
            defaultValue: id,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "source",
            label: "Source",
            defaultValue: source,
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "target",
            label: "Target",
            defaultValue: '',
            style: {width: '250px'},
            rules: {required: false},
        }
    ];
    const getReferrals = async (params) => {
        const {data} = await referralsStore.getReferrals(params)
        return {data: data.referrals, count: data.count};
    }
    return (
        <>
            <Table
                loadCounter={loadCounter}
                rowProps={{hover: true}}
                filtersOptions={filtersOptions}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        cell: ({row}) => <Box display={"flex"}>
                            <Tooltip title='Bonuses' placement="top" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => push(`/bonuses?referral=${row.id}`)}>
                                    <GiTakeMyMoney size={27}/>
                                </IconButton>
                            </Tooltip>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setModelToEdit(row)
                                    }}>
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </Box>
                    },
                    {
                        key: 'id',
                        sortable: false,
                        title: 'Referral ID',
                        cell: ({row}) => <>

                            {row.id}
                        </>
                    },
                    {
                        key: 'source',
                        sortable: false,
                        title: 'Source',
                        cell: ({row}) => <>

                            {row.source && <>
                                <span className='text-bold'>{row.source.username}</span>
                                <Box marginTop='5px'>
                                    {row.source.id}
                                </Box>
                            </>}
                        </>
                    },
                    {
                        key: 'target',
                        sortable: false,
                        title: 'Target',
                        cell: ({row}) => <>

                            {row.target && <>
                                <span className='text-bold'>{row.target.username}</span>
                                <Box marginTop='5px'>
                                    {row.target.id}
                                </Box>
                            </>}
                        </>
                    },
                    {
                        key: 'enabled',
                        title: 'Enabled',
                        cell: ({row}) => <>
                            <YesNoLabel value={row.enabled}/>
                        </>
                    },
                    {
                        key: 'bonuses',
                        sortable: false,
                        title: 'Bonuses',
                        cell: ({row}) => <>
                            <ReferralBonusLabel referral={row}/>
                        </>
                    },


                    {
                        key: 'createdAt',
                        title: 'CreatedAt',
                        cell: ({row}) => <>

                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
                loadData={getReferrals}
            />
            <EditReferralDialog referral={modelToEdit} onClose={() => {
                setModelToEdit(null);
                setLoadCounter(prevState => prevState + 1)
            }}/>
        </>


    )
}


export default ReferralsTable
