import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import InteractionCardLabel from "../labels/InteractionCardLabel";
import GuestIdLabel from "../labels/GuestIdLabel";
import MessageLabel from "../labels/MessageLabel";
import ProgressionLabel from "../labels/ProgressionLabel";
import StatusLabel from "../labels/StatusLabel";
import LevelLabel from "../labels/LevelLabel";
import YesNoLabel from "../../games/labels/YesNoLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import StreamStreamerLabel from "../../challenges/labels/StreamStreamerLabel";
import ChallengePageLabel from "../../streams/labels/ChallengePageLabel";
import StreamingPlatformLabel from "../../streams/labels/StreamingPlatformLabel";
import StreamerVideosPageLabel from "../../streams/labels/StreamerVideosPageLabel";


const currencies = {
    "USD": "$"
}
const InteractionsTable = observer(({defaultFilters = {}}) => {
        const {userId = ""} = defaultFilters;
        const {interactionsStore} = useStores();

        const filtersOptions = [
            {
                type: 'text',
                name: "streamer",
                label: "User-ID",
                defaultValue: userId,
                style: {width: '250px'},
                rules: {required: false},
            },
            {
                type: 'select',
                name: "status",
                label: "Status",
                defaultValue: '',
                style: {width: '200px'},
                rules: {required: false},
                options: [
                    {label: "Created", value: 'created'},
                    {label: "Voided", value: 'voided'},
                    {label: "Captured", value: 'captured'},
                    {label: "Refund Manual", value: 'refund-manual'},
                    {label: "Refund Expired", value: 'refund-expired'},
                    {label: "Refund end-stream", value: 'refund-end-stream'},
                ]
            },
            {
                type: 'select',
                name: "type",
                label: "Type",
                defaultValue: '',
                style: {width: '200px'},
                rules: {required: false},
                options: [
                    {label: "AI", value: 'ai'},
                    {label: "Contract", value: 'contract'},
                    {label: "Endorsement", value: 'endorsement'},
                    {label: "Super Card", value: 'digital-asset'},
                    {label: "Store Purchase", value: 'merchandise'},
                ]
            },
            {
                type: 'text',
                name: "guest_username",
                label: "Guest Name",
                defaultValue: "",
                style: {width: '250px'},
                rules: {required: false},
            }
        ];

        const getInteractions = async (params) => {
            const {data} = await interactionsStore.getInteractions(params)
            return {data: data.interactions, count: data.count};
        }

        // TODO : add email in case of digital asset
        return (
            <>
                <Table
                    rowProps={{hover: true}}
                    filtersOptions={filtersOptions}
                    loadData={getInteractions}
                    columns={[

                        {
                            key: 'id',
                            title: 'ID',

                            cell: ({row}) => <>
                                {row.id}
                            </>
                        },


                        {
                            key: 'streamer',
                            title: 'Streamer',
                            sortable: false,
                            cell: ({row}) => <>
                                <span className='text-bold'>{row.streamer.username}</span>
                                <Box mb='1'>
                                    <StreamStreamerLabel stream={row}/>
                                </Box>
                                <Box marginTop='5px'>
                                    {row.streamer.id}
                                </Box>
                            </>
                        },
                        {
                            sortable: false,
                            key: 'links',
                            title: 'Links',
                            cell: ({row}) => <>

                                <Box className={'streamer-links'}>
                                    <ChallengePageLabel username={row.streamer.username}/>

                                    <Box my={1}>
                                        <StreamingPlatformLabel username={row.streamer.streaming_platform_username}
                                                                streamingPlatform={row.streamer.streaming_platform}/>


                                    </Box>
                                    <StreamerVideosPageLabel username={row.streamer.streaming_platform_username}
                                                             streamingPlatform={row.streamer.streaming_platform}/>
                                </Box>
                            </>
                        },
                        {
                            key: 'guest_username',
                            title: 'Guest',

                            cell: ({row}) => <>
                                <GuestIdLabel interaction={row}/>
                            </>
                        },
                        {
                            key: 'card',
                            title: 'card',
                            style: {
                                width: 190
                            },
                            cell: ({row}) => <>
                                <InteractionCardLabel interaction={row}/>
                            </>
                        },
                        {
                            key: 'amount',
                            title: 'amount',

                            cell: ({row}) => <>

                                <Tooltip title={'Gross amount'} arrow placement={"top"}>
                                    <span className={'primary-color'}>
                                        <span>{currencies[row.currency]}</span>{row.gross_amount}</span>
                                </Tooltip>
                                <div>
                                    <Tooltip title={'NOE'} arrow placement={"top"}>
                                        <span className={'text-muted'}>
                                            x{row.noe}
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Tooltip title={'PPE'} arrow placement={"top"}>
                                        <span className={'text-muted'}>
                                            {currencies[row.currency]}{row.ppe}
                                        </span>
                                    </Tooltip>
                                </div>
                            </>
                        },
                        {
                            key: 'level',
                            title: 'level',
                            cell: ({row}) => <LevelLabel interaction={row}/>
                        },
                        {
                            key: 'status',
                            title: 'status',

                            cell: ({row}) => <>
                                <StatusLabel interaction={row}/>
                            </>
                        },
                        {
                            key: 'message',
                            title: 'message',

                            cell: ({row}) => <>
                                <MessageLabel interaction={row}/>
                            </>
                        },
                        {
                            key: 'email',
                            title: 'Super Card Buyer',

                            cell: ({row}) => <>
                                {row.email}
                            </>
                        },
                        {
                            key: 'sell_price',
                            title: 'Super Card Sell Price',

                            cell: ({row}) => <>
                                {row.sell_price}
                            </>
                        },

                        {
                            key: 'progression',
                            style: {
                                width: 120
                            },
                            title: 'Progression',

                            cell: ({row}) => <>
                                <ProgressionLabel interaction={row}/>
                            </>
                        },
                        {
                            key: 'stats',
                            title: 'Stats',

                            cell: ({row}) => <div style={{width: 150}}>
                                <div>
                                    Donation: <YesNoLabel value={row.is_donation}/>
                                </div>
                                <div>
                                    UPC: <YesNoLabel value={row.upc}/>
                                </div>
                                <div>
                                    Exp: {row.exp_points}
                                </div>
                            </div>
                        },
                        // {
                        //     key: 'is_donation',
                        //     title: 'Donation',
                        //
                        //     cell: ({row}) => <>
                        //         <YesNoLabel value={row.is_donation}/>
                        //     </>
                        // },
                        // {
                        //     key: 'upc',
                        //     title: 'UPC',
                        //     cell: ({row}) => <>
                        //         <YesNoLabel value={row.upc}/>
                        //     </>
                        // },
                        {
                            key: 'stream',
                            title: 'stream',

                            cell: ({row}) => <>
                                {row.stream && row.stream.id}
                                {!row.stream && <span className={'text-muted'}>Not activated</span>}
                            </>
                        },
                        // {
                        //     key: 'extras',
                        //     title: 'Meta',
                        //
                        //     cell: ({row}) => <div style={{width: 100}}>
                        //         <div>
                        //             Secret: <YesNoLabel value={row.is_secret}/>
                        //         </div>
                        //     </div>
                        // },


                        {
                            key: 'createdAt',
                            title: 'createdAt',

                            cell: ({row}) => <>
                                <DateTimeLabel value={row.createdAt}/>
                            </>
                        },
                    ]}
                />

            </>
        )
    }
)

export default InteractionsTable
