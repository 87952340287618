import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";

export class StreamsStore {

    @observable _count = 0;

    constructor() {
    }

    @observable _streams = null;

    @computed get streams() {
        return toJS(this._streams);
    }

    @action
    getStreams(params) {
        // console.log(params)
        return adminApi.get(`/streams/`, {params});

    }
}
