import React, {useEffect, useState} from "react";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import Grid from "@material-ui/core/Grid";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../../bots/forms/BotForm";
import ChipsSelect from "@PlaystreamENV/front-lib/src/form/select/chips-select/ChipsSelect";

const BotsForm = ({zone}) => {
    const [bots, setBots] = useState([]);
    const {watch} = useForm();
    const {botsStore} = useStores();
    const isBotEnabled = watch('bot_enabled');
    const getBots = async (params) => {
        const {data} = await botsStore.getBots(params)
        return setBots(data.bots)
    }
    useEffect(() => {
        getBots();
    }, [])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h5>Bot</h5>
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    name={'bot_enabled'}
                    defaultChecked={zone.bot_enabled}
                    label={"Bot enabled"}
                    validation={{}}/>
            </Grid>
            <Grid item xs={12}>
                <SingleSelect
                    label='bot'
                    name={'bot'}
                    defaultValue={zone.bot || undefined}
                    disabled={!isBotEnabled}
                    displayProp={"name"}
                    options={bots}
                />
            </Grid>
            <Grid item xs={12}>
                <FormInputText label={'Bot action interval'} defaultValue={zone.bot_action_interval_minutes}
                               name='bot_action_interval_minutes'
                               type={"number"}
                               validation={formValidation.required}/>
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    name={'bot_gg_enabled'}
                    defaultChecked={zone.bot_gg_enabled}
                    label={"Bot GG command enabled"}
                    validation={{}}/>
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    name={'bot_action_enabled'}
                    defaultChecked={zone.bot_action_enabled}
                    label={"Bot Action command enabled"}
                    validation={{}}/>
            </Grid>
            <Grid item xs={12}>
                <ChipsSelect
                    name='bot_action_triggers'
                    rules={{
                        // validate: {
                        //     not_empty: val => {
                        //         if (val && !val.length) {
                        //             return 'Please have at least one option.'
                        //         }
                        //     }
                        // }
                    }}
                    defaultValues={zone.bot_action_triggers}

                />
            </Grid>


        </Grid>
    )
}
export default BotsForm
