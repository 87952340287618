import React, {useEffect, useState} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@PlaystreamENV/front-lib/src/tabs/TabPanel";
import Box from "@material-ui/core/Box";
import {observer} from "mobx-react-lite";
import ButtonResetUserBalance from "./ButtonResetUserBalance";
import UserStats from "./UserStats";
import Grid from "@material-ui/core/Grid";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import TicketsTable from "../../tickets/table/TicketsTable";
import {BaseLoader} from "@PlaystreamENV/front-lib/src/loaders/BaseLoader";
import TransactionsTable from "../../transactions/table/TransactionsTable";
import InteractionsTable from "../../interactions/table/InteractionsTable";
import BonusesTable from "../../bonuses/table/BonusesTable";


const UsersTabs = observer(({user}) => {
    const [value, setValue] = React.useState(0);
    const [isLoadingBalance, setIsLoadingBalance] = useState(false)
    const {adminStore} = useStores();
    const {streamerStats} = adminStore;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const loadUserTabsData = () => {
        const streamer = user.id;
        adminStore.getStreamerStats({
            streamer
        })
    }

    useEffect(() => {
        if (!user) return;
        loadUserTabsData();
    }, [user]);


    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {!!streamerStats && <UserStats stats={streamerStats}/>}

                </Grid>
                <Grid item xs={12} style={{position: 'relative'}}>

                    <Box mt={3}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                        >
                            <Tab label="Orders pending withdraw" style={{minWidth: 300}}/>
                            <Tab label="Orders already withdrawn" style={{minWidth: 300}}/>
                            <Tab label="Bonuses Pending withdraw" style={{minWidth: 300}}/>
                            <Tab label="Bonuses already withdrawn" style={{minWidth: 300}}/>
                            <Tab label="Challenges"/>
                            <Tab label="Tickets"/>
                            <ButtonResetUserBalance
                                disabled={!streamerStats}
                                user={user}
                                style={{marginLeft: 'auto', alignSelf: 'center'}}
                                setIsLoadingBalance={setIsLoadingBalance}
                                onCompleted={() => loadUserTabsData()}
                            />
                        </Tabs>
                        {isLoadingBalance ? <BaseLoader center={true}/> : (
                            <>
                                <TabPanel value={value} index={0}>
                                    <TransactionsTable
                                        defaultFilters={{
                                            userId: user && user.id || "",
                                            withdrawStatus: 'no',

                                        }}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <TransactionsTable
                                        defaultFilters={{
                                            userId: user && user.id || "",
                                            withdrawStatus: 'yes',
                                            enabledStatus: true
                                        }}
                                    />
                                </TabPanel>
                            </>
                        )}
                        <TabPanel value={value} index={2}>
                            <BonusesTable defaultFilters={{
                                streamer: user && user.id,
                                withdrawStatus: 'no',
                                completionStatus: "completed",
                                enabledStatus: true
                            }}/>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <BonusesTable defaultFilters={{streamer: user && user.id, withdrawStatus: 'yes',}}/>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <InteractionsTable defaultFilters={{userId: user && user.id}}/>
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            <TicketsTable user={user}/>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>


        </>
    )
})

export default UsersTabs
