import React, {useEffect} from "react";

import {observer} from "mobx-react-lite";

import LineChart from "@PlaystreamENV/front-lib/src/charts/LineChart";
import {Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import DashboardCard from "../../dashboard/cards/DashboardCard";
import colors from "./colors";
import NoDataContainer from "./NoDataContainer";


const SnapshotBytesHistoryChart = observer(({syncId, data}) => {

    const title = 'Upload in bytes ';


    const [value, setValue] = React.useState(null);


    useEffect(() => {

        if (!data) return;
        const _data = data.map((r, index) => {
            const date = new Date(r.createdAt);
            const date_text = date.toLocaleDateString('en', {day: 'numeric', month: 'short', weekday: 'long'});
            const time_text = date.toLocaleTimeString('en', {
                hour12: false,
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            });

            return {
                createdAt: `${date_text}  ${time_text}`,
                data_bytes_size: r.data.data_bytes_size

            }
        })
        setValue(_data)

    }, [data])

    return (
        <>
            <DashboardCard title={title}>
                <NoDataContainer data={data}>
                    <ResponsiveContainer width={'100%'} height={150}>
                        <LineChart syncId={syncId} data={value} margin={{
                            top: 10, right: 10, left: -5, bottom: 10,
                        }}>
                            {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                            <XAxis hide={true} dataKey="createdAt"/>
                            <YAxis hide={false}/>
                            <Tooltip/>
                            {/*<Legend />*/}
                            <Line dataKey={'data_bytes_size'} stroke={"#EC058E"} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>

                        </LineChart>
                    </ResponsiveContainer>
                </NoDataContainer>


            </DashboardCard>

        </>
    )
})

const FramesHistoryTooltip = ({active, payload, label}) => {

    return <div>
        {label}
    </div>
}

export default React.memo(SnapshotBytesHistoryChart)
