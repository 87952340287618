import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";


const InputCheckbox = ({name, defaultChecked, label, color = "primary", ...rest}) => {

    return (
        <FormControlLabel name={name}

                          control={<Checkbox defaultChecked={defaultChecked} color={color} />}
                          label={label} {...rest} />
    )
}

export default InputCheckbox
