import React, {useState} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";


function GamePicker({onChange, value, games, direction = 'right', color = "primary", ...rest}) {


    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onItemClick = (game) => {
        handleClose();
        onChange && onChange(game)
    }

    const game = games.filter(g => {
            return g.key === value
        }
    )[0];


    return (
        <div>


            <Button color={color} onClick={handleClick} variant={'contained'} {...rest}>
                {game ? game.name : "No game selected"}
            </Button>

            <Menu
                id="user-menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: direction,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: direction,
                }}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {games.map((game) => {
                    return <MenuItem key={game.key} selected={game.key === value} disabled={!game.enabled}
                                     onClick={() => {
                                         onItemClick(game.key)
                                     }}> {game.name} </MenuItem>
                })}


            </Menu>
        </div>
    );
}

export default GamePicker
