import React from 'react';
import {IconButton, makeStyles, SnackbarContent} from '@material-ui/core';
import {IoIosClose, MdError} from "react-icons/all";
import "./base-alert.scss";

import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none'
    },
    marginTop: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing()
    },
    // success: {
    //     backgroundColor: green[600],
    // },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    // info: {
    //     backgroundColor: theme.palette.primary.dark,
    // },
    // warning: {
    //     backgroundColor: amber[700],
    // },
    icon: {
        fontSize: 25,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));


const variantIcon = {
    // success: CheckCircleIcon,
    // warning: WarningIcon,
    error: MdError,
    // info: InfoIcon,
};

function BaseAlert({variant = 'info', message = '', onClose, ...rest}) {

    const classes = useStyles();

    const Icon = variantIcon[variant];
    const actions = [];
    if (onClose) {
        actions.push(<IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
        >
            <IoIosClose className={classes.icon}/>
        </IconButton>)
    }

    return (
        <div className={'base-alert'} {...rest} >
            <SnackbarContent
                className={clsx(classes[variant], classes.marginTop, classes.root)}
                message={<div className={classes.message}>
                    <div className={'base-alert-icon'}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)}/>

                    </div>
                    <span>
                    {message}
                    </span>

                </div>}
                action={actions}
            />
        </div>
    );
}


export {BaseAlert};
