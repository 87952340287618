import React, {useRef} from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from "@material-ui/core/Button";
import {observer} from "mobx-react-lite";
import useTus from "./hooks/useTus";
import useLanguage from "../l10n/hooks/useLanguage";


const TusDropZone = ({onFileStarted, onFileCompleted, getBlob, isUploading}) => {
    const { translate } = useLanguage();

    const {progress, isFinished, upload} = useTus();

    const hiddenFileInput = useRef(null);

    const onFileChanged = async (file) => {
        console.log("TusDropZone onFileChanged", file)
        onFileStarted && await onFileStarted(file)
        const url = await upload(file)
        console.log("TusDropZone done upload", url)

        onFileCompleted && onFileCompleted(url)
    }
    const handleClick = () => {
        console.log("handle click")
        if (isUploading) return;
        if (getBlob) {

            (async () => {
                const blob = await getBlob();
                await onFileChanged(blob)
            })();

        } else {
            hiddenFileInput.current.value = null;
            hiddenFileInput.current.click();
        }

    };

    const handleChange = event => {
        console.log("files changed")
        onFileChanged(event.target.files[0])
    };

    console.log("TusDropZone render", progress, isUploading)
    return (
        <>
            <Box style={{height: "100%"}} onClick={handleClick}>

                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}
                     height={"100%"}>
                    {isUploading &&
                    <CircularProgress color={'secondary'}
                                      variant={progress === 0 || progress === 100 ? 'indeterminate' : 'static'}
                                      value={progress}/>}
                    {!isUploading && !isFinished &&
                    <Box display={"flex"} alignItems={"center"} justifyContent={'flex-start'} flexDirection={"column"}>
                        <p className={'text-center'}>
                            {translate('drop_zone_description')}
                        </p>
                        <Button variant='contained' size={'large'} color={'secondary'}
                        >
                            {translate('drop_zone_browse_button')}
                        </Button>
                    </Box>}
                    {isUploading && <p>{translate('drop_zone_loading')}</p>}

                    {/*{isFinished && <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>*/}

                    {/*    <p>Upload Finished</p>*/}
                    {/*    <Button variant='contained' size={'large'} color={'secondary'}*/}
                    {/*            onClick={handleClick}>Browse</Button>*/}

                    {/*</Box>}*/}

                </Box>
            </Box>
            <input
                type="file"
                accept=".png,.jpg"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{display: 'none'}}/>
        </>

    )
}


export default TusDropZone
