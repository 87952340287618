import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog} from "@material-ui/core";
import PersonalAchievementForm from "../../forms/PersonalAchievementForm";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";


function EditPersonalAchievementDialog({personalAchievement, onSaved, onClose}) {

    const {handleSubmit} = useForm();
    const {achievementsStore} = useStores();
    const onSave = () => {
        handleSubmit(async (formData) => {

            await achievementsStore.editPersonalAchievement({id: personalAchievement.id, ...formData});
            onSaved();
            onClose()
        })();
    }
    return (
        <Dialog open={!!personalAchievement} onClose={onClose} maxWidth={"md"} fullWidth>
            <DialogTitle title={'Edit Achievement'} onClose={onClose}/>
            {personalAchievement && <DialogContent>
                <PersonalAchievementForm
                    personalAchievement={personalAchievement}
                />
            </DialogContent>}
            <DialogActions onSave={onSave} onCancel={onClose}/>
        </Dialog>
    )
}

export default withForm(EditPersonalAchievementDialog)
