import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";

const StatIconWidgetCard = ({title, icon, value}) => {

    const name = "stats_" + title.replaceAll(" ", "_").toLowerCase();
    return (
        <Card>
            <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <Box display={'flex'} alignItems={'center'}>

                    <Box mr={2}>{icon}</Box>
                    <Box style={{fontSize: 34}} name={name}>{value}</Box>


                </Box>
                <Box className={'text-bold'}>{title}</Box>
            </Box>
        </Card>
    )
}

export default StatIconWidgetCard
