import React from "react";
import {useForm} from "./context";
import {FormInputErrorMessage} from "./FormInputErrorMessage";
import InputSelect from "./inputs/InputSelect";

function FormSelect({children, name, validation, disabled, ...rest}) {
    const {errors, register, formState} = useForm();
    return (
        <>
            <InputSelect  name={name} {...rest} inputRef={register(validation)}
                         disabled={disabled || formState.isSubmitting}>
                {children}
            </InputSelect>
            <FormInputErrorMessage name={name}/>
        </>
    )
}

export default FormSelect