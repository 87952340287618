import React from "react";
import ReactJson from "react-json-view";

const MetaDataLabel = ({value}) => {
    const {metadata} = value;
    return (
        <>
            <ReactJson theme={'threezerotwofour'} displayDataTypes={false} enableClipboard={false}
                       displayObjectSize={false} quotesOnKeys={false} displayDataTypes={false} name={null}
                       src={metadata || {}}/>
        </>
    )

}

export default MetaDataLabel