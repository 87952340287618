import React from "react";

import {
    LineChart as RechartsLineChart,
    ResponsiveContainer,
    Tooltip,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    Legend
} from 'recharts';
import {useTheme} from "@material-ui/core";

function LineChart({
                       data, children, ...rest
                   }) {


    // const theme = useTheme()
    // const wrapperStyle = theme.palette.type !== "dark" ? {backgroundColor: '#222'} : {backgroundColor: '#fff'};
    // const cursorStyle = theme.palette.type !== "dark" ? {fill: '#red'} : {fill: '#000'};

    return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
            <RechartsLineChart

                data={data}
                margin={{
                    top: 5, right: 30, left: 0, bottom: 5,
                }}
                {...rest}
            >
                {children}

            </RechartsLineChart>
        </ResponsiveContainer>
    )
}

export default React.memo(LineChart)