import React, {useEffect, useState} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BotsTable from "../../../components/entities/bots/table/BotsTable";
import CreateBotDialog from "../../../components/entities/bots/dialogs/CreateBotDialog";

const BotsRoute = ({}) => {

    const [lastCreated, setLastCreated] = useState(new Date());
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Bots
                    </Typography>

                    <CreateBotDialog onCreated={() => {
                        setLastCreated(new Date())
                    }}/>

                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>
                <BotsTable lastCreated={lastCreated}/>
            </Box>
        </>
    )
}

export default BotsRoute
