import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import YesNoLabel from "../../games/labels/YesNoLabel";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {MdEdit} from "react-icons/all";
import EditPersonalAchievementDialog from "../dialogs/edit/EditPersonalAchievementDialog";
import {Button} from "@material-ui/core";
import CreatePersonalAchievementDialog from "../dialogs/create/CreatePersonalAchievementDialog";

const PersonalAchievementsTable = observer(({defaultFilters, lastCreated = new Date()}) => {
    const {achievementsStore} = useStores();
    const [rowToEdit, setRowToEdit] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(new Date());

    const {userId = ''} = defaultFilters || {}
    const filtersOptions = [
        {
            type: 'text',
            name: "streamer",
            label: "streamer",
            defaultValue: userId,
            style: {width: '250px'},
            rules: {required: false},
        },

    ];

    const getAchievements = async (params) => {
        const {data} = await achievementsStore.getPersonalAchievements(params)
        return {data: data.achievements, count: data.count};
    }


    return (
        <>
            <Table
                rowProps={{hover: true}}
                filtersOptions={filtersOptions}
                loadCounter={lastUpdated.getTime() + lastCreated.getTime()}
                loadData={getAchievements}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setRowToEdit(row)
                                    }}
                                >
                                    <MdEdit/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',

                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        key: 'streamer',
                        title: 'streamer',

                        cell: ({row}) => <>
                            {row.streamer && row.streamer.email}
                        </>
                    },
                    {
                        key: 'name',
                        title: 'name',

                        cell: ({row}) => <>
                            {row.achievement.name}
                        </>
                    },
                    {
                        key: 'description',
                        title: 'description',

                        cell: ({row}) => <>
                            {row.achievement.description}
                        </>
                    },
                    {
                        key: 'exp',
                        title: 'exp',

                        cell: ({row}) => <>
                            {row.achievement.exp}
                        </>
                    },
                    {
                        key: 'steps',
                        title: 'steps',

                        cell: ({row}) => <>
                            {row.step}/{row.achievement.steps_to_complete}
                        </>
                    },

                    {
                        key: 'createdAt',
                        title: 'createdAt',

                        cell: ({row}) => <>
                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />
            <EditPersonalAchievementDialog
                personalAchievement={rowToEdit}
                onSaved={() => {
                    setLastUpdated(new Date());
                }}
                onClose={() => {
                    setRowToEdit(null)
                }}
            />

        </>
    )
})

export default PersonalAchievementsTable
