import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {BsChatDots, MdEdit} from "react-icons/all";
import EditBotDialog from "../dialogs/EditBotDialog";
import YesNoLabel from "../../games/labels/YesNoLabel";
import BotChatTerminalDialog from "../dialogs/BotChatTerminalDialog";

const BotsTable = observer(({lastCreated}) => {
    const {botsStore} = useStores();
    const [rowToEdit, setRowToEdit] = useState(null);
    const [botToSendMessage, setBotToSendMessage] = useState(null);

    const [lastUpdated, setLastUpdated] = useState(new Date());
    const filtersOptions = [];

    const getBots = async (params) => {
        const {data} = await botsStore.getBots(params)

        return {data: data.bots, count: data.count};
    }
    return (
        <>
            <Table
                rowProps={{hover: true}}
                loadCounter={lastUpdated.getTime() + lastCreated.getTime()}
                filtersOptions={filtersOptions}
                loadData={getBots}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setRowToEdit(row)
                                    }}
                                >
                                    <MdEdit/>
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setBotToSendMessage(row)
                                    }}
                                >
                                    <BsChatDots size={30}/>
                                </IconButton>
                            </Box>
                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',

                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        key: 'name',
                        title: 'Name',

                        cell: ({row}) => <>
                            {row.name}
                        </>
                    },
                    {
                        key: 'key',
                        title: 'Key',

                        cell: ({row}) => <>
                            {row.key}
                        </>
                    },
                    {
                        key: 'platform',
                        title: 'Platform',

                        cell: ({row}) => <>
                            {row.platform}
                        </>
                    },
                    {
                        key: 'enabled',
                        title: 'enabled',
                        cell: ({row}) => <>
                            <YesNoLabel value={row.enabled}/>
                        </>
                    },
                    // {
                    //     key: 'streamer',
                    //     title: 'Streamer',
                    //
                    //     cell: ({row}) => <>
                    //         {row.streamer.username}
                    //     </>
                    // },
                    {
                        key: 'createdAt',
                        title: 'createdAt',

                        cell: ({row}) => <>
                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />

            <EditBotDialog bot={rowToEdit}
                           onClose={async () => {
                               setRowToEdit(null)
                           }}
                           onSaved={() => {
                               setLastUpdated(new Date())
                           }}
            />

            <BotChatTerminalDialog bot={botToSendMessage}
                                   onClose={async () => {
                                       setBotToSendMessage(null)
                                   }}
            />
        </>
    )
})

export default BotsTable
