import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import Box from "@material-ui/core/Box";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import ChallengeExpirationPicker from "../ChallengeExpirationPicker";
import useLanguage from "@PlaystreamENV/front-lib/src/l10n/hooks/useLanguage";

const CaMetaForm = observer(({metadata}) => {
    const {watch, setValue, getValues} = useForm()
    const game = watch('metadata.game', metadata.game);
    const {gamesStore, eventsStore} = useStores();
    // const {games} = gamesStore;
    // const {events} = eventsStore;
    const {translate} = useLanguage();

    //hacky solution for values gone on change
    const [games] = useState(gamesStore.games.filter(g => g.enabled && eventsStore.events.some(e => e.game.key === g.key && e.enabled)).map(g => {
        return {id: g.key, name: g.name}
    }))

    const events = eventsStore.events.filter(e => e.game.key === game && e.enabled).map(e => {

        const single = translate(`${e.game.key}_${e.key}_single`)

        return {id: e.key, single}
    })
    useEffect(() => {
        const event = getValues("metadata.event")
        console.log("game", game, "event", event)

        const isEventBelong = game && event && events.some(e => e.id === event);
        if (!isEventBelong) {
            console.log("reset")
            setValue('metadata.event', "")
            // setEvents(eventsStore.events.filter(e => e.game.key === game).map(e => {
            //     return {id: e.key}
            // }))
        }

    }, [game])


    // console.log("CaMetaForm", game)
    return (
        <>
            {/*<Grid item xs={12}>*/}
            {/*    <FormInputText*/}
            {/*        label={'Step to complete'}*/}
            {/*        type={"number"}*/}
            {/*        defaultValue={metadata.steps_to_complete}*/}
            {/*        name='metadata.steps_to_complete'*/}
            {/*        validation={formValidation.required}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
                <SingleSelect
                    name={"metadata.game"}
                    label={"Game"}
                    prop={"id"}
                    defaultValue={metadata.game}
                    options={games}
                    rules={{required: 'Field is required'}}>
                    {({option}) => {
                        return <Box
                            display={"flex"}
                            alignItems={"center"}
                            width={"100%"}
                            justifyContent={"space-between"}>
                            <span>{option.name}</span>
                        </Box>
                    }}
                </SingleSelect>


            </Grid>
            <Grid item xs={12}>
                <SingleSelect
                    name={"metadata.event"}
                    key={`${game}`}
                    label={"Event"}
                    disabled={!game}
                    prop={"id"}
                    options={events}
                    defaultValue={metadata.event}
                    rules={{required: 'Field is required'}}>
                    {({option}) => {
                        return <Box
                            display={"flex"}
                            alignItems={"center"}
                            width={"100%"}
                            justifyContent={"space-between"}>
                            <span>{option.single}</span>
                        </Box>
                    }}
                </SingleSelect>
            </Grid>
            <ChallengeExpirationPicker
                metadata={metadata}
                type='ai'
            />

        </>
    )
})


export default CaMetaForm
