import React from "react";
import './pickers.scss';
import SingleSelect from "../form/select/single-select/SingleSelect";
import facebookGaming from '../assets/images/streaming-platforms/FB platform.png';
import youtubeGaming from '../assets/images/streaming-platforms/YT platform.png';
import twitch from '../assets/images/streaming-platforms/Twitch Platform.png';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {FaGhost} from "react-icons/all";
import useLanguage from "../l10n/hooks/useLanguage";


const platformsItems = {
    'facebook': <div className='social-platform-picker'>
        <ListItemIcon className='social-platform-picker__icon'>
            <img src={facebookGaming} alt='streaming platform facebook'/>
        </ListItemIcon>
        <span>Facebook</span>
    </div>,
    'youtube': <div className='social-platform-picker'>
        <ListItemIcon className='social-platform-picker__icon'>
            <img src={youtubeGaming} alt='streaming platform youtube'/>
        </ListItemIcon>
        <span>Youtube</span>
    </div>,
    'twitch': <div className='social-platform-picker'>
        <ListItemIcon className='social-platform-picker__icon'>
            <img src={twitch} alt='streaming platform twitch'/>
        </ListItemIcon>
        <span>Twitch</span>
    </div>,
    'other': <div className='social-platform-picker'>
        <ListItemIcon className='social-platform-picker__icon'>
            <FaGhost style={{fontSize: '25px'}}/>
        </ListItemIcon>
        <span>Other</span>
    </div>
}

const platformOptions = [
    {id: 'facebook'},
    {id: 'youtube'},
    {id: 'twitch'},
    {id: 'other'},
]
const SocialPlatformPicker = ({defaultValue}) => {
    const { translate } = useLanguage();
    return (
        <>
            <SingleSelect
                label={translate('streaming_platform_dropdown_label')}
                defaultValue={defaultValue}
                name='streaming_platform'
                prop='id'
                options={platformOptions}
            >
                {({option}) => (
                    <>
                        {platformsItems[option.id]}
                    </>
                )}
            </SingleSelect>
        </>
    )
}

export default SocialPlatformPicker
