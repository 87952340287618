import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import UsersAutocomplete from "../../../components/entities/users/forms/UsersAutocomplete";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import Button from "@material-ui/core/Button";
import {MdEdit} from "react-icons/all";

const TestRoute = ({}) => {
    const {handleSubmit} = useForm();
    const save = () => {
        handleSubmit(async (formData) => {

        })();
    };
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Test Route
                    </Typography>


                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>

                <form>
                    <UsersAutocomplete  />

                </form>

                <Box mt={2}>
                    <Button size='small' variant='contained' color='secondary'
                            startIcon={<MdEdit/>}
                            onClick={save}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default withForm(TestRoute)
