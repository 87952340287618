import React from "react";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import DialogTitle from "@PlaystreamENV/front-lib/src/dialog/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@PlaystreamENV/front-lib/src/dialog/DialogActions";
import {Dialog} from "@material-ui/core";
import BonusForm from "../forms/BonusForm";


function EditBonusDialog({bonus, onClose}) {
    const {handleSubmit} = useForm();
    const {referralsStore} = useStores();
    const onSave = () => {

        handleSubmit(async (formData) => {
            await referralsStore.editBonusModel({id: bonus.id, ...formData})
            onClose();
        })();
    }
    return (
        <Dialog open={!!bonus} onClose={onClose} maxWidth={"lg"}>
            <DialogTitle title={'Edit Bonus'} onClose={onClose}/>
            {bonus && <DialogContent>
                <BonusForm bonus={bonus}/>
            </DialogContent>}
            <DialogActions onSave={onSave} onCancel={onClose}/>
        </Dialog>
    )
}

export default withForm(EditBonusDialog)
