import React from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../entities/zones/panels/ZonePanels";

const RarityForm = ({rarityModel}) => {
    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <h3>Rarity multiplier</h3>
                </Grid>
                <Grid item sm={12}>
                    <form>

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <FormInputText label={'Common'} defaultValue={rarityModel.common_multiplier} type={"number"}
                                               name='common_multiplier'
                                               validation={formValidation.required}/>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'Rare'} defaultValue={rarityModel.rare_multiplier} type={"number"}
                                               name='rare_multiplier'
                                               validation={formValidation.required}/>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'Epic'} defaultValue={rarityModel.epic_multiplier} type={"number"}
                                               name='epic_multiplier'
                                               validation={formValidation.required}/>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'Legendary'} defaultValue={rarityModel.legendary_multiplier} type={"number"}
                                               name='legendary_multiplier'
                                               validation={formValidation.required}/>
                            </Grid>


                        </Grid>
                    </form>

                </Grid>


            </Grid>
        </>
    )

}

export default RarityForm
