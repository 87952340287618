import React from "react";
import {Card as MdCard, CardContent, CardHeader, Box} from '@material-ui/core';
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import './cards.scss';

function Card({children, avatar, title, subheader, action, style, color, size = "small", className}) {

    const theme = useTheme();
    const cardContent = "cardContent_" + size;
    return (
        <MdCard style={style} classes={{
            root: clsx(`base-card card-${color}`, className, 'base-card'),


        }}>

            {(title || avatar || action) &&
            <>
                <Box display={'flex'} alignItems={'center'} flex={1}>
                    <CardHeader avatar={avatar} classes={{}} titleTypographyProps={{variant: 'body2'}} title={title}
                                action={action} subheader={subheader}
                                flex={1}/>


                </Box>
                <Divider light
                         style={{  height: 1, backgroundColor: "#eee", margin: "0 5px"}}/>
            </>


            }
            <CardContent className={'base-card-content'} classes={{}}>
                {children}
            </CardContent>
        </MdCard>
    )
}

export default Card
