import React from "react";
import './zone-panel.scss';
import Tabs from "../tabs/Tabs";
import DetailsForm from "../forms/DetailsForm";
import TTSForm from "../forms/TTSForm";
import AlertForm from "../forms/AlertForm";
import CategoriesForm from "../forms/CategoriesForm";
import BrandForm from "../forms/BrandForm";
import BotsForm from "../forms/BotsForm";
import {useForm} from '@PlaystreamENV/front-lib/src/form/context/'
import GoalsForm from "../forms/GoalsForm";
import DeadlineForm from "../forms/DeadlineForm";
import PollForm from "../forms/PollForm";

const translations = {
    required_field: "Field is required",
}
export const formValidation = {

    not_empty: {
        validate: value => value.length > 0 || translations.required_field
    },
    required: {
        required: translations.required_field
    },
    enabled: {
        // required: translations.required_field
    },

};

const ZonePanels = ({zone,onSaved}) => {

    console.log(zone);
    return (
        <form>
            <Tabs
                labels={[
                    {text: 'Details'},
                    {text: 'Text To Speech'},
                    {text: 'Alerts'},
                    {text: 'Categories'},
                    {text: "Brand"},
                    {text: "Goals"},
                    {text: "Poll"},
                    {text: "Deadline"},
                    {text: "Bot"}
                ]}
                panels={[
                    <DetailsForm zone={zone}/>,
                    <TTSForm zone={zone}/>,
                    <AlertForm zone={zone}/>,
                    <CategoriesForm zone={zone} onSaved={onSaved}/>,
                    <BrandForm brand={zone.brand || {}} merch_store_enabled={zone.merch_store_enabled} merch_store_url={zone.merch_store_url}/>,
                    <GoalsForm  zone={zone}/>,
                    <PollForm zone={zone} />,
                    <DeadlineForm zone={zone} />,
                    <BotsForm zone={zone}/>
                ]}
            />
        </form>
    )
}

export default ZonePanels


