import {Redirect, Route} from "react-router-dom";
import React from "react";
import {useUser} from "../role/VisibleByRole";
import {observer} from "mobx-react-lite";

export const PrivateRoute = observer(({Component, ...rest}) => {
    const user = useUser();
    return (
        <Route {...rest} render={props => !!user ? (<Component {...props}/>) :
            (<Redirect to={{pathname: '/login', state: {from: props.location.pathname}}}/>)
        }/>
    )

})

