import {action, computed, observable, toJS} from 'mobx'
import adminApi from "../../../services/adminApi";

export class TicketsStore {


    @observable  _tickets = null;

    @computed get tickets() {
        return toJS(this._tickets)
    }

    @action
    getTickets(params) {
        return adminApi.get('/tickets', {params})
    }

    @action
    editTicket(ticket) {
        return adminApi.patch(`/tickets/${ticket.id}`, {ticket});
    }


}
