import React from "react";
import {FiLogOut} from "react-icons/all";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {ListItemIcon, ListItemText, MenuItem} from "@material-ui/core";

const LogoutMenuItem = ({onClick}, ref) => {

    const {authStore, clearStores} = useStores();

    const logout = async () => {
        onClick();
        console.log("logout");
        clearStores();
        authStore.doLogout();

    }

    return (
        <MenuItem ref={ref} onClick={logout} aria-disabled='true'>
            <ListItemIcon style={{minWidth: 36}}>
                <FiLogOut/>
            </ListItemIcon>
            <ListItemText primary={'Logout'}/>
        </MenuItem>
    )
}

export default React.forwardRef(LogoutMenuItem)
