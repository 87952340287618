import React, {useEffect, useState} from "react";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import Box from "@material-ui/core/Box";

import "./live-stream-panel.scss";
import Button from "@material-ui/core/Button";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import {useForm, withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {Toaster} from "@PlaystreamENV/front-lib/src/toast/toaster";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import Grid from "@material-ui/core/Grid";
import ButtonWithConfirm from "../entities/users/forms/ButtonWithConfirm";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {formValidation} from "../entities/zones/panels/ZonePanels";


const options = [
    {id: 'chunk'},
    {id: 'slow'},
    {id: 'hard'},
    {id: 'crazy'},
    {id: 'little'},
    {id: 'horizontal'},
    {id: 'vertical'},
    {id: 'rotate'},
    {id: 'opacity'},
];
const OverlayActionForm = observer(({streamer}) => {

        const {adminStore} = useStores();

        const {handleSubmit} = useForm();
        const sendShake = () => {
            handleSubmit(async (formData) => {
                await adminStore.pushOverlayAction("shake", streamer, {shake_animation: formData.shake_animation});
                Toaster.create(`Overlay action sent`, {autoClose: true})
            })();
        };
        const showScreenSaver = () => {
            handleSubmit(async (formData) => {
                await adminStore.pushOverlayAction("show-logo-saver", streamer, {seconds: formData.seconds});
                Toaster.create(`Overlay action sent`, {autoClose: true})
            })();
        };
        const showGoodGame = () => {
            handleSubmit(async (formData) => {
                await adminStore.pushOverlayAction("show-gg-saver", streamer, {amount: formData.amount});
                Toaster.create(`Overlay gg action sent`, {autoClose: true})
            })();
        };


        return (
            <Box className={''}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h3>Logo animation actions</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <SingleSelect
                                    options={options}
                                    defaultValue={'hard'}
                                    label={'Shake'}
                                    name='shake_animation'
                                    prop='id'
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <ButtonWithConfirm size={"small"} color={'secondary'}

                                                   onConfirm={sendShake}> Shake</ButtonWithConfirm>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGlobalErrorMessage/>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h3>Screen saver</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Seconds'} defaultValue={5}

                                               name='seconds'
                                               type={"number"}
                                               validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>

                                <ButtonWithConfirm size={"small"} color={'secondary'}

                                                   onConfirm={showScreenSaver}> animate</ButtonWithConfirm>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGlobalErrorMessage/>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h3>GG</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInputText label={'Amount'} defaultValue={25}

                                               name='amount'
                                               type={"number"}
                                               validation={formValidation.required}/>
                            </Grid>
                            <Grid item xs={12}>

                                <ButtonWithConfirm size={"small"} color={'secondary'}

                                                   onConfirm={showGoodGame}> Show GG</ButtonWithConfirm>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGlobalErrorMessage/>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
        )

    }
)

export default React.memo(withForm(OverlayActionForm))
