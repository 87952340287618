import React from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({

        default: {
            color: 'white',
            background: theme.palette.background.paper
        },
        primary: {
            color: 'white',
            background: theme.palette.primary.dark
        },
        secondary: {
            color: 'white',
            background: theme.palette.secondary.dark
        },

        white: {
            // color: 'white',
            background: "white"
        },
    })
);

function PageTitleNavBar({children, color = 'primary'}) {

    const classes = useStyles();

    return (
        <AppBar elevation={0} classes={{

            colorDefault: classes[color]
        }} color='default' position='static'>
            <Toolbar>
                {children}
            </Toolbar>
        </AppBar>
    )
}

export {PageTitleNavBar}
