import React, {useEffect} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";


const GamesTabs = observer(({selectedGame, onChange}) => {

    const {gamesStore} = useStores();
    const {games} = gamesStore;
    return (
        <>

            <Grid container spacing={2}>

                <Grid item xs={12} style={{position: 'relative'}}>

                    <Box mt={3}>
                        <Tabs value={selectedGame} onChange={(event, value) => {
                            onChange(value)
                        }} aria-label="simple tabs example">


                            {games.filter(g => g.enabled).map((game) => {
                                return <Tab key={game.id} value={game.key} label={game.key}/>
                            })}


                        </Tabs>

                        {/*<TabPanel>*/}
                        {/*    aaa*/}

                        {/*</TabPanel>*/}


                    </Box>
                </Grid>
            </Grid>


        </>
    )
})

export default GamesTabs
