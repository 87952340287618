import React from "react";
import {Tooltip} from "@material-ui/core";

const ContractStatusLabel = ({interaction}) => {
    const {progression, card} = interaction;
    const {step, steps_to_complete} = (progression || {});
    return (
        <>
            {!!steps_to_complete && <span className='status__progress'>{step}/{steps_to_complete}</span>}
            {!steps_to_complete &&
            <Tooltip title={"Only live challenges will show updated data"} arrow placement={"top"}>
                <span className={'text-muted'}>No live data</span>
            </Tooltip>
            }
        </>
    )

}

export default ContractStatusLabel