import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";


const StatsIconWithText = ({count, icon, text, color}) => {

    return (<Box style={{minWidth: 90}} display={'flex'} alignItems={'center'} justifyContent={'center'}
                 flexDirection={'column'}>
        <span style={{fontSize: 45}}>{icon}</span>

        <span style={{fontSize: 20, fontWeight: 'bold',  }}>{count}</span>
        <span style={{    }}>{text}</span>
    </Box>)
}

export default StatsIconWithText
