import React from "react";
import {Chip} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";

const StatusChip = styled(Chip)(({status}) => {
    let bgColor;
    let color = "#363636";
    if (status === "forced-close") {
        bgColor = "#e46666";
    }
    if (status === "completed") {
        bgColor = "#70e270";
    }
    if (status === "online") {
        bgColor = "#38b0ff";
        color = "#fff";
    }
    return {
        backgroundColor: `${bgColor} !important`,
        color,
        fontWeight: 'bold',
        minWidth: "150px",
    };
});
const StreamStatusLabel = ({status}) => {
    return <StatusChip label={status} status={status}/>;
};

export default StreamStatusLabel;
