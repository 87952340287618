import React from "react";

const challengeURL = process.env.REACT_APP_HOME_PAGE_URL + "challenge/";

const ChallengePageLabel = ({username}) => {
    return (
        <a className={'secondary-color'} target={"_blank"}
           href={challengeURL + username}>Challenge page </a>
    )
}

export default ChallengePageLabel
