import React, {useState} from "react";
import PersonalAchievementsTable from "../table/PersonalAchievementsTable";
import CreatePersonalAchievementDialog from "../dialogs/create/CreatePersonalAchievementDialog";

const PersonalAchievementsPanel = ({defaultFilters}) => {

    const [lastCreated, setLastCreated] = useState(new Date());
    return (
        <>
            <CreatePersonalAchievementDialog
                onCreated={() => {
                    setLastCreated(new Date())
                }}
            />
            <PersonalAchievementsTable defaultFilters={defaultFilters} lastCreated={lastCreated}/>

        </>
    )
}
export default PersonalAchievementsPanel