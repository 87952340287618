import React, {useState} from "react";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import YesNoLabel from "../../games/labels/YesNoLabel";
import StreamersLabel from "../../cards/labels/StreamersLabel";
import CardsLabel from "../labels/CardsLabel";
import EditCategoryDialog from "../dialogs/EditCategoryDialog";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {FiCreditCard, MdEdit} from "react-icons/all";
import CategoryCommunityLabel from "../labels/CategoryCommunityLabel";
import CardsCategoryViewerDialog from "../dialogs/CardsCategoryViewerDialog";
import {Tooltip} from "@material-ui/core";

const CategoriesTable = observer(({lastCreated}) => {
    const {categoriesStore} = useStores();
    const [categoryCards, setCategoryCards] = useState([]);
    const [rowToEdit, setRowToEdit] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(new Date());

    const filtersOptions = [
        {
            type: 'text',
            name: "name",
            label: "Category Name",
            defaultValue: "",
            style: {width: '250px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "published",
            label: "Publish",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Published", value: true},
                {label: "Not Published", value: false},
            ]
        },
    ];

    const getCategories = async (params) => {
        const {data} = await categoriesStore.getCategories(params)

        return {data: data.categories, count: data.count};
    }
    return (
        <>
            <Table
                rowProps={{hover: true}}
                filtersOptions={filtersOptions}
                loadCounter={lastUpdated.getTime() + lastCreated.getTime()}
                loadData={getCategories}
                columns={[
                    {
                        sortable: false,
                        title: "Actions",
                        style: {
                            width: 70,
                        },
                        cell: ({row}) => <>
                            <Box display='flex' alignItems='center'>
                                <Tooltip title='Edit Category' arrow placement='top'>
                                    <IconButton
                                        color="inherit"
                                        edge="start"
                                        onClick={() => setRowToEdit(row)}
                                    >
                                        <MdEdit/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Category Cards' arrow placement='top'>
                                    <span>
                                        <IconButton
                                            disabled={!row.cards.length}
                                            color="inherit"
                                            edge="start"
                                            onClick={() => setCategoryCards(row.cards)}
                                        >
                                            <FiCreditCard/>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>

                        </>
                    },
                    {
                        key: 'id',
                        title: 'ID',

                        cell: ({row}) => <>
                            {row.id}
                        </>
                    },
                    {
                        key: 'name',
                        title: 'name',

                        cell: ({row}) => <>
                            {row.name}
                        </>
                    },
                    {
                        key: 'description',
                        title: 'description',
                        cell: ({row}) => <div style={{maxWidth: '250px'}}>
                            {row.description}
                        </div>
                    },
                    {
                        key: 'published',
                        title: 'published',

                        cell: ({row}) => <>
                            <YesNoLabel value={row.published}/>
                        </>
                    },
                    {
                        key: 'community',
                        title: 'community',

                        cell: ({row}) => <>
                            <CategoryCommunityLabel category={row}/>
                        </>
                    },
                    {
                        key: 'cards',
                        title: 'cards',
                        sortable: false,
                        cell: ({row}) => <Tooltip title={`Number of cards in category`} arrow placement='top'>
                            <span>
                            {row.cards.length || "Empty"}
                            </span>
                        </Tooltip>

                    },
                    // {
                    //     key: 'streamers',
                    //     title: 'streamers',
                    //
                    //     cell: ({row}) => <>
                    //         <StreamersLabel streamers={row.streamers}/>
                    //     </>
                    // },
                    {
                        key: 'created_by',
                        title: 'created_by',
                        sortable: false,
                        cell: ({row}) => <>
                            {row.created_by && row.created_by.email}
                        </>
                    },
                    {
                        key: 'createdAt',
                        title: 'createdAt',

                        cell: ({row}) => <>
                            <DateTimeLabel value={row.createdAt}/>
                        </>
                    },
                ]}
            />
            <EditCategoryDialog
                category={rowToEdit}
                onClose={async () => setRowToEdit(null)}
                onSaved={() => setLastUpdated(new Date())}/>
            <CardsCategoryViewerDialog
                cards={categoryCards}
                onClose={() => setCategoryCards([])}
            />
        </>
    )
})

export default CategoriesTable
