import React from "react";
import {useStores} from "../../mbox/hooks/useStores";
import {getRoleByKey} from "./roles";

export const VisibleByRole = ({role, children}) => {

    if (useByRole(role)) {
        return children;
    }

    return null;
};

export const useUser = () => {
    const {authStore} = useStores();
    const {user} = authStore;
    return user;
};

export const useProfile = () => {
    const {authStore} = useStores();
    const {profile} = authStore;
    return profile;
};

export const useByRole = (roleKey) => {
    const user = useUser();
    const roleNumberToMatch = getRoleByKey(roleKey).value;
    const userRoleNumber = !!user && getRoleByKey(user.role).value;
    // console.log("roleNumber", roleNumberToMatch, userRoleNumber)
    return userRoleNumber >= roleNumberToMatch;
};