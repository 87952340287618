import React, {useState} from "react";
import AchievementsTable from "../table/AchievementsTable";
import {Button} from "@material-ui/core";
import CreateAchievementDialog from "../dialogs/create/CreateAchievementDialog";

const AchievementsPanel = ({}) => {
    const [lastCreated, setLastCreated] = useState(new Date());
    return (
        <>
            <CreateAchievementDialog

                onCreated={() => {
                    setLastCreated(new Date())
                }}
            />


            <AchievementsTable
                lastCreated={lastCreated}
            />


        </>
    )
}
export default AchievementsPanel