import {useContext} from 'react'
import {RootStoreContext} from '../provider/RootStoreProvider';


export const useStores = () => {

    const [context, setContext] = useContext(RootStoreContext);


    const clearStores = () => {

        localStorage.removeItem('user');
        setContext((prevState) => {
            return {...prevState, rootStore: context.getRootStore()}
        })

    };

    return {...context.rootStore, clearStores};
};

