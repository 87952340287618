import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import Typography from "@material-ui/core/Typography";
import {withForm} from "@PlaystreamENV/front-lib/src/form/context";
import {CirclePicker} from 'react-color';
import ColorPicker from "./ColorPicker";


const translations = {
    required_field: "Field is required",
}
export const formValidation = {
    required_field: {
        required: translations.required_field
    },
};

function CustomizationThemeColorsForm({customizationModel}) {
    const [theme, setTheme] = useState({
        primaryColor : "#03a9f4",
        secondaryColor :"#009688"
    });
    const onPrimaryColorChanged = ({hex})=>{
        setTheme({...theme, primaryColor: hex})
    }

    const onSecondaryColorChanged = ({hex})=>{
        setTheme({...theme, secondaryColor: hex})

    }

    const {primaryColor, secondaryColor} = theme;
    return (
        <Box>
            <form>

                <Grid container spacing={4}>



                    {/*<Grid item xs={6}>*/}
                    {/*    <Box mb={2}>*/}
                    {/*        <Typography variant="body2">*/}
                    {/*            Fill the following area with your age.*/}
                    {/*        </Typography>*/}
                    {/*    </Box>*/}
                    {/*    <FormInputText label={'Age'} defaultValue={customizationModel.age}*/}
                    {/*                   name='age'*/}
                    {/*                   variant={'outlined'}*/}
                    {/*                   validation={formValidation.required_field}/>*/}
                    {/*</Grid>*/}


                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography variant="body2">
                                Primary color of your personal brand.
                            </Typography>
                        </Box>
                        <ColorPicker color={primaryColor}  label={"Primary Color"}  onChangeComplete={onPrimaryColorChanged}/>
                    </Grid>


                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography variant="body2">
                                Secondary color of your personal brand.
                            </Typography>
                        </Box>
                        <ColorPicker color={secondaryColor}  label={"Secondary Color"}  onChangeComplete={onSecondaryColorChanged}/>
                    </Grid>


                </Grid>

                <FormGlobalErrorMessage/>
            </form>
        </Box>

    )
}

export default withForm(CustomizationThemeColorsForm)
