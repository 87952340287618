import React, {useEffect} from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {observer} from "mobx-react-lite";
import GamesTable from "../../../components/entities/games/table/GamesTable";

const GamesRoute = observer(({}) => {

    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Games
                    </Typography>


                </Box>

            </PageTitleNavBar>

            <Box mt={4} px={3}>

                <GamesTable  />


            </Box>
        </>
    )
})

export default GamesRoute
