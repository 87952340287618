import React, {useState} from "react";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import Grid from "@material-ui/core/Grid";
import {ClickAwayListener} from "@material-ui/core";
import {SketchPicker} from "react-color";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";


const BrandForm = ({brand,merch_store_enabled,merch_store_url}) => {
    const {primary, secondary} = brand;

    return (
        <>
            <Grid item xs={12}>
                <h5>Brand</h5>
            </Grid>
            <Grid item xs={12}>
                <ColorBox name='primary' defaultValue={primary}/>
            </Grid>
            <Grid item xs={12}>
                <ColorBox name='secondary' defaultValue={secondary}/>
            </Grid>
            <Grid item xs={12}>
                <div style={{height: 100}}></div>
            </Grid>
            <Grid item xs={12}>
                <FormInputCheckbox
                    name={'merch_store_enabled'}
                    defaultChecked={merch_store_enabled}
                    label={"Merch Store Enabled"}
                    validation={{}}/>
            </Grid>
            <Grid item xs={12}>
                    <FormInputText
                        label={'Merch Store URL'}
                        defaultValue={merch_store_url}
                        name='merch_store_url' />
                </Grid>
        </>

    )

}

export default BrandForm

const presetColors = [
    '#E2C044',
    '#E3170A',
    '#A9E5BB',
    '#E6ADEC',
    '#C3D350',
    '#75DDDD',
    '#F28123',
    '#FCBCB8',
    '#776274',
    '#CA054D',
    '#3ABEFF',
    '#26FFE6',
    '#CA3CFF',
    '#59A96A',
    '#EDEBA0',
    '#9B7EDE',

]

const ColorBox = ({name, defaultValue}) => {
    const {setValue, watch, register} = useForm();
    const [open, setOpen] = useState(false);
    const watchBrandColor = watch(`brand.${name}`, defaultValue);

    const onChangeColor = val => setValue(`brand.${name}`, val)
    const handleClickAway = () => {
        setOpen(false);
    };
    return (
        <div className='color-picker__container'>
            <input
                ref={register({})}
                name={`brand.${name}`}
                style={{display: 'none'}}
                defaultValue={defaultValue}
            />
            <span className={'color-box-label text-center'}>
                    {name} color:
           </span>
            <div className='color-box-container' onClick={() => setOpen(!open)}>
                <div
                    className={`${name}-color color-box`}
                    style={{backgroundColor: watchBrandColor}}
                >
                </div>
                <span style={{textTransform: 'uppercase'}}>
                     {watchBrandColor}
                </span>
            </div>
            {open && <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <SketchPicker
                        color={watchBrandColor}
                        disableAlpha={true}
                        onChange={({hex}) => onChangeColor(hex)}
                        presetColors={presetColors}
                    />
                </div>
            </ClickAwayListener>}
        </div>
    )
}