import React, {useRef, useMemo} from "react";
import debounce from 'lodash/debounce'
import {Button, DialogActions as MdDialogActions} from '@material-ui/core';
import './actions.scss';


function DialogActions({onSave, onCancel, cancelText = "Cancel", saveText = "Save", ...rest}) {
    const btnConfirm = useRef();

    const debounceOnSave = useMemo(() => debounce(async () => {
        if (!btnConfirm.current) return;
        if (btnConfirm.current.getAttribute('disabled') === 'true') return;
        btnConfirm.current.setAttribute('disabled', true);
        onSave && await onSave()
        btnConfirm.current.setAttribute('disabled', false);
    }, 200, {leading: true}), [])

    return (
        <MdDialogActions {...rest}>
            {!!onCancel && <Button
                onClick={onCancel}
                variant='contained'
                color='secondary'>
                {cancelText}
            </Button>}
            {!!onSave && <Button
                color={'primary'}
                ref={btnConfirm}
                onClick={debounceOnSave}
                variant='contained'
            >
                {saveText}
            </Button>}
        </MdDialogActions>
    )
}

export default DialogActions
