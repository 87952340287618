import React from "react";

import Box from "@material-ui/core/Box";
import {FaRegListAlt} from "react-icons/all";

function NoDataMessage({text = 'No Data', ...rest}) {
    return (
        <Box className={'no-data-message'} display={'flex'} height={'100%'} flexDirection={'column'}
             alignItems={'center'}
             justifyContent={'center'} style={{color: '#999'}} {...rest}
        >
            <FaRegListAlt style={{fontSize: 30, opacity: 0.3}}/>

            <div style={{marginTop: 4}}>
                {text}
            </div>
        </Box>
    )
}

export default NoDataMessage