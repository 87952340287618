import React, {useMemo} from "react";
import Grid from "@material-ui/core/Grid";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";

import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
import UsersAutocomplete from "../../users/forms/UsersAutocomplete";
import CardsAutoComplete from "../../cards/forms/CardsAutoComplete";
import CategoryImageUploader from "@PlaystreamENV/front-lib/src/category-image-uploader/CategoryImageUploader";

const translations = {
    required_field: "Field is required",
    sudo_error: "Only sudo can be community and only streamers can be personal"
}
export const formValidation = {

    not_empty: {
        validate: value => value.length > 0 || translations.required_field
    },
    required: {
        required: translations.required_field
    },
    enabled: {
        // required: translations.required_field
    },

};
const CategoryForm = ({category}) => {

    const {watch} = useForm();
    const categoryFormModel = watch(undefined, category);
    const published = watch("published", category.published);
    const created_by = watch("created_by", category.created_by);
    const searchByFilters = useMemo(() => {
        return ({
            streamer: created_by && created_by.id || undefined,
            community: true
        })
    }, [created_by && created_by.id]);

    // console.log("categoryFormModel",categoryFormModel)
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormInputText
                                    label={'Name'}
                                    defaultValue={category.name}
                                    name='name'
                                    validation={formValidation.required}/>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputText label={'description'} defaultValue={category.description}
                                               name='description'
                                               className={"multiline"}
                                               multiline={true}
                                               validation={{}}/>

                            </Grid>

                            <Grid item xs={12}>
                                <FormInputCheckbox name={'published'} defaultChecked={category.published}
                                                   label={"Published"}
                                                   validation={formValidation.disabled}/>
                            </Grid>

                            <Grid item xs={12}>
                                <FormInputCheckbox
                                    name={'community'}
                                    defaultChecked={category.community}
                                    label={"Community"}
                                    validation={{
                                        validate: {
                                            sudo_cannot_be_personal: value => value || (!value && created_by && created_by.email !== "sudo@playstream.gg") || translations.sudo_error,
                                            sudo_must_be_community: value => !value || (value && created_by && created_by.email === "sudo@playstream.gg") || translations.sudo_error,
                                        }
                                    }}/>
                            </Grid>

                            <Grid item xs={12}>
                                <UsersAutocomplete name={'created_by'} label={'Created by'}
                                                   defaultValue={category.created_by}
                                                   rules={formValidation.required}/>
                                {/*<FormInputText label={'created_by'}*/}
                                {/*               defaultValue={(category.created_by || {}).id}*/}
                                {/*               name='created_by'*/}
                                {/*               validation={formValidation.required}/>*/}
                            </Grid>
                            <Grid item xs={12}>
                                <CardsAutoComplete
                                    searchByFilters={searchByFilters}
                                    defaultValue={category.cards || []}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormGlobalErrorMessage/>

                            </Grid>
                        </Grid>
                    </form>

                </Grid>

                <Grid item sm={6}>
                    <CategoryImageUploader category={categoryFormModel}/>
                </Grid>

            </Grid>


        </>
    )

}

export default CategoryForm
