import React from "react";

const EndorsementStatusLabel = ({interaction}) => {
    return (
        <>

        </>
    )

}

export default EndorsementStatusLabel