import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InteractionsTable from "../../../components/entities/interactions/table/InteractionsTable";
import useQueryFilters from "../../hooks/useQueryFilters";

const InteractionsRoute = () => {
    const { user: userId = "" } = useQueryFilters();
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Interactions
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <InteractionsTable defaultFilters={{userId}}/>
            </Box>
        </>
    )
}

export default InteractionsRoute
