import React, {useEffect, useState} from 'react';
import {
    ThemeProvider as MdThemeProvider,
    createMuiTheme,
    responsiveFontSizes,
    jssPreset
} from '@material-ui/core/styles';
import {CssBaseline} from "@material-ui/core";
import {create} from "jss";
import rtl from "jss-rtl";
import {StylesProvider} from "@material-ui/core";
import {observer} from "mobx-react-lite";


const jss = create({plugins: [...jssPreset().plugins, rtl()]});


const createTheme = function (themeModel) {
    return createMuiTheme(themeModel);
    // uncomment this to enable responsive fonts
    // return responsiveFontSizes(createMuiTheme(themeModel));
}


const ThemeProvider = observer(({children, defaultTheme}) => {

    const [activeTheme, setActiveTheme] = useState(createTheme({...defaultTheme}));


    // console.log(`ThemeProvider`)
    return (
        <MdThemeProvider theme={activeTheme}>
            <StylesProvider jss={jss}>
                <CssBaseline/>
                {children}
            </StylesProvider>
        </MdThemeProvider>
    )
})

export default React.memo(ThemeProvider)
