import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";

export class TransactionsStore {

    constructor() {
    }

    @observable  _transactions = null;

    @computed get transactions() {
        return toJS(this._transactions)
    }

    @action
    getTransactions(params) {
        return adminApi.get('/transactions', {params})
    }

}
