import React, {useLayoutEffect} from 'react';
import {Switch, Route} from "react-router-dom";
import NotFoundRoute from "./all/NotFoundRoute";
import {PrivateRoute} from "@PlaystreamENV/front-lib/src/auth/routing/PrivateRoute";
import MainDrawer from "./drawer/MainDrawer";
import StreamsRoute from "./routes/app/StreamsRoute";
import TransactionsRoute from "./routes/app/TransactionsRoute";
import TicketsRoute from "./routes/app/TicketsRoute";
import GamesRoute from "./routes/app/GamesRoute";
import TestRoute from "./routes/app/TestRoute";
import DashboardRoute from "./routes/app/DashboardRoute";
import CustomizationRoute from "./routes/app/CustomizationRoute";
import UsersRoute from "./routes/app/UsersRoute";
import {useSocket} from "@PlaystreamENV/front-lib/src/socket/useSocket";
import {Toaster} from "@PlaystreamENV/front-lib/src/toast/toaster";
import {useUser} from "@PlaystreamENV/front-lib/src/auth/role/VisibleByRole";
import LabsRoute from "./routes/app/LabsRoute";
import ApplicationRoute from "./routes/app/ApplicationRoute";
import MetricsRoute from "./routes/app/MetricsRoute";
import InteractionsRoute from "./routes/app/InteractionsRoute";
import CardsRoute from "./routes/app/CardsRoute";
import CategoriesRoute from "./routes/app/CategoriesRoute";
import ZonesRoute from "./routes/app/ZonesRoute";
import AchievementsRoute from "./routes/app/AchievementsRoute";
import ReferralsRoute from "./routes/app/ReferralsRoute";
import CouponsRoute from "./routes/app/CouponsRoute";
import EventsRoute from "./routes/app/EventsRoute";
import {withAppRouterData} from "./hoc/withAppRouterData";
import BotsRoute from "./routes/app/BotsRoute";
import BonusesRoute from "./routes/app/BonusesRoute";

function AppRouter({location, match}) {

    const user = useUser();


    const {connect, isConnected, subscribe} = useSocket();
    useLayoutEffect(() => {


        connect(user.secret, () => {
            window.location.href = "/login"
        });

    }, []);

    useLayoutEffect(() => {

        if (!isConnected) return;

        const sub1 = subscribe("channel-new-challenge", ({challenge}) => {

            Toaster.create(`New Challenge for streamer: ${challenge.streamer} amount: $${challenge.noe * challenge.ppe}`, {autoClose: true})

        });

        return () => {

            sub1();
        }


    }, [isConnected]);


    return (
        <MainDrawer>
            <Switch>
                {/*<Route component={TestRoute} path='/test'/>*/}

                <PrivateRoute Component={AchievementsRoute} exact path='/achievements'/>
                <PrivateRoute Component={StreamsRoute} exact path='/streams'/>
                <PrivateRoute Component={InteractionsRoute} exact path='/interactions'/>
                <PrivateRoute Component={CardsRoute} exact path='/cards'/>
                <PrivateRoute Component={CategoriesRoute} exact path='/categories'/>
                <PrivateRoute Component={ZonesRoute} exact path='/zones'/>
                <PrivateRoute Component={TransactionsRoute} exact path='/transactions'/>
                <PrivateRoute Component={TicketsRoute} exact path='/tickets'/>
                <PrivateRoute Component={GamesRoute} exact path='/games'/>
                <PrivateRoute Component={EventsRoute} exact path='/events'/>
                <PrivateRoute Component={UsersRoute} exact path='/users'/>
                <PrivateRoute Component={CustomizationRoute} exact path='/customization'/>
                <PrivateRoute Component={ApplicationRoute} exact path='/application'/>
                <PrivateRoute Component={TestRoute} exact path='/test'/>
                <PrivateRoute Component={LabsRoute} exact path='/labs'/>
                <PrivateRoute Component={BotsRoute} exact path='/bots'/>

                <PrivateRoute Component={MetricsRoute} exact path='/metrics'/>
                <PrivateRoute Component={ReferralsRoute} exact path='/referrals'/>
                <PrivateRoute Component={BonusesRoute} exact path='/bonuses'/>
                <PrivateRoute Component={CouponsRoute} exact path='/coupons'/>

                <PrivateRoute Component={DashboardRoute} exact path='/'/>

                <Route component={NotFoundRoute} path='/'/>
            </Switch>
        </MainDrawer>
    )

}

export default withAppRouterData(AppRouter)
