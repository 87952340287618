import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        opacity: ({disabled}) => disabled ? 0.7 : 1,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: ({vertical}) => vertical ? 'start' : 'center',

        width: '100%',
    },
    icon: {
        opacity: ({showIcon}) => showIcon ? 1 : 0,
        marginRight: '10px',
    },
    contentContainer: {
        position: "relative",
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        color: 'white !important',
        textAlign: 'start',
    },
    labelSelected: {
        '&::after': {
            bottom: '1px',
            height: '2px',
            content: '""',
            left: 0,
            backgroundColor: theme.palette.secondary.main,
            // backgroundColor :"#00EFF8",
            width: '100%',
            position: 'absolute',
        },
    },
    helperText: {
        fontSize: '8px',
        textAlign: 'start',
    }
}))

const TabLabel = ({text, icon, helperText, disabled, vertical, selected}) => {
    const classes = useStyles({showIcon: !!icon, disabled, vertical, selected});
    return (
        <div className={classes.container}>
            {/*<div className={classes.icon}>*/}
            {/*    {icon ? icon : <FaLock size={24}/>}*/}
            {/*</div>*/}
            <div className={classes.contentContainer}>
                <span className={`${classes.label} ${selected && classes.labelSelected}`}>
                    {text}
                </span>
                {helperText && <span className={classes.helperText}>{helperText}</span>}
            </div>
        </div>
    )
}

export default TabLabel
