import React, {useEffect} from "react";
import PieChart from "@PlaystreamENV/front-lib/src/charts/PieChart";
import ChartLabelItem from "@PlaystreamENV/front-lib/src/charts/ChartLabelItem";
import {observer} from "mobx-react-lite";
import {Colors} from "../../../theme/Colors";
import DashboardCard from "../cards/DashboardCard";


const ChallengesByTypeChart = observer(({data}) => {

    const title = 'Cards diversity';

    const _data = (data || []).sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return 0;
    })

    const topChallenges = _data.splice(0, 10)

    const others = _data.reduce((last, obj, index) => {
        last.count += obj.count;
        return last;
    }, {card_name: "Others", count: 0});
    return (
        <>
            <DashboardCard title={title}>


                <PieChart data={[...topChallenges, others]} nameKey={'card_name'} dataKey={'count'} height={400}
                          label={({row}) => {

                              return (
                                  <ChartLabelItem key={row.card_name}
                                                  selected={false}
                                                  onClick={() => {
                                                      // setFilters(row)
                                                  }}>
                                      <span>{row.card_name}</span>
                                      <span> {row.count}</span>
                                  </ChartLabelItem>
                              )


                          }}
                          colors={getColors()}/>
            </DashboardCard>

        </>
    )
})

function getColors() {

    return Colors.challenges;
}


export default ChallengesByTypeChart
