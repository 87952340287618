import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {VscJson} from "react-icons/all";
import TransactionDialog from "../dialogs/TransactionDialog";
import Box from "@material-ui/core/Box";

const ActionsLabel = ({transaction}) => {
    const [transactionForDialog , setTransactionForDialog] = useState(null);

    return (
        <>

                <Box display='flex' alignItems='center'>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={() => {
                            setTransactionForDialog(transaction)
                        }}
                    >
                        <VscJson/>
                    </IconButton>
                </Box>
            <TransactionDialog transaction={transactionForDialog} onClose={() => {
                setTransactionForDialog(null)
            }}/>
        </>
    )

}

export default ActionsLabel