import React from "react";
import ReactJson from "react-json-view";


const GroupsLabel = ({groups}) => {

    return (
        <ReactJson theme={'threezerotwofour'} displayDataTypes={false} enableClipboard={false}
                   displayObjectSize={false} quotesOnKeys={false} displayDataTypes={false} name={null}
                   src={groups || {}}/>
    )

}

export default GroupsLabel