import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {IoMdColorPalette} from "react-icons/all";
import Box from "@material-ui/core/Box";
import {CirclePicker} from 'react-color';


export default function ColorPicker({color,label = "Color",onChangeComplete}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>

            <Button style={{backgroundColor:color , color:"#fff"}}  variant="contained"  startIcon={ <IoMdColorPalette size={28}/>} onClick={handleClick}>

                {color}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box style={{padding:15 , overflow: 'hidden'  }}>
                    <Typography id="continuous-slider" gutterBottom>
                        {label}
                    </Typography>
                    <CirclePicker color={color} triangle={'hide'} onChangeComplete={ (ev)=>{

                        handleClose();
                        onChangeComplete(ev);
                    }}/>
                </Box>
            </Popover>
        </div>
    );
}
