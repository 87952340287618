import React, {useEffect, useState} from "react";
import {Accordion, AccordionDetails} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {FaArrowUp} from "react-icons/all";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    expanded: {
        margin: '0 !important'
    }
})
// Todo use one object for all accordions status ( saving it separately now )
const DrawerAccordion = ({title, children}) => {
    const localNavigationStatus = JSON.parse(localStorage.getItem(`navigation_${title}`))
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(!!localNavigationStatus);
    const onToggleAccordion = () => {
        setIsOpen(prevState => !prevState)
    }
    useEffect(() => {
        localStorage.setItem(`navigation_${title}`, JSON.stringify(isOpen))
    }, [isOpen])
    return (
        <>
            <Accordion classes={{expanded: classes.expanded}} expanded={isOpen} onChange={onToggleAccordion}>
                <AccordionSummary expandIcon={<FaArrowUp style={{fontSize: '16px'}}/>}>
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={'inner-accordion'}>
                        {children}
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    )
}
export default DrawerAccordion
