import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {FormGlobalErrorMessage} from "@PlaystreamENV/front-lib/src/form/FormGlobalErrorMessage";
import {FormInputText} from "@PlaystreamENV/front-lib/src/form";
import SingleSelect from "@PlaystreamENV/front-lib/src/form/select/single-select/SingleSelect";
import ChipsSelect from "@PlaystreamENV/front-lib/src/form/select/chips-select/ChipsSelect";
import UsersAutocomplete from "../../users/forms/UsersAutocomplete";
import {formValidation} from "../../cards/forms/CardForm";
import FormInputCheckbox from "@PlaystreamENV/front-lib/src/form/FormInputCheckbox";
import {useForm} from "@PlaystreamENV/front-lib/src/form/context";
const translations = {
    required_field: "Field is required",
    sudo_error: "Only sudo can be community and only streamers can be personal",
    only_one_personal_category: "Personal card can be attached to only one personal category"
}

function CouponForm({coupon}) {
    const {watch} = useForm();
    const created_by = watch("created_by", coupon.type);
    return (
        <Box>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormInputText
                            label='Coupon Key'
                            name='key'
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                            defaultValue={coupon.key}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Description'}
                            name='description'
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                            defaultValue={coupon.description}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Discount'}
                            name='discount'
                            disabled={true}
                            type='number'
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                            defaultValue={coupon.discount}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputText
                            label={'Usage'}
                            name='usage'
                            type='number'
                            InputLabelProps={{shrink: true}}
                            validation={{required: 'Required Field'}}
                            defaultValue={coupon.usage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <SingleSelect
                                options={[
                                    {id: 'legendary'},
                                    {id: 'epic'},
                                    {id: 'rare'},
                                    {id: 'common'},
                                ]}
                                defaultValue={coupon.rarity}
                                rules={formValidation.required}
                                label={'Rarity'}
                                name='rarity'
                                prop='id'
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <UsersAutocomplete name={'created_by'} label={'Created by'}
                                           defaultValue={coupon.created_by}
                                           rules={formValidation.required}/>

                    </Grid>
                    <Grid item xs={12}>
                        <FormInputCheckbox name={'published'} defaultChecked={coupon.published}
                                           label={"Published"}
                                           validation={{}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInputCheckbox name={'community'} defaultChecked={coupon.community}
                                           label={"Community"}
                                           validation={{
                                               validate: {
                                                   sudo_cannot_be_personal: value => value || (!value && created_by && created_by.email !== "sudo@playstream.gg") || translations.sudo_error,
                                                   sudo_must_be_community: value => !value || (value && created_by && created_by.email === "sudo@playstream.gg") || translations.sudo_error,
                                               }
                                           }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ChipsSelect
                            name='features'
                            rules={{
                                validate: {
                                    not_empty: val => {
                                        if (!val.length) {
                                            return 'Please have at least one option.'
                                        }
                                    }
                                }
                            }}
                            defaultValues={coupon.features}

                        />
                    </Grid>
                </Grid>
                <FormGlobalErrorMessage/>
            </form>
        </Box>
    )
}

export default CouponForm
