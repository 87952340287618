import React from 'react'
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LoginForm from "@PlaystreamENV/front-lib/src/auth/forms/LoginForm";
import {withRouter} from "react-router-dom";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";

function LoginRoute({history}) {

    const goTo = () => {
        history.push(`/auth/register`)
    }

    return (
        <>

            <PageTitleNavBar>
                <Typography variant="h6" noWrap>
                    Sign In
                </Typography>
            </PageTitleNavBar>
            <Box display='flex' alignItems={'center'} justifyContent='center'
                 style={{position: 'relative', width: '100%', height: 'calc(100vh - 280px)'}}>
                <Box className={'login-form-container'} style={{maxWidth: 450, width: '100%'}}>

                    <Box mb={2}>
                        <Typography className={'text-center'} variant={'h5'}>Playstream</Typography>
                    </Box>

                    <Typography variant="body2" className={'text-muted'}>
                        Complete this form with your login credentials information
                    </Typography>
                    <Box mt={2}>
                        <LoginForm onLoginCompleted={() => {
                            history.push(`/`)
                        }}/>
                    </Box>
                    {/*<Box mt={10} className={'text-center'}>*/}
                    {/*    Don’t have an account? <Link component="button" onClick={goTo}> Get Started</Link>*/}
                    {/*</Box>*/}
                </Box>
            </Box>

        </>

    )
}

export default withRouter(LoginRoute)
