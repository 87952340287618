import React from "react";
import NoDataMessage from "@PlaystreamENV/front-lib/src/widgets/NoDataMessage";
import Box from "@material-ui/core/Box";

const NoDataContainer = ({data, children}) => {
    return (
        <>

            {!!data && data.length === 0 && <Box height={150}>
                <NoDataMessage/>
            </Box>}
            {!!data && !!data.length && children}

        </>
    )

}

export default NoDataContainer