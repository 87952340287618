import React from "react";
import {PageTitleNavBar} from "../../navbar/PageTitleNavBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {observer} from "mobx-react-lite";
import StreamsTable from "../../../components/entities/streams/table/StreamsTable";
import useQueryFilters from "../../hooks/useQueryFilters";

const StreamsRoute = observer(({}) => {
    const {user: userId = ""} = useQueryFilters();
    return (
        <>
            <PageTitleNavBar>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="h6" noWrap>
                        Streams
                    </Typography>
                </Box>
            </PageTitleNavBar>
            <Box mt={4} px={3}>
                <StreamsTable defaultFilters={{userId}}/>
            </Box>
        </>
    )
})

export default StreamsRoute
