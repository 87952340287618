import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";

import Tooltip from "@material-ui/core/Tooltip";
import TableView from "@PlaystreamENV/front-lib/src/table/TableView";
import GuestIdLabel from "../entities/interactions/labels/GuestIdLabel";
import InteractionCardLabel from "../entities/interactions/labels/InteractionCardLabel";
import LevelLabel from "../entities/interactions/labels/LevelLabel";
import StatusLabel from "../entities/interactions/labels/StatusLabel";
import ProgressionLabel from "../entities/interactions/labels/ProgressionLabel";
import YesNoLabel from "../entities/games/labels/YesNoLabel";
import Box from "@material-ui/core/Box";
import ButtonWithConfirm from "../entities/users/forms/ButtonWithConfirm";
import {FaMinus, FaPlus} from "react-icons/all";


const currencies = {
    "USD": "$"
}
const LiveChallengesTable = observer(({stream}) => {
        const {adminStore} = useStores();

        const updateChallenge = async (progression, step = 1) => {
            const type = progression.interaction.card.type;
            if (type === "ai") {
                console.log("updateAi", progression.id, type, step);
                await adminStore.updateStreamerAi(stream.streamer.id, progression.id, step)

            } else if (type === "contract") {
                console.log("updateContract", progression.id, type, step)
                await adminStore.updateStreamerContract(stream.streamer.id, progression.id, step)
            }
        }

        return (
            <>
                <TableView
                    rowProps={{hover: true}}
                    rows={(stream.progression || []).sort((a, b) => {
                        if (a.createdAt > b.createdAt)
                            return -1;
                        return 1;
                    })}
                    columns={[

                        {
                            key: 'id',
                            title: 'ID',

                            cell: ({row}) => <>
                                {row.id}
                            </>
                        },

                        {
                            key: 'guest_username',
                            title: 'Guest',

                            cell: ({row}) => <>
                                <GuestIdLabel interaction={row.interaction}/>
                            </>
                        },
                        {
                            key: 'card',
                            title: 'card',
                            style: {
                                width: 190
                            },
                            cell: ({row}) => <>
                                <InteractionCardLabel interaction={row.interaction}/>
                            </>
                        },
                        {
                            key: 'amount',
                            title: 'amount',

                            cell: ({row}) => <>

                                <Tooltip title={'Gross amount'} arrow placement={"top"}>
                                    <span className={'primary-color'}>
                                        <span>{currencies[row.interaction.currency]}</span>{row.interaction.gross_amount}</span>
                                </Tooltip>
                                <div>
                                    <Tooltip title={'Price Per Event'} arrow placement={"top"}>
                                    <span
                                        className={'text-muted'}><span>{currencies[row.interaction.currency]}</span>{row.interaction.ppe}
                                    </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Tooltip title={'XP Points'} arrow placement={"top"}>
                                    <span
                                        className={'text-muted'}><span>xp</span>{row.interaction.exp_points}
                                    </span>
                                    </Tooltip>
                                </div>
                            </>
                        },
                        {
                            key: 'level',
                            title: 'level',
                            cell: ({row}) => <LevelLabel interaction={row.interaction}/>
                        },
                        // {
                        //     key: 'status',
                        //     title: 'status',
                        //
                        //     cell: ({row}) => <>
                        //         <StatusLabel interaction={row.interaction}/>
                        //     </>
                        // },

                        {
                            key: 'progression',
                            style: {
                                width: 120
                            },
                            title: 'Progression',

                            cell: ({row}) => <>
                                <ProgressionLabel interaction={{...row.interaction, progression: row}}/>
                            </>
                        },


                        //
                        // {
                        //     key: 'extras',
                        //     title: 'Meta',
                        //
                        //     cell: ({row}) => <div style={{width: 100}}>
                        //         <div>
                        //             Secret: <YesNoLabel value={row.is_secret}/>
                        //         </div>
                        //     </div>
                        // },


                        {
                            key: 'createdAt',
                            title: 'createdAt',

                            cell: ({row}) => <>
                                <DateTimeLabel value={row.createdAt}/>
                            </>
                        },
                        {
                            cell: ({row}) => {

                                const isActive = row.step === row.steps_to_complete;
                                return <Box display={"flex"}>
                                    <Box mr={2}>
                                        <ButtonWithConfirm size={"small"} color={'secondary'}
                                                           disabled={row.step === 0 || row.step === row.steps_to_complete}
                                                           onConfirm={() => {
                                                               updateChallenge(row, -1)
                                                           }}>
                                            <FaMinus/>

                                        </ButtonWithConfirm>
                                    </Box>

                                    <ButtonWithConfirm size={"small"} color={'secondary'}
                                                       disabled={row.step === row.steps_to_complete}
                                                       onConfirm={() => {
                                                           updateChallenge(row, 1)
                                                       }}>
                                        <FaPlus/>
                                    </ButtonWithConfirm>
                                </Box>
                            }
                        }
                    ]}
                />

            </>
        )
    }
)

export default LiveChallengesTable
