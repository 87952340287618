import React, {useEffect} from "react";

import {observer} from "mobx-react-lite";

import LineChart from "@PlaystreamENV/front-lib/src/charts/LineChart";
import {Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import DashboardCard from "../../dashboard/cards/DashboardCard";
import colors from "./colors";
import {Box, Dialog} from "@material-ui/core";
import NoDataContainer from "./NoDataContainer";
import ReactJson from "react-json-view";

const PredictionSnapshotsScoreChart = observer(({syncId, data, snapshotsLogs}) => {

    const title = 'Prediction snapshots score';


    const [value, setValue] = React.useState(null);
    const [activeJson, setActiveJson] = React.useState(null);


    useEffect(() => {

        if (!data) return;
        const _data = data.map(r => {
            const date = new Date(r.createdAt);
            const date_text = date.toLocaleDateString('en', {day: 'numeric', month: 'short', weekday: 'long'});
            const time_text = date.toLocaleTimeString('en', {
                hour12: false,
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            });

            const dimensions = r.data.predictions.reduce((last, obj, index) => {

                const scores = (obj.compareResultArray || []).reduce((last, obj, index) => {
                    last[index] = obj;
                    return last;
                }, {});


                last[index] = scores;

                return last;
            }, {});


            const snapshots = ((snapshotsLogs || []).filter(s => {
                return s.job_id === r.job_id
            })[0])

            return {
                createdAt: `${date_text}  ${time_text}`,
                dimensions,
                snapshots: snapshots ? snapshots.data.snapshots : null

            }
        })

        setValue(_data)

    }, [data, snapshotsLogs])


    // const lines = [1, 2, 3].map(i => <Line key={`${i}-lolo`} dataKey={`dimensions.${i}.0.snapshotScore`}
    //                                                       stroke={colors[i % 3]} isAnimationActive={false}
    //                                                       dot={{
    //                                                           radius: 1,
    //                                                           strokeWidth: 0,
    //                                                           fill: 'transparent'
    //                                                       }}/>);

    return (
        <>
            <DashboardCard title={title}>
                <NoDataContainer data={data}>
                    <ResponsiveContainer width={'100%'} height={150}>
                        <LineChart syncId={syncId} data={value} onClick={(item) => {
                            const {activeLabel, activePayload} = item;
                            const payload = activePayload[0].payload;
                            setActiveJson({payload});
                        }} margin={{
                            top: 10, right: 10, left: -15, bottom: 10,
                        }}>
                            {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                            <XAxis hide={true} dataKey="createdAt"/>
                            <YAxis hide={false}/>
                            <Tooltip wrapperStyle={{zIndex: 99999}} allowEscapeViewBox={{x: true, y: true}}
                                     active={true} content={<SnapshotsScoreTooltip/>}/>
                            {/*<Legend />*/}

                            <Line dataKey={'dimensions.0.1.snapshotScore'} stroke={colors[1]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.0.2.snapshotScore'} stroke={colors[2]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>


                            <Line dataKey={'dimensions.1.0.snapshotScore'} stroke={colors[0]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>

                            <Line dataKey={'dimensions.1.1.snapshotScore'} stroke={colors[1]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>
                            <Line dataKey={'dimensions.1.2.snapshotScore'} stroke={colors[2]} isAnimationActive={false}
                                  dot={{radius: 1, strokeWidth: 0, fill: 'transparent'}}/>

                        </LineChart>
                    </ResponsiveContainer>
                </NoDataContainer>
            </DashboardCard>

            <Dialog open={!!activeJson} maxWidth={"lg"} onClose={() => {
                setActiveJson(null)
            }}>
                <ReactJson theme={'threezerotwofour'} displayDataTypes={false} enableClipboard={true}
                           displayObjectSize={false}
                           quotesOnKeys={false} displayDataTypes={false} name={null} src={activeJson}/>
            </Dialog>

        </>
    )
})

const SnapshotsScoreTooltip = React.memo(({active, payload = {}, label}) => {

    const groups = payload && payload.length ? payload[0].payload.snapshots : [];
    const dimensions = payload && payload.length ? payload[0].payload.dimensions : [];
    console.log("SnapshotsScoreTooltip", groups)
    return <div className={'recharts-custom-tooltip'}>
        <div>{label} </div>

        <div>
            {(groups || []).map((snapshots, index) => {
                return snapshots.map((snapshot, index) => {
                    // console.log("image", snapshot)
                    return <Box key={`img-${index}`}>
                        {/*{dimensions && dimensions[0] && dimensions[0][index] && <> {dimensions[0][index].snapshotScore.toFixed(2)} vs {dimensions[0][index].imgScore.toFixed(2)}</>}*/}
                        <br/>
                        <img className={'snapshot-img'} src={`data:image/png;base64,${snapshot}`}/>
                    </Box>
                });

            })}
        </div>

    </div>
}, ((prevProps, nextProps) => {

    return false
}))


export default React.memo(PredictionSnapshotsScoreChart)
