import {action, computed, observable, toJS} from "mobx";
import adminApi from "../../../services/adminApi";

export class CouponsStore {

    constructor() {
    }


    @action
    getCoupons(params) {
        return adminApi.get('/coupons', {params})
    }

    @action
    getClaimedCoupons(params) {
        return adminApi.get('/claimed-coupons', {params})
    }

    @action
    async createCoupon(coupon) {

        return await adminApi.post('/coupons', {
            coupon: convertToServerCoupon(coupon)
        })
    }

    @action
    async editCoupon(coupon) {

        return await adminApi.patch(`/coupons/${coupon.id}`, {
            coupon: convertToServerCoupon(coupon)
        })
    }

}

const convertToServerCoupon = (coupon) => {
    return {
        ...coupon,
        created_by: coupon.created_by.id,
    }
}