import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    streamer: {
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
        },
    },

}));

function StreamStreamerLabel({stream}) {
    const classes = useStyles();
    const {streamer} = stream;
    return (
        <span className={classes.streamer}>{streamer.email} </span>
    )
}

export default StreamStreamerLabel;
