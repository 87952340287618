import React from 'react'
import {useTheme} from "@material-ui/styles";
import Fade from "@material-ui/core/Fade";
import {useForm} from "./context";


function FormInputErrorMessage({name = ''}) {
    const theme = useTheme();
    const {getError} = useForm();
    const error = getError(name);
    // console.log("FormInputErrorMessage", error)
    return (
        !!error ? <Fade in={!!error}>
            <h3
                style={{margin: 0, color: theme.palette.error.main, fontSize: '14px', marginTop: '1px'}}>
                {error.message}</h3>
        </Fade> : null
    )
}


export {FormInputErrorMessage};
