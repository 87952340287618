import React, {useState} from "react";
import {useStores} from "../../mbox/hooks/useStores";
import {useForm, withForm} from "../../form/context";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import {FormInputText} from "../../form";
import {MdEmail} from "react-icons/all";
import {FormInputPassword} from "../../form/FormInputPassword";
import {FormSubmitButton} from "../../form/FormSubmitButton";
import {Link} from "react-router-dom";
import {FormGlobalErrorMessage} from "../../form/FormGlobalErrorMessage";
import FormInputCheckbox from "../../form/FormInputCheckbox";
import Box from "@material-ui/core/Box";
import RequiredLabel from "../../form/labels/RequiredLabel";
import LocalizedText from "../../l10n/LocalizedText";
import useLanguage from "@PlaystreamENV/front-lib/src/l10n/hooks/useLanguage";

function LoginForm({onLoginCompleted}) {
    const {translate} = useLanguage()
    const {authStore} = useStores();

    const {handleSubmit} = useForm()
    // const nextUrl = history.location.state ? history.location.state.from : "/";
    const nextUrl = "/";
    // const [rememberMe, setRememberMe] = useState(true);
    // console.log("LoginForm - nextUrl", nextUrl)

    const onSubmit = async ({email, password}) => {
        // console.log(`LoginForm doing login for ${email} rememberMe ${rememberMe}`);

        // localStorage.setItem("remember-me", rememberMe.toString())

        await authStore.doLogin({email, password});

        onLoginCompleted && onLoginCompleted();

    };
    const lastEmail = localStorage.getItem('last-email') || "";
    // const rememberMe = JSON.parse(localStorage.getItem('remember-me'))

    // const autoComplete = rememberMe ? "new-password" : '';

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

            <Grid container spacing={4}>
                <Grid item xs={12}>

                    <FormInputText
                        placeholder={translate('form_field_email_placeholder')}
                        label={<RequiredLabel><LocalizedText id='form_field_email_label'/></RequiredLabel>}
                        name='email'
                        variant={"outlined"}
                        InputLabelProps={{shrink: true}}
                        defaultValue={lastEmail}
                        startAdornment={<InputAdornment position="start"><MdEmail/></InputAdornment>}
                        validation={{
                            required: translate('form_validation_required'),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: translate('form_validation_email')
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>

                    <FormInputPassword
                        name='password'
                        placeholder={translate('form_field_password_placeholder')}
                        label={<RequiredLabel><LocalizedText id='form_field_password_label'/></RequiredLabel>}
                        variant={"outlined"}
                        InputLabelProps={{shrink: true}}
                        validation={{
                            required: translate('form_validation_required'),
                        }}/>
                </Grid>
                <Grid item xs={12} style={{paddingTop: 0}}>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>

                        {/*<span/>*/}
                        <Link to='/forgot-password' className='btn-forgot-password text-muted'
                              style={{textDecoration: 'none'}}>
                            <LocalizedText id='auth_login_forgot_password'/>
                        </Link>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                    <div style={{width: 200, margin: '0 auto'}}>
                        <FormSubmitButton color={'primary'} text={translate('auth_login_text')}/>
                    </div>

                </Grid>
            </Grid>
            <FormGlobalErrorMessage/>
        </form>
    )


}

export default withForm(LoginForm)
