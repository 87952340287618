import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import {
    FaPiggyBank,
    RiUserSettingsFill,
    GrAchievement,
    VscReferences,
    SiQzone, FiCreditCard, CgScreen, FaPeopleCarry
} from "react-icons/all";
import UserTabsDialog from "../dialogs/UserTabsDialog";
import EditUserDialog from "../dialogs/EditUserDialog";
import UserPersonalAchievementsDialog from "../dialogs/UserPersonalAchievementsDialog";
import ShowForDevOnly from "@PlaystreamENV/front-lib/src/debug/ShowForDevOnly";
import {useHistory} from "react-router-dom";
import {Tooltip} from "@material-ui/core";

const UserActions = ({
                         streamer,
                         children,
                         showUserDialog = true,
                         showOrdersDialog = true,
                         showAchievementsDialog = true,
                         showReferralDialog = true,
                         onDeleted,
                         onSaved
                     }) => {
    const {push} = useHistory();
    const [rowWithAchievements, setRowWithAchievements] = useState(null);
    const [activeRow, setActiveRow] = useState(null);
    const [rowToEdit, setRowToEdit] = useState(null);

    return (
        <>
            <Box display='flex' alignItems='center'>
                {children}
                {showUserDialog && <Tooltip title={'User Settings'} placement="top" arrow>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={() => setRowToEdit(streamer)}>
                        <RiUserSettingsFill size={27}/>
                    </IconButton>
                </Tooltip>
                }
                <Tooltip title='Interactions' placement="top" arrow>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => push(`/interactions?user=${streamer.id}`)}>
                        <FaPeopleCarry size={27}/>
                    </IconButton>
                </Tooltip>
                {showOrdersDialog && <Tooltip title={'User Payments'} placement="top" arrow>
                    <IconButton
                        key="dashboard"
                        aria-label="Close"
                        edge="start"
                        color="inherit"
                        onClick={() => setActiveRow(streamer)}
                    >
                        <FaPiggyBank size={27}/>
                    </IconButton>
                </Tooltip>
                }
                {showAchievementsDialog && <Tooltip title='Achievements' placement="top" arrow>
                    <IconButton
                        key="achievements"
                        aria-label="Close"
                        edge="start"
                        color="inherit"
                        onClick={() => setRowWithAchievements(streamer)}
                    >
                        <GrAchievement className={'gr-trophy-icon'} size={27}/>
                    </IconButton>
                </Tooltip>}

                {showReferralDialog && <Tooltip title='Referrals' placement="top" arrow>
                    <IconButton
                        key="referrals"
                        aria-label="Close"
                        edge="start"
                        color="inherit"
                        onClick={() => push(`/referrals?source=${streamer.id}`)}>

                        <VscReferences size={27}/>
                    </IconButton>
                </Tooltip>}
                <Tooltip title='Zone' placement="top" arrow>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => push(`/zones?user=${streamer.id}`)}>
                        <SiQzone size={27}/>
                    </IconButton>
                </Tooltip>

                <Tooltip title='Cards' placement="top" arrow>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => push(`/cards?user=${streamer.id}`)}>
                        <FiCreditCard size={27}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Streams' placement="top" arrow>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => push(`/streams?user=${streamer.id}`)}>
                        <CgScreen size={27}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <UserTabsDialog user={activeRow} onClose={() => setActiveRow(null)}/>
            <EditUserDialog user={rowToEdit} onDeleted={onDeleted} onClose={() => {
                setRowToEdit(null)
                onSaved && onSaved();
            }}/>
            <UserPersonalAchievementsDialog user={rowWithAchievements} onClose={() => {
                setRowWithAchievements(null)
            }}/>

        </>

    )

}

export default UserActions
