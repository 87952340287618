import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import Table from "@PlaystreamENV/front-lib/src/table/Table";
import DateTimeLabel from "@PlaystreamENV/front-lib/src/widgets/DateTimeLabel";
import RoleLabel from "@PlaystreamENV/front-lib/src/auth/role/RoleLabel";
import StreamingPlatformLabel from "../streams/labels/StreamingPlatformLabel";
import ChallengePageLabel from "../streams/labels/ChallengePageLabel";
import StreamerVideosPageLabel from "../streams/labels/StreamerVideosPageLabel";
import UserActions from "./actions/UserActions";
import {useStores} from "@PlaystreamENV/front-lib/src/mbox/hooks/useStores";
import {FaFacebook, FaTwitch, FaYoutube} from "react-icons/all";
import ReferralLabel from "./labels/ReferralLabel";

function UsersTable({defaultFilters = {}}) {
    const {userId = "", email = ""} = defaultFilters;
    const {usersStore} = useStores();
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const filtersOptions = [
        {
            type: 'text',
            name: "id",
            label: "User-ID",
            defaultValue: userId,
            style: {width: '200px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "name",
            label: "Username",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
        },
        {
            type: 'text',
            name: "email",
            label: "Email",
            defaultValue: email,
            style: {width: '200px'},
            rules: {required: false},
        },
        {
            type: 'select',
            name: "partnership",
            label: "Partnership",
            defaultValue: '',
            style: {width: '200px'},
            rules: {required: false},
            options: [
                {label: "Streamer", value: 'streamer'},
                {label: "Affiliate", value: 'affiliate'},
                {label: "Partner", value: 'partner'},
            ]
        },
    ]

    const getUsers = async (params) => {
        const {data} = await usersStore.getUsers(params)
        return {data: data.users, count: data.count};
    }
    return (
        <Table
            rowProps={{hover: true}}
            loadData={getUsers}
            loadCounter={lastUpdated.getTime()}
            columns={[
                {
                    sortable: false,
                    title: "Actions",
                    cell: ({row}) => <>
                        <UserActions streamer={row} onSaved={() => {
                            setLastUpdated(new Date());
                        }} onDeleted={() => {
                            setLastUpdated(new Date());
                        }}/>
                    </>
                },
                {
                    key: 'id',
                    title: 'User ID',
                    style: {
                        width: 370,
                    },
                    cell: ({row}) => <Box>
                        {/*<Clipboard text={row.id} btnText='ID' tooltip={row.id}/>*/}
                        {row.id}
                    </Box>
                },
                {
                    key: 'email',
                    title: 'Email',
                    cell: ({row}) => <>

                        {row.email}
                    </>
                },
                {
                    key: 'username',
                    title: 'Username',
                    cell: ({row}) => <>

                        {row.username}
                    </>
                },
                {
                    key: 'streaming_platform_username',
                    title: 'platform username',
                    cell: ({row}) => <>
                        {row.streaming_platform_username}
                    </>
                },
                {
                    sortable: false,
                    key: 'social',
                    title: "Social",
                    cell: ({row}) =>
                        <div style={{width: '75px', display: 'flex', justifyContent: 'space-between'}}>
                            <FaTwitch style={{color: row.twitch_id ? '#673AB7' : 'gray'}}/>
                            <FaFacebook style={{color: row.facebook_id ? '#3b5998' : 'gray'}}/>
                            <FaYoutube style={{color: row.youtube_id ? '#FF0000' : 'gray'}}/>
                        </div>
                },
                {
                    sortable: false,
                    key: 'name',
                    title: 'Name',
                    cell: ({row}) => <>
                        {row.firstname} {row.lastname}
                    </>
                },
                {
                    sortable: false,
                    key: 'links',
                    title: 'Links',
                    cell: ({row}) => <>
                        <Box className={'streamer-links'}>
                            <ChallengePageLabel username={row.username}/>

                            <Box my={1}>
                                <StreamingPlatformLabel username={row.streaming_platform_username}
                                                        streamingPlatform={row.streaming_platform}/>


                            </Box>
                            <StreamerVideosPageLabel username={row.streaming_platform_username}
                                                     streamingPlatform={row.streaming_platform}/>
                        </Box>
                    </>
                },
                {
                    key: 'followers_count',
                    title: 'Followers',
                    cell: ({row}) => <>
                        {row.followers_count}
                    </>
                },


                {
                    key: 'country',
                    title: 'Country',
                    cell: ({row}) => <>

                        {row.country}
                    </>
                },

                {
                    key: 'partnership',
                    title: 'Partnership',
                    cell: ({row}) => <>
                        {row.partnership}
                    </>
                },
                {
                    key: 'paypal_email',
                    title: 'Paypal',
                    cell: ({row}) => <>

                        {row.paypal_email}
                    </>
                },

                {
                    sortable: false,
                    key: 'stats',
                    title: 'Stats',
                    cell: ({row}) => <Box style={{width: 140}}>

                        <Box>
                            Viewers: {row.avg_viewers}
                        </Box>

                        <Box>
                            Donations: {row.avg_donations}
                        </Box>
                        <Box>
                            Role: <RoleLabel user={row}/>
                        </Box>
                        <Box>
                            <ReferralLabel user={row}/>
                        </Box>
                    </Box>
                },
                {
                    key: 'createdAt',
                    title: 'Created At',
                    cell: ({row}) => <>

                        <DateTimeLabel value={row.createdAt}/>
                    </>
                },
            ]}
            filtersOptions={filtersOptions}/>
    )
}

export default UsersTable
